<template>
    <v-dialog v-model="modelValue" @update:model-value="(isOpen) => hadlerCloseDialog(isOpen)" width="auto">
        <v-card class="dialog__wrapper">

            <!-- Крутилка загрузки -->
            <span class="progress-circular" v-if="isShowLoadingData">
                <v-progress-circular
                indeterminate 
                bg-color="var(--bg-color-white)" 
                color="var(--bg-color-default)"
                :size="60" 
                :width="6"
                ></v-progress-circular>
            </span>
            <form class="dialog__form">

               <!-- Превью -->
               <div class="dialog__form--preview-block">
                    <input id="preview-image" v-show="false"/>
                    <label 
                    class="preview-image" 
                    for="preview-image"
                    >
                        <v-icon 
                        icon="mdi-water-outline" 
                        color="var(--color-default)"
                        size="80px"
                        ></v-icon>
                    </label>
                    <h1 class="preview-name">{{ `Процедура ${id}` }}</h1>
                </div>

                <!-- Общие данные -->
                <div class="dialog__form--general-data">
                    <v-card-title class="data-title">Общие данные</v-card-title>

                    <v-table class="procedure-list__wrapper" :density="'comfortable'" fixed-header>
                        <thead>
                            <tr>
                                <th class="text-left th"></th>
                                <th class="text-left th"></th>
                            </tr>
                        </thead>
                        <tbody class="tbody">
                            <!-- НАЗВАНИЕ ПРЕПАРАТА -->
                            <tr class="row-hover">
                                <td class="list-item" style="border-top: 1px solid rgba(128, 128, 128, 0.316);">
                                    Название препарата
                                </td>
                                <td class="list-item" style="border-top: 1px solid rgba(128, 128, 128, 0.316);">
                                    {{ drugName }}
                                </td>
                            </tr>

                            <!-- КОЛИЧЕСТВО ПРЕПАРАТА -->
                            <tr class="row-hover">
                                <td class="list-item">Количество препарата</td>
                                <td class="list-item">{{ drugVolume }} капель</td>
                            </tr>

                            <!-- ИНИЦИАЛЫ ПАЦИЕНТА -->
                            <tr class="row-hover">
                                <td class="list-item">ФИО пациента</td>
                                <td class="list-item">{{ patientName }}</td>
                            </tr>

                            <!-- ОЖИДАЕМАЯ ПРОДОЛЖИТЕЛЬНОСТЬ -->
                            <tr class="row-hover">
                                <td class="list-item">Ожидаемая продолжительность</td>
                                <td class="list-item">{{ expectedDuration }} сек.</td>
                            </tr>

                            <!-- НАЧАЛО ПРОВЕДЕНИЯ -->
                            <tr class="row-hover">
                                <td class="list-item">Начало проведения</td>
                                <td class="list-item">{{ store.timestampFormat((started * 1000), 'HH:mm / DD.MM.YYYY') }}</td>
                            </tr>

                            <!-- КОНЕЦ ПРОВЕДЕНИЯ -->
                            <tr class="row-hover">
                                <td class="list-item">Конец проведения</td>
                                <td class="list-item">{{ ended ? store.timestampFormat((ended * 1000), 'HH:mm / DD.MM.YYYY') : "-"}}</td>
                            </tr>

                            <!-- ОБЩАЯ ПРОДОЛЖИТЕЛЬНОСТЬ -->
                            <tr class="row-hover">
                                <td class="list-item">Общая продолжительность</td>
                                <td class="list-item">{{ actualDuration ?? 0 }} сек.</td>
                            </tr>

                            <!-- ИДЕНТИФИКАТОР УСТРОЙСТВА -->
                            <tr class="row-hover">
                                <td class="list-item">Идентификатор устройства</td>
                                <td class="list-item">{{ idropperId }}</td>
                            </tr>

                            <!-- ОТВЕТСТВЕННЫЙ -->
                            <tr class="row-hover">
                                <td class="list-item">Ответственный</td>
                                <td class="list-item">{{ responsible }}</td>
                            </tr>

                            <!-- БОЛЬНИЦА -->
                            <tr class="row-hover">
                                <td class="list-item">Больница</td>
                                <td class="list-item">{{ hospitalName }}</td>
                            </tr>

                            <!-- ОТДЕЛЕНИЕ -->
                            <tr class="row-hover">
                                <td class="list-item">Отделение</td>
                                <td class="list-item">{{ departmentName }}</td>
                            </tr>

                            <!-- ПАЛАТА -->
                            <tr class="row-hover">
                                <td class="list-item">Палата</td>
                                <td class="list-item">{{ hospitalRoomName }}</td>
                            </tr>

                            <!-- КОЙКА -->
                            <tr class="row-hover">
                                <td class="list-item" style="border-bottom: 1px solid rgba(128, 128, 128, 0.316);">
                                    Койка
                                </td>
                                <td class="list-item" style="border-bottom: 1px solid rgba(128, 128, 128, 0.316);">
                                    {{ hospitalBedName }}
                                </td>
                            </tr>

                        </tbody>
                    </v-table>

                </div>

            </form>
            <div class="dialog__actions">
                <!-- Закрыть -->
                <v-btn 
                variant="outlined"
                color="red" 
                @click="handlerClose">
                    Закрыть
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ProcedureClient } from '../../types/procedureType';
import { getProcedureByID } from '../../api/superuser/proceduresApi';
import { getProcedureByID__chief } from '../../api/hospitalChief/proceduresApi';
import { ref, defineModel, defineEmits, watch, onMounted } from 'vue';
import useMainStore from '../../store/mainStore';
import { useRoute } from 'vue-router';
import useAuthStore from '../../store/authStore';

const store = useMainStore();
const authStore = useAuthStore();
const route = useRoute();

const modelValue = defineModel<boolean>();
const emit = defineEmits<{
    close: [],
}>();

// ============================  DATA  ============================
const isShowLoadingData = ref<boolean>(false);
const id = ref<null | number>(0);
const patientName = ref<string | null>(null);
const drugName = ref<string | null>(null);
const drugVolume = ref<number | null>(null);
const expectedDuration = ref<number | null>(null);
const started = ref<number>(0);
const ended = ref<number>(0);
const actualDuration = ref<number | null>(null);
const idropperId = ref<number | null>(null);
const responsible = ref<string | null>(null);
const hospitalName = ref<string | null>(null);
const departmentName = ref<string | null>(null);
const hospitalRoomName = ref<string | null>(null);
const hospitalBedName = ref<string | null>(null);


// ============================  METHODS  ============================
// Функция разбивает ключи получаемого объекта по реактивным переменным для отображаения их в полях ввода
async function parsingObject(procedure: ProcedureClient) {
    try {
        id.value = procedure.id;
        patientName.value = procedure.patientName;
        drugName.value = procedure.drugName;
        drugVolume.value = procedure.drugVolume;
        expectedDuration.value = procedure.expectedDuration;
        started.value = procedure.started ?? 0;
        ended.value = procedure.ended ?? 0;
        actualDuration.value = procedure.actualDuration;
        idropperId.value = procedure.idropper.vendorId;
        responsible.value = procedure.user.fullName;
        hospitalName.value = procedure.hospital.name;
        departmentName.value = procedure.department.name;
        hospitalRoomName.value = procedure.hospitalRoom.name;
        hospitalBedName.value = procedure.hospitalBed.name;

    } catch (err) {
        throw new Error(`components/employees/dialogComp.vue: parsingObject  => ${err}`);
    }
}

// Обработчик закрытия формы
function handlerClose() {
    emit('close');
    id.value = null;
    patientName.value = null;
    drugName.value = null;
    drugVolume.value = null;
    expectedDuration.value = null;
    started.value = 0;
    ended.value = 0;
    actualDuration.value = null;
    idropperId.value = null;
    responsible.value = null;
    hospitalName.value = null;
    departmentName.value = null;
    hospitalRoomName.value = null;
    hospitalBedName.value = null;
}

// Выполняется когда нажимаем на бэкграунд модального окна
function hadlerCloseDialog(isOpen: boolean) {
    if(isOpen === false) {
        handlerClose();
    }
}

// Получение процедуры по её ID
async function getProcedureByIDGeneral(procedureId: number) {
    try {
        // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
        if(authStore.isSuperUser === true) {
            return await getProcedureByID(procedureId);
        }
        // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
        return await getProcedureByID__chief(procedureId);
    } catch (err) {
        throw new Error(`components/procedure/procedureDialogComp.vue: getProcedureByID  => ${err}`)
    }
} 
// ======================================  WATCH  ======================================

// Получение данных выбранного объекта в списке
watch(() => route.query['select-procedure'], async (procedureId) => {
    try {
        isShowLoadingData.value = true;
        if(typeof procedureId === 'string' && +procedureId > 0) {
            const procedure = await getProcedureByIDGeneral(+procedureId);
            // Заполняем поля формы при открытии информации о процедуре
            await parsingObject(procedure);
        }
    } catch (err) {
        throw new Error(`components/procedure/procedureDialogComp.vue: watch[fetch procedure]  => ${err}`)
    } finally {
        isShowLoadingData.value = false;
    }
});

// ============================  LIFECYCLE HOOKS  ============================
onMounted( async() => {
    // Получение query параметра select-procedure
    try {
        if(route.query['select-procedure']) {
            const procedureIdQuery =  route.query['select-procedure']
            if(typeof procedureIdQuery === 'string' && +procedureIdQuery > 0) {
                // Загружаем данные выбранной процедуры если query параметр select-procedure существует
                try {
                    isShowLoadingData.value = true;
                    const procedure = await getProcedureByIDGeneral(+procedureIdQuery);
                    await parsingObject(procedure);
                } catch (err) {
                    throw new Error(`components/procedure/procedureDialogComp.vue: onMounted[fetch procedure-data]  => ${err}`)
                } finally {
                    isShowLoadingData.value = false;
                }
            }
        } else {
            emit('close');
        }
    } catch (err) {
        throw new Error(`components/procedure/procedureDialogComp.vue: onMounted[fetch query-param - 'select-procedure']  => ${err}`)
    }
});

</script>

<style scoped>
.progress-circular {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.45);
    z-index: 999;
}
.dialog__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 900px;
    height: 80vh;
    background-color: var(--bg-color-block);
}
.dialog__form {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg-color-block);
}
.dialog__form--preview-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: max-content;
    padding: 1rem 0;
}
.preview-image {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
}
.image {
    width: 100%;
    height: 100%;
}
.preview-username {
    font-size: 26px;
    font-weight: 700;
}
.dialog__actions {
    display: flex;
    justify-content: flex-end;
    width: 80%;
    padding: 2rem 1.5rem;
    margin-bottom: 2rem;
    background-color: var(--bg-color-white);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.data-body {
    display: flex;
}
.data-body__item {
    display: flex;
    align-items: center;
    margin: 0.8rem;
}
.item-title {
    color: var(--color-dark);
    font-weight: 400;
    font-size: 16px;
}
.th {
    height: 0 !important;
}
.list-item {
    width: 15% !important;
}
.list-item + .list-item {
    border-left: 1px solid rgba(128, 128, 128, 0.316);
}
.item-value {
    font-size: 15px;
    color: var(--color-dark);
    font-weight: 500;
}
.data-title {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 17px;
    text-align: left;
    width: max-content;
    padding: 0.4rem 0 !important;
}
.dialog__form--general-data {
    width: 80%;
    padding: 0 1.5rem;
    background-color: var(--bg-color-white);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.general-data__input {
    margin-bottom: 1rem;
}

.dialog__form--credentials-data {
    width: 80%;
    padding: 0 1.5rem;
    /* border-top: 2px solid rgba(128, 128, 128, 0.316); */
    background-color: var(--bg-color-white);
}
</style>