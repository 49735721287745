<script setup lang="ts">

import { defineEmits, reactive } from "vue";
import {createSends} from "@/api/superuser/employeesApi";

const emit = defineEmits<{
  close: [],
}>();

const sends = reactive({
  title: "",
  link: "",
  copy: "",
})

const handleReset = () => {
  sends.title = ""
  sends.copy = ""
  sends.link = ""
}

const required = value => !!value || 'Обязательное поле';

const handleSubmit = async () => {
  if (!!sends.title && !!sends.copy) {
    try {
      await createSends(sends.title, sends.copy, sends.link)
      emit("close")
      handleReset()
    } catch (e) {
      throw new Error(`components/employees/employeesListCom: createSends  => ${e}`);
    }
  }
}

</script>

<template>
  <v-card title="Рассылка">
    <v-form @submit.prevent="handleSubmit">
      <v-container>

            <v-text-field
              class="mb-2"
              v-model="sends.title"
              variant="outlined"
              label="Заголовок"
              :rules="[required]"
            ></v-text-field>

            <v-textarea
              class="mb-2"
              v-model="sends.copy"
              variant="outlined"
              label="Текст"
              :rules="[required]"
            ></v-textarea>

            <v-text-field
              class="mb-8"
              v-model="sends.link"
              variant="outlined"
              :counter="10"
              label="Ссылка"
              hide-details
            ></v-text-field>
          <div class="dialog-btns__container">
            <div>
              <!-- Отправить -->
              <v-btn
                class="mr-2"
                variant="flat"
                color="var(--bg-color-default)"
                style="color: white;"
                type="submit"
              >
                Отправить
              </v-btn>
              <!-- Сбросить изменения -->
              <v-btn
                variant="tonal"
                style="margin-right: auto; font-size: 0.8rem;"
                color="var(--bg-color-default)"
                type="reset"
                @click="handleReset"
              >
                Сбросить
              </v-btn>


            </div>
            <!-- Закрыть -->
            <v-btn
              variant="outlined"
              color="red"
              @click="emit('close')">
              Закрыть
            </v-btn>
          </div>
      </v-container>
    </v-form>
  </v-card>
</template>

<style scoped>
.dialog-btns__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px 0;
}
</style>