<template>
    <!-- Главный Блок для взаимодействия с Койками -->
    <div class="hospital-beds-main">
        <div class="hospital-beds-main__wrapper">
            <!-- Список Коек -->
            <hospitalBedsListComp
            :hospital-beds="hospitalBeds" 
            :is-load-hospital-beds="isLoadHospitalBeds"
            :isDeleted="isDeleted"
            :is-active-hospital-room="isActiveHospitalRoom"
            @load-hospital-bed="(hospitalBed) => appendHospitalBed(hospitalBed)"
            @update-hospital-bed="(hospitalBed) => updateHospitalBed(hospitalBed)"
            @hospital-bed-filter="(filterData) => filteredHospitalBeds(filterData)"
            @pagination-up="uploadNewPageItems"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import {onBeforeUnmount, onMounted, ref, watch} from 'vue';
// COMPONENTS
import hospitalBedsListComp from './hospitalBedsListComp.vue';
// API
import { getAllHospitalBedsDB, getHospitalBedsByHospitalRoomIdDB } from '../../api/superuser/hospitalBedsApi';
import { getAllHospitalBedsDB__chief, getHospitalBedsByHospitalRoomIdDB__chief } from '../../api/hospitalChief/hospitalBedsApi';
import { getHospitalRoomById__chief } from '../../api/hospitalChief/hospitalRoomsApi';
import { getHospitalRoomById } from '../../api/superuser/hospitalRoomsApi';
// TYPES
import {ArrayHospitalBedClient, HospitalBedClient} from '../../types/hospitalBedType';
// STORE
import useMainStore from '@/store/mainStore';
import useDataStore from '../../store/dataStore';
import useAuthStore from '../../store/authStore';
import { useRoute } from 'vue-router';


const route = useRoute();
const mainStore = useMainStore();
const authStore = useAuthStore();
const dataStore = useDataStore();
const page = ref<number>(1);

// ======================================  DATA  ======================================
// Полученный с БД массив данных
const hospitalBeds = ref<ArrayHospitalBedClient>([]);
const isLoadHospitalBeds = ref<boolean>(false);
const isSelectedHospitalRoom = ref<boolean>(false);
const selectHospitalRoomId = ref<null | number>(null);
const isActiveHospitalRoom = ref<boolean>(true);
const isDeleted = ref<boolean>(false);


// ======================================  METHODS  ======================================
// Получение всех коек больницы
async function getAllHospitalBeds(
    isDeleted?: boolean | undefined, 
    page?: number | undefined, 
    hospitalId?: number | undefined, 
    departmentId?: number | undefined, 
    hospitalRoomId?: number | undefined
    ) {
    try {
        // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
        if(authStore.isSuperUser === true) {
            return await getAllHospitalBedsDB(isDeleted, page, hospitalId, departmentId, hospitalRoomId);
        }
        // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
        return await getAllHospitalBedsDB__chief(isDeleted, page);
    } catch (err) {
        throw new Error(`components/hospitalBeds/hospitalBedsMainComp: getAllHospitalBeds  => ${err}`);
    }
}

// Получение палаты по ID
async function getHospitalRoomByIdGeneral(hospitalRoomId: number) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getHospitalRoomById(hospitalRoomId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getHospitalRoomById__chief(hospitalRoomId);
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsMainComp: getHospitalRoomByIdGeneral  => ${err}`);
  }
}

// Получение коек выбранной палаты
async function getHospitalBedsByHospitalRoomId(
    hospitalRoomId: number, 
    hospitalId: number, 
    departmentId: number, 
    isDeleted?: boolean | undefined, 
    page?: number | undefined
    ) {
    try {
        // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
        if(authStore.isSuperUser === true) {
            return await getHospitalBedsByHospitalRoomIdDB(hospitalRoomId, hospitalId, departmentId, isDeleted, page);
        }
        // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
        return await getHospitalBedsByHospitalRoomIdDB__chief(hospitalRoomId, isDeleted, page);
    } catch (err) {
        throw new Error(`components/hospitalBeds/hospitalBedsMainComp: getHospitalBedsByHospitalRoomId  => ${err}`);
    }
}

// Отрабатывает при создании новой койки
function appendHospitalBed(hospitalBed: HospitalBedClient) {
    try {
        dataStore.hospitalBeds.push(hospitalBed);
        hospitalBeds.value = dataStore.hospitalBeds;
    } catch (err) {
        throw new Error(`components/hospitalBeds/hospitalBedsMainComp: appendHospitalBed  => ${err}`);
    }
}

// Отрабатывает когда происходит обновление данных койки
function updateHospitalBed(employeeUpdated: HospitalBedClient) {
    try {
        dataStore.hospitalBeds = dataStore.hospitalBeds.map((hospitalBed: HospitalBedClient) => {
            if(employeeUpdated.id === hospitalBed.id) {
                hospitalBed = employeeUpdated;
                return employeeUpdated;
            }
            return hospitalBed;
        });
        hospitalBeds.value = dataStore.hospitalBeds;
    } catch (err) {
        throw new Error(`components/hospitalBeds/hospitalBedsMainComp: updateHospitalBed  => ${err}`);
    }
}

// Подгрузка новых данных по пагинации
async function uploadNewPageItems() {
    try {
        // Проверка, если на сервере есть следующая страница с данными то выполнять подгрузку этой страницы
        if(mainStore.isHospitalBedsPaginationHasNext === true && hospitalBeds.value.length >= 30) {
            page.value += 1;
            const employeesNextPage: any = await getAllHospitalBeds(isDeleted.value, page.value, undefined);
            // Добавляем в уже существующий массив элементов новые элементы пришедшие с другой страницы пагинации
            dataStore.hospitalBeds = dataStore.hospitalBeds.concat(employeesNextPage);
            hospitalBeds.value = dataStore.hospitalBeds;
        }
    } catch (err) {
        throw new Error(`components/employees/employeesComp: uploadNewPageItems  => ${err}`);
    }
}

// Функция фильтрует массив Коек относительно фильтр-данных
async function filteredHospitalBeds(filterData: { hospitalBedName: string | null | undefined, isDeleted: boolean }) {
    try {
        isDeleted.value = false;
        page.value = 1;
        isLoadHospitalBeds.value = true;

        // Если фильтр-данные пусты, то возвращаем список коек в исходное состояние
        if (filterData.hospitalBedName === undefined && !filterData.isDeleted) {
            if (dataStore.departments.length) {
                hospitalBeds.value = dataStore.hospitalBeds;
            } else {
                // так как поле isDeleted в getAllHospitalBeds и isDeleted не одно и то же, мы меняем isActive на противоположное значение
                dataStore.hospitalBeds = await getAllHospitalBeds(false, page.value);
                hospitalBeds.value = dataStore.hospitalBeds;
            }
            // Возвращаем переменные для пагинации в исходное состояние
            isDeleted.value = false;
            mainStore.isHospitalBedsPaginationHasNext = true;
            page.value = 1;
            return;
        }
        // Если данные для фильтрации были указаны то получаем весь список Отделений в системе и фильтруем его по фильтр данным
        else {
            let fetchAllHospitalBeds = await getAllHospitalBeds(filterData.isDeleted, undefined);
            let filteredDataList = fetchAllHospitalBeds;
            // Фильтрация по Названию больницы
            if (filterData.hospitalBedName) {
                filteredDataList = filteredDataList.filter((hospitalBed: HospitalBedClient) => {
                    if (filterData.hospitalBedName) {
                        if(hospitalBed.name?.toLowerCase().includes(filterData.hospitalBedName.toLowerCase())) {
                            return true;
                        }
                        else return false;
                    }
                    return true;
                });
            }
            // Фильтрация по isActive
            if (filterData.isDeleted) {
                isDeleted.value = true;
                filteredDataList = await getAllHospitalBeds(filterData.isDeleted, undefined);
                // Если введены другие данные в фильтр панель
                if(filterData.hospitalBedName) {
                    filteredDataList = filteredDataList.filter((hospitalBed: HospitalBedClient) => {
                        // Если в фильтр панеле указано название Койки, то проверяем сопоставляется ли оно
                        if(hospitalBed.name && filterData.hospitalBedName) {
                            if(hospitalBed.name.toLocaleLowerCase().includes(filterData.hospitalBedName?.toLocaleLowerCase())) {
                                return true;
                            }
                            else return false;
                        }
                    });
                }
            }
            hospitalBeds.value = filteredDataList;
        }
    } catch (err) {
        throw new Error(`components/hospitalBeds/hospitalBedsMainComp: filteredHospitalBeds  => ${err}`);
    } finally {
        isLoadHospitalBeds.value = false;
    }
}

// ======================================  WATCH  ======================================
// Отслеживает удаление query-параметра hospital-room в случае если мы переходим со страницы выбранных коек конкретной палаты 
// на список всех коек
watch(() => route.query['hospital-room'], async (newValue) => {
    if(newValue === undefined) {
        try {
            isLoadHospitalBeds.value = true;
            dataStore.hospitalBeds.length = 0;
            hospitalBeds.value.length = 0;
            // Получаем массив коек задавая при этом каждой койке название ее палаты
            dataStore.hospitalBeds = await getAllHospitalBeds(false, page.value);
            hospitalBeds.value = dataStore.hospitalBeds;
        } catch (err) {
            throw new Error(`components/hospitalBeds/hospitalBedsMainComp: watch[fetch hospitalBeds]  => ${err}`);
        } finally {
            isLoadHospitalBeds.value = false;
        }
    }
});


// ======================================  LIFECYCLE HOOKS  ======================================
// Получение массива ПАЛАТ с БД
onMounted(async () => {
    // Получение списка коек если есть query-параметр (hospital-room) открытого отделения
    try {
        isLoadHospitalBeds.value = true;
        const hospitalRoomId = route.query['hospital-room'];
        if(hospitalRoomId && +hospitalRoomId > 0) {
            const hospitalId = route.query['hospital'];
            const departmentId = route.query['department'];
            selectHospitalRoomId.value = +hospitalRoomId;
            isSelectedHospitalRoom.value = true;
            if(hospitalId && departmentId) {
                dataStore.hospitalBeds = await getHospitalBedsByHospitalRoomId(+hospitalRoomId, +hospitalId, +departmentId, false);
            }
            hospitalBeds.value = dataStore.hospitalBeds;
            // Получаем выбранную в query парметр палату для того чтобы получить неактивные койки, если эта палата не активна
            const currentHospitalRoom = await getHospitalRoomByIdGeneral(+hospitalRoomId);
            if(currentHospitalRoom.department?.hospital.deleted || currentHospitalRoom.deleted) {
                isActiveHospitalRoom.value = false;
            }
            return;
        } else {
            // Получение списка коек
            dataStore.hospitalBeds = await getAllHospitalBeds(false, page.value);
            hospitalBeds.value = dataStore.hospitalBeds;
        }
    } catch (err) {
        throw new Error(`components/hospitalBeds/hospitalBedsMainComp: onMounted[fetch hospitalBeds]  => ${err}`);
    } finally {
        isLoadHospitalBeds.value = false;
    }
});

// При размонтировании компонента происходит очистка массива dataStore если при монтировании этого компонента была выбрана палата  и был query-параметр `hospital-room`
// Нужно для того чтобы по новой подгрузить актуальные данные с сервера 
onBeforeUnmount(() => {
    if(isSelectedHospitalRoom.value === true) {
        dataStore.hospitalBeds.length = 0;
        hospitalBeds.value.length = 0;
    }
})

</script>

<style scoped>
.hospital-beds-main {
    width: 100%;
    height: 100%;
    padding: 0 1rem 0 4rem;
}

.hospital-beds-main__wrapper {
    width: 100%;
    height: 107%;
    margin-top: 1rem;
    background-color: var(--bg-color-white);
}
</style>