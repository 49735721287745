<template>
  <v-dialog v-model="modelValue" width="auto">
    <v-card class="dialog__wrapper">

      <form class="dialog__form">
        <!-- Превью -->
        <div class="dialog__form--preview-block">
          <input id="preview-image" v-show="false"/>
          <label
            class="preview-image"
            for="preview-image"
          >
            <v-icon
              icon="mdi-hospital-building"
              color="var(--color-default)"
              size="80px"
            ></v-icon>
          </label>
          <h1 class="preview-name">Создание Организации</h1>
        </div>

        <!-- Общие данные -->
        <div class="dialog__form--general-data">
          <div class="data-body">
            <!-- Имя -->
            <div class="data-body__item">
              <v-card-subtitle class="mb-2">* Название больницы</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                :rules="inputRules"
                v-model="name"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
              ></v-text-field>
            </div>

            <!-- Адрес -->
            <div class="data-body__item">
              <v-card-subtitle class="mb-2">* Адрес</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model="address"
                :rules="inputRules"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
              ></v-text-field>
            </div>
          </div>
          <!-- Управляющий -->
          <chiefListComp
            style="width: 100% !important;"
            :label-compbox="'Назначить управляющего'"
            @close-menu="chiefList.length = 0"
            @select-chief="(chief: UserClient | null) => selectChief(chief)"
            @clear-chief="() => selectChief(null)"
            :selected-chief-name="chiefName"
          />
        </div>
      </form>
      <div class="dialog__actions">
        <v-btn
          class="mr-6"
          @click="confirmData"
          variant="flat"
          style="color: white; font-weight: normal; font-size: 12px;"
          :loading="isLoading"
          color="var(--bg-color-default)"
        >
          Сохранить
        </v-btn>

        <!-- Очистить форму -->
        <v-btn
          variant="tonal"
          style="margin-right: auto; font-size: 12px;"
          color="var(--bg-color-default)"
        >
          Очистить форму
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          variant="outlined"
          color="red"
          style="font-size: 12px;"
          @click="emit('close')">
          Закрыть
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import chiefListComp from './chiefListComp.vue';
import { ArrayUserClient, UserClient } from '../../types/userType';
import { getEmployeesDB } from '../../api/superuser/employeesApi';
import { createNewHospitalDB } from '../../api/superuser/hospitalsApi';
import { ref, defineModel, defineEmits } from 'vue';
import { HospitalClient } from '../../types/hospitalType';

const modelValue = defineModel<boolean>();

// =======================================   EMITS   =======================================
const emit = defineEmits<{
  close: [],
  error: [msg: string],
  success: [];
  appendHospital: [hospital: HospitalClient];
}>();

// =======================================   DATA   =======================================
const isLoading = ref(false);
const chiefList = ref<ArrayUserClient>([]);
const name = ref<string>('');
const address = ref<string>('');
const selectedChief = ref<UserClient | null>(null);
const chiefName = ref<string>('');

const inputRules = ref([
  (value: string) => !!value || 'Обязательное поле',
]);


// =======================================   METHODS   =======================================
// Выбор управляющего больницей в селект-меню
function selectChief(user: UserClient | null) {
  selectedChief.value = user;
  if (user?.fullName) {
    chiefName.value = user?.fullName;
  }
}

// Подтверждение данных для создания новой больницы
async function confirmData() {
  try {
    isLoading.value = true;
    if ((name.value.length > 0 || address.value.length > 0) && !!selectedChief.value) {
      const newHospital = await createNewHospitalDB(name.value, address.value, selectedChief.value.id);
      emit('appendHospital', newHospital);
      emit('success');
    } else {
      // Поднимаем ошибку если
      emit('error', 'Заполните все поля!');
    }
  } catch (err) {
    throw new Error(`components/hospitals/creationHospitalComp.vue: confirmData  => ${err}`);
  } finally {
    isLoading.value = false;
  }
}

</script>

<style scoped>
.dialog__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  padding-bottom: 2rem;
  height: max-content;
  background-color: var(--bg-color-block);

}

.dialog__form {
  width: 100%;
  height: max-content;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-block);
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 2rem 1.5rem;
  background-color: var(--bg-color-white);
  margin-bottom: 2rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dialog__form--preview-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: max-content;
  padding: 2rem 0;
  background-color: rgba(239, 246, 248, 1);
}

.preview-image {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.image {
  width: 100%;
  height: 100%;
}

.preview-name {
  font-size: 26px;
  font-weight: 500;
  margin-top: 1rem;
}

.data-body {
  display: flex;
  gap: 1rem;
  padding-top: 2rem;
}

.data-body__item {
  flex: 1;
}

.dialog__form--general-data {
  width: 80%;
  padding: 0 2rem;
  background-color: var(--bg-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.general-data__input {
  margin-bottom: 1rem;
}

.general-data__departments-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid black;
}

.general-data__departments-amount p {
  color: var(--color-default);
}

</style>