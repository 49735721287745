<template>
  <!-- Компонент для отрисовки Списка Устройств -->
  <div class="droppers-list__container">

    <!-- уведомление об ошибке -->
    <error-note-comp>{{ errorMsg }}</error-note-comp>

    <!-- Уведомление об успехе -->
    <success-note-comp @complete="handlerCompleteAnimation"></success-note-comp>

    <!-- Заголовок -->
    <div class="droppers-list__header">
      <v-icon
        @click="router.go(-1)"
        class="btn-back"
        icon="mdi-arrow-left"
        size="22"
      ></v-icon>
      <h1 class="droppers-list__header--title">Дропперы</h1>
    </div>

    <!-- Диалоговое окно с данными о выбранном элементе -->
    <droppersDialogComp
      v-model="isShowDialog"
      @error="(e) => handlerError(e)"
      @success="handlerSuccess"
      @close="handlerCloseDialogInfo"
      @load-dropper="(dropper: DropperClient) => emit('loadDropper', dropper)"
      @update-dropper="(dropper: DropperClient) => emit('updateDropper', dropper)"
    />

    <!-- Диалоговое окно для создания новой койки -->
    <creationDroppersComp
      v-model="isShowCreationDialog"
      @error="(e) => handlerError(e)"
      @success="handlerSuccess"
      @close="isShowCreationDialog = false"
      @append-hospital-bed="(hospitalBed: DropperClient) => emit('loadDropper', hospitalBed)"
    />

    <!-- Диалоговое окно для подтверждения деактивации элемента -->
    <disabelDroppersDialogComp
      v-model="isShowDisabledDialog"
      @cancel="isShowDisabledDialog = false"
      @confirm="confirmDisabledDropper"
      :is-loading="isLoadingDisabled"
      :title="`${disabledDropperData.vendorId} `"
      :subtitle="isDeleted ? 'активным' : 'неактивным'"
    />

    <!-- Панель действий -->
    <div class="droppers-list__actions-block">
      <v-btn
        style="color: var(--color-white); font-size: 0.8em; font-weight: bold;"
        color="var(--color-default)"
        rounded="large"
        size="large"
        @click="isShowCreationDialog = true"
      >
        Создать
      </v-btn>

      <!-- Фильтр панель -->
      <droppersFilterPanelComp
        :list-dropper-ids="listDropperIds"
        :list-hospital-names="listHospitalNames"
        :list-department-names="listDepartmentNames"
        @select-dropper-id="(id: string | null) => filledFilterData(id, null, null, null)"
        @select-status="(status: boolean) => filledFilterData(null, null, null, status)"
        @select-hospital-name="(hospitalName: string) => filledFilterData(null, hospitalName, null, null)"
        @select-department-name="(departmentName: string) => filledFilterData(null, null, departmentName, null)"
      />
    </div>

    <!-- Контент список -->
    <div class="droppers-list__content-container" v-if="!props.isLoadDroppers">

      <!-- Подсказка указывает на отсутствие элементов в списке -->
      <div class="is-not-items-hint" v-if="!props.droppers.length">
        <h1>Дропперы не найдены</h1>
      </div>

      <!-- Отрисовка элементов списка -->
      <v-table class="droppers-list__wrapper" :density="'comfortable'" fixed-header>
        <thead>
        <tr>
          <th class="text-left th avatar-droppers">
          </th>
          <th class="text-left th">
            ID
          </th>
          <th class="text-left th">
            Организация
          </th>
          <th class="text-left th">
            Отделение
          </th>
          <th class="text-left th">
            Статус
          </th>
          <th class="text-left th actions-th">
            Действия
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          class="row-hover"
          v-for="dropper in props.droppers as ArrayDropperClient"
          :key="dropper.id">
          <td class="list-item">
            <v-icon icon="mdi-water-outline" color="var(--color-default)"></v-icon>
          </td>
          <!-- ID -->
          <td class="list-item droppers-name">{{ dropper.vendorId }}</td>
          <!-- Организация -->
          <td class="list-item">{{ dropper.department.hospital.name }}</td>
          <!-- Отделение -->
          <td class="list-item">{{ dropper.department.name }}</td>
          <!-- Статус -->
          <td class="list-item">{{ (!props.isDeleted) ? "Активен" : "Не активен" }}</td>
          <!-- ДЕЙСТВИЯ -->
          <td class="list-item actions-td">

            <!-- Редактировать -->
            <v-btn
              class="actions__btn"
              @click.stop="selectDropper(dropper?.id)"
              rounded="xs"
              icon="mdi-cog-outline"
              :color="'var(--color-gray)'"
              variant="text"
              :density="'comfortable'"
            >
            </v-btn>

            <!-- Удалить -->
            <v-btn
              class="actions__btn del"
              @click.stop="handlerDisabledDropper(dropper?.id, dropper?.vendorId)"
              rounded="xs"
              variant="text"
              :density="'comfortable'"
              icon="mdi-power"
              :color="dropper.deleted ? 'green' : 'red'"
            >
            </v-btn>
          </td>
        </tr>
        <!-- Триггерный блок для пагинации -->
        <tr class="last-item">
          <td class="trigger-pagination">
            <v-progress-circular
              v-show="isShowLoadingNewPage"
              indeterminate
              bg-color="var(--bg-color-white)"
              color="var(--bg-color-default)"
              :size="20"
              :width="2"
            ></v-progress-circular>
          </td>
        </tr>
        </tbody>
      </v-table>
    </div>

    <!-- Крутилка загрузки -->
    <span class="progress-circular" v-else>
      <v-progress-circular
        indeterminate
        bg-color="var(--bg-color-white)"
        color="var(--bg-color-default)"
        :size="60"
        :width="6"
      ></v-progress-circular>
    </span>
  </div>
</template>

<script setup lang="ts">
// COMPONENTS
import droppersDialogComp from './droppersDialogComp.vue';
import creationDroppersComp from './creationDroppersComp.vue';
import disabelDroppersDialogComp from './disabelDroppersDialogComp.vue';
import droppersFilterPanelComp from './droppersFilterPanelComp.vue';
// STORE
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';
// TYPES
import {DropperClient, ArrayDropperClient} from '../../types/droppersType';
// API
import {putStatusDropperById} from "../../api/superuser/droppersApi";
import {putStatusDropperById__chief} from '../../api/hospitalChief/droppersApi';
// VUE
import {useRoute, useRouter} from 'vue-router';
import {ref, defineProps, onMounted, watch, defineEmits, nextTick, computed} from 'vue';

const store = useMainStore();
const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

// ======================================   PROPS   ======================================
const props = defineProps<{
  droppers: Array<any>,
  isLoadDroppers: boolean,
  isDeleted: boolean,
}>();

const emit = defineEmits<{
  loadDropper: [dropper: DropperClient];
  updateDropper: [dropper: DropperClient];
  disableDropper: [dropperId: number];
  dropperFilter: [filterData: { hospitalName: string | null, departmentName: string | null, isDeleted: boolean }];
  paginationUp: [];
}>();

// ======================================   DATA   ================================
const errorMsg = ref('');
const isShowDialog = ref<boolean>(false);
const isShowCreationDialog = ref<boolean>(false);
const isShowDisabledDialog = ref<boolean>(false);
const isShowLoadingNewPage = ref<boolean>(false);
const isLoadingDisabled = ref<boolean>(false);
const disabledDropperData = ref({
  id: null as number | null,
  vendorId: null as string | null,
});
const filterData = ref({
  id: undefined as string | undefined,
  hospitalName: undefined as string | undefined,
  departmentName: undefined as string | undefined,
  isDeleted: false as boolean,
});
// Наблюдатель для триггера подгрузки страниц пагинации списка
const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if(store.isDropperPaginationHasNext === true) {
        isShowLoadingNewPage.value = true; 
      } else isShowLoadingNewPage.value = false;
      // Событие paginationUp для подгрузки данных следующей страницы пагинации
      emit('paginationUp');
    }
  });
});

// ======================================   METHODS   ==================================
// Заполняет объект фильтр данных
function filledFilterData(id: string | null, hospitalName: string | null, departmentName: string | null, isDeleted: boolean | null) {
  try {
    if (id !== null) {
      if (id === '') {
        filterData.value.id = undefined;
      } else filterData.value.id = id;
    }

    // hospitalName
    if (hospitalName !== null) {
      if (hospitalName === '') {
        filterData.value.hospitalName = undefined;
      } else filterData.value.hospitalName = hospitalName;
    }
    // departmentName
    if (departmentName !== null) {
      if (departmentName === '') {
        filterData.value.departmentName = undefined;
      } else filterData.value.departmentName = departmentName;
    }
    if (isDeleted !== null) {
      filterData.value.isDeleted = isDeleted;
    }
    emit('dropperFilter', filterData.value);
  } catch (err) {
    throw new Error(`components/droppers/droppersListComp: filledFilterData  => ${err}`);
  }
}

// функция для открытия окна информации Устройства
function selectDropper(dropperId: number) {
  isShowDialog.value = true;
  store.toPushWithQuery({name: 'select-dropper', value: dropperId}, route, router);
}

// отображение уведомления об успехе какой-либо операции
function handlerSuccess() {
  store.activeSuccessOperation(1200);
}

// отображение уведомления об ошибке при выполнении какой-либо операции
function handlerError(error: string) {
  errorMsg.value = error;
  store.activeErrorOperation(3500);
}

// Обработчик выполнения анимации уведомления об успехе
function handlerCompleteAnimation() {
  if (isShowCreationDialog.value === true) {
    isShowCreationDialog.value = false;
    window.location.reload();
  }
  if (isShowDialog.value === true) {
    isShowDialog.value = false;
  }
  if (isShowDisabledDialog.value === true) {
    isShowDisabledDialog.value = false;
    isLoadingDisabled.value = false;
    window.location.reload();
  }
}

// Обработчик открытия окна деактивации Устройства
function handlerDisabledDropper(dropperId: number, vendorId: string) {
  try {
    disabledDropperData.value.id = dropperId;
    disabledDropperData.value.vendorId = vendorId;
    isShowDisabledDialog.value = true;
  } catch (err) {
    throw new Error(`components/droppers/droppersListComp: handlerDisabledDropper  => ${err}`);
  }
}

// Функция для отпарвки запроса на обновление статуса активности Устройства 
async function putStatusDropperByIdGeneral(dropperId: number, deleted: boolean) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await putStatusDropperById(dropperId, deleted);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await putStatusDropperById__chief(dropperId, deleted);
  } catch (err) {
    throw new Error(`components/droppers/droppersListComp: putStatusDropperByIdGeneral  => ${err}`);
  }
}

// Подтверждение деактивации Устройства
async function confirmDisabledDropper() {
  try {
    isLoadingDisabled.value = true;
    if (disabledDropperData.value.id) {
      await putStatusDropperByIdGeneral(disabledDropperData.value.id, !props.isDeleted);
      emit('disableDropper', disabledDropperData.value.id);
      store.activeSuccessOperation(900);
    }
  } catch (err) {
    throw new Error(`components/droppers/droppersListComp: confirmDisabledDropper  => ${err}`);
  } finally {
    isLoadingDisabled.value = false;
  }
}

// Обработчик закрытия окна информации об Устройстве 
function handlerCloseDialogInfo() {
  try {
    store.toPopQuery('select-dropper', route, router);
    isShowDialog.value = false;
  } catch (err) {
    throw new Error(`components/droppers/droppersListComp: handlerCloseDialogInfo  => ${err}`);
  }
}

// ==================================   COMPUTED  ============================
const listDropperIds = computed<Array<string | undefined>>(() => {
  try {
    let ids = props.droppers.map((dropper: DropperClient) => {
      if (dropper.vendorId) {
        return dropper.vendorId;
      }
    });

    return [...new Set(ids)];
  } catch (err) {
    throw new Error(`components/droppers/droppersListComp: computed[listHospitalsName]  => ${err}`);
  }
});

// Вычисление списка Названий Больниц для фильтр-панели
const listHospitalNames = computed<Array<string | undefined>>(() => {
  try {
    let names = props.droppers.map((dropper: DropperClient) => {
      if (dropper.department.hospital.name !== null && dropper.department.hospital.name !== undefined) {
        return dropper.department.hospital.name;
      }
    });

    return [...new Set(names)];
  } catch (err) {
    throw new Error(`components/droppers/droppersListComp: computed[listHospitalsName]  => ${err}`);
  }
});

// Вычисление списка Названий Отделений для фильтр-панели
const listDepartmentNames = computed<Array<string | undefined>>(() => {
  try {
    let names = props.droppers.map((dropper: DropperClient) => {
      if (dropper.department.name !== null && dropper.department.name !== undefined) {
        return dropper.department.name;
      }
    });

    return [...new Set(names)];
  } catch (err) {
    throw new Error(`components/droppers/droppersListComp: computed[listHospitalsName]  => ${err}`);
  }
});

// ========================================   WATCH   ===================================
// Отслеживание достижения тригеррного блока для смены страницы пагинации
watch(() => props.droppers.length, async (newLength) => {
  if (newLength > 0) {
    await nextTick();
    // получение последнего элемента списка
    const triggerPagination = document.querySelector('.trigger-pagination') as HTMLDivElement;
    if (triggerPagination) {
      observer.observe(triggerPagination);  // запуск observer
    }
  }
});

// Удаляем query-параметр если диалоговое окно просмотра закрывается
watch(() => isShowDialog.value, (isShow) => {
  if (!isShow) {
    handlerCloseDialogInfo();
  }
})

// ========================================   LIFECYCLE HOOKS   ================================
onMounted(() => {
  // При монтировании списка Устройств проверям существует ли query-параметр select-dropper
  try {
    const dropperIdQuery = route.query['select-dropper'];
    if (dropperIdQuery) {
      if (typeof dropperIdQuery === 'string' && +dropperIdQuery > 0) {
        // Если query-параметр существует то открывается окно просмотра этого Устройства
        selectDropper(+dropperIdQuery);
      }
    }
  } catch (err) {
    throw new Error(`components/droppers/droppersListComp: onMounted  => ${err}`);
  }
});

</script>

<style scoped>
.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
}

.row-hover {
  transition: background-color 0.4s ease;
  cursor: pointer;
}

.row-hover:hover {
  background-color: rgba(128, 128, 128, 0.2);
  transition: background-color 0.4s ease;
}

.droppers-list__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--bg-color-white);
  overflow: hidden;
}

.is-not-items-hint {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--color-default);
  z-index: 25;
}

.droppers-list__header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  background-color: var(--bg-color-default);
  color: var(--color-white);
  border-bottom: var(--border-thin);
  z-index: 20;
}

.btn-back {
  position: absolute;
  left: 1rem;
  cursor: pointer;
}

.btn-back:hover {
  color: rgba(255, 255, 255, 0.519);
}

.droppers-list__header--title {
  font-family: var(--font);
  font-size: 1.25rem;
  font-weight: bold;
}

.droppers-list__actions-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
}

.droppers-list__content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 93%;
  top: 0;
  max-height: 100%;
}

.droppers-list__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 5px;
  padding-bottom: 4rem;
  z-index: 10;
}

.avatar-droppers {
  border-left: 1px solid rgba(128, 128, 128, 0.316);
}

.th {
  backdrop-filter: blur(9px);
  font-weight: 100;
  border-top: 1px solid rgba(128, 128, 128, 0.316);
  font-size: 13px;
}

.droppers-name {
  font-weight: 600;
}

.actions-th {
  width: 10%;
}

.actions-td {
  display: flex;
  align-items: center;
}

.actions__btn.del {
  margin-left: 5px;
}

.last-item {
  position: relative;
}

.trigger-pagination {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: rgba(0, 0, 0, 0);
}
</style>