// Взаимодействие суперпользователя со сменами 
import { ArrayShiftClient } from '@/types/shiftType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import useMainStore from '../../store/mainStore';
import axios from 'axios';

// Получение списка смен с БД
async function getShiftsDB(
        page?: number | undefined,
        isActive?: boolean | undefined,
        userId?: number | undefined,
        hospitalId?: number | undefined,
        departmentId?: number | undefined,
        startedFrom?: number | undefined,
        startedTo?: number | undefined,
        endedFrom?: number | undefined,
        endedTo?: number | undefined,
    ): Promise<ArrayShiftClient> {
    const store = useMainStore();
    try {
        const response = await axios.get(hostname + 'api/cp/shifts', {
            params: {
                page,
                is_active: isActive,
                user_id: userId,
                hospital_id: hospitalId,
                department_id: departmentId,
                started_from: startedFrom,
                started_to: startedTo,
                ended_from: endedFrom,
                ended_to: endedTo,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            }
        });
        const { data } = response;
        const user = data?.data;
        const paginator = data?.paginator;
        // Задаем в стор информацию о том доступны ли следующие страницы пагинации
        store.isEmployeePaginationHasNext = paginator?.has_next;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(user);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/shiftApi: getShiftsDB  => ${err}`);
    }
}

export {
    getShiftsDB,
}