<template>
    <!-- Шапка сервиса -->
    <div class="navbar">
        <!-- Логотип -->
        <div class="navbar__logo-container">
            <img 
            class="logo-img" 
            @click="router.push({ name: 'procedures' })" 
            src="../../assets/Logo-bar.png"
            :style="(userName)? {left: '80px'} : {left: '20px'}"
            />
        </div>

        <v-dialog v-model="isShowAskDialog" width="auto">
            <v-card class="dialog__wrapper">
                <v-card-text>Вы уверены, что хотите выйти из системы?</v-card-text>
                <div class="dialog__actions">
                    <v-btn variant="outlined" @click="confirmLogout" color="red" class="mr-6">Выйти</v-btn>
                    <v-btn variant="tonal" color="var(--bg-color-default)" @click="isShowAskDialog = false">Отмена
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

        <!-- Кнопки взаимодйествия пользователя -->
        <div v-if="props.isShowUserActions" class="navbar__buttons">
            <h3 class="navbar__username" @click="router.push({ name: 'profile' })">{{ userName }}</h3>
            <v-icon class="icon-account" @click="router.push({ name: 'profile' })" size="25px"
                icon="mdi-account-circle"></v-icon>
            <v-icon class="icon-logout" @click="isShowAskDialog = true" size="25px" icon="mdi-logout-variant"></v-icon>
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';
import { ref, onMounted, watch } from 'vue';
import useMainStore from '../../store/mainStore';
import { UserClient } from '../../types/userType';
import { useRoute, useRouter } from 'vue-router';

const store = useMainStore();
const route = useRoute();
const router = useRouter();

// =========================   PROPS   ==============================
const props = withDefaults(defineProps<{
    isShowUserActions?: boolean,
}>(), {
    isShowUserActions: false,
});


// =========================   DATA   ==============================
const isShowAskDialog = ref<boolean>(false);
const userName = ref<string | null>(null);

// =========================   METHODS   ==============================
// Функция для выхода из системы авторизованного пользователя
function confirmLogout() {
    try {
        // Удаляем метаданные с localStorage при выходе из системы
        isShowAskDialog.value = false;
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.reload();
    } catch (err) {
        throw new Error(`components/navbar/navbarComp.vue: confirmLogout  => ${err}`);
    }
}

// Установка имени авторизованного пользователя в navbar
function setUsernameNavbar() {
    try {
        const user: UserClient = store.decryptObject(localStorage.getItem('user'), localStorage.getItem('token'));
        if (user) {
            userName.value = user.fullName;
        }
    } catch (err) {
        throw new Error(`components/navbar/navbarComp.vue: setUsernameNavbar  => ${err}`);
    }
}

// =========================   LYFECYCLE HOOKS   ==============================
watch(() => route.meta.requireAuth, (newState, oldState) => {
    try {
        if (newState === true && oldState === false) {
            setUsernameNavbar();
        }
    } catch (err) {
        throw new Error(`components/navbar/navbarComp.vue: watch  => ${err}`);
    }
});

onMounted(() => {
    try {
        setUsernameNavbar();
    } catch (err) {
        throw new Error(`components/navbar/navbarComp.vue: onMounted  => ${err}`);
    }
});

</script>

<style scoped>
.dialog__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem 2.5rem 2rem;
}

.navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    min-height: 60px;
    background-color: var(--bg-color-white);
    box-shadow: var(--shadow);
    z-index: 35 !important;
}

.navbar__logo-container {
    width: max-content;
    display: flex;
    align-items: center;
    margin-left: 5%;
}

.navbar__logo-text {
    position: absolute;
    font-size: 2rem;
    font-weight: 900 !important;
    color: var(--bg-color-default);
    user-select: none;
    cursor: pointer;
}
.logo-img {
    position: absolute;
    left: 15px;
    object-fit: cover;
    z-index: 1;
    width: calc(3rem * 3);
    cursor: pointer;
}

.navbar__buttons {
    width: max-content;
    max-width: 24%;
    display: flex;
    align-items: center;
    margin-right: 2.5%;
}

/* ICON-ACCOUNT */
.icon-account {
    color: var(--color-default);
    transition: color .4s ease;
    cursor: pointer;
}

.icon-account:hover {
    color: var(--hover-default);
    transition: color .4s ease;
}

.icon-account:hover~.navbar__username {
    color: var(--hover-default);
    transition: color .4s ease;
}

/* ICON-LOGOUT */
.icon-logout {
    margin-left: 0.5rem;
    color: var(--color-default);
    transition: color .4s ease;
    cursor: pointer;
}

.icon-logout:hover {
    color: var(--hover-default);
    transition: color .4s ease;
}

/* USERNAME-text */
.navbar__username {
    color: gray;
    font-size: 1.1rem;
    font-weight: 500;
    margin-right: 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: color .4s ease;
    cursor: pointer;
}

.navbar__username:hover {
    color: var(--hover-default);
    transition: color .4s ease;
}

.navbar__username:hover~.icon-account {
    color: var(--hover-default);
    transition: color .4s ease;
}
</style>