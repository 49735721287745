// Взаимодействие суперпользователя с Палатами 
import { UserClient } from '../../types/userType';
import { ProfileChangedServer } from '../../types/profileType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import axios from 'axios';



// Обновление общих даных профиля суперпользователя
async function updateProfileGeneralData(firstName?: string, lastName?: string, patronymic?: string, email?: string): Promise<UserClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.put(hostname + 'api/cp/users/me/', {
            first_name: firstName,
            last_name: lastName,
            patronymic: patronymic,
            email: email,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/profileApi: updateProfileGeneralData  => ${err}`);
    }
}

// Обновление автарки профиля суперпользователя
async function updateProfileAvatar(avatar?: File | null): Promise<UserClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.put(hostname + 'api/cp/users/me/avatars', {
            avatar: avatar,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/profileApi: updateProfileAvatar  => ${err}`);
    }
}

// Обновление пароля для суперпользователя
async function updateProfilePassword(password: string): Promise<UserClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.put(hostname + 'api/cp/users/me/', {
            password,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/profileApi: updateProfilePassword  => ${err}`);
    }
}


export { 
    updateProfileGeneralData,
    updateProfilePassword,
    updateProfileAvatar,
}