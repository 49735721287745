<template>
  <v-menu
    v-model="isShowMenu"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ props }">
      <v-select
        variant="outlined"
        clearable
        class="filter-input"
        hide-details
        hide-no-data
        @update:model-value="handlerUpdateDate"
        :label="mainProps.labelDefault"
        v-model="selectedDate"
        :density="'comfortable'"
        v-bind="props"
      >
        <template v-slot:selection>
          <p class="selection-date" style="font-size: 14px;">{{ previewDate }}</p>
        </template>
      </v-select>
    </template>
    <template v-slot:default>
      <v-window
        class="selection-window"
        v-model="window"
      >

        <!-- Выбор Даты -->
        <v-window-item class="selection-window__item" :value="windowItems[0]">
          <h2 class="window-title">Выберите Дату</h2>
          <v-date-picker
            v-if="true"
            class="filter-datepicker"
            :hide-header="true"
            show-adjacent-months
            v-model="datepickerValue"
            @update:model-value="(date: null | Date) => selectDate(date)"
          ></v-date-picker>
        </v-window-item>

        <!-- Выбор Времени -->
        <v-window-item class="selection-window__item" :value="windowItems[1]">
          <procedureTimePicker
            @select-time="(time: string | null) => selectTime(time)"
          />
        </v-window-item>


        <v-btn
          class="window-btn"
          @click="handlerWindowSwipe"
          :prepend-icon="((window === windowItems[1])? 'mdi-chevron-left' : '')"
          :append-icon="((window === windowItems[0])? 'mdi-chevron-right' : '')"
        >
          {{ (window === windowItems[0]) ? 'Выбрать время' : 'Выбрать дату' }}
        </v-btn>
      </v-window>

    </template>
  </v-menu>
</template>

<script setup lang="ts">
import {ref, defineEmits, defineProps} from 'vue';
import useMainStore from '../../store/mainStore';
import procedureTimePicker from './procedureTimePicker.vue';

const store = useMainStore();

// ============================   PROPS   ==============================
const mainProps = defineProps<{
  labelDefault: string;
}>();

// ============================   EMITS   ==============================
const emit = defineEmits<{
  selectDate: [date: number | null];
  selectTime: [time: number | null];
  clearFilterField: [];
}>();

// ============================   DATA   ==============================
const isShowMenu = ref<boolean>(false);
const datepickerValue = ref<Date | null>(null);
const previewDate = ref<string | null>(null);
const selectedDate = ref<string | null>(null);
const selectedTime = ref<string | null>(null);
const window = ref<string>('date');
const windowItems = ref<Array<string>>(['date', 'time']);

// ============================   METHODS   ==============================
function handlerUpdateDate(e) {
  try {
    if (e === null) {
      emit('clearFilterField');
    }
  } catch (err) {
    throw new Error(`components/procedure/procedureDatepickerComp: handlerUpdateDate  => ${err}`);
  }
}

// Выбор даты
function selectDate(date: Date | null) {
  try {
    let timestamp: number | null = null;
    if (date) {
      selectedDate.value = store.timestampFormat(date, 'DD/MM/YYYY');
      previewDate.value = selectedDate.value;
      timestamp = date.getTime();
    }
    window.value = windowItems.value[1];
    if (timestamp) {
      emit('selectDate', timestamp);
    }

  } catch (err) {
    throw new Error(`components/procedure/procedureDatepickerComp: selectDate  => ${err}`);
  }
}

// Выбор времени
function selectTime(timeString: string | null) {
  try {
    let timestamp: number | null = null;
    if (timeString) {
      selectedDate.value
      timestamp = store.parseTimeString(timeString, 'HH:mm');
      selectedTime.value = store.timestampFormat(timestamp, 'HH:mm');
      if (selectedDate.value) {
        previewDate.value = `${selectedTime.value} ${selectedDate.value}`;
      }
    } else {
      selectedTime.value = null;
    }
    isShowMenu.value = false;
    if (timestamp) {
      emit('selectTime', timestamp);
    }

  } catch (err) {
    throw new Error(`components/procedure/procedureDatepickerComp: selectTime  => ${err}`);
  }
}

// Обработчик свайпа окон datepicker
function handlerWindowSwipe() {
  try {
    if (window.value === 'date') {
      window.value = windowItems.value[1];
    } else if (window.value === 'time') {
      window.value = windowItems.value[0];
    }
  } catch (err) {
    throw new Error(`components/procedure/procedureDatepickerComp: handlerWindowSwipe  => ${err}`);

  }
}

</script>

<style scoped>
.filter-input {
  width: 20%;
}

.selection-window {
  width: 380px;
  height: max-content;
  background-color: white;
  box-shadow: var(--shadow);
}

.selection-window__item {
  width: 100%;
  height: 440px !important;
}

.window-title {
  color: var(--color-dark);
  font-size: 18px;
  margin-top: 0.4rem;
  text-align: center;
}

.selection-date {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.window-btn {
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>