// Взаимодействие суперпользователя с Сотрудниками 
import { ArrayUserClient, UserClient, UserClientCreationServer } from '@/types/userType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import useMainStore from '../../store/mainStore';
import axios from 'axios';

// Создание нового пользователя
async function createNewEmployeeDB( 
    firstName: string,
    lastName: string,
    email: string, 
    password: string, 
    isSuperuser: boolean, 
    hospitalId?: number | null,
    departmentId?: number | null,
    fullName?: string,
    patronymic?: string,
    ): Promise<UserClient> {
    try {
        if(isSuperuser) {
            hospitalId = undefined;
            departmentId = undefined;
        }
        if(hospitalId === null || departmentId === null) {
            hospitalId = undefined;
            departmentId = undefined;
        }
        // Создание сущности и получение её с сервера
        const response = await axios.post(hostname + 'api/cp/users', {
            full_name: fullName,
            first_name: firstName,
            last_name: lastName,
            patronymic: patronymic,
            email: email,
            password: password,
            is_superuser: isSuperuser,
            hospital_id: hospitalId,
            department_id: departmentId,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/employeesApi: createNewEmployeeDB  => ${err}`);
    }
}

// Проверка существования пользователя по E-mail или Телефону
async function existsUserDB(email?: string, tel?: string): Promise<boolean> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.get(hostname + 'api/cp/users/exists', {
            params: {
                email,
                tel,
            },
            headers: {
                'Content-Type': 'application/www-form-urlencoded',
            }
        });
        const { data: { data } } = response;
        return data?.exists;
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/employeesApi: createNewEmployeeDB  => ${err}`);
    }
}

// Получение пользователя по ID
async function getUserById(userId: number): Promise<UserClient> {
    try {
        const response = await axios.get(hostname + `api/cp/users/${userId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/employeesApi: getUsersById  => ${err}`);
    }
}

// Получение списка пользователей с БД
async function getEmployeesDB(page?: number, isActive?: boolean, isSuperuser?: boolean, email?: string, fullName?: string): Promise<ArrayUserClient> {
    const store = useMainStore();
    try {
        const response = await axios.get(hostname + 'api/cp/users', {
            params: {
                page,
                email,
                full_name: fullName,
                is_active: isActive,
                is_superuser: isSuperuser,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            }
        });
        const { data } = response;
        const user = data?.data;
        const paginator = data?.paginator;
        // Задаем в стор информацию о том доступны ли следующие страницы пагинации
        store.isEmployeePaginationHasNext = paginator?.has_next;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(user);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/employeesApi: getEmployeesDB  => ${err}`);
    }
}

// Изменение активности пользователя
async function changeStatusEmployeeById(userId: number, isActive: boolean) {
    try {
        // Обновление статуса пользователя и получение его с сервера
        const response = await axios.put(hostname + `api/cp/users/${userId}/is_active`, {
            is_active: isActive,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/employeesApi: changeStatusEmployeeById  => ${err}`);
    }
}


// Обновление пользователя по ID
async function putEmployeeById(userId: number, changedFields: UserClientCreationServer): Promise<UserClient> {
    try {
        // Если пользователь назначается как суперпользователь, то удаляются поля hospitalId и departmentId
        if(changedFields.is_superuser === true) {
            changedFields.hospital_id = undefined;
            changedFields.department_id = undefined;
        }
        // Обновление статуса пользователя и получение его с сервера
        const response = await axios.put(hostname + `api/cp/users/${userId}`, {
            ...changedFields,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/employeesApi: changeStatusEmployeeById  => ${err}`);
    }
}

async function createSends(title: string, copy: string, link?: string): Promise<any> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.post(hostname + 'api/cp/notifications/broadcasts', {
            title: title,
            body: copy,
            link: link || null
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/employeesApi: createNewEmployeeDB  => ${err}`);
    }
}


export {
    createNewEmployeeDB,
    existsUserDB,
    getEmployeesDB,
    changeStatusEmployeeById,
    putEmployeeById,
    getUserById,
    createSends,
}