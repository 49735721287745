// Взаимодействие суперпользователя с Больницами 
import { UserClient } from '../../types/userType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import axios from 'axios';


// Получение информации о своей больнице (по токену)
async function getInfoProfileChief(): Promise<UserClient> {
    try {
        const response = await axios.get(hostname + `api/users/me`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/profileApi: getInfoMyHospital  => ${err}`);
    }
}


// Обновление общих даных профиля суперпользователя
async function updateProfileGeneralData__chief(firstName?: string, lastName?: string, patronymic?: string, email?: string): Promise<UserClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.put(hostname + 'api/users/me/', {
            first_name: firstName,
            last_name: lastName,
            patronymic: patronymic,
            email: email,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/profileApi: updateProfileGeneralData__chief  => ${err}`);
    }
}

// Обновление автарки профиля Управляющего
async function updateProfileAvatar__chief(avatar?: File | null): Promise<UserClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.put(hostname + 'api/users/me/avatars', {
            avatar: avatar,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/profileApi: updateProfileAvatar__chief  => ${err}`);
    }
}

export {
    getInfoProfileChief,
    updateProfileGeneralData__chief,
    updateProfileAvatar__chief,
}