<template>
  <div class="employees-main">
    <div class="employees-main__wrapper">
      <employeesListComp
        :employees="employees"
        @create-new-user="(user) => appendNewUser(user)"
        @update-user="(employee) => updateEmployee(employee)"
        @pagination-up="uploadNewPageItems"
        @user-filter="(filterData) => filteredUsers(filterData)"
        :is-not-employees="isNotEmployees"
        :is-not-employees-msg="causeEmptyMsg"
        :is-loading-data="isLoadingData"
        :is-active-employees="isActive"
      />
    </div>

    <button class="employees-send__btn" @click="showSends = true">
      <v-icon icon="mdi-send" color="white"></v-icon>

      <span class="employees-send__text">Рассылка</span>
    </button>

    <v-dialog
      v-model="showSends"
      max-width="800px"
    >

      <employeesSendsDialog @close="showSends = false"/>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
// COMPONENTS
import employeesListComp from './employeesListComp.vue';
import EmployeesSendsDialog from "./employeesSendsDialog.vue";
// API
import {getEmployeesDB} from '../../api/superuser/employeesApi';
import {getEmployeesDB__chief} from '../../api/hospitalChief/employeesApi';
import {getShiftsDB} from '../../api/superuser/shiftApi';
import {getShiftsDB__chief} from '../../api/hospitalChief/shiftApi';
// TYPES
import {ArrayUserClient, UserClient} from '../../types/userType'
import {ShiftClient} from '../../types/shiftType';
// STORE
import useAuthStore from '../../store/authStore';
import useDataStore from '../../store/dataStore';
import useMainStore from '../../store/mainStore';
// VUE
import {onMounted, ref} from 'vue';

const dataStore = useDataStore();
const authStore = useAuthStore();
const store = useMainStore();

// ===========================   DATA   ==========================
const employees = ref<ArrayUserClient>([]);
const isNotEmployees = ref<boolean>(false);
const causeEmptyMsg = ref<string>('Пользователи не найдены');
const causeList = ref<Array<string>>([
  "Пользователи не найдены",
  "Пользователей с таким именем нет",
  "Пользователей с таким E-mail нет",
  "Не активных пользователей нет",
  "На смене никого нет",
]);
const isActive = ref<boolean>(true);
const isLoadingData = ref<boolean>(false);
const page = ref<number>(1);
const showSends = ref<boolean>(false);


// ===========================   METHODS   ==========================
// УПРАВЛЕНИЕ НЕ ДОХОДИТ ДО ЭТОЙ ФУНКЦИИ ТАК КАК СТРАНЦИА ПЕРЕЗАГРУЖАЕТСЯ РАНЬШЕ
function updateEmployee(employeeUpdated: UserClient) {
  try {
    if (dataStore.employees.length) {
      dataStore.employees.forEach((employee: UserClient, index: number) => {
        if (employeeUpdated.id === employee.id) {
          dataStore.employees.splice(index, 1, employeeUpdated)
          employees.value.splice(index, 1, employeeUpdated)
        }
      });
    }
  } catch (err) {
    throw new Error(`components/employees/employeesComp: updateEmployee  => ${err}`);
  }
}

// Функция добавляет нового пользователя после его создания  
function appendNewUser(user: UserClient) {
  try {
    dataStore.employees.push(user);
    employees.value = dataStore.employees;
  } catch (err) {
    throw new Error(`components/employees/employeesComp: appendNewUser  => ${err}`);
  }
}

// Получение всех пользователей с сервера
async function getEmployees(page?: number | undefined, isActive?: boolean | undefined, isSuperuser?: boolean | undefined, email?: string | undefined, fullName?: string | undefined) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getEmployeesDB(page, isActive, isSuperuser, email, fullName);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getEmployeesDB__chief(page, isActive, email, fullName);
  } catch (err) {
    throw new Error(`components/employees/employeesComp: getEmployees  => ${err}`);
  }
}

// Получение всех Смен с сервера
async function getShifts(
  page?: number | undefined,
  isActive?: boolean | undefined,
  userId?: number | undefined,
  hospitalId?: number | undefined,
  departmentId?: number | undefined,
  startedFrom?: number | undefined,
  startedTo?: number | undefined,
  endedFrom?: number | undefined,
  endedTo?: number | undefined
) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getShiftsDB(page, isActive, userId, hospitalId, departmentId, startedFrom, startedTo, endedFrom, endedTo);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getShiftsDB__chief(page, isActive, userId, departmentId, startedFrom, startedTo, endedFrom, endedTo);
  } catch (err) {
    throw new Error(`components/employees/employeesComp: getShifts  => ${err}`);
  }
}

// Подгрузка новых данных по пагинации
async function uploadNewPageItems() {
  try {
    // Проверка, если на сервере есть следующая страница с данными то выполнять подгрузку этой страницы
    if (store.isEmployeePaginationHasNext === true) {
      page.value += 1;
      const employeesNextPage: any = await getEmployees(page.value, isActive.value);
      // Добавляем в уже существующий массив элементов новые элементы пришедшие с другой страницы пагинации
      dataStore.employees = dataStore.employees.concat(employeesNextPage);
      employees.value = dataStore.employees;
    }
  } catch (err) {
    throw new Error(`components/employees/employeesComp: uploadNewPageItems  => ${err}`);
  }
}

// Функция фильтрует массив пользователь относительно фильтр-данных
async function filteredUsers(filterData: {
  email: string | undefined,
  fullname: string | undefined,
  isShifter: boolean,
  isActive: boolean
}) {
  try {
    isLoadingData.value = true
    isActive.value = filterData.isActive;
    // Если фильтр-данные пусты, то возвращаем список пользователей в исходное состояние
    if (filterData.email === undefined && filterData.fullname === undefined && !filterData.isShifter && filterData.isActive) {
      // Убираем сообщения о пустом массиве пользователей
      causeEmptyMsg.value = causeList.value[0];
      isNotEmployees.value = false;
      isActive.value = true;
      if (dataStore.employees.length) {
        employees.value = dataStore.employees;
      } else {
        employees.value = await getEmployees(page.value, filterData.isActive);
      }
      // Возвращаем переменные для пагинации в исходное состояние
      isActive.value = true;
      store.isEmployeePaginationHasNext = true;
      page.value = 1;
      return;
    } else {
      // Если данные для фильтрации были указаны то получаем весь список пользователей в системе и фильтруем его по фильтр данным
      let filteredDataList = await getEmployees(undefined, filterData.isActive);

      // Фильтрация по isShifter
      if (filterData.isShifter) {
        const shifts = await getShifts(undefined, filterData.isActive);
        // Получаем массив всех сотрудников которые сейчас на смене
        filteredDataList = shifts.map((shift: ShiftClient) => {
          return shift.user;
        });
      }

      // Фильтрация по полному Имени
      if (filterData.fullname) {
        filteredDataList = filteredDataList.filter((employee: UserClient) => {
          // фильтрация по имени
          if (filterData.fullname) {
            return employee.fullName?.toLocaleLowerCase()?.includes(filterData.fullname.toLocaleLowerCase());
          }
        });
      }

      employees.value = filteredDataList;
    }
  } catch (err) {
    throw new Error(`components/employees/employeesComp: filteredUsers  => ${err}`);
  } finally {
    isLoadingData.value = false
  }
}

// ===========================   LYFECYCLE HOOKS   ==========================
// Получение массива СОТРУДНИКОВ с БД
onMounted(async () => {
  try {
    isLoadingData.value = true;
    // Если ранее не был получен список пользователей, то получаем его с сервера
    if (!dataStore.employees.length) {
      employees.value = [];
      const respondedEmployees = await getEmployees(page.value, isActive.value);
      if (respondedEmployees.length > 0) {
        dataStore.employees = respondedEmployees;
        employees.value = dataStore.employees;
        isNotEmployees.value = false;
      } else isNotEmployees.value = true;
    } else {
      // Если в dataStore есть массив сотрудников, то данные не запрашиваются у сервера
      employees.value = dataStore.employees;
    }
  } catch (err) {
    throw new Error(`components/employees/employeesComp: onMounted  => ${err}`);
  } finally {
    isLoadingData.value = false;
  }
});

</script>

<style scoped>
.employees-main {
  width: 100%;
  height: 100%;
  padding: 0 1rem 0 4rem;
}

.employees-main__wrapper {
  width: 100%;
  height: 107%;
  margin-top: 1rem;
  background-color: var(--bg-color-white);
}

.employees-send__btn {
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  max-width: 48px;
  border-radius: 8px;
  background: var(--bg-color-default);
  color: #FFFFFF;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  z-index: 10;

  .employees-send__text {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
  }

  &:hover {
    max-width: 100%;

    .employees-send__text {
      opacity: 1;
    }
  }
}
</style>