<template>
  <v-dialog v-model="modelValue" width="auto">
    <v-card class="dialog__wrapper">

      <!-- Крутилка загрузки -->
      <span class="progress-circular" v-if="isShowLoadingData">
        <v-progress-circular
          indeterminate
          bg-color="var(--bg-color-white)"
          color="var(--bg-color-default)"
          :size="60"
          :width="6"
        ></v-progress-circular>
      </span>
      <form class="dialog__form">

        <!-- Блок с аватаркой -->
        <div class="dialog__form--preview-block">
          <avatarInputComp
            :for-preview="true"
            :avatar="avatar"
            :is-show-upload="false"
          />
          <h1 class="preview-username">{{ fullName }}</h1>
        </div>

        <!-- Общие данные -->
        <div class="dialog__form--general-data">
          <v-card-title class="data-title">Общие данные</v-card-title>
          <div class="data-body">
            <!-- Фамилия -->
            <div class="data-body__item">
              <v-card-subtitle class="mb-2">Фамилия</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model.trim="lastname"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
                :placeholder="'Иванов'"
                @update:model-value="lastnameErrorMsg = ''"
                :rules="lastnameRules"
                :error-messages="lastnameErrorMsg"
              ></v-text-field>
            </div>

            <!-- Имя -->
            <div class="data-body__item">
              <v-card-subtitle class="mb-2">Имя</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model.trim="firstname"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
                :placeholder="'Иван'"
                @update:model-value="firstnameErrorMsg = ''"
                :rules="firstnameRules"
                :error-messages="firstnameErrorMsg"
              ></v-text-field>
            </div>

            <!-- Отчество -->
            <div class="data-body__item">
              <v-card-subtitle class="mb-2">Отчество</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model.trim="patronymic"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
                :placeholder="'Иванович'"
                hide-details
              ></v-text-field>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Учетные данные -->
        <div class="dialog__form--credentials-data">
          <v-card-title class="data-title">Учетные данные</v-card-title>

          <!-- Email -->
          <v-card-subtitle class="mb-2">E-mail</v-card-subtitle>
          <v-text-field
            class="general-data__input"
            bg-color="var(--bg-color-white)"
            v-model="email"
            color="var(--color-default)"
            rounded="xs"
            :density="'compact'"
            :type="'email'"
            variant="outlined"
            :placeholder="'ivanivanov@ex.com'"
            @update:model-value="emailErrorMsg = ''"
            :rules="[...emailRulesForUpdated, ...existsUserByEmail()]"
            :error-messages="emailErrorMsg"
          ></v-text-field>

          <!-- Привязать сотрудника к Организации -->
          <hospitalListComp
            v-if="authStore.isSuperUser === true"
            style="width: 100% !important; margin-top: 1rem;"
            :label-compbox="(selectedHospitalName)? '' : 'Укажите организацию'"
            :header-title="'Организация, к которой привязан сотрудник'"
            @clear-hospital="handlerClearHospital"
            @select-hospital="(hospital) => handlerSelectHospital(hospital)"
            :selected-hospital-name="selectedHospitalName"
            :is-disabled="isDisabledHospital"
          />

          <!-- Привязать сотрудника к отделению -->
          <departmentListComp
            style="width: 100% !important; margin-top: 1rem;"
            :label-compbox="(selectedDepartmentName)? '' : 'Укажите отделение'"
            :header-title="'Отделение, к которому привязан сотрудник'"
            :item-list="departmentList"
            @load-items="loadDepartments"
            @clear-department="clearDepartment"
            @select-department="(department) => handlerSelectDepartment(department)"
            :filter-by-hospital-id="hospitalId"
            :selected-department-name="selectedDepartmentName"
            :is-disabled="isDisabledDepartment"
          />

          <!-- Назначить суперпользователем -->
          <v-switch
            v-model="isSuperuser"
            @update:model-value="(isChecked: boolean | null) => handlerCheckedSuperuser(isChecked)"
            base-color="var(--bg-color-second)"
            color="var(--bg-color-second)"
            :label="isSuperuserLabel"
            inset
          ></v-switch>

          <!-- Статус -->
          <div style="display: flex; align-items: center;">
            <v-card-subtitle>Статус</v-card-subtitle>
            <v-card-subtitle
              class="ma-0 pa-0"
              style="font-weight: bolder;"
              :style="((isActive === false)? { color: 'red' } : { color: 'var(--color-default)' })"
            >
              {{ (isActive === true) ? 'Активен' : 'Не активен' }}
            </v-card-subtitle>
          </div>
        </div>
      </form>
      <!-- Панель действий -->
      <div class="dialog__actions">
        <!-- Сохранить -->
        <v-btn
          class="mr-6"
          variant="flat"
          color="var(--bg-color-default)"
          style="color: white;"
          :loading="isLoadingConfirmData"
          @click="confirmToUpdateEmployee"
        >
          Сохранить
        </v-btn>
        <!-- Сбросить изменения -->
        <v-btn
          variant="tonal"
          style="margin-right: auto; font-size: 0.8rem;"
          color="var(--bg-color-default)"
          @click="resetChanges"
        >
          Сбросить изменения
        </v-btn>

        <!-- Закрыть -->
        <v-btn
          variant="outlined"
          color="red"
          @click="handlerClose">
          Закрыть
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
// COMPONENTS
import hospitalListComp from '../departments/hospitalListComp.vue';
import departmentListComp from '../hospitalRooms/departmentListComp.vue';
import avatarInputComp from '../general/avatarInputComp.vue';
// TYPES
import {UserClient, UserClientCreationClient, UserClientCreationServer} from '../../types/userType';
import {HospitalClient} from '../../types/hospitalType';
import {DepartmentClient} from '../../types/departmentType';
// COMPOSABLES
import useValidationUserFields from '../../composable/validationUserFields'
import useLoadingHospitals from '../../composable/loadHospitalList';
import useLoadingDepartments from '../../composable/loadDepartmentList';
// STORE
import useMainStore from '../../store/mainStore';
import useDataStore from '../../store/dataStore';
import useAuthStore from '../../store/authStore';
// API
import {getAllHospitalDB} from '../../api/superuser/hospitalsApi';  // Superuser
import {getUserById, putEmployeeById, existsUserDB} from '../../api/superuser/employeesApi';  // Superuser
import {getUserById__chief, putEmployeeById__chief} from '../../api/hospitalChief/employeesApi';  // Chief
// VUE
import {ref, defineModel, defineEmits, watch, onMounted} from 'vue';
import {useRoute} from 'vue-router';


const store = useMainStore();
const authStore = useAuthStore();
const dataStore = useDataStore();
const route = useRoute();

const modelValue = defineModel<boolean>();
const emit = defineEmits<{
  close: [],
  success: [],
  error: [msg: string],
  updateEmployee: [employee: UserClient]
}>();

// ============================  DATA  ============================
const isSuperuserLabel = ref<string>('Назначить суперпользователем');
const isShowLoadingData = ref<boolean>(false);
const isLoadingConfirmData = ref<boolean>(false);
const id = ref<null | number>(0);
const fullName = ref<string | null>(null);
const firstname = ref<string | null>(null);
const lastname = ref<string | null>(null);
const patronymic = ref<string | null>(null);
const email = ref<string>('');
const avatar = ref<string | null | undefined>(null);
const isSuperuser = ref<boolean>(false);
const hospitalName = ref<string | null>('');
const isActive = ref<boolean>(false);
const password = ref<string | null>(null);
const isDisabledHospital = ref<boolean>(false);
const isDisabledDepartment = ref<boolean>(false);
const hospitalId = ref<number | null>(null);
const departmentId = ref<number | null>(null);
const empolyeeFormComp = ref({
  firstname: null as null | string,
  lastname: null as null | string,
  patronymic: null as null | string,
  email: null as null | string,
  isSuperuser: false as boolean,
  avatar: null as null | string,
  isActive: false,
  password: null as null | string,
  selectedHospitalName: null as null | string,
  selectedDepartmentName: null as null | string,
});
const {
  isValidEmail,
  firstnameErrorMsg,
  lastnameErrorMsg,
  emailErrorMsg,
  firstnameRules,
  lastnameRules,
  emailRulesForUpdated,
} = useValidationUserFields();

// Компонуемый файл для получения списка больниц и отображения их в выпадающем меню
const {
  selectedHospital,
  selectedHospitalName,

  clearHospital,
  selectHospital,
} = useLoadingHospitals();

// Компонуемый файл для получения списка отделений и отображения их в выпадающем меню
const {
  departmentList,
  selectedDepartment,
  selectedDepartmentName,

  loadDepartments,
  clearDepartment,
  selectDepartment,
} = useLoadingDepartments();

// ============================  METHODS  ============================

// Обработчик отчистки поляя ввода больницы
function handlerClearHospital() {
  clearHospital();
  selectedDepartment.value = null;
  selectedDepartmentName.value = null;
}

function handlerCheckedSuperuser(isChecked: boolean | null) {
  try {
    if (isChecked === true) {
      isDisabledHospital.value = true;
      isDisabledDepartment.value = true;
    } else {
      isDisabledHospital.value = false;
      isDisabledDepartment.value = false;
    }
  } catch (err) {
    throw new Error(`components/employees/dialogComp.vue: handlerCheckedSuperuser  => ${err}`);
  }
}

// Обработчик выбора отделения
function handlerSelectDepartment(department: DepartmentClient | null) {
  try {
    if (selectedHospital.value === null) {
      dataStore.hospitals.forEach((hospital: HospitalClient) => {
        if (hospital.id === department?.hospital.id) {
          selectedHospitalName.value = hospital.name;
          selectHospital(hospital);
        }
      });
    }
    if(department) {
        selectedDepartment.value = department;
        selectedDepartmentName.value = department?.name;
        selectDepartment(department);
    }
  } catch (err) {
    throw new Error(`components/employees/dialogComp.vue: handlerSelectDepartment  => ${err}`);
  }
}

// Обработчик выбора больницы
function handlerSelectHospital(hospital: HospitalClient | null) {
    try {
        if(hospital) {
            selectHospital(hospital);
            hospitalId.value = hospital.id
            departmentId.value = null;
            selectedDepartment.value = null;
            selectedDepartmentName.value = null;
        }
    } catch (err) {
        throw new Error(`components/employees/dialogComp.vue: handlerSelectHospital  => ${err}`);
    }
}

// Функция возвращает правило проверки email на существование пользователя по такому e-mail
function existsUserByEmail(): any[] {
  return [
    async (value: string) => {
      // Делать запрос на проверку пользователя только при заполненном поле и в том случае если введенный email не равен текущему e-mail пользователя
      if (value.length > 0 && value !== empolyeeFormComp.value.email) {
        // Проверка на существование пользователя с таким E-mail в базе данных
        const exists = await existsUserDB(value, undefined);
        if (exists === false) {
          isValidEmail.value = true;
          return true;
        } else {
          if (empolyeeFormComp.value.email === null) {
            isValidEmail.value = true;
            return true;
          } else {
            if (value === empolyeeFormComp.value.email) {
              isValidEmail.value = true;
              return true;
            }
            isValidEmail.value = false;
            emailErrorMsg.value = 'Пользователь с таким E-mail уже существует'; // записываем сообщение об ошибке для ее сохраенения если пользователь закрывает форму
            return 'Пользователь с таким E-mail уже существует';
          }
        }
      } else {
        emailErrorMsg.value = ''; // записываем сообщение об ошибке для ее сохраенения если пользователь закрывает форму
        return true;
      }
    }
  ]
}

// Получение текущего пользователя по ID
async function getUserByIdGeneral(userId: number) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getUserById(userId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getUserById__chief(userId);
  } catch (err) {
    throw new Error(`components/employees/dialogComp.vue: getUserByIdGeneral  => ${err}`);
  }
}

// Изменение данных текущего пользователя по ID
async function putEmployeeByIdGeneral(userId: number, changedFields: UserClientCreationServer) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await putEmployeeById(userId, changedFields);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await putEmployeeById__chief(userId, changedFields);
  } catch (err) {
    throw new Error(`components/employees/dialogComp.vue: putEmployeeByIdGeneral  => ${err}`);
  }
}

// функция собирает ключи объекта с полученного массива entries функции compareObjectsFetchFields 
// И подготавливает объект к отправке на сервер. Собирает только те поля которые фактически были изменены
function bundleDataForSend(entries: Array<any>): UserClientCreationClient {
  try {
    const changedFields: object | any = {};
    let firstnameChanged: string | null = null;
    let lastnameChanged: string | null = null;
    let patronymicChanged: string | null = null;
    // В цикле собираем объект тех ключей, чьи значения действительно были изменены,
    // чтобы отправить этот объект на сервер для изменения пользователя
    entries.forEach((entry) => {
      if (entry[0] === 'selectedHospitalName') {
        changedFields.hospitalId = selectedHospital.value?.id;
      } else if (entry[0] === 'selectedDepartmentName') {
        changedFields.departmentId = selectedDepartment.value?.id;
      }
      // собираем fullName из полей ввода ФИО. Если какое-то поле было изменено, то мы добавялем его в состав fullName
      else if (entry[0] === 'firstname' || entry[0] === 'lastname' || entry[0] === 'patronymic') {
        if (entry[0] === 'firstname') {
          firstnameChanged = entry[1];
          changedFields.firstName = firstnameChanged?.split(' ').join('');
          firstnameChanged = changedFields.firstName;
        } else firstnameChanged = firstname.value;

        if (entry[0] === 'lastname') {
          lastnameChanged = entry[1];
          changedFields.lastName = lastnameChanged?.split(' ').join('');
          lastnameChanged = changedFields.lastName;
        } else lastnameChanged = lastname.value;

        if (entry[0] === 'patronymic') {
          patronymicChanged = entry[1];
          changedFields.patronymic = patronymicChanged?.split(' ').join('');
          patronymicChanged = changedFields.patronymic;
        } else patronymicChanged = patronymic.value;
      } else {
        changedFields[entry[0]] = entry[1];
      }
    });
    // Сопоставление частей полного имени, если некоторые поля пустые то они не вставляются
    const readyFullName = [lastnameChanged, firstnameChanged, patronymicChanged].join(' ').trim();
    if (readyFullName.length) {
      changedFields.fullName = readyFullName;
    }
    return changedFields;
  } catch (err) {
    throw new Error(`components/employees/dialogComp.vue: bundleDataForSend  => ${err}`);
  }
}

// Подтверждение обновления выбранного пользователя
async function confirmToUpdateEmployee() {
  try {
    // сравниваем два объекта на наличие изменения данных в полях ввода (Для исключения пустого запроса)
    const {entries, isCompare} = store.compareObjectsFetchFields(empolyeeFormComp.value, {
      firstname: firstname.value,
      lastname: lastname.value,
      patronymic: patronymic.value,
      email: email.value,
      avatar: avatar.value,
      isSuperuser: isSuperuser.value,
      isActive: isActive.value,
      password: password.value,
      selectedHospitalName: selectedHospitalName.value,
      selectedDepartmentName: selectedDepartmentName.value,
    });

    // Если два объекта отличаются то обрабатываем запрос к серверу на изменение данных
    if (isCompare === false) {
      // Если изменения были внесены в форму и валидация полей прошла все проверки, то выполняется запрос на обновление пользователя
      if (isValidEmail.value === true) {
        try {
          isLoadingConfirmData.value = true;
          if (id.value) {
            const filedsForUpdated = bundleDataForSend(entries);
            const fieldsChangedSnake: UserClientCreationServer = store.camelToSnake(filedsForUpdated);
            const updatedEmployee = await putEmployeeByIdGeneral(id.value, fieldsChangedSnake);
            emit('updateEmployee', updatedEmployee);
            emit('success');
            store.activeSuccessOperation(1200);
          }
        } catch (err) {
          throw new Error(`components/employees/dialogComp.vue: запрос на обновление пользователя  => ${err}`);
        } finally {
          isLoadingConfirmData.value = false;
        }
      } else {
        emit('error', 'Проверьте правильность введенных данных');
      }
    }
    
  } catch (err) {
    throw new Error(`components/employees/dialogComp.vue: confirmToUpdateHospital  => ${err}`)
  }

}

// Функция разбивает ключи получаемого объекта по реактивным переменным для отображаения их в полях ввода
async function parsingObject(employee: UserClient) {
  try {
    id.value = employee.id;
    // ФИО пользователя
    fullName.value = employee.fullName;
    firstname.value = employee.firstName;
    lastname.value = employee.lastName;
    patronymic.value = employee.patronymic;
    if (employee.firstName === null && employee.lastName === null && employee.patronymic === null) {
      const splitterFullName = employee.fullName.split(' ');
      lastname.value = splitterFullName[0]
      firstname.value = splitterFullName[1];
      patronymic.value = splitterFullName[2];
    }
    if (employee.email) email.value = employee.email;
    if (typeof employee.isActive === 'boolean') isActive.value = employee.isActive;

    avatar.value = employee.avatar;
    isSuperuser.value = employee.isSuperuser;
    if (isSuperuser.value === true) {
      isSuperuserLabel.value = 'Назначен суперпользователем';
      isDisabledHospital.value = true;
      isDisabledDepartment.value = true;
    }
    // Запись Больницы управляющим, которой является сотрудник
    selectedHospitalName.value = employee.hospital;

    
    let hospitalsList: Array<HospitalClient> = [];
    if (dataStore.hospitals.length > 0) {
      hospitalsList = dataStore.hospitals;
    } else {
      if (authStore.isSuperUser === true) {
        hospitalsList = await getAllHospitalDB(false, undefined);
      }
    }
    // Добавление выбранной больницы в selectedHospital
    hospitalsList.forEach((hospital: HospitalClient) => {
      if (hospital.name === selectedHospitalName.value) {
        selectedHospital.value = hospital;
      }
    });
    if(employee.hospitalId) hospitalId.value = employee.hospitalId;
    // Запись Отделения владельцем которой является сотрудник
    selectedDepartmentName.value = employee.department;
    if(employee.departmentId) departmentId.value = employee.departmentId;
  } catch (err) {
    throw new Error(`components/employees/dialogComp.vue: parsingObject  => ${err}`);
  }
}

// Функция применяется для создания копии объекта формы
function createCopyForm() {
  try {
    empolyeeFormComp.value = {
      firstname: firstname.value,
      lastname: lastname.value,
      patronymic: patronymic.value,
      email: email.value,
      avatar: avatar.value,
      isSuperuser: isSuperuser.value,
      isActive: isActive.value,
      password: password.value,
      selectedDepartmentName: selectedDepartmentName.value,
      selectedHospitalName: selectedHospitalName.value,
    }
  } catch (err) {
    throw new Error(`components/employees/dialogComp.vue: createCopyForm  => ${err}`);
  }
}

// Сбросить изменения в форме 
function resetChanges() {
  try {
    const isCompare = store.compareObjects(empolyeeFormComp.value, {
      firstname: firstname.value,
      lastname: lastname.value,
      patronymic: patronymic.value,
      email: email.value,
      avatar: avatar.value,
      isSuperuser: isSuperuser.value,
      isActive: isActive.value,
      password: password.value,
    });
    if (isCompare === false) {
      firstname.value = empolyeeFormComp.value.firstname;
      lastname.value = empolyeeFormComp.value.lastname;
      patronymic.value = empolyeeFormComp.value.patronymic;
      if (empolyeeFormComp.value.email) email.value = empolyeeFormComp.value.email;
      isSuperuser.value = empolyeeFormComp.value.isSuperuser;
      hospitalName.value = '';
      isActive.value = empolyeeFormComp.value.isActive;
      password.value = null;
      avatar.value = null;
    }
  } catch (err) {
    throw new Error(`components/hospitals/hospitalDialogComp.vue: resetChanges  => ${err}`)
  }
}

// Обработчик закрытия формы
function handlerClose() {
  id.value = 0;
  hospitalName.value = '';
  firstname.value = '';
  lastname.value = '';
  patronymic.value = '';
  email.value = '';
  isSuperuser.value = false;
  selectedHospital.value = null;
  empolyeeFormComp.value = {
    firstname: null,
    lastname: null,
    patronymic: null,
    email: null,
    avatar: null,
    isSuperuser: false,
    isActive: false,
    password: null,
    selectedHospitalName: null,
    selectedDepartmentName: null,
  }
  isDisabledHospital.value = false;
  isDisabledDepartment.value = false;
  emit('close');
}

// Получение данных выбранного объекта в списке
watch(() => route.query['select-user'], async (userId) => {
  try {
    isShowLoadingData.value = true;
    if (typeof userId === 'string' && +userId > 0) {
      const employee = await getUserByIdGeneral(+userId);
      // Заполняем поля формы при открытии информации о пользователе
      await parsingObject(employee);
      // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
      createCopyForm();
    }
  } catch (err) {
    throw new Error(`components/employees/dialogComp.vue: watch[fetch user-data]  => ${err}`)
  } finally {
    isShowLoadingData.value = false;
  }
});

// ============================  LIFECYCLE HOOKS  ============================
onMounted(async () => {
  // Получение query параметра select-user
  try {
    if (route.query['select-user']) {
      const userIdQuery = route.query['select-user']
      if (typeof userIdQuery === 'string' && +userIdQuery > 0) {
        // Загружаем данные выбранного пользователя если query параметр select-user существует
        try {
          isShowLoadingData.value = true;
          const employee = await getUserByIdGeneral(+userIdQuery);
          await parsingObject(employee);
          // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
          createCopyForm();
        } catch (err) {
          throw new Error(`components/employees/dialogComp.vue: onMounted[fetch user-data]  => ${err}`)
        } finally {
          isShowLoadingData.value = false;
        }
      }
    } else {
      emit('close');
    }
  } catch (err) {
    throw new Error(`components/employees/dialogComp.vue: onMounted[fetch query-param - 'select-user']  => ${err}`)
  }
});

</script>

<style scoped>
.progress-circular {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
  z-index: 999;
}

.dialog__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  background-color: var(--bg-color-block);
}

.dialog__form {
  width: 100%;
  height: max-content;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-block);
}

.dialog__form--preview-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  padding: 2rem 0;
}

.preview-username {
  font-size: 26px;
  font-weight: 700;
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: var(--bg-color-white);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.data-body {
  display: flex;
  gap: 8px;
}

.data-body__item {
  flex: 1;
}

.data-title {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 17px;
  text-align: left;
  width: max-content;
  padding: 0.4rem 0 !important;
}

.dialog__form--general-data {
  width: 80%;
  padding: 2rem 2rem 0 2rem;
  background-color: var(--bg-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.general-data__input {
  margin-bottom: 1rem;
}

.dialog__form--credentials-data {
  width: 80%;
  padding: 0 2rem;
  /* border-top: 2px solid rgba(128, 128, 128, 0.316); */
  background-color: var(--bg-color-white);
}
</style>