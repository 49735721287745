// Взаимодействие суперпользователя с Больницами 
import { ArrayHospitalClient, HospitalClient, HospitalForCreate } from '@/types/hospitalType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import axios from 'axios';

// Создание новой больницы
async function createNewHospitalDB(name: string, address: string, chiefId: number | null): Promise<HospitalClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.post(hostname + 'api/cp/hospitals/', {
            name,
            address,
            chief_id: chiefId,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data)
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalsApi: createNewHospitalDB  => ${err}`);
    }
}

// Получение всех больниц с сервера
async function getAllHospitalDB(isDeleted?: boolean, page?: number): Promise<ArrayHospitalClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + 'api/cp/hospitals', {
            params: {
                deleted: isDeleted,
                page,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalsApi: getAllHospitalDB  => ${err}`);
    }
}

// Получение больницы по ID
async function getHospitalByID(hospitalId: number): Promise<HospitalClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/cp/hospitals/${hospitalId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalsApi: getHospitalByID  => ${err}`);
    }
}

// Изменить больницу по ID
async function putHospitalById(hospitalId: number, changedFields: HospitalForCreate): Promise<HospitalClient> {
    try {
        // Обновление сущности и получение её с сервера
        const response = await axios.put(hostname + `api/cp/hospitals/${hospitalId}`, {
            ...changedFields,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalsApi: createNewHospitalDB  => ${err}`);
    }
}

// Изменение активности больницы по ID
async function putStatusHospitalById(hospitalId: number, deleted: boolean ): Promise<HospitalClient> {
    try {
        // Обновление статуса сущности и получение её с сервера
        const response = await axios.put(hostname + `api/cp/hospitals/${hospitalId}/is_active`, {
            deleted,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalsApi: putStatusHospitalById  => ${err}`);
    }
}


export {
    createNewHospitalDB,
    getAllHospitalDB,
    getHospitalByID,
    putHospitalById,
    putStatusHospitalById,
}