<template>
  <v-dialog v-model="modelValue" width="auto">

    <v-card class="dialog__wrapper">

      <form class="dialog__form">
        <!-- Превью -->
        <div class="dialog__form--preview-block">
          <input id="preview-image" v-show="false"/>
          <label
            class="preview-image"
            for="preview-image"
          >
            <v-icon
              icon="mdi-water-outline"
              color="var(--color-default)"
              size="80px"
            ></v-icon>
          </label>
          <h1 class="preview-name">Создание Дроппера</h1>
        </div>

        <!-- Общие данные -->
        <div class="dialog__form--general-data">
          <!-- Имя -->
          <v-card-subtitle class="mb-2">* ID Дроппера</v-card-subtitle>
          <v-text-field
            class="general-data__input"
            bg-color="var(--bg-color-white)"
            :rules="inputRules"
            v-model="vendorID"
            color="var(--color-default)"
            rounded="xs"
            :placeholder="'ID'"
            :density="'compact'"
            variant="outlined"
          ></v-text-field>

          <!-- Выбор Организаци -->
          <hospitalListComp
            v-if="authStore.isSuperUser === true"
            style="width: 100% !important; margin-top: 1rem;"
            :label-compbox="'Организация'"
            :item-list="hospitalList"
            :header-title="'Выберите организацию'"
            @load-items="loadHospitals"
            @close-menu="hospitalList.length = 0"
            @clear-hospital="handlerClearHospital"
            @select-hospital="(hospital) => handlerSelectHospital(hospital)"
            :selected-hospital-name="selectedHospitalName"
          />

          <!-- Выбор отделения -->
          <departmentListComp
            style="width: 100% !important; margin-top: 1rem;"
            :label-compbox="'Отделение'"
            :item-list="departmentList"
            :header-title="'Выберите отделение'"
            :filter-by-hospital-id="(authStore.isSuperUser === true)? hospitalId : null"
            @load-items="loadDepartments"
            @close-menu="departmentList.length = 0"
            @clear-department="handlerClearDepartment"
            @select-department="(department) => handlerSelectDepartment(department)"
            :selected-department-name="selectedDepartmentName"
            :is-disabled="hospitalId === null && authStore.isSuperUser === true"
          />
        </div>
      </form>
      <div class="dialog__actions">
        <v-btn
          class="mr-6"
          @click="confirmData"
          variant="flat"
          :loading="isLoading"
          style="color: white; font-weight: normal; font-size: 12px;"
          color="var(--bg-color-default)"
        >
          Сохранить
        </v-btn>

        <!-- Очистить форму -->
        <v-btn
          variant="tonal"
          style="margin-right: auto; font-size: 12px;"
          append-icon="mdi-reload"
          color="var(--bg-color-default)"
          @click="resetFormFields"
        >
          Очистить форму
        </v-btn>
        <v-btn
          variant="outlined"
          color="red"
          style="font-size: 12px;"
          @click="emit('close')">
          Закрыть
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
// COMPONENTS
import hospitalListComp from '../departments/hospitalListComp.vue';
import departmentListComp from '../hospitalRooms/departmentListComp.vue';
// API
import {createNewDropperDB} from '../../api/superuser/droppersApi';
import {createNewDropperDB__chief} from '../../api/hospitalChief/droppersApi';
// COMPOSABLES
import useLoadingHospital from '../../composable/loadHospitalList';
import useLoadingDepartmetns from '../../composable/loadDepartmentList';
// VUE
import {ref, defineModel, defineEmits, onMounted} from 'vue';
import {useRoute} from 'vue-router';
// TYPES
import {HospitalClient} from '../../types/hospitalType';
import {DepartmentClient} from '../../types/departmentType';
import {DropperClient} from '../../types/droppersType';
// STORE
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';

const mainStore = useMainStore();
const authStore = useAuthStore();
const route = useRoute();
const modelValue = defineModel<boolean>();

// ============================  EMITS  ============================
const emit = defineEmits<{
  close: [];
  error: [msg: string];
  success: [];
  appendDropper: [dropper: DropperClient];
}>();

// ============================  DATA  ============================
const isLoading = ref(false);
// const id = ref<number>(0);

const vendorID = ref<string | null>(null);
const hospitalId = ref<number | null>(null);
const departmentId = ref<number | null>(null);

const inputRules = ref([
  (value: string) => !!value || 'Обязательное поле',
]);

// Компонуемый файл для получения списка больниц и отображения их в выпадающем меню
const {
  hospitalList,
  selectedHospital,
  selectedHospitalName,

  loadHospitals,
  clearHospital,
  selectHospital,
} = useLoadingHospital();

// Компонуемый файл для получения списка отделений и отображения их в выпадающем меню
const {
  departmentList,
  selectedDepartment,
  selectedDepartmentName,

  loadDepartments,
  clearDepartment,
  selectDepartment,
} = useLoadingDepartmetns();

// ==========================================   METHODS   ==========================================

function handlerClearHospital() {
  clearHospital();
  hospitalId.value = null;
}

function handlerClearDepartment() {
  clearDepartment();
  departmentId.value = null;
}

// Функция обработчик для фиксирования ID БОЛЬНИЦЫ из селект-меню и сохранения выбранной БОЛЬНИЦЫ
function handlerSelectHospital(hospital: HospitalClient | null) {
  if (hospital) {
    hospitalId.value = hospital.id;
    selectHospital(hospital);
  }
}

// Функция обработчик для фиксирования ID ОТДЕЛЕНИЯ из селект-меню и сохранения выбранного ОТДЕЛЕНИЯ
function handlerSelectDepartment(department: DepartmentClient | null) {
  if (department) {
    departmentId.value = department.id;
    selectDepartment(department);
  }
}

// Создание нового Устройства
async function createNewDropper(vendorId: string, hospitalId: number, departmentId: number) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await createNewDropperDB(vendorId, hospitalId, departmentId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await createNewDropperDB__chief(vendorId, hospitalId, departmentId);
  } catch (err) {
    throw new Error(`components/droppers/creationDroppersComp.vue: createNewDropper  => ${err}`);
  }
}

// Подтверждение данных для создания нового Устройства
async function confirmData() {
  try {
    isLoading.value = true;
    if (
      departmentId.value &&
      vendorID.value &&
      vendorID.value.length > 0
    ) {
      // Если перешли на список коек через панель навигации
      if (selectedHospital.value === null && authStore.isSuperUser === true) return emit('error', 'Укажите больницу');
      if (selectedDepartment.value === null) return emit('error', 'Укажите отделение');

      // Если айдишника выбранной больницы нет и мы в роли управляющего, то берем hospitalId с localStorage (user)
      if (!hospitalId.value && authStore.isChief === true) {
        const chief = mainStore.fetchDecryptedUser();
        hospitalId.value = chief.hospitalId;
      }
      if (hospitalId.value) {
        const newDropper = await createNewDropper(vendorID.value, hospitalId.value, departmentId.value);
        emit('success');
        emit('appendDropper', newDropper);
      }
    } else {
      // Поднимаем ошибку если поля ввода пусты
      emit('error', 'Заполните все поля');
    }
  } catch (err) {
    throw new Error(`components/droppers/creationDroppersComp.vue: confirmData  => ${err}`);
  } finally {
    isLoading.value = false;
  }
}

// Функция сбрасывает поля формы
function resetFormFields() {
  try {
    vendorID.value = null;
    selectedDepartment.value = null;
    selectedHospital.value = null;
    selectedHospitalName.value = null
    selectedDepartmentName.value = null;
  } catch (err) {
    throw new Error(`components/droppers/creationDroppersComp.vue: resetFormFields  => ${err}`);
  }
}

// ==========================================   LIFECYCLE HOOKS   ==========================================
onMounted(() => {
  // Задается hospitalId для создания нового Устройства. Берется ID больницы с localStorage, если пользователь является управляющим больницы
  if (authStore.isChief === true) {
    const chief = mainStore.fetchDecryptedUser();
    hospitalId.value = chief.hospitalId;
  }
  // ПРОВЕРИТЬ ОСТАВИТЬ/УДАЛИТЬ
  // try {
  //     // Получение query- department
  //     const departmentIdQuery = route.query['department'];
  //     if(typeof departmentIdQuery === 'string') {
  //         departmentId.value = +departmentIdQuery;
  //     }
  //     // Получение query- hospital
  //     const hospitalIdQuery = route.query['hospital'];
  //     if(typeof hospitalIdQuery === 'string') {
  //         hospitalId.value = +hospitalIdQuery;
  //     }
  // } catch (err) {
  //     throw new Error(`components/droppers/creationDroppersComp.vue: onMounted[fetch query]  => ${err}`);
  // }
});

</script>

<style scoped>
.dialog__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  padding-bottom: 2rem;
  height: max-content;
  background-color: var(--bg-color-block);
}

.dialog__form {
  width: 100%;
  height: max-content;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-block);
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 2rem;
  background-color: var(--bg-color-white);
  margin-bottom: 0rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dialog__form--preview-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: max-content;
  padding: 2rem 0;
  background-color: rgba(239, 246, 248, 1);
}

.preview-image {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.image {
  width: 100%;
  height: 100%;
}

.preview-name {
  font-size: 26px;
  font-weight: 500;
  margin-top: 1rem;
}

.dialog__form--general-data {
  width: 80%;
  padding: 2rem 2rem 0 2rem;
  background-color: var(--bg-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.patient-data-block {
  display: flex;
  width: 100%;
}

.patient-data-block__item {
  display: flex;
  width: 33.3333%;
  padding: 0 0.4rem;
  flex-direction: column;
}

.general-data__input {
  margin-bottom: 1rem;
}
</style>