<template>
    <div class="hospital-rooms-filter-panel">

        <!-- Ввод названия Койки -->
        <v-combobox
        class="filter-input"
        v-model="selectedHospitalBedName"
        :items="props.hospitalBedNames"
        clearable
        label="Название койки"
        variant="outlined"
        @update:model-value="(name: string | null) => selectHospitalBedName(name)"
        :loading="isLoadingFilteringHospitalBedName"
        :density="'compact'"
        :hide-details="true"
        ></v-combobox>

        <v-spacer></v-spacer>

        <!-- Выбор Активности койки -->
        <v-switch
        v-model="selectedStatus"
        color="var(--bg-color-default)"
        label="Неактивные"
        @update:model-value="(status: boolean | null) => selectStatus(status)"
        :value="true"
        hide-details
        >
        </v-switch>
    </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, defineProps, watch } from 'vue';
import useMainStore from '../../store/mainStore';

const store = useMainStore();


// ==========================   PROPS   ==================================
const props = defineProps<{
    isActiveHospitalRoom: boolean;
    hospitalBedNames: Array<string | undefined>;
}>();

// ==========================   EMIT   ==================================
const emit = defineEmits<{
    selectStatus: [status: boolean];
    selectHospitalBedName: [hospitalBedName: string | null];
}>();

// ==========================   DATA   ==================================
const selectedStatus = ref<boolean>(false);
const selectedHospitalBedName = ref<string | null>(null);
const isLoadingFilteringHospitalBedName = ref<boolean>(false);
const payload = store.LazyLoading(300);

// ==========================   WATCH   ==================================
watch(() => props.isActiveHospitalRoom, (newValue) => {
    if(newValue === false) {
        selectedStatus.value = true;
        emit('selectStatus', true);
    }
});

// ==========================   METHODS   ==================================

// Выбор названия койки
function selectHospitalBedName(value: string | null) {
    try {
        isLoadingFilteringHospitalBedName.value = true;
        payload(value, (entries) => {
            let currentMessage: string | null = entries[entries.length - 1];
            if(currentMessage === null) currentMessage = '';
            isLoadingFilteringHospitalBedName.value = false;
            emit('selectHospitalBedName', currentMessage);
        });
    } catch (err) {
        throw new Error(`components/hospitalBeds/hospitalBedsFilterPanelComp: selectHospitalBedName  => ${err}`);
    }
}

// Функция для выбора Статуса пользователя в списке
function selectStatus(status: boolean | null) {
    try {
        if(status !== null) {
            emit('selectStatus', status);
        }
    } catch (err) {
        throw new Error(`components/hospitalBeds/hospitalBedsFilterPanelComp: selectStatus  => ${err}`);
    }
}


</script>

<style scoped>
.hospital-rooms-filter-panel {
    min-width: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 1.5rem 0 2rem;
}
.filter-input {
    width: 33%;
}

</style>