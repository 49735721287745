<template>
  <v-dialog v-model="modelValue" width="auto">
    <v-card class="dialog__wrapper">

      <!-- Крутилка загрузки -->
      <span class="progress-circular" v-if="isShowLoadingData">
        <v-progress-circular
          indeterminate
          bg-color="var(--bg-color-white)"
          color="var(--bg-color-default)"
          :size="60"
          :width="6"
        ></v-progress-circular>
      </span>

      <form class="dialog__form">
        <!-- Превью -->
        <div class="dialog__form--preview-block">
          <input id="preview-image" v-show="false"/>
          <label
            class="preview-image"
            for="preview-image"
          >
            <v-icon
              icon="mdi-water-outline"
              color="var(--color-default)"
              size="80px"
            ></v-icon>
          </label>
          <h1 class="preview-name">{{ dropperFormCopy.vendorId }}</h1>
        </div>

        <!-- Общие данные -->
        <div class="dialog__form--general-data">

          <!-- Имя -->
          <v-card-subtitle class="mb-2">* ID Дроппера</v-card-subtitle>
          <v-text-field
            class="general-data__input"
            bg-color="var(--bg-color-white)"
            v-model="vendorId"
            color="var(--color-default)"
            rounded="xs"
            :placeholder="'ID'"
            :density="'compact'"
            variant="outlined"
          ></v-text-field>

          <!-- Список Организаций -->
          <hospitalListComp
            v-if="authStore.isSuperUser === true"
            style="width: 100% !important;"
            :label-compbox="'Организация'"
            :item-list="hospitalList"
            @load-items="loadHospitals"
            @close-menu="hospitalList.length = 0"
            @clear-hospital="clearHospital"
            @select-hospital="(hospital) => handlerSelectHospital(hospital)"
            :selected-hospital-name="selectedHospitalName"
          />

          <!-- Список Отделений -->
          <departmentListComp
            style="width: 100% !important;"
            :label-compbox="'Отделение'"
            :item-list="departmentList"
            :filter-by-hospital-id="hospitalId"
            @load-items="loadDepartments"
            @close-menu="departmentList.length = 0"
            @clear-department="clearDepartment"
            @select-department="(department) => handlerSelectDepartment(department)"
            :selected-department-name="selectedDepartmentName"
          />

        </div>
      </form>
      <div class="dialog__actions">

        <!-- Сохранить -->
        <v-btn
          class="mr-6"
          @click="confirmToUpdateDropper"
          :loading="isLoading"
          variant="flat"
          style="color: white; font-weight: normal; font-size: 12px;"
          color="var(--bg-color-default)"
        >
          Сохранить
        </v-btn>
        <!-- Сбросить изменения -->
        <v-btn
          variant="tonal"
          style="margin-right: auto; font-size: 12px;"
          color="var(--bg-color-default)"
          @click="resetChanges"
        >
          Сбросить изменения
        </v-btn>

        <!-- Закрыть -->
        <v-btn
          variant="outlined"
          color="red"
          style="font-size: 12px;"
          @click="handlerClose">
          Закрыть
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
// COMPONENTS
import hospitalListComp from '../departments/hospitalListComp.vue';
import departmentListComp from '../hospitalRooms/departmentListComp.vue';
// API
import {getDropperById, putDropperById} from '../../api/superuser/droppersApi'
import {getDropperById__chief, putDropperById__chief} from '../../api/hospitalChief/droppersApi';
// COMPOSABLES
import useLoadingHospital from '../../composable/loadHospitalList';
import useLoadingDepartments from '../../composable/loadDepartmentList';
// VUE
import {ref, defineModel, defineEmits, watch, onMounted} from 'vue';
import {useRoute} from 'vue-router';
// STORE
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';
// TYPES
import {DropperClient, DropperForUpdate, DropperForUpdateServer} from '../../types/droppersType';
import {DepartmentClient} from '../../types/departmentType';
import {HospitalClient} from '../../types/hospitalType';

const store = useMainStore();
const authStore = useAuthStore();
const route = useRoute();
const modelValue = defineModel<boolean>();

// ==============================================  EMITS  ==============================================
const emit = defineEmits<{
  close: [],
  success: [],
  error: [msg: string],
  loadDropper: [dropper: DropperClient],
  updateDropper: [dropper: DropperClient],
}>();

// ==============================================  DATA  ==============================================
const vendorId = ref<string | null>(null);
const isLoading = ref(false);
const isShowLoadingData = ref<boolean>(false);
const id = ref<number>(0);
const hospitalId = ref<number | null>(null);
const departmentId = ref<number | null>(null);
const dropperFormCopy = ref({
    vendorId: null as string | null,
    selectedHospitalId: null as number | null,
    selectedDepartmentId: null as number | null,
    selectedHospitalName: null as string | null,
    selectedDepartmentName: null as string | null,
});

// Компонуемый файл для получения списка больниц и отображения их в выпадающем меню
const {
  hospitalList,
  selectedHospital,
  selectedHospitalName,

  loadHospitals,
  clearHospital,
  selectHospital,
} = useLoadingHospital();

// Компонуемый файл для получения списка отделений и отображения их в выпадающем меню
const {
  departmentList,
  selectedDepartment,
  selectedDepartmentName,

  loadDepartments,
  clearDepartment,
  selectDepartment,
} = useLoadingDepartments();

// ==============================================  METHODS  ===============================================

// Функция обработчик для фиксирования ID БОЛЬНИЦЫ из селект-меню и сохранения выбранной БОЛЬНИЦЫ
function handlerSelectHospital(hospital: HospitalClient | null) {
  if (hospital) {
    hospitalId.value = hospital.id;
    selectHospital(hospital);
  }
}

// Функция обработчик для фиксирования ID ОТДЕЛЕНИЯ из селект-меню и сохранения выбранного ОТДЕЛЕНИЯ
function handlerSelectDepartment(department: DepartmentClient | null) {
  if (department) {
    departmentId.value = department.id;
    selectDepartment(department);
  }
}

// Получение Устройства по его ID
async function getDropperByIdGeneral(dropperId: number) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getDropperById(dropperId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getDropperById__chief(dropperId);
  } catch (err) {
    throw new Error(`components/droppers/droppersDialogComp.vue: getDropperByIdGeneral  => ${err}`)
  }
}

// Обновление данных Устройства
async function putDropperByIdGeneral(changedData?: DropperForUpdateServer | undefined) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await putDropperById(changedData);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await putDropperById__chief(changedData);
  } catch (err) {
    throw new Error(`components/droppers/droppersDialogComp.vue: getDropperByIdGeneral  => ${err}`)
  }
}

// Подготавливает объект для отправки на сервер
function prepareForConfirm(entries: Array<Array<string>>) {
  try {
    const resultObjectData: object | any = {};
    for (const entry of entries) {
      if (entry[0] === 'selectedHospitalName') {
        resultObjectData['hospitalId'] = selectedHospital.value?.id;
      }
      if (entry[0] === 'selectedDepartmentName') {
        resultObjectData['departmentId'] = selectedDepartment.value?.id;
      } else {
        resultObjectData[entry[0]] = entry[1]
      }
    }
    resultObjectData.dropperId = id.value;
    resultObjectData.hospitalId = hospitalId.value;
    resultObjectData.departmentId = departmentId.value;
    return resultObjectData;
  } catch (err) {
    throw new Error(`components/droppers/droppersDialogComp.vue: prepareForConfirm  => ${err}`)
  }
}

// Подтверждение данных для обновления выбранного Устройства
async function confirmToUpdateDropper() {
  try {
    isLoading.value = true;
    // сравниваем два объекта на наличие изменения данных в полях ввода (Для исключения пустого запроса)
    const {entries, isCompare} = store.compareObjectsFetchFields(dropperFormCopy.value, {
      vendorId: vendorId.value,
      selectedHospitalName: selectedHospitalName.value,
      selectedDepartmentName: selectedDepartmentName.value,
    });

    // Если два объекта отличаются то обрабатываем запрос к серверу на изменение данных
    if (isCompare === false) {
      const readyChangedData = prepareForConfirm(entries);
      const formattedChangeData: DropperForUpdateServer = store.camelToSnake(readyChangedData);
      const updatedDropper = await putDropperByIdGeneral(formattedChangeData);
      emit('success');
      emit('updateDropper', updatedDropper); // Передаем обновленное Устройство в родительский компонент для обновления списка элементов
    }
  } catch (err) {
    throw new Error(`components/droppers/droppersDialogComp.vue: confirmToUpdateDropper  => ${err}`)
  } finally {
    isLoading.value = false;
  }
}

// Сбросить изменения в форме 
function resetChanges() {
    try {
        // Проверяем объекты на разность значений их свойств
        const isCompare = store.compareObjects(dropperFormCopy.value, { 
            vendorId: vendorId.value,
            selectedHospitalName: selectedHospitalName.value,
            selectedDepartmentName: selectedDepartmentName.value,
        });
        if(isCompare === false) {
            // Возвращаем поля формы в исзодное состояние
            vendorId.value = dropperFormCopy.value.vendorId;
            selectedHospitalName.value = dropperFormCopy.value.selectedHospitalName;
            selectedDepartmentName.value = dropperFormCopy.value.selectedDepartmentName;
            hospitalId.value = dropperFormCopy.value.selectedHospitalId;
            departmentId.value = dropperFormCopy.value.selectedDepartmentId;
        }
    } catch (err) {
        throw new Error(`components/droppers/droppersDialogComp.vue: resetChanges  => ${err}`)
    }
}

// Функция применяется для создания копии объекта формы
function createCopyForm() {
  try {
    // На этом этапе selectedDepartmentName и др. еще не существуют так как они будет поулчены асинхронно в parsingObject
    dropperFormCopy.value.vendorId = vendorId.value;
  } catch (err) {
    throw new Error(`components/droppers/droppersDialogComp.vue: createCopyForm  => ${err}`)
  }
}

// Функция разбивает ключи получаемого объекта по реактивным переменным для отображаения их в полях ввода
async function parsingObject(dropper: DropperClient) {
    try {
        vendorId.value = dropper.vendorId;
        if(dropper.department) {
            try {
                isLoading.value = true;
                // Получаем все родительские сущности Устройства
                const fetchedHospital = dropper.department.hospital;
                const fetchedDepartment = dropper.department;

                // Добавляем в селект-меню БОЛЬНИЦУ
                selectedHospitalName.value = fetchedHospital.name;
                selectedHospital.value = fetchedHospital;
                hospitalId.value = fetchedHospital.id;

                // Добавляем в селект-меню ОТДЕЛЕНИЕ
                selectedDepartmentName.value = fetchedDepartment.name;
                selectedDepartment.value = fetchedDepartment;
                departmentId.value = fetchedDepartment.id;

                // собираем копию селект-полей 
                dropperFormCopy.value.selectedHospitalName = fetchedHospital.name;
                dropperFormCopy.value.selectedDepartmentName = fetchedDepartment.name;
                dropperFormCopy.value.selectedHospitalId = fetchedHospital.id;
                dropperFormCopy.value.selectedDepartmentId = fetchedDepartment.id;
            } catch (err) {
                throw new Error(`components/droppers/droppersDialogComp.vue: parsingObject[fetch dropper-data]  => ${err}`);
            } finally {
                isLoading.value = false;
            }
        }
    } catch (err) {
        throw new Error(`components/droppers/droppersDialogComp.vue: parsingObject  => ${err}`)
    }
}

// Обработчик закрытия формы
function handlerClose() {
    id.value = 0;
    dropperFormCopy.value = {
        vendorId: null,
        selectedHospitalName: null,
        selectedDepartmentName: null,
        selectedDepartmentId: null,
        selectedHospitalId: null,
    }
    emit('close');

}

// Получение данных выбранного объекта в списке
watch(() => route.query['select-dropper'], async (dropperId) => {
  try {
    isShowLoadingData.value = true;
    if (typeof dropperId === 'string' && +dropperId > 0) {
      id.value = +dropperId;
      const dropper = await getDropperByIdGeneral(id.value);
      // Заполняем поля формы при открытии информации об Устройстве
      parsingObject(dropper);
      // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
      createCopyForm();
    } else {
      // Иммитируем обнуление полей ввода для формы этого компонента
      emit('close');
    }
  } catch (err) {
    throw new Error(`components/droppers/droppersDialogComp.vue: watch[fetch dropper-data]  => ${err}`)
  } finally {
    isShowLoadingData.value = false;
  }
});


// ==============================================   LIFECYCLE HOOKS   ==============================================
onMounted(async () => {
  try {
    if (route.query['select-dropper']) {
      const dropperIdQuery = route.query['select-dropper'];
      if (typeof dropperIdQuery === 'string' && +dropperIdQuery > 0) {
        // Загружаем данные выбранного Устройства если query параметр select-dropper существует
        try {
          isShowLoadingData.value = true;
          id.value = +dropperIdQuery;
          const dropper = await getDropperByIdGeneral(id.value);
          parsingObject(dropper);
          // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
          createCopyForm();
        } catch (err) {
          throw new Error(`components/droppers/droppersDialogComp.vue: onMounted[fetch dropper-data]  => ${err}`)
        } finally {
          isShowLoadingData.value = false;
        }
      }
    } else {
      emit('close');
    }
  } catch (err) {
    throw new Error(`components/droppers/droppersDialogComp.vue: onMounted => ${err}`);
  }
});


</script>

<style scoped>
.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
  z-index: 999;
}

.dialog__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  padding-bottom: 2rem;
  background-color: var(--bg-color-block);
}

.dialog__form {
  width: 100%;
  height: max-content;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-block);
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 2rem !important;
  background-color: var(--bg-color-white);
  margin-bottom: 0rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dialog__form--preview-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: max-content;
  padding: 2rem 0;
  background-color: rgba(239, 246, 248, 1);
}

.preview-image {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.image {
  width: 100%;
  height: 100%;
}

.preview-name {
  font-size: 26px;
  font-weight: 500;
  margin-top: 1rem;
}

.dialog__form--general-data {
  width: 80%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  background-color: var(--bg-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.general-data__hospital-room-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid black;
}

.general-data__hospital-room-amount p {
  color: var(--color-default);
}

</style>