<template >
    <div class="preview-image__container">
        <v-btn
          v-show="selectedImage"
          class="avatar__reset"
          icon="mdi-close"
          size="28"
          color="red"
          :density="'compact'"
          @click="resetSelectedImage"
        ></v-btn>
        <v-btn
        v-if="props.isShowUpload"
        class="upload-avatar"
        icon="mdi-camera-outline"
        size="28"
        :density="'compact'"
        @click="openFiles"
        >
        </v-btn>
        <label 
        class="preview-image__label" 
        :for="(props.forPreview === true)? '' : 'preview-image'"
        >
            <img 
              class="image"
              :src="(uploaded === null)? require('../../assets/base-icons/empty-user.svg') : uploaded"
              alt="avatar"
            >
        </label>

        <input 
        id="preview-image" 
        type="file" 
        accept="image/jpeg, image/png"  
        @change="(event: Event) => handleFileInput(event)"
        v-show="false"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, nextTick, defineEmits, defineProps, watch } from 'vue';

// ===============================   PROPS   =================================
const props = defineProps<{
    avatar: string | undefined | null;
    selectedImage?: File | null | undefined;
    isShowUpload?: boolean;
    forPreview?: boolean;
}>();

// ===============================   EMIT   =================================
const emit = defineEmits<{
    selectImage: [image: File | undefined, blobImage: string | null];
    resetSelectedImage: [];
}>();


// ===============================   DATA   =================================
const uploaded = ref<string | null>(null);

// ===============================   METHODS   =================================
// Открывает выбор файлов
function openFiles() {
    try {
        const inputAvatar = document.getElementById('preview-image') as HTMLInputElement;
        inputAvatar.click();
    } catch (err) {
        throw new Error(`components/general/avatarInputComp.vue: openFiles => ${err}`);
    }
}

// Запись нового файла в поле для просмотра и последующей загрузки на сервер
async function handleFileInput(event: any) {
    try {
        const file = event?.target?.files[0];
        // Ожидаем следующего тика DOM-реактивности, присваиваем ресурс к video
        nextTick(async() => {
            const blobImage = await loadImage(file);
            uploaded.value = blobImage;
            emit('selectImage', file, blobImage);
        });
    } catch (err) {
        return new Error(`components/general/avatarInputComp.vue: handleFileInput => ${err}`);
    }
}

// Форматируем изображение в blob-формат
function loadImage(file: any): any {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const result = e?.target?.result;
                resolve(result);
            };
            if (file) {
                reader.readAsDataURL(file);
            } else {
                resolve(null);
            }
        } catch (err) {
            reject(new Error(`components/general/avatarInputComp.vue: loadImage => ${err}`));
        }
    });
}

const resetSelectedImage = () => {
  emit('resetSelectedImage')
  uploaded.value = null
  const inputAvatar: HTMLInputElement = document.getElementById('preview-image')
  inputAvatar.value = ""
}

// ===============================   WATCH   =================================
// Поулчение аватарки с пропсов
watch(() => props.avatar, (newValue: string | null | undefined) => {
    if(newValue) {
        uploaded.value = newValue;
    }
});

</script>

<style setup>
.preview-image__container {
    position: relative;
    width: 110px;
    height: 110px;
}
.preview-image__label {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    z-index: 69;
}
.upload-avatar {
    position: absolute !important;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-default) !important;
    font-size: 0.8rem;
    z-index: 99;
}
.image {
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.avatar__reset {
  position: absolute !important;
  top: 0;
  right: 0;
  font-size: 0.8rem;
  color: red;
}

</style>