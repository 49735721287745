// Главное Харнилище для работы с общим состоянием приложения

import { defineStore } from "pinia";
import { ref } from 'vue';
import CryptoJS from "crypto-js";
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';
import moment from "moment";

const useMainStore = defineStore('mainStore', () => {

    // ============================   STATE   ==========================
    const isSuccessOperation = ref(false);
    const isErrorOperation = ref(false);
    // Настройки для маски (эта маска используется для полей ввода где нужно указать номер телефона)
    const optionsMask = { mask: '+7 (###) ###-##-##' };
    
    const optionsMaskTime = { mask: '##' };
    const isEmployeePaginationHasNext = ref<boolean>(false);
    const isDepartmentPaginationHasNext = ref<boolean>(false);
    const isHospitalPaginationHasNext = ref<boolean>(false);
    const isHospitalBedsPaginationHasNext = ref<boolean>(false);
    const isHospitalRoomsPaginationHasNext = ref<boolean>(false);
    const isProceduresPaginationHasNext = ref<boolean>(false);
    const isDropperPaginationHasNext = ref<boolean>(false);
    // Moment локализация
    moment.locale('ru');
    

    // ==========================   MUTATION   ========================
    function activeSuccessOperation(duration?: number) {
        isSuccessOperation.value = true;
        setTimeout(() => {
            isSuccessOperation.value = false;
        }, duration ?? 1500);
    }

    function activeErrorOperation(duration?: number) {
        isErrorOperation.value = true;
        setTimeout(() => {
            isErrorOperation.value = false;
        }, duration ?? 1500);
    }

    // =========================  ACTIONS  ===========================

    // Функция для форматирования timestamp в читабельную строку в определенном формате
    function timestampFormat(timestamp: number | Date, mask: string) {
        try {
            return moment(timestamp).format(mask);
        } catch (err) {
            throw new Error(`store/mainStore: timeFormat => ${err}`);
        }
    }

    // Функция парсит строку времени в timestamp
    function parseTimeString(timeString: string, format: string): number {
        try {
            return moment(timeString, format).valueOf();
        } catch (err) {
            throw new Error(`store/mainStore: timeFormat => ${err}`);
        }
    }

    // функция для сохранения query-параметров
    function toPushWithQuery(pathQuery: { name: string, value: any, namePath?: string }, route: RouteLocationNormalizedLoaded, router: Router) {
        try {
            router.push({
                query: { ...route.query, [pathQuery.name]: pathQuery.value },
                name: pathQuery.namePath,
            });
        } catch (err) {
            throw new Error(`store/mainStore:toPushWithQuery => ${err}`);
        }
    }
    // Функция которая удаляет из строки url необходимый query-параметр
    function toPopQuery(queryName: string, route: RouteLocationNormalizedLoaded, router: Router) {
        try {
            const splitterOne = route.fullPath?.split('?');
            let path = splitterOne[0];
            let queriesArray = splitterOne[1]?.split('&');

            queriesArray = queriesArray.filter((query) => {
                if (query.includes(queryName)) {
                    return false;
                } else return true;
            });
            path = `${path}?${queriesArray.join('&')}`;
            router.push(path);
        } catch (err) {
            return;
        }
    }

    // Функция для шифрования объекта
    function encryptObject(object: object, key: string): string {
        try {
            const jsonString = JSON.stringify(object);
            const encrypted = CryptoJS.AES.encrypt(jsonString, key).toString();
            return encrypted;
        } catch (err) {
            throw new Error(`store/mainStore:encryptObject => ${err}`);
        }
    }
    // Функция для расшифровки объекта
    function decryptObject(encrypted: string | null, key: string | null) {
        try {
            if(encrypted && key) {
                const decrypted = CryptoJS.AES.decrypt(encrypted, key).toString(CryptoJS.enc.Utf8);
                const object = JSON.parse(decrypted);
                return object;
            } 
            else return null;
        } catch (err) {
            throw new Error(`store/mainStore:decryptObject => ${err}`);
        }
    }

    // Функция получает с localStorage расшифрованный объект пользователя
    function fetchDecryptedUser() {
        try {
            return decryptObject(localStorage.getItem('user'), localStorage.getItem('token'));
        } catch (err) {
            throw new Error(`store/mainStore:fetchDecryptedUser => ${err}`);
        }
    }

    // Функция для обновления данных пользователя в localStorage
    function updateUserLocalStorage(key: string, value: any) {
        try {
            const token = localStorage.getItem('token');
            const userDataHash = localStorage.getItem('user');
            const decryptedUser = decryptObject(userDataHash, token);
            decryptedUser[key] = value;
            if (token) {
                const cryptedUser = encryptObject(decryptedUser, token);
                localStorage.setItem('user', cryptedUser);  // запись захешированного пользователя в LocalStorage
            }
        } catch (err) {
            throw new Error(`store/mainStore:decryptObject => ${err}`);
        }
    }

    // Функция для форматирования ключей объекта с формата CamelCase в формат snake_case
    function camelToSnake(obj: object) {
        try {
            if (typeof obj !== 'object') {
                return obj;
            } else if (Array.isArray(obj)) {
                return obj.map(item => camelToSnake(item));
            } else {
                return Object.keys(obj).reduce((acc, key) => {
                    const newKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
                    acc[newKey] = camelToSnake(obj[key]);
                    return acc;
                }, {});
            }
        } catch (err) {
            throw new Error(`store/mainStore: camelToSnake => ${err}`);
        }
    }

    // Функция для сравнения двух объектов на разность значений их ключей
    function compareObjects(obj1: object, obj2: object): boolean {
        try {
            let flag = true;
            for (const key in obj1) {
                if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                    continue;
                }
                if (obj1[key] !== obj2[key]) {
                    flag = false;
                    break;
                }
            }
            return flag;
        } catch (err) {
            throw new Error(`store/mainStore: compareObjects => ${err}`);
        }
    }

    // Тип для объекта который будет возвращен функцией compareObjects если нужно получить ключи объекта значения которых не совпадают с ключами исходного объекта
    type ChangedStatus = {
        isCompare: boolean,
        entries: Array<any>,
    }
    // Функция для сравнения двух объектов на разность значений их ключей и получение этих ключей если проверка не прошла
    function compareObjectsFetchFields(obj1: object, obj2: object): ChangedStatus {
        try {
            const status: ChangedStatus = { isCompare: true, entries: [] };
            for (const key in obj1) {
                if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                    continue;
                }
                if (obj1[key] !== obj2[key]) {
                    status.isCompare = false;
                    status.entries.push([key, obj2[key]]);
                }
            }
            return status;
        } catch (err) {
            throw new Error(`store/mainStore: compareObjectsFetchFields => ${err}`);
        }
    }

    // Функция для форматирования строки в российский номер телефона 
    function formatPhoneNumber(phoneNumber: string | null): string | null {
        try {
            if (typeof phoneNumber === 'string') {
                // Оставляем только цифры
                phoneNumber = phoneNumber.replace(/\D/g, '');
    
                // Применяем формат
                phoneNumber = phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($2) $3-$4-$5');
    
                return phoneNumber;
            } else {
                return null;
            }
        } catch (err) {
            throw new Error(`store/mainStore: formatPhoneNumber => ${err}`);
        }
    }
    // Функция для форматирования номера телефона формата +7(999)-999-99-99 в формат 79999999999
    function getPhoneNumberDigits(phoneNumber: string | null) {
        try {
            if (typeof phoneNumber === 'string') {
                // Удаляем все символы, кроме цифр
                const digitsOnly: string = phoneNumber.replace(/[^0-9]/g, '');
                return digitsOnly;
            } else {
                return null;
            }
        } catch (err) {
            throw new Error(`store/mainStore: getPhoneNumberDigits => ${err}`);
        }
    }

    // Функция для извлечения токена доступа из localStorage
    function getAccessToken() {
        try {
            const token = localStorage.getItem('token');
            return token;
        } catch (err) {
            throw new Error(`store/mainStore: getAccessToken => ${err}`);
        }
    }

    // Функция для отложенной загрузки многократно выполняющегося события за единицу времени
    function LazyLoading(delay: number) {
        const dataEntries = [];
        let beforeLength: number = dataEntries.length;
        let isStartedInterval = false;
        return function payload(iterData: never, callback: (entries: Array<any>) => void) {
            dataEntries.push(iterData);
            // Ограничиваем запуск нового setInterval если в цикле событий уже работает setInterval
            if(isStartedInterval === false) {
                isStartedInterval = true;  // Передаем флаг запущенного setInterval
                const intervalID = setInterval(() => {
                    let afterLength: number = dataEntries.length;
                    // Выполняется только если мы вводим какие-либо входные данные
                    if (afterLength !== beforeLength) {
                        beforeLength = afterLength;  // Перезаписываем начальную длину массива
                        return;
                    } 
                    // Если спустя тик времени мы не ввели новые входные данные то callback отправляет массив собранных данных наружу
                    if(afterLength === beforeLength) {
                        clearInterval(intervalID);
                        isStartedInterval = false;
                        callback([...dataEntries]);
                        dataEntries.length = 0;
                        beforeLength = 0;
                        afterLength = 0;
                        return;
                    }
                }, delay);
            }
        }
    }

    return {
        // State
        isSuccessOperation,
        isErrorOperation,
        optionsMask,
        optionsMaskTime,
        isEmployeePaginationHasNext,
        isHospitalPaginationHasNext,
        isDepartmentPaginationHasNext,
        isProceduresPaginationHasNext,
        isHospitalRoomsPaginationHasNext,
        isHospitalBedsPaginationHasNext,
        isDropperPaginationHasNext,
        moment,

        // Mutations
        activeSuccessOperation,
        activeErrorOperation,

        // Actions
        timestampFormat,
        parseTimeString,
        toPushWithQuery,
        toPopQuery,
        encryptObject,
        decryptObject,
        fetchDecryptedUser,
        camelToSnake,
        compareObjects,
        compareObjectsFetchFields,
        updateUserLocalStorage,
        formatPhoneNumber,
        getPhoneNumberDigits,
        getAccessToken,
        LazyLoading,
    }
});

export default useMainStore;