<template>
  <v-combobox
    class="group-items"
    :clearable="true"
    :placeholder="props.labelCompbox"
    :items="chiefNames"
    :rules="inputRules"
    @update:model-value="(name: string) => selectChief(name)"
    @update:menu="(e: boolean) => handlerOpenMenu(e)"
    @click:clear="emit('clearChief')"
    rounded="lg"
    v-model="chiefName"
    variant="outlined"
    bg-color="var(--bg-color-white)"
    color="var(--color-default)"
    base-color="var(--color-default)"
    :density="'compact'"
    :error="chiefNames.length <= 0"
    :error-messages="computedErrorMsg"
    :disabled="chiefNames.length <= 0"
  >
    <!-- Компонент для отображения загрузки -->
    <template v-if="true" v-slot:no-data>
      <v-list-item class="d-flex justify-center" :density="'compact'">
        <!-- Крутилка загрузки -->
        <v-progress-circular
          indeterminate
          bg-color="var(--bg-color-white)"
          color="var(--bg-color-default)"
          :size="20"
          :width="2"
        ></v-progress-circular>
      </v-list-item>
    </template>

    <template v-slot:selection>
      <p class="group-items__selection-value">{{ computedChiefName }}</p>
    </template>


    <!-- Элемент группы -->
    <template v-slot:item="{item, props}">
      <div v-bind="props" class="group-items__item">
        <p class="item__value">{{ item.value }}</p>
      </div>
    </template>

    <template v-slot:append-item>
      <div id="trigger">
        <v-progress-circular
          v-show="isShowLoadingNewPage"
          indeterminate
          bg-color="var(--bg-color-white)"
          color="var(--bg-color-default)"
          :size="20"
          :width="2"
        ></v-progress-circular>
      </div>
    </template>

  </v-combobox>
</template>

<script setup lang="ts">
import {ChiefClient, UserClient} from '../../types/userType';
import useDataStore from '../../store/dataStore';
import {ref, watch, computed, defineProps, defineEmits, defineModel, nextTick} from 'vue';

const dataStore = useDataStore();
// =======================================  V-MODEL  =======================================
defineModel<string>('checked')

// =======================================  PROPS  =======================================
const props = defineProps<{
  labelCompbox: string;
  itemList?: Array<any>;
  selectedChiefName: string | null;
  isRequired?: boolean;
}>();

// =======================================  EMITS  =======================================
const emit = defineEmits<{
  loadItems: [],
  closeMenu: [],
  selectChief: [UserClient | null],
  clearChief: [],
}>()

// =======================================  DATA  =======================================
const chiefName = ref<string | null>(null);
const selectedChiefData = ref<ChiefClient | null>(null);
const isShowLoadingNewPage = ref<boolean>(false);

const inputRules = ref([
  (value: string) => {
    if(props.isRequired === true) {
      if(value) {
        return true;
      } else {
        return 'Обязательное поле';
      }
    } else {
      return true;
    }
  },
])

// Наблюдатель для триггера подгрузки страниц пагинации списка
const observer = new IntersectionObserver((entries) => {
  entries.forEach(async entry => {
    if (entry.isIntersecting) {
      emit('loadItems')
    }
  });
});

// =======================================  METHODS  =======================================

async function handlerOpenMenu(e: boolean) {
  await nextTick();
  const trigger = document.getElementById('trigger') as HTMLDivElement;
  if (trigger) {
    observer.observe(trigger);  // запуск observer
  }
}

// Функция отправляет событие для загрузки элементов списка с БД
// function openMenu(isOpenMenu: boolean) {
//     if(props.itemList.length <= 0 &&  isOpenMenu === true) {
//         isLoading.value = true;
//         emit('loadItems');
//     } else emit('closeMenu');
// }

// Функция для отправки выбранного пользователя в диалоговое окно создания больницы 
function selectChief(userName: string) {
  dataStore.employees.forEach((employee: UserClient) => {
    if (userName === employee.fullName) {
      emit('selectChief', employee);
    }
  });
}

// =======================================  COMPUTED  =======================================
// Вычисляем имя управляющего больниец. Если он существует и был передан как реквизит с род.компонента или он выбирается в селект-меню
const computedChiefName = computed(() => {
  if (props.selectedChiefName && props.selectedChiefName.length > 0) {
    return props.selectedChiefName;
  } else {
    return selectedChiefData.value?.fullName;
  }
});

const chiefNames = computed(() => {
  const names: string[] = []

  for (const employee of dataStore.employees) {
    if (
      employee.isSuperuser === false && 
      employee.isActive === true && 
      employee.hospitalId === null && 
      employee.departmentId === null
  ) {
      names.push(employee.fullName);
    }
  }

  return names;
});

const computedErrorMsg = computed(() => {
  if(chiefNames.value.length <= 0) {
    return 'Нет доступных пользователей для назначения управляющего этой больнией'
  }
  else return '';
});

// =======================================  WATCH  =======================================

// Отслеживание имени управляющего больницы если он существует
watch(() => props.selectedChiefName, (newChiefName: string | null) => {
  if (newChiefName && newChiefName.length) {
    chiefName.value = newChiefName;
  } else {
    chiefName.value = null;
  }
});

// =======================================  LIFECYCLE HOOKS  =======================================

</script>

<style scoped>
.group-items {
  width: 15% !important;
  margin-top: 20px;
}

.group-items__selection-value {
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

.group-items__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: .5rem .5rem .5rem 1rem;
  cursor: pointer;
}
.group-items__item:hover {
  background-color: var(--bg-color-gray);
}
.group-items__item + .group-items__item {
  border-top: var(--border-thin-light);
}

.item__value {
  width: 65% !important;
  padding: 0.1rem 0.5rem;
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#trigger {
  height: 10px;
  width: 100%;
}

.group-items__btn {

}
</style>