import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import DefaultView from '@/views/DefaultView.vue';
import NotFound404View from '@/views/NotFound404View.vue';
// Роуты
import mainRoutes from './mainRoutes';
import authRoutes from './authRoutes';
// Store
import useMainStore from '../store/mainStore';

const routes: Array<RouteRecordRaw | any> = [
    {
        path: '/',
        name: 'default',
        component: DefaultView,
        redirect: { name: 'auth' },
    },
    
    ...authRoutes,  // Auth
    ...mainRoutes,  // Main

    // Not Found 404
    {
        path: '/:notMatched(.*)*',
        name: 'notFound',
        component: NotFound404View,
    }
];

const router = createRouter({
    history: createWebHistory('/idropper/'),
    routes,
});

// =============================   ROUTER GUARD   ====================================
router.beforeEach((to, from, next) => {
    const store = useMainStore();
    const tokenLength = store.getAccessToken()?.length;
    // Если пользователь авторизован
    if(tokenLength && tokenLength > 0) {
        // если токен доступа найден и маршрут требует авторизации то навигация продолжается
        if(to.meta.requireAuth === true) {
            next();
        } else {
            if(to.name !== 'main' && to.name !== 'notFound') {
                next({ name: 'main' });
            } 
            else if(to.name === 'notFound') {
                next();
            }
        }
    } else {
        // если токен доступа НЕ найден и маршрут требует авторизацию то навигация прерывается
        if(to.meta.requireAuth === true) {
            if(to.name !== 'default' && to.name !== 'notFound') {
                // Если пользователь не авторизован, но пытается перейти на аутентифицированный маршрут, то его перенаправляет 
                // на default который делает redirect на страницу auth  
                next({ name: 'default' });
            }
        } else {
            // Если пользователь не авторизован, и совершается переход на не аутентифицированный маршрут, то он продолжается
            next();
        }
    }
});

export default router
