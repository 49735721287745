<template>
  <v-dialog v-model="modelValue" width="auto">
    <v-card class="dialog__wrapper">

      <!-- Крутилка загрузки -->
      <span class="progress-circular" v-if="isShowLoadingData">
                <v-progress-circular
                  indeterminate
                  bg-color="var(--bg-color-white)"
                  color="var(--bg-color-default)"
                  :size="60"
                  :width="6"
                ></v-progress-circular>
            </span>

      <form class="dialog__form">
        <!-- Превью -->
        <div class="dialog__form--preview-block">
          <input id="preview-image" v-show="false"/>
          <label
            class="preview-image"
            for="preview-image"
          >
            <v-icon
              icon="mdi-table"
              color="var(--color-default)"
              size="80px"
            ></v-icon>
          </label>
          <h1 class="preview-name">{{ departmentFormCopy.name }}</h1>
        </div>

        <!-- Общие данные -->
        <div class="dialog__form--general-data">
          <!-- Имя -->
          <v-card-subtitle class="mb-2">Название</v-card-subtitle>
          <v-text-field
            class="general-data__input"
            bg-color="var(--bg-color-white)"
            v-model.trim="name"
            color="var(--color-default)"
            rounded="xs"
            :density="'compact'"
            variant="outlined"
            hide-details
          ></v-text-field>

          <!-- Список Организаций -->
          <hospitalListComp
            v-if="authStore.isSuperUser === true"
            style="width: 100% !important; margin-top: 1.5rem;"
            :label-compbox="'Организация'"
            :item-list="hospitalList"
            :is-disabled="true"
            @load-items="loadHospitals"
            @close-menu="hospitalList.length = 0"
            @clear-hospital="clearHospital"
            @select-hospital="(hospital) => selectHospital(hospital)"
            :selected-hospital-name="selectedHospitalName"
            :is-loading="isLoadingHospitalsFetch"
          />
        </div>
      </form>
      <div class="dialog__actions">
        <!-- Сохранить -->
        <v-btn
          class="mr-6"
          @click="confirmToUpdateDepartment"
          :loading="isLoading"
          style="color: white; font-weight: normal; font-size: 12px;"
          variant="flat"
          color="var(--bg-color-default)"
        >
          Сохранить
        </v-btn>
        <!-- Сбросить изменения -->
        <v-btn
          variant="tonal"
          style="margin-right: auto; font-size: 12px;"
          color="var(--bg-color-default)"
          @click="resetChanges"
        >
          Сбросить изменения
        </v-btn>


        <!-- Закрыть -->
        <v-btn
          variant="outlined"
          color="red"
          style="font-size: 12px;"
          @click="handlerClose">
          Закрыть
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import hospitalListComp from './hospitalListComp.vue';
import {DepartmentClient} from '../../types/departmentType';
import {getDepartmentByID, putDepartmentById} from '@/api/superuser/departmentsApi';
import {getDepartmentByID__chief, putDepartmentById__chief} from '@/api/hospitalChief/departmentsApi';
import {ref, defineModel, defineEmits, watch, onMounted} from 'vue';
import useLoadingHospitals from '../../composable/loadHospitalList';
import {useRoute} from 'vue-router';
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';

const store = useMainStore();
const authStore = useAuthStore();
const route = useRoute();
const modelValue = defineModel<boolean>();
const emit = defineEmits<{
  close: [],
  success: [],
  error: [msg: string],
  loadDepartment: [department: DepartmentClient],
  updateDepartment: [department: DepartmentClient],
}>();

// ============================  DATA  ============================

const isLoading = ref(false);
const isShowLoadingData = ref<boolean>(false)
const id = ref<number>(0);
const name = ref<string>('');
const departmentFormCopy = ref({
  name: null as string | null,
  selectedHospitalName: null as string | null,
});

// Компонуемый файл для получения списка больниц и отображения их в выпадающем меню
const {
  hospitalList,
  selectedHospital,
  selectedHospitalName,
  isLoadingHospitalsFetch,

  loadHospitals,
  clearHospital,
  selectHospital,
} = useLoadingHospitals();

// ============================  METHODS  ============================

// Подтверждение данных для обновления выбранного отделения
async function confirmToUpdateDepartment() {
  try {
    isLoading.value = true;
    // сравниваем два объекта на наличие изменения данных в полях ввода (Для исключения пустого запроса)
    const isCompare = store.compareObjects(departmentFormCopy.value, {
      name: name.value,
      selectedHospitalName: selectedHospitalName.value,
    });
    // Если два объекта отличаются то обрабатываем запрос к серверу на изменение данных
    if (!isCompare) {
      if (name.value.length > 0) {
        // Обновление отделения для Суперпользователя
        if (authStore.isSuperUser === true) {
          if (selectedHospital.value?.id && selectedHospitalName.value) {
            const updatedDepartment: DepartmentClient = await putDepartmentById(name.value, id.value);
            emit('success');
            emit('updateDepartment', updatedDepartment); // Передаем обновленное отделение в родительский компонент для обновления списка элементов
          } else {
            emit('error', 'Укажите больницу');
          }
        }
        // Обновление отделения для Управляющего
        else if (authStore.isChief === true) {
          const updatedDepartment: DepartmentClient = await putDepartmentById__chief(name.value, id.value);
          emit('success');
          emit('updateDepartment', updatedDepartment); // Передаем обновленное отделение в родительский компонент для обновления списка элементов
        }
      } else {
        emit('error', 'Укажите название отделения');
      }
    }
  } catch (err) {
    throw new Error(`components/departments/departmentsDialogComp.vue: confirmToUpdateDepartment  => ${err}`)
  } finally {
    isLoading.value = false;
  }
}

// Сбросить изменения в форме 
function resetChanges() {
  try {
    // Проверяем объекты на разность значений их свойств
    const isCompare = store.compareObjects(departmentFormCopy.value, {
      name: name.value,
      selectedHospitalName: selectedHospitalName.value,
    });
    if (!isCompare) {
      if (departmentFormCopy.value.name) {
        name.value = departmentFormCopy.value.name;
      } else name.value = '';
      selectedHospitalName.value = departmentFormCopy.value.selectedHospitalName;
    }
  } catch (err) {
    throw new Error(`components/departments/departmentsDialogComp.vue: resetChanges  => ${err}`)
  }
}

// Функция применяется для создания копии объекта формы
function createCopyForm() {
  try {
    // На этом этапе selectedHospitalName еще не существует так как он будет поулчен асинхронно в parsingObject
    departmentFormCopy.value.name = name.value;
  } catch (err) {
    throw new Error(`components/departments/departmentsDialogComp.vue: createCopyForm  => ${err}`);
  }
}

// Функция разбивает ключи получаемого объекта по реактивным переменным для отображаения их в полях ввода
async function parsingObject(department: DepartmentClient) {
  try {
    if (department.name) {
      name.value = department.name;
    }
    if (department.hospital) {
      try {
        isLoadingHospitalsFetch.value = true;
        const hospitalName = department.hospital.name;
        selectedHospitalName.value = hospitalName;
        selectedHospital.value = department.hospital;
        // Также добавляем имя выбранной больницы в копию формы
        departmentFormCopy.value.selectedHospitalName = hospitalName;
      } catch (err) {
        throw new Error(`components/departments/departmentsDialogComp.vue: parsingObject[fetch hospital-data]  => ${err}`);
      } finally {
        isLoadingHospitalsFetch.value = false;
      }
    }
  } catch (err) {
    throw new Error(`components/departments/departmentsDialogComp.vue: parsingObject  => ${err}`);
  }
}

// Обработчик закрытия формы
function handlerClose() {
  id.value = 0;
  selectedHospital.value = null;
  selectedHospitalName.value = '';
  departmentFormCopy.value = {
    name: null,
    selectedHospitalName: null,
  }
  emit('close');
}

// ============================  WATCH  ============================

// Получение данных выбранного объекта в списке
watch(() => route.query['select-department'], async (departmentId) => {
  try {
    isShowLoadingData.value = true;
    if (typeof departmentId === 'string' && +departmentId > 0) {
      id.value = +departmentId;
      let department: null | DepartmentClient = null;
      // Получение отделения для управляющего
      if (authStore.isChief === true) {
        department = await getDepartmentByID__chief(id.value)
      }
      // Получение отделения для суперпользователя
      else if (authStore.isSuperUser === true) {
        department = await getDepartmentByID(id.value);
      }
      // Заполняем поля формы при открытии информации о отделении
      if (department) {
        parsingObject(department);
        // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
        createCopyForm();
      }
    } else {
      // Иммитируем обнуление полей ввода для формы этого компонента
      emit('close');
    }
  } catch (err) {
    throw new Error(`components/departments/departmentsDialogComp.vue: watch[fetch department-data]  => ${err}`)
  } finally {
    isShowLoadingData.value = false;
  }
});


// ============   LIFECYCLE HOOKS   ============
onMounted(async () => {
  try {
    if (route.query['select-department']) {
      const departmentIdQuery = route.query['select-department']
      if (typeof departmentIdQuery === 'string' && +departmentIdQuery > 0) {
        // Загружаем данные выбранного отделения если query параметр select-department существует
        try {
          isShowLoadingData.value = true;
          id.value = +departmentIdQuery;
          let department: null | DepartmentClient = null;
          // Отслеживаем когда значение роли пользователя будет установлено (Так как за определение роли отвечает асинхронная операция)
          watch(() => authStore.isChief, async (isChief) => {
            // Получение отделения для управляющего
            if (isChief) {
              department = await getDepartmentByID__chief(id.value);
            }
            if (department) {
              // Заполняем поля формы при открытии информации о отделении
              parsingObject(department);
              // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
              createCopyForm();
            }
          })
          // Отслеживаем когда значение роли пользователя будет установлено (Так как за определение роли отвечает асинхронная операция)
          watch(() => authStore.isSuperUser, async (isSuperUser) => {
            // Получение отделения для суперпользователя
            if (isSuperUser) {
              department = await getDepartmentByID(id.value);
            }
            if (department) {
              // Заполняем поля формы при открытии информации о отделении
              parsingObject(department);
              // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
              createCopyForm();
            }
          })

        } catch (err) {
          throw new Error(`components/departments/departmentsDialogComp.vue: onMounted[fetch department-data]  => ${err}`)
        } finally {
          isShowLoadingData.value = false;
        }
      }
    } else {
      emit('close');
    }
  } catch (err) {
    throw new Error(`components/departments/departmentsDialogComp.vue: onMounted => ${err}`);
  }
});


</script>

<style scoped>
.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
  z-index: 999;
}

.dialog__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 900px;
  background-color: var(--bg-color-block);
}

.dialog__form {
  width: 100%;
  height: max-content;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-block);
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 2rem 1.5rem;
  background-color: var(--bg-color-white);
  margin-bottom: 3rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dialog__form--top {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 1rem 2rem 1rem;
  color: var(--color-default);
  background-color: var(--bg-color-op-blue);
  backdrop-filter: blur(6px);
  z-index: 90;
}

.dialog__form--preview-block {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: max-content;
  padding: 2rem 0;
  background-color: rgba(239, 246, 248, 1);
}

.preview-image {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.image {
  width: 100%;
  height: 100%;
}

.preview-name {
  font-size: 26px;
  font-weight: 500;
  margin-top: 1rem;
}

.data-title {
  display: flex;
  justify-content: center;
  font-size: 1.1em;
}

.dialog__form--general-data {
  width: 80%;
  padding: 2rem 2rem 0 2rem;
  background-color: var(--bg-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.general-data__input {
  margin-bottom: 1rem;
}

.general-data__hospital-room-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1.5rem;
  border: 1px solid black;
}

.general-data__hospital-room-amount p {
  color: var(--color-default);
}

</style>