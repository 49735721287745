<template>
    <div class="not-found-view">
        <h1 class="not-found-view__title">
            <h1>404</h1>
            <h1>Ресурс не найден</h1>
        </h1>
        <div class="not-found-view__actions">
            <v-btn 
            @click="router.go(-1)"
            color="var(--color-default)"
            variant="tonal"
            >
                Вернутся назад
            </v-btn>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
const router = useRouter();
</script>

<style scoped>
.not-found-view {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 123%;
    overflow: auto;
    background-color: var(--bg-color-block);
}
.not-found-view__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-default);
    font-size: 1.5rem;
}
.not-found-view__actions {
    margin-top: 3rem;
}
</style>