<template>
    <div class="main__container">
        <div class="main__content-wrapper">
            <!-- Контент Блок -->
            <div class="main__content-block">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
</script>

<style scoped>
.main__container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--radius) !important;
}

.main__content-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--bg-color-block) !important;
    border-radius: var(--radius);
}

.main__content-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
    border-radius: var(--radius) !important;
}
</style>