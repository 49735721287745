// Взаимодействие управляющего с Устройствами
import { ArrayDropperClient, DropperClient, DropperForUpdate, DropperForUpdateServer } from '../../types/droppersType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import useMainStore from '../../store/mainStore';
import axios from 'axios';

// Создание нового устройства
async function createNewDropperDB__chief(vendorId: string, hospitalId: number, departmentId: number): Promise<DropperClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.post(hostname + 'api/hospitals/departments/idroppers', {
            vendor_id: vendorId,
            hospital_id: hospitalId,
            department_id: departmentId,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/droppersApi: createNewDropperDB__chief  => ${err}`);
    }
}

// Получение всех устройств моей больницы с сервера
async function getAllDroppersDB__chief(isDeleted?: boolean, page?: number, vendorId?: string, departmentId?: number): Promise<ArrayDropperClient> {
    const store = useMainStore();
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + 'api/hospitals/idroppers', {
            params: {
                deleted: isDeleted,
                page,
                department_id: departmentId,
                vendor_id: vendorId,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data } = response;
        const droppers = data?.data;
        const paginator = data?.paginator;
        // Задаем в стор информацию о том доступны ли следующие страницы пагинации
        store.isDropperPaginationHasNext = paginator?.has_next;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(droppers);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/droppersApi: getAllDroppersDB__chief  => ${err}`);
    }
}

// Получение Устройства по ID
async function getDropperById__chief(dropperId: number): Promise<DropperClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/hospitals/departments/idroppers/${dropperId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/droppersApi: getDropperById__chief  => ${err}`);
    }
}

// Изменить данные Устройства по его ID
async function putDropperById__chief(changedData?: DropperForUpdateServer): Promise<DropperClient> {
    try {
        // Обновление сущности и получение её с сервера
        const response = await axios.put(hostname + `api/hospitals/departments/idroppers/${changedData?.dropper_id}`, {
            vendor_id: changedData?.vendor_id,
            hospital_id: changedData?.hospital_id,
            department_id: changedData?.department_id,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/droppersApi: putDropperById__chief  => ${err}`);
    }
}

// Обновление статуса активности
async function putStatusDropperById__chief(dropperId: number, deleted: boolean ): Promise<DropperClient> {
    try {
        // Обновление статуса сущности и получение её с сервера
        const response = await axios.put(hostname + `api/hospitals/departments/idroppers/${dropperId}/is_active`, {
            deleted,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/droppersApi: putStatusDropperById__chief  => ${err}`);
    }
}

export {
    createNewDropperDB__chief,
    getAllDroppersDB__chief,
    getDropperById__chief,
    putDropperById__chief,
    putStatusDropperById__chief,
}