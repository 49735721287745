<template>
  <v-dialog v-model="modelValue" width="auto">

    <v-card class="dialog__wrapper">
          
          <form class="dialog__form">
             <!-- Превью -->
             <div class="dialog__form--preview-block">
                  <input id="preview-image" v-show="false"/>
                  <label 
                  class="preview-image" 
                  for="preview-image"
                  >
                      <v-icon 
                      icon="mdi-bed-outline" 
                      color="var(--color-default)"
                      size="80px"
                      ></v-icon>
                  </label>
                  <h1 class="preview-name">Создание койки</h1>
              </div>

              <!-- Общие данные -->
              <div class="dialog__form--general-data">
                  <!-- Имя -->
                  <v-card-subtitle>* Название койки</v-card-subtitle>
                  <v-text-field
                  class="general-data__input"
                  bg-color="var(--bg-color-white)"
                  :rules="inputRules"
                  v-model="bedName"
                  color="var(--color-default)"
                  rounded="xs"
                  :placeholder="'Название'"
                  :density="'compact'"
                  variant="filled"
                  ></v-text-field>

                  <div class="patient-data-block">
                      
                      <!-- Фамилия -->
                      <div class="patient-data-block__item">
                          <v-card-subtitle>* Фамилия пациента</v-card-subtitle>
                          <v-text-field
                          class="general-data__input"
                          bg-color="var(--bg-color-white)"
                          :rules="inputRules"
                          v-model="patientLastName"
                          color="var(--color-default)"
                          rounded="xs"
                          :placeholder="'Иванов'"
                          :density="'compact'"
                          variant="filled"
                          ></v-text-field>
                      </div>

                      <!-- Имя -->
                      <div class="patient-data-block__item">
                          <v-card-subtitle>* Имя пациента</v-card-subtitle>
                          <v-text-field
                          class="general-data__input"
                          bg-color="var(--bg-color-white)"
                          :rules="inputRules"
                          v-model="patientFirstName"
                          color="var(--color-default)"
                          rounded="xs"
                          :placeholder="'Иван'"
                          :density="'compact'"
                          variant="filled"
                          ></v-text-field>
                      </div>

                      <!-- Отчество -->
                      <div class="patient-data-block__item">
                          <v-card-subtitle>Отчество пациента</v-card-subtitle>
                          <v-text-field
                          class="general-data__input"
                          bg-color="var(--bg-color-white)"
                          v-model="patientPatronymic"
                          color="var(--color-default)"
                          rounded="xs"
                          :placeholder="'Иванович'"
                          :density="'compact'"
                          variant="filled"
                          ></v-text-field>
                      </div>
                  </div>

                  <!-- Выбор Больницы -->
                  <hospitalListComp
                  v-if="authStore.isSuperUser === true && isSelectedAnotherItems === false"
                  style="width: 100% !important; margin-top: 1.5rem;" 
                  :label-compbox="'Больница'" 
                  :item-list="hospitalList"
                  :header-title="'Выберите больницу'"
                  @load-items="loadHospitals"
                  @close-menu="hospitalList.length = 0"
                  @clear-hospital="handlerClearHospital"
                  @select-hospital="(hospital) => handlerSelectHospital(hospital)"
                  :selected-hospital-name="selectedHospitalName"
                  />

                  <!-- Выбор отделения -->
                  <departmentListComp
                  v-if="isSelectedAnotherItems === false"
                  style="width: 100% !important; margin-top: 1.5rem;" 
                  :label-compbox="'Отделение'" 
                  :item-list="departmentList"
                  :header-title="'Выберите отделение'"
                  :filter-by-hospital-id="(authStore.isSuperUser === true)? hospitalId : null"
                  @load-items="loadDepartments"
                  @close-menu="departmentList.length = 0"
                  @clear-department="handlerClearDepartment"
                  @select-department="(department) => handlerSelectDepartment(department)"
                  :selected-department-name="selectedDepartmentName"
                  :is-disabled="hospitalId === null && authStore.isSuperUser === true"
                  />

                  <!-- Выбор палаты -->
                  <hospitalRoomListComp
                  v-if="isSelectedAnotherItems === false"
                  style="width: 100% !important; margin-top: 1.5rem;" 
                  :label-compbox="'Палата'" 
                  :item-list="hospitalRoomList"
                  :header-title="'Выберите палату'"
                  :filter-by-department-id="departmentId"
                  @load-items="loadHospitalRoom"
                  @close-menu="hospitalRoomList.length = 0"
                  @clear-hospital-room="clearHospitalRoom"
                  @select-hospital-room="(hospitalRoom) => handlerSelectHospitalRoom(hospitalRoom)"
                  :selected-hospital-room-name="selectedHospitalRoomName"
                  :is-disabled="departmentId === null && authStore.isSuperUser === true"
                  />
              </div>
          </form>
          <div class="dialog__actions">
              <v-btn 
              class="mr-6"
              @click="confirmData"
              variant="flat" 
              :loading="isLoading"
              style="color: white; font-weight: normal; font-size: 12px;"
              color="var(--bg-color-default)" 
              >
                  Сохранить
              </v-btn>

              <!-- Очистить форму -->
              <v-btn 
              variant="tonal"
              style="margin-right: auto; font-size: 12px;"
              color="var(--bg-color-default)"
              @click="resetFormFields" 
              >
                  Очистить форму
              </v-btn>
              <v-btn 
              variant="outlined" 
              color="red" 
              style="font-size: 12px;"
              @click="emit('close')">
                  Закрыть
              </v-btn>
          </div>
    </v-card>
</v-dialog>
</template>

<script setup lang="ts">
// COMPONENTS
import hospitalRoomListComp from './hospitalRoomListComp.vue';
import hospitalListComp from '../departments/hospitalListComp.vue';
import departmentListComp from '../hospitalRooms/departmentListComp.vue';
// API
import {createNewHospitalBedDB} from '../../api/superuser/hospitalBedsApi';
import {createNewHospitalBedDB__chief} from '../../api/hospitalChief/hospitalBedsApi';
// COMPOSABLES
import useLoadingHospitalRooms from '../../composable/loadHospitalRoomList';
import useLoadingHospital from '../../composable/loadHospitalList';
import useLoadingDepartmetns from '../../composable/loadDepartmentList';
// TYPES
import {HospitalClient} from '../../types/hospitalType';
import {DepartmentClient} from '../../types/departmentType';
import {HospitalRoomClient} from '../../types/hospitalRoomType';
import {HospitalBedClient} from '../../types/hospitalBedType';
// STORE
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';
// VUE
import {ref, defineModel, defineEmits, onBeforeMount, watch} from 'vue';
import {useRoute} from 'vue-router';


const mainStore = useMainStore();
const authStore = useAuthStore();
const route = useRoute();
const modelValue = defineModel<boolean>();

// ============================  EMITS  ============================
const emit = defineEmits<{
  close: [],
  error: [msg: string],
  success: [];
  appendHospitalBed: [hospitalBed: HospitalBedClient],
}>();

// ============================  DATA  ============================
const isLoading = ref(false);
// const id = ref<number>(0);

const bedName = ref<string | null>(null);
const patientFirstName = ref<string | null>(null);
const patientLastName = ref<string | null>(null);
const patientPatronymic = ref<string | undefined | null>(null);
const hospitalId = ref<number | null>(null);
const departmentId = ref<number | null>(null);
const hospitalRoomId = ref<number | null>(null);
const isSelectedAnotherItems = ref<boolean>(false);

const inputRules = ref([
  (value: string) => !!value || 'Обязательное поле',
]);
// Компонуемый файл для получения списка отделений и отображения их в выпадающем меню
const {
  hospitalRoomList,
  selectedHospitalRoom,
  selectedHospitalRoomName,

  loadHospitalRoom,
  clearHospitalRoom,
  selectHospitalRoom,
} = useLoadingHospitalRooms();

// Компонуемый файл для получения списка больниц и отображения их в выпадающем меню
const {
  hospitalList,
  selectedHospital,
  selectedHospitalName,

  loadHospitals,
  clearHospital,
  selectHospital,
} = useLoadingHospital();

// Компонуемый файл для получения списка отделений и отображения их в выпадающем меню
const {
  departmentList,
  selectedDepartment,
  selectedDepartmentName,

  loadDepartments,
  clearDepartment,
  selectDepartment,
} = useLoadingDepartmetns();

// ==========================================   METHODS   ==========================================

function handlerClearHospital() {
  clearHospital();
  hospitalId.value = null;
}

function handlerClearDepartment() {
  clearDepartment();
  departmentId.value = null;
}

// Функция обработчик для фиксирования ID БОЛЬНИЦЫ из селект-меню и сохранения выбранной БОЛЬНИЦЫ
function handlerSelectHospital(hospital: HospitalClient | null) {
  if (hospital) {
    hospitalId.value = hospital.id;
    selectHospital(hospital);
  }
}

// Функция обработчик для фиксирования ID ОТДЕЛЕНИЯ из селект-меню и сохранения выбранного ОТДЕЛЕНИЯ
function handlerSelectDepartment(department: DepartmentClient | null) {
  if (department) {
    departmentId.value = department.id;
    selectDepartment(department);
  }
}

// Функция обработчик для фиксирования ID ОТДЕЛЕНИЯ из селект-меню и сохранения выбранного ОТДЕЛЕНИЯ
function handlerSelectHospitalRoom(hospitalRoom: HospitalRoomClient | null) {
  if (hospitalRoom) {
    hospitalRoomId.value = hospitalRoom.id;
    selectHospitalRoom(hospitalRoom);
  }
}

async function createNewHospitalBed(
  bedName: string,
  patientFirstName: string,
  patientLastName: string,
  patientPatronymic: string | undefined | null,
  hospitalId: number,
  departmentId: number,
  hospitalRoomId: number
) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      if (patientPatronymic === null) patientPatronymic = undefined;
      return await createNewHospitalBedDB(bedName, patientFirstName, patientLastName, patientPatronymic, hospitalId, departmentId, hospitalRoomId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    if (patientPatronymic === null) patientPatronymic = undefined;
    return await createNewHospitalBedDB__chief(bedName, patientFirstName, patientLastName, patientPatronymic, hospitalId, departmentId, hospitalRoomId);
  } catch (err) {
    throw new Error(`components/hospitalBeds/creationHospitalBedsComp.vue: createNewHospitalBed  => ${err}`);
  }
}

// Подтверждение данных для создания новой койки
async function confirmData() {
  try {
    isLoading.value = true;
    if (
      patientFirstName.value &&
      patientFirstName.value.length > 0 &&
      patientLastName.value &&
      patientLastName.value.length > 0 &&
      departmentId.value &&
      hospitalRoomId.value &&
      bedName.value &&
      bedName.value.length > 0
    ) {
      // Если перешли на список коек через панель навигации
      if (selectedHospital.value === null && authStore.isSuperUser === true && isSelectedAnotherItems.value === false) return emit('error', 'Укажите больницу');
      if (selectedDepartment.value === null && isSelectedAnotherItems.value === false) return emit('error', 'Укажите отделение');
      if (selectedHospitalRoom.value === null && isSelectedAnotherItems.value === false) return emit('error', 'Укажите палату');
      if (patientPatronymic.value === '') patientPatronymic.value = undefined;

      // Если айдишника выбранной больницы нет и мы в роли управляющего, то берем hospitalId с localStorage (user)
      if (!hospitalId.value && authStore.isChief === true) {
        const chief = mainStore.fetchDecryptedUser();
        hospitalId.value = chief.hospitalId;
      }
      if (hospitalId.value) {
        const newBed = await createNewHospitalBed(
          bedName.value,
          patientFirstName.value,
          patientLastName.value,
          patientPatronymic.value,
          hospitalId.value,
          departmentId.value,
          hospitalRoomId.value
        );
        emit('success');
        emit('appendHospitalBed', newBed);
      }
    } else {
      // Поднимаем ошибку если поля ввода пусты
      emit('error', 'Заполните все поля');
    }
  } catch (err) {
    throw new Error(`components/hospitalBeds/creationHospitalBedsComp.vue: confirmData  => ${err}`);
  } finally {
    isLoading.value = false;
  }
}

// Функция сбрасывает поля формы
function resetFormFields() {
    try {
        bedName.value = null;
        patientFirstName.value = null;
        patientLastName.value = null;
        patientPatronymic.value = null;
        selectedHospitalRoom.value = null;
        selectedDepartment.value = null;
        selectedHospital.value = null;
        selectedHospitalName.value = null
        selectedDepartmentName.value = null;
        selectedHospitalRoomName.value = null;
        departmentId.value = null;
        hospitalRoomId.value = null;
        hospitalId.value = null;
    } catch (err) {
        throw new Error(`components/hospitalBeds/creationHospitalBedsComp.vue: resetFormFields  => ${err}`);
    }
}


// ==========================================   WATCH   ==========================================
watch(() => [route.query['department'], route.query['hospital']], ([newValueDepartment, newValueHospital]) => {
  if(!newValueDepartment && !newValueHospital) {
    isSelectedAnotherItems.value = false;
  }  
}) 


// ==========================================   LIFECYCLE HOOKS   ==========================================
onBeforeMount(() => {
  // Задается hospitalId для создания новой койки. Берется ID больницы с localStorage, если пользователь является управляющим больницы
  if (authStore.isChief === true) {
    const chief = mainStore.fetchDecryptedUser();
    hospitalId.value = chief.hospitalId;
  }
  try {
    // Получение query параметра hospital-room
    const hospitalRoomIdQuery = route.query['hospital-room'];
    if (hospitalRoomIdQuery) {
      if (+hospitalRoomIdQuery > 0) {
        hospitalRoomId.value = +hospitalRoomIdQuery;
      }
    } else {
      emit('close');
    }
    // Получение query- department
    const departmentIdQuery = route.query['department'];
    if (typeof departmentIdQuery === 'string') {
      departmentId.value = +departmentIdQuery;
    }
    // Получение query- hospital
    const hospitalIdQuery = route.query['hospital'];
    if (typeof hospitalIdQuery === 'string') {
      hospitalId.value = +hospitalIdQuery;
    }
    if(departmentIdQuery && hospitalRoomIdQuery) {
      isSelectedAnotherItems.value = true;
    }
  } catch (err) {
    throw new Error(`components/hospitalBeds/creationHospitalBedsComp.vue: onMounted[fetch query]  => ${err}`);
  }
});

</script>

<style scoped>
.dialog__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  padding-bottom: 2rem;
  height: max-content;
  background-color: var(--bg-color-block);
}

.dialog__form {
  width: 100%;
  height: max-content;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-block);
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 2rem 1.5rem;
  background-color: var(--bg-color-white);
  margin-bottom: 0rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dialog__form--preview-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: max-content;
  padding: 2rem 0;
  background-color: rgba(239, 246, 248, 1);
}

.preview-image {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.image {
  width: 100%;
  height: 100%;
}

.preview-name {
  font-size: 26px;
  font-weight: 500;
  margin-top: 1rem;
}

.dialog__form--general-data {
  width: 80%;
  padding: 2rem 2rem 0 2rem;
  background-color: var(--bg-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.patient-data-block {
  display: flex;
  gap: 8px;
  width: 100%;
}

.patient-data-block__item {
  display: flex;
  width: 33.3333%;
  flex-direction: column;
}

.general-data__input {
  margin-bottom: 1rem;
}
</style>