<template>
  <div class="droppers-filter-panel my-2 d-flex align-center">
    <v-combobox
      class="filter-input"
      v-model="selectedDropperId"
      :items="listDropperIds"
      :loading="isLoadingFilteringDropperId"
      :density="'compact'"
      :hide-details="true"
      @update:model-value="(id: string | null) => selectDropperId(id)"
      type="number"
      variant="outlined"
      label="ID"
      clearable
      hide-spin-buttons
    ></v-combobox>

    <!-- Ввод названия Организации -->
    <v-combobox
      v-if="authStore.isSuperUser === true"
      class="filter-input"
      v-model="selectedHospitalName"
      :items="listHospitalNames"
      clearable
      label="Организация"
      variant="outlined"
      @update:model-value="(name: string | null) => selectHospitalName(name)"
      :loading="isLoadingFilteringHospitalName"
      :density="'compact'"
      :hide-details="true"
    ></v-combobox>

    <!-- Ввод названия отделения -->
    <v-combobox
      class="filter-input"
      v-model="selectedDepartmentName"
      :items="listDepartmentNames"
      clearable
      label="Отделение"
      variant="outlined"
      @update:model-value="(name: string | null) => selectDepartmentName(name)"
      :loading="isLoadingFilteringDepartmentName"
      :density="'compact'"
      :hide-details="true"
    ></v-combobox>

    <!-- Выбор Активности Устройства -->
    <v-switch
      class="ml-6"
      v-model="selectedStatus"
      color="var(--bg-color-default)"
      label="Неактивные"
      @update:model-value="(status: boolean | null) => selectStatus(status)"
      :value="true"
      hide-details
    >
    </v-switch>
  </div>
</template>

<script setup lang="ts">
import {ref, defineEmits, defineProps, watch} from 'vue';
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';

const store = useMainStore();
const authStore = useAuthStore();

// ==========================   PROPS   ==================================
const props = defineProps<{
  listDropperIds: Array<number | undefined>;
  listHospitalNames: Array<string | undefined>;
  listDepartmentNames: Array<string | undefined>;
}>();

// ==========================   EMIT   ==================================
const emit = defineEmits<{
  selectDropperId: [id: string];
  selectStatus: [status: boolean];
  selectHospitalName: [hospitalName: string];
  selectDepartmentName: [departmentName: string];
}>();

// ==========================   DATA   ==================================
const selectedDropperId = ref<string | null>(null);
const selectedStatus = ref<boolean>(false);
const selectedHospitalName = ref<string | null>(null);
const selectedDepartmentName = ref<string | null>(null);
const listDropperIds = ref<Array<string | undefined>>([]);
const listHospitalNames = ref<Array<string | undefined>>([]);
const listDepartmentNames = ref<Array<string | undefined>>([]);
const isLoadingFilteringDropperId = ref<boolean>(false);
const isLoadingFilteringHospitalName = ref<boolean>(false);
const isLoadingFilteringDepartmentName = ref<boolean>(false);
const payload = store.LazyLoading(500);

// ==========================   METHODS   ==================================
// Функция для выбора Статуса Устройства в списке
function selectDropperId(id: string | null) {
  try {
    isLoadingFilteringDropperId.value = true;
    payload(id, (entries) => {
      let currentMessage: string | null = entries[entries.length - 1];
      if (currentMessage === null) currentMessage = '';
      isLoadingFilteringDropperId.value = false;
      emit('selectDropperId', currentMessage);
    });
  } catch (err) {
    throw new Error(`components/droppers/droppersFilterPanelComp: selectStatus  => ${err}`);
  }
}


// Функция для записи Названия Больницы в поле поиска. 
function selectHospitalName(value: string | null) {
  try {
    isLoadingFilteringHospitalName.value = true;
    payload(value, (entries) => {
      let currentMessage: string | null = entries[entries.length - 1];
      if (currentMessage === null) currentMessage = '';
      isLoadingFilteringHospitalName.value = false;
      emit('selectHospitalName', currentMessage);
    });
  } catch (err) {
    throw new Error(`components/droppers/droppersFilterPanelComp: selectHospitalName  => ${err}`);
  }
}

// Функция для записи Названия Отделения в поле поиска.
function selectDepartmentName(value: string | null) {
  try {
    isLoadingFilteringDepartmentName.value = true;
    payload(value, (entries) => {
      let currentMessage: string | null = entries[entries.length - 1];
      if (currentMessage === null) currentMessage = '';
      isLoadingFilteringDepartmentName.value = false;
      emit('selectDepartmentName', currentMessage);
    });
  } catch (err) {
    throw new Error(`components/droppers/droppersFilterPanelComp: selectDepartmentName  => ${err}`);
  }
}

// Функция для выбора Статуса Устройства в списке
function selectStatus(status: boolean | null) {
  try {
    if (status !== null) {
      emit('selectStatus', status);
    }
  } catch (err) {
    throw new Error(`components/droppers/droppersFilterPanelComp: selectStatus  => ${err}`);
  }
}

// ==========================   WATCH   ==================================
watch(() => props.listDropperIds.length, (newLength) => {
  if (newLength > 0) {
    listDropperIds.value = props.listDropperIds;
  }
});

// Отслеживание изменения приходящего массива с Названиями Больницы для их отображения в списке
watch(() => props.listHospitalNames.length, (newLength) => {
  if (newLength > 0) {
    listHospitalNames.value = props.listHospitalNames;
  }
});

// Отслеживание изменения приходящего массива с Названиями Отделений для их отображения в списке
watch(() => props.listDepartmentNames.length, (newLength) => {
  if (newLength > 0) {
    listDepartmentNames.value = props.listDepartmentNames;
  }
});


</script>

<style>
.droppers-filter-panel {
  min-width: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 1.5rem;
}

.filter-input {
  width: 33%;

  .v-input__control {
    .v-field {
      .v-field__field {
        height: 44px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.filter-input + .filter-input {
  margin-left: 0.4rem;
}
</style>