// Взаимодействие суперпользователя с Палатами 
import { ArrayHospitalRoomClient, HospitalRoomClient, HospitalRoomCreationServer } from '@/types/hospitalRoomType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import axios from 'axios';
import useMainStore from '../../store/mainStore';

// Создание новой палаты
async function createNewHospitalRoomDB__chief(name: string, hospitalId: number | null, departmentId: number): Promise<HospitalRoomClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.post(hostname + 'api/hospitals/departments/rooms', {
            name,
            hospital_id: hospitalId,
            department_id: departmentId,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/hospitalRoomsApi: createNewHospitalRoomDB__chief  => ${err}`);
    }
}

// Получение всех палат больницы с сервера
async function getAllHospitalRoomsDB__chief(isDeleted?: boolean, page?: number): Promise<ArrayHospitalRoomClient> {
    const store = useMainStore();
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/hospitals/rooms`, {
            params: {
                deleted: isDeleted,
                page,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data } = response;
        const rooms = data?.data;
        const paginator = data?.paginator;
        // Задаем в стор информацию о том доступны ли следующие страницы пагинации
        store.isHospitalRoomsPaginationHasNext = paginator?.has_next;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(rooms);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/hospitalRoomsApi: getAllHospitalRoomsDB__chief  => ${err}`);
    }
}

// Получение Палат которые принадлежат конкретному отделению (по ID отделения)
async function getHospitalRoomByDepartmentIdDB__chief(departmentId: number, isDeleted?: boolean, page?: number): Promise<ArrayHospitalRoomClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/hospitals/departments/${departmentId}/rooms`, {
            params: {
                deleted: isDeleted,
                page,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/hospitalRoomsApi: getHospitalRoomByDepartmentIdDB__chief  => ${err}`);
    }
}

// Получение Палаты по ID
async function getHospitalRoomById__chief(hospitalRoomId: number): Promise<HospitalRoomClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/hospitals/departments/rooms/${hospitalRoomId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/hospitalRoomsApi: getHospitalRoomById__chief  => ${err}`);
    }
}

// Изменить данные палаты по её ID
async function putHospitalRoomById__chief( roomId: number, changedFields: HospitalRoomCreationServer): Promise<HospitalRoomClient> {
    try {
        // Обновление сущности и получение её с сервера
        const response = await axios.put(hostname + `api/hospitals/departments/rooms/${roomId}`, {
            ...changedFields,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/hospitalRoomsApi: putHospitalRoomById__chief  => ${err}`);
    }
}

// Сделать палату не активной
async function putStatusHospitalRoomById__chief(hospitalRoomId: number, deleted: boolean): Promise<HospitalRoomClient> {
    try {
        // Обновление статуса сущности и получение её с сервера
        const response = await axios.put(hostname + `api/hospitals/departments/rooms/${hospitalRoomId}/is_active`, {
            deleted,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/hospitalRoomsApi: putStatusHospitalRoomById  => ${err}`);
    }
}


export {
    createNewHospitalRoomDB__chief,
    getAllHospitalRoomsDB__chief,
    getHospitalRoomById__chief,
    getHospitalRoomByDepartmentIdDB__chief,
    putHospitalRoomById__chief,
    putStatusHospitalRoomById__chief,
}