<template>
  <v-dialog v-model="modelValue" width="auto">

    <v-card class="dialog__wrapper">

      <form class="dialog__form">
        <!-- Превью -->
        <div class="dialog__form--preview-block">
          <input id="preview-image" v-show="false"/>
          <label
            class="preview-image"
            for="preview-image"
          >
            <v-icon
              icon="mdi-table-large"
              color="var(--color-default)"
              size="80px"
            ></v-icon>
          </label>
          <h1 class="preview-name">Создание палаты</h1>
        </div>

        <!-- Общие данные -->
        <div class="dialog__form--general-data">
          <!-- Имя -->
          <v-card-subtitle class="mb-2">* Название палаты</v-card-subtitle>
          <v-text-field
            class="general-data__input"
            bg-color="var(--bg-color-white)"
            :rules="inputRules"
            v-model="name"
            color="var(--color-default)"
            :placeholder="'Укажите название'"
            rounded="xs"
            :density="'compact'"
            variant="outlined"
          ></v-text-field>

          <!-- Выбор Больницы -->
          <hospitalListComp
            v-if="isOpenByQueries === false && authStore.isSuperUser === true"
            style="width: 100% !important;"
            :label-compbox="'Организация'"
            :item-list="hospitalList"
            :header-title="'Выберите организацию'"
            @clear-hospital="handlerClearHospital"
            @select-hospital="(hospital) => handlerSelectHospital(hospital)"
            :selected-hospital-name="selectedHospitalName"
          />

          <!-- Выбор отделения -->
          <departmentListComp
            v-if="!isOpenByQueries"
            style="width: 100% !important;"
            :label-compbox="'Отделение'"
            :required="{ isRequired: true, errorMessage: 'Отделение обязательное поле', isTriggerErr: false }"
            :item-list="departmentList"
            :header-title="'Выберите отделение'"
            :filter-by-hospital-id="(authStore.isSuperUser === true)? hospitalId : null"
            @clear-department="handlerClearDepartment"
            @select-department="(department) => handlerSelectDepartment(department)"
            :selected-department-name="selectedDepartmentName"
            :is-disabled="hospitalId === null && authStore.isSuperUser === true"
          />


        </div>
      </form>
      <div class="dialog__actions">
        <v-btn
          class="mr-6"
          @click="confirmData"
          style="color: white; font-weight: normal; font-size: 12px;"
          variant="flat"
          :loading="isLoading"
          color="var(--bg-color-default)"
        >
          Сохранить
        </v-btn>

        <!-- Очистить форму -->
        <v-btn
          variant="tonal"
          style="margin-right: auto; font-size: 12px;"
          color="var(--bg-color-default)"
          @click="clearForm"
        >
          Очистить форму
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn
          variant="outlined"
          color="red"
          style="font-size: 12px;"
          @click="emit('close')">
          Закрыть
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
// COMPONENTS
import hospitalListComp from '../departments/hospitalListComp.vue';
import departmentListComp from './departmentListComp.vue';
// API
import {createNewHospitalRoomDB} from '../../api/superuser/hospitalRoomsApi';
import {createNewHospitalRoomDB__chief} from '../../api/hospitalChief/hospitalRoomsApi';
// COMPOSABLES
import useLoadingDepartments from '../../composable/loadDepartmentList';
import useLoadingHospital from '../../composable/loadHospitalList';
import {ref, watch, defineModel, defineEmits, onMounted} from 'vue';
import {useRoute} from 'vue-router';
// TYPES
import {UserClient} from '../../types/userType';
import {HospitalClient} from '../../types/hospitalType';
import {DepartmentClient} from '../../types/departmentType';
import {HospitalRoomClient} from '../../types/hospitalRoomType';
// STORE
import useAuthStore from '../../store/authStore';
import useMainStore from '../../store/mainStore';

const mainStore = useMainStore();
const authStore = useAuthStore();
const route = useRoute();
const modelValue = defineModel<boolean>();
const emit = defineEmits<{
  close: [],
  error: [msg: string],
  success: [];
  appendHospitalRoom: [hospitalRoom: HospitalRoomClient],
}>();

// ============================  DATA  ============================

const isLoading = ref(false);
// const id = ref<number>(0);
const name = ref<string | null>(null);
const hospitalId = ref<number | null>(null);
const departmentId = ref<number | null>(null);
const isOpenByQueries = ref<boolean>(false);  // Указывает на то отображать ли поля выбора больницы и отделения. Если переход был по Больницы -> Отделение то селект поля не нужны, айдишники берутся из query-параметров URL

const inputRules = ref([
  (value: string) => !!value || 'Обязательное поле',
]);

// Компонуемый файл для получения списка больниц и отображения их в выпадающем меню
const {
  hospitalList,
  selectedHospital,
  selectedHospitalName,
  clearHospital,
  selectHospital,
} = useLoadingHospital();

// Компонуемый файл для получения списка отделений и отображения их в выпадающем меню
const {
  departmentList,
  selectedDepartment,
  selectedDepartmentName,
  clearDepartment,
  selectDepartment,
} = useLoadingDepartments();

// ==========================================   METHODS   ==========================================
function handlerClearHospital() {
  clearHospital();
  hospitalId.value = null;
  hospitalList.value.length = 0;
}

function handlerClearDepartment() {
  clearDepartment();
  departmentId.value = null;
  departmentList.value.length = 0;
}

function clearForm() {
  selectedDepartment.value = null;
  selectedDepartmentName.value = null;
  selectedHospital.value = null;
  selectedHospitalName.value = null;
  name.value = null;
  hospitalId.value = null;
  departmentId.value = null;
}

// Функция обработчик для фиксирования ID БОЛЬНИЦЫ из селект-меню и сохранения выбранной БОЛЬНИЦЫ
function handlerSelectHospital(hospital: HospitalClient | null) {
  if (hospital) {
    hospitalId.value = hospital.id;
    selectHospital(hospital);
  }
}

// Функция обработчик для фиксирования ID ОТДЕЛЕНИЯ из селект-меню и сохранения выбранного ОТДЕЛЕНИЯ
function handlerSelectDepartment(department: DepartmentClient | null) {
  if (department) {
    departmentId.value = department.id;
    selectDepartment(department);
  }
}

// Функция для создания нового айтема. Вызывает запрос относительно роли пользователя
async function createNewHospitalRoom(name: string, hospitalId: number | null, departmentId: number) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await createNewHospitalRoomDB(name, hospitalId, departmentId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await createNewHospitalRoomDB__chief(name, hospitalId, departmentId);
  } catch (err) {
    throw new Error(`components/hospitalRooms/creationHospitalRoomsComp.vue: createNewHospitalRoom  => ${err}`);

  }
}

// Подтверждение данных для создания новой палаты
async function confirmData() {
  try {
    isLoading.value = true;
    if (name.value && name.value.length > 0) {
      if (hospitalId.value === null && authStore.isSuperUser === true) return emit('error', 'Выберите больницу');
      if (departmentId.value === null) return emit('error', 'Выберите отделение');
      const createdHospitalRoom = await createNewHospitalRoom(name.value, hospitalId.value, departmentId.value);
      emit('appendHospitalRoom', createdHospitalRoom);
      emit('success');
    } else {
      // Поднимаем ошибку если поля ввода пусты
      emit('error', 'Укажите название');
    }
  } catch (err) {
    throw new Error(`components/hospitalRooms/creationHospitalRoomsComp.vue: confirmData  => ${err}`);
  } finally {
    isLoading.value = false;
    clearForm();
  }
}

// ==========================================   WATCH   ==========================================
// Если находимся на странице палат и установлены query парметры hospital и department и если они удаляются то ставим режим isOpenByQueries в значение false
watch([() => route.query['hospital'], () => route.query['department']], ([hospitalQuery, departmentQuery]) => {
  if (hospitalQuery === undefined && departmentQuery === undefined) {
    isOpenByQueries.value = false;
  }
});

watch(() => authStore.isChief === true, (isChief) => {
  // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы то ID больницы достаем из localStorage (ключ user)
  if (isChief === true) {
    const user: UserClient = mainStore.fetchDecryptedUser();
    if (user.hospitalId) {
      hospitalId.value = user.hospitalId;
    }
  }
})

// ==========================================   LIFECYCLE HOOKS   ==========================================
onMounted(() => {
  try {
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы то ID больницы достаем из localStorage (ключ user)
    if (authStore.isChief === true) {
      const user: UserClient = mainStore.fetchDecryptedUser();
      if (user.hospitalId) {
        hospitalId.value = user.hospitalId;
      }
    }
    // Получение query параметра hospital
    const hospitalIdQuery = route.query['hospital'];
    // Получение query параметра department
    const departmentIdQuery = route.query['department'];
    if (departmentIdQuery && hospitalIdQuery) {
      if (+departmentIdQuery > 0 && +hospitalIdQuery > 0) {
        departmentId.value = +departmentIdQuery;
        hospitalId.value = +hospitalIdQuery;
        isOpenByQueries.value = true;
      }
    } else {
      emit('close');
    }
  } catch (err) {
    throw new Error(`components/hospitalRooms/creationHospitalRoomsComp.vue: onMounted[fetch query]  => ${err}`);

  }
});

</script>

<style scoped>
.dialog__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  padding-bottom: 2rem;
  height: max-content;
  background-color: var(--bg-color-block);
}

.dialog__form {
  width: 100%;
  height: max-content;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-block);
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 2rem 1.5rem;
  background-color: var(--bg-color-white);
  margin-bottom: 2rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dialog__form--preview-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: max-content;
  padding: 2rem 0;
  background-color: rgba(239, 246, 248, 1);
}

.preview-image {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.image {
  width: 100%;
  height: 100%;
}

.preview-name {
  font-size: 26px;
  font-weight: 500;
  margin-top: 1rem;
}

.dialog__form--general-data {
  width: 80%;
  padding: 2rem 2rem 0 2rem;
  background-color: var(--bg-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
</style>