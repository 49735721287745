<template>
    <div class="hospital-rooms-filter-panel">

        <v-spacer></v-spacer>

        <!-- Выбор Активности отделения -->
        <v-switch
        v-model="selectedStatus"
        color="var(--bg-color-default)"
        label="Неактивные"
        @update:model-value="(status: boolean) => selectStatus(status)"
        :value="true"
        hide-details
        >
        </v-switch>
    </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, defineProps, watch } from 'vue';

// ==========================   PROPS   ==================================
const props = defineProps<{
    isActiveDepartment: boolean;
}>();

// ==========================   EMIT   ==================================
const emit = defineEmits<{
    selectStatus: [status: boolean];
}>();

// ==========================   DATA   ==================================
const selectedStatus = ref<boolean>(false);

// ==========================   WATCH   ==================================
watch(() => props.isActiveDepartment, (newValue) => {
    if(newValue === false) {
        selectedStatus.value = true;
        emit('selectStatus', true);
    }
})

// ==========================   METHODS   ==================================

// Функция для выбора Статуса пользователя в списке
function selectStatus(status: boolean) {
    try {
        emit('selectStatus', status);
    } catch (err) {
        throw new Error(`components/hospitalRooms/hospitalRoomsFilterPanelComp: selectStatus  => ${err}`);
    }
}


</script>

<style scoped>
.hospital-rooms-filter-panel {
    min-width: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 1.5rem 0 2rem;
}
.filter-input {
    width: 33%;
}

</style>