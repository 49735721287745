<template>
    <v-navigation-drawer 
    class="navigation-drawer" 
    :width="props.drawerWidth" 
    expand-on-hover rail
    @mouseenter="drawerExpand = true" 
    @mouseleave="drawerExpand = false"
    >
        <div class="navigation-logo">
            <div class="navigation-logo__text">
                <img src="@/assets/livenh.svg" alt="livenh">
            </div>
            <div class="navigation-logo__wrapper">
                <img class="navigation-logo__img" src="@/assets/logo.svg" alt="o">
            </div>
            <div class="navigation-logo__text">
                <img src="@/assets/use.svg" alt="use">
            </div>
        </div>


        <v-list density="compact" nav>
            <!-- ПРОЦЕДУРЫ -->
            <RouterLink :to="{ name: 'procedures' }" class="navigation-drawer__link">
                <v-list-item prepend-icon="mdi-water-outline" :title="titleProcedure" base-color="var(--color-drawer)"
                    value="procedures">
                </v-list-item>
            </RouterLink>

            <!-- СОТРУДНИКИ -->
            <RouterLink :to="{ name: 'employees' }" class="navigation-drawer__link">
                <v-list-item prepend-icon="mdi-account-tie" :title="titleEmployees" base-color="var(--color-drawer)"
                    value="employees">
                </v-list-item>
            </RouterLink>

            <!-- НАСТРОЙКИ (Группа элементов) -->
            <v-list-group class="v-list-group" value="Settings">
                <template v-slot:activator="{ props }">
                    <div v-bind="props">
                        <v-list-item base-color="var(--color-drawer)" prepend-icon="mdi-cog"
                            title="Настройки"></v-list-item>
                    </div>
                </template>

                <!-- ОРГАНИЗАЦИИ / МОЯ БОЛЬНИЦА -->
                <RouterLink
                    :to="authStore.isSuperUser ? { name: 'hospitals' } : authStore.isChief ? { name: 'myHospital' } : { name: 'main' }"
                    class="navigation-drawer__link">
                    <v-list-item class="navigation-drawer__item"
                        :class="{ 'navigation-drawer__item_opened': !drawerExpand }"
                        prepend-icon="mdi-hospital-building" :title="titleHospitals" base-color="var(--color-drawer)"
                        value="hospitals">
                    </v-list-item>
                </RouterLink>

                <!-- ОТДЕЛЕНИЯ -->
                <RouterLink :to="{ name: 'departments' }" class="navigation-drawer__link">
                    <v-list-item class="navigation-drawer__item"
                        :class="{ 'navigation-drawer__item_opened': !drawerExpand }" prepend-icon="mdi-table"
                        :title="titleDepartments" base-color="var(--color-drawer)" value="departments">
                    </v-list-item>
                </RouterLink>

                <!-- ПАЛАТЫ -->
                <RouterLink :to="{ name: 'hospitalRooms' }" class="navigation-drawer__link">
                    <v-list-item class="navigation-drawer__item"
                        :class="{ 'navigation-drawer__item_opened': !drawerExpand }" prepend-icon="mdi-table-large"
                        :title="titleHospitalRooms" base-color="var(--color-drawer)" value="hospitalRooms">
                    </v-list-item>
                </RouterLink>

                <!-- КОЙКИ -->
                <RouterLink :to="{ name: 'hospitalBeds' }" class="navigation-drawer__link">
                    <v-list-item class="navigation-drawer__item"
                        :class="{ 'navigation-drawer__item_opened': !drawerExpand }" prepend-icon="mdi-bed-outline"
                        :title="titleBeds" base-color="var(--color-drawer)" value="hospitalBeds">
                    </v-list-item>
                </RouterLink>

                <!--  УСТРОЙСТВА  -->
                <RouterLink :to="{ name: 'droppers' }" class="navigation-drawer__link">
                    <v-list-item class="navigation-drawer__item"
                        :class="{ 'navigation-drawer__item_opened': !drawerExpand }" prepend-icon="mdi-water-outline"
                        :title="titleDroppers" base-color="var(--color-drawer)" value="droppers">
                    </v-list-item>
                </RouterLink>
            </v-list-group>

        </v-list>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
import { ref, defineProps, onBeforeMount } from 'vue';
import { getInfoMyHospital_chief } from '@/api/hospitalChief/hospitalsApi';
import { HospitalClient } from '@/types/hospitalType';
import useMainStore from '@/store/mainStore';
import useAuthStore from '@/store/authStore';

const store = useMainStore();
const authStore = useAuthStore();

// ============================  PROPS  ================================
const props = defineProps<{
    drawerWidth: number,
}>();

// ============================  DATA  ================================
const titleHospitals = ref('Организации');
const titleDepartments = ref('Отделения');
const titleHospitalRooms = ref('Палаты');
const titleBeds = ref('Койки');
const titleEmployees = ref('Сотрудники');
const titleProcedure = ref('Процедуры');
const titleDroppers = ref('Дропперы');
const drawerExpand = ref(false)

// ============================  LIFECYCLE HOOKS  ================================
onBeforeMount(async () => {
    try {
        const encryptedUser = localStorage.getItem('user');
        const key = localStorage.getItem('token');
        const user = store.decryptObject(encryptedUser, key);
        if (user) {
            if (user.isSuperuser === true) {
                authStore.setAuthRole(true);
                authStore.setChiefRole(false);
            } else {
                // Проверка является ли авторизованный пользователем управляющим больницы
                let infoHospital: null | HospitalClient = null;
                try {
                    infoHospital = await getInfoMyHospital_chief();
                } catch (err) {
                    authStore.setChiefRole(false);
                    authStore.setAuthRole(false);
                }
                if (infoHospital?.chief?.id === user.id) {
                    authStore.setChiefRole(true);
                    titleHospitals.value = 'Моя больница';
                }
            }
        } else {
            throw new Error(`components/navigationDrawerComp:onBeforeMount  => не удалось получить пользователя из localStorage`);
        }
    } catch (err) {
        throw new Error(`components/navigationDrawerComp:onBeforeMount  => ${err}`);
    }
});

</script>

<style scoped>
.navigation-drawer {
    height: 100% !important;
    background-color: rgba(140, 107, 231, 0.65);
    backdrop-filter: blur(6px);
    margin-top: auto;
    border-right: none;
    z-index: 9999 !important;


    .navigation-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
        width: 100%;
        height: 60px;
        background-color: #1F2420;
        color: #FFFFFF;

        .navigation-logo__text {
            display: flex;
            align-items: center;
            max-width: 0;
            opacity: 0;
            transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }

        .navigation-logo__wrapper {
            display: flex;
            align-items: center;
            width: 40px;
            transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }

        .navigation-logo__img {
            width: 100%;
        }
    }

    &:hover {
        .navigation-logo {
            background-color: rgba(183, 164, 234, 0.65);
            backdrop-filter: blur(6px);
            transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

            .navigation-logo__text {
                max-width: 100%;
                opacity: 1;
            }

            .navigation-logo__wrapper {
                width: 23px;
            }
        }
    }

    .navigation-drawer__link {
        text-decoration: none;
    }

    .navigation-drawer__item {
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .navigation-drawer__item_opened {
        padding-inline-start: 8px !important;
    }

}
.navbar-logo-img {
    position: absolute;
    right: -60px;
    width: 50px;
    height: 50px;
    z-index: 1;
}
.logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
</style>