<template>
  <div class="departments-filter-panel">

    <!-- Ввод названия Организации -->
    <v-combobox
      class="filter-input"
      v-model="selectedHospitalName"
      :items="listHospitalNames"
      clearable
      label="Организация"
      variant="outlined"
      @update:model-value="(name: string | null) => selectHospitalName(name)"
      :loading="isLoadingFilteringHospitalName"
      :density="'compact'"
      :hide-details="true"
    ></v-combobox>

    <v-spacer></v-spacer>

    <!-- Выбор Активности отделения -->
    <v-switch
      v-model="selectedStatus"
      color="var(--bg-color-default)"
      label="Неактивные"
      @update:model-value="(status: boolean) => selectStatus(status)"
      :value="true"
      hide-details
    >
    </v-switch>
  </div>
</template>

<script setup lang="ts">
import {ref, defineEmits, defineProps, watch} from 'vue';
import useMainStore from '../../store/mainStore';

const store = useMainStore();


// ==========================   PROPS   ==================================
const props = defineProps<{
  isActiveHospital: boolean;
  listHospitalNames: Array<string | undefined>;
}>();

// ==========================   EMIT   ==================================
const emit = defineEmits<{
  selectStatus: [status: boolean];
  selectHospitalName: [hospitalName: string];
}>();

// ==========================   DATA   ==================================
const selectedStatus = ref<boolean>(false);
const selectedHospitalName = ref<string | null>(null);
const listHospitalNames = ref<Array<string | undefined>>([]);
const isLoadingFilteringHospitalName = ref<boolean>(false);
const payload = store.LazyLoading(300);


// ==========================   WATCH   ==================================
watch(() => props.isActiveHospital, (newValue) => {
  if (newValue === false) {
    selectedStatus.value = true;
    emit('selectStatus', true);
  }
})

// ==========================   METHODS   ==================================
// Функция для выбора Статуса пользователя в списке
function selectStatus(status: boolean) {
  try {
    emit('selectStatus', status);
  } catch (err) {
    throw new Error(`components/departments/departmentsFilterPanelComp: selectStatus  => ${err}`);
  }
}

// Функция для записи Названия Больницы в поле поиска. 
function selectHospitalName(value: string | null) {
  try {
    isLoadingFilteringHospitalName.value = true;
    payload(value, (entries) => {
      let currentMessage: string | null = entries[entries.length - 1];
      if (currentMessage === null) currentMessage = '';
      isLoadingFilteringHospitalName.value = false;
      emit('selectHospitalName', currentMessage);
    });
  } catch (err) {
    throw new Error(`components/droppers/droppersFilterPanelComp: selectHospitalName  => ${err}`);
  }
}

</script>

<style scoped>
.departments-filter-panel {
  min-width: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 1.5rem 0 2rem;
}

.filter-input {
  width: 33%;
}
</style>