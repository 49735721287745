<template>
  <!-- Компонент для отрисовки Списка Сотрудников -->
  <div class="employees-list__container">

    <!-- Уведомление об успехе -->
    <success-note-comp @complete="handlerCompleteSuccAnim"></success-note-comp>
    <!-- Уведомление об ошибке -->
    <error-note-comp>{{ errorMsg }}</error-note-comp>

    <!-- Заголовок -->
    <div class="employees-list__header">
      <v-icon
        @click="router.go(-1)"
        class="btn-back"
        icon="mdi-arrow-left"
        size="22"
      ></v-icon>
      <h1 class="employees-list__header--title">Сотрудники</h1>
    </div>

    <!-- Диалоговое окно -->
    <dialogComp
      v-model="isShowDialog"
      @success="handlerSuccess"
      @update-employee="(employee) => emit('updateUser', employee)"
      @error="(msg: string) => handlerError(msg)"
      @close="isShowDialog = false"
    />

    <!-- Диалоговое окно создания нового сотрудника -->
    <creationEmployeeComp
      v-model="isShowCreationDialog"
      @close="isShowCreationDialog = false"
      @success="handlerSuccess"
      @append-user="(user) => emit('createNewUser', user)"
      @error="(msg) => errorMsg = msg"
      @is-reset-fields="isResetCreationFields = false"
      :toResetFields="isResetCreationFields"
    />

    <!-- Диалоговое окно для подтверждения Декативации элемента -->
    <disableEmployeeDialogComp
      :employee-name="deletedEmployeeData.fullName"
      v-model="isShowDisableDialog"
      @confirm="confirmDeleteEmployee"
      @cancel="isShowDisableDialog = false"
      :is-loading="isLoadingDeleted"
    />

    <!-- Диалоговое окно для подтверждения Активации элемента -->
    <activeEmployeeDialogComp
      :employee-name="activatedData.fullName"
      v-model="isShowActivateDialog"
      @confirm="confirmActivateEmployee"
      @cancel="isShowActivateDialog = false"
      :is-loading="isLoadingActivated"
    />

    <!-- Диалоговое окно для уведомление пользователя о том что пароль выслан на Email -->
    <successDialog 
    :is-show="isShowSuccessDialog"
    @close="isShowSuccessDialog = false"
    />

    <!-- Панель действий -->
    <div class="employees-list__actions-block">
      <v-btn
        style="color: var(--color-white); font-size: 0.8em; font-weight: bold;"
        color="var(--color-default)"
        rounded="large"
        size="large"
        @click="isShowCreationDialog = true"
      >
        Создать
      </v-btn>

      <!-- Фильтр панель -->
      <employeesFilterPanelComp
        :list-names="listNames"
        :list-emails="listEmails"
        @select-search-value="(value: string| null) => filledFilterData(value, null, null)"
        @select-shifter="(status: boolean) => filledFilterData(null, status, null)"
        @select-status="(status: boolean) => filledFilterData(null, null, status)"
      />
    </div>

    <!-- Крутилка загрузки -->
    <span class="progress-circular" v-if="props.isLoadingData">
      <v-progress-circular
        indeterminate
        bg-color="var(--bg-color-white)"
        color="var(--bg-color-default)"
        :size="60"
        :width="6"
      ></v-progress-circular>
    </span>

    <!-- Контент список -->
    <div class="employees-list__content-container" v-else-if="!!props.employees.length || props.isNotEmployees">
      <!-- Баннер сообщающий что пользователей нет -->
      <div class="is-not-items" v-show="props.isNotEmployees">
        <h1 class="is-not-items__title">
          {{ props.isNotEmployeesMsg }}
        </h1>
      </div>
      <!-- Отрисовка элеменитов списка -->
      <v-table class="employees-list__wrapper" :density="'comfortable'" fixed-header>
        <thead>
        <tr>
          <th class="text-left th avatar-employee">
          </th>
          <th class="text-left th names-th">
            ФИО
          </th>
          <th class="text-left th">
            Больница
          </th>
          <th class="text-left th">
            Отделение
          </th>
          <th class="text-left th">
            E-mail
          </th>
          <th class="text-left th">
            Статус
          </th>
          <th class="text-center th actions-th">
            Действия
          </th>
        </tr>
        </thead>
        <tbody class="tbody">
        <tr
          class="row-hover"
          @click="() => selectEmployee(employee?.id)"
          v-for="(employee) in employeesList as ArrayUserClient"
          :key="employee.id">
          <!-- № Номер -->
          <td class="list-item img" :id="`item-${employee?.id}`">
            <img class="employee-img"
                 :src="(employee?.avatar)? employee.avatar : require('../../assets/base-icons/empty-user.svg')"/>
          </td>
          <!-- ФИО -->
          <td class="list-item fullname">{{ employee?.fullName }}</td>
          <!-- БОЛЬНИЦА -->
          <td class="list-item">{{ employee.hospital }}</td>
          <!-- ОТДЕЛЕНИЕ -->
          <td class="list-item">{{ employee.department }}</td>
          <!-- E-mail -->
          <td class="list-item">{{ employee?.email }}</td>
          <!-- СТАТУС -->
          <td class="list-item">{{ (employee?.isActive) ? 'Активен' : "Не активен" }}</td>
          <!-- ДЕЙСТВИЯ -->
          <td class="list-item actions-td">
            <!-- Редактировать -->
            <v-btn
              rounded="xs"
              icon="mdi-cog-outline"
              :color="'var(--color-gray)'"
              variant="text"
              :density="'comfortable'"
            >
            </v-btn>

            <!-- Удалить -->
            <v-btn
              class="actions__btn del"
              @click.stop="handlerStatusEmployee(employee?.id, employee?.fullName)"
              rounded="xs"
              variant="text"
              :density="'comfortable'"
              icon="mdi-power"
              :color="(employee.isActive === false)? 'green' : 'red'"
            >
            </v-btn>
          </td>
        </tr>
        <!-- Триггерный блок для пагинации -->
        <tr class="last-item">
          <td class="trigger-pagination">
            <v-progress-circular
              v-show="isShowLoadingNewPage"
              indeterminate
              bg-color="var(--bg-color-white)"
              color="var(--bg-color-default)"
              :size="20"
              :width="2"
            ></v-progress-circular>
          </td>
        </tr>
        </tbody>
      </v-table>
    </div>

    <!-- Баннер сообщающий что пользователей нет -->
    <div class="is-list-empty" v-else-if="!props.employees.length || props.isNotEmployees === false">
      <h1 class="is-list-empty__title">
        {{ props.isNotEmployeesMsg }}
      </h1>
    </div>
  </div>
</template>

<script setup lang="ts">
// COMPONENTS
import dialogComp from './dialogComp.vue';
import creationEmployeeComp from './creationEmployeeComp.vue';
import disableEmployeeDialogComp from './disableEmployeeDialogComp.vue';
import activeEmployeeDialogComp from './activeEmployeeDialogComp.vue';
import employeesFilterPanelComp from './employeesFilterPanelComp.vue';
import successDialog from './successDialog.vue';
// API
import {changeStatusEmployeeById} from '../../api/superuser/employeesApi';
import {changeStatusEmployeeById__chief} from '../../api/hospitalChief/employeesApi';
// STORE
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';
// TYPES
import {UserClient, ArrayUserClient} from '../../types/userType';
// VUE
import {useRoute, useRouter} from 'vue-router';
import {ref, defineProps, defineEmits, watch, onMounted, computed, nextTick} from 'vue';

const store = useMainStore();
const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();

// ======================================   PROPS   ===========================================
const props = defineProps<{
  employees: Array<any>;
  isNotEmployees: boolean;
  isNotEmployeesMsg?: string;
  isLoadingData: boolean;
  isActiveEmployees: boolean;
}>();

// ======================================   EMITS   ======================================
const emit = defineEmits<{
  createNewUser: [user: UserClient];
  updateUser: [user: UserClient];
  disableUser: [userId: number | null];
  activateUser: [userId: number | null];
  paginationUp: [];
  userFilter: [filterData: {
    email: string | undefined,
    fullname: string | undefined,
    isShifter: boolean,
    isActive: boolean
  }];
}>();

// ======================================   DATA   ======================================
const isShowSuccessDialog = ref<boolean>(false);
const isShowDialog = ref<boolean>(false);
const isShowDisableDialog = ref<boolean>(false);
const isShowActivateDialog = ref<boolean>(false);
const isShowCreationDialog = ref(false);
const isLoadingDeleted = ref<boolean>(false);
const isLoadingActivated = ref<boolean>(false);
const isShowLoadingNewPage = ref<boolean>(false);
const isResetCreationFields = ref(false);
const employeesList = ref<Array<any>>([]);
const deletedEmployeeData = ref({
  id: null as null | number,
  fullName: '',
});
const activatedData = ref({
  id: null as null | number,
  fullName: '',
});
const errorMsg = ref('');
const filterData = ref({
  fullname: undefined as string | undefined,
  email: undefined as string | undefined,
  isShifter: false as boolean,
  isActive: true as boolean,
});

// Наблюдатель для триггера подгрузки страниц пагинации списка
const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (store.isEmployeePaginationHasNext === true && props.employees.length >= 30) {
        isShowLoadingNewPage.value = true;
      } else isShowLoadingNewPage.value = false;
      // Событие paginationUp для подгрузки данных следующей страницы пагинации
      emit('paginationUp');
    }
  });
});


// ==================================   COMPUTED  ============================

// Вычисление списка имен пользователей для фильтр-панели
const listNames = computed<Array<string | undefined>>(() => {
  try {
    return props.employees.map((employee: UserClient) => {
      if (employee.fullName !== null && employee.fullName !== undefined) {
        return employee.fullName;
      }
    });
  } catch (err) {
    throw new Error(`components/employees/employeesListComp: computed[listNames]  => ${err}`);
  }
});

// Вычисление списка электронных почт пользователей для фильтр-панели
const listEmails = computed<Array<string | undefined>>(() => {
  try {
    return props.employees.map((employee: UserClient) => {
      if (employee.email !== null && employee.email !== undefined) {
        return employee.email;
      }
    });
  } catch (err) {
    throw new Error(`components/employees/employeesListComp: computed[listEmails]  => ${err}`);
  }
});

// ======================================   METHODS   ========================================

// Заполняет объект фильтр данных
function filledFilterData(searchValue: string | null, isShifter: boolean | null, isActive: boolean | null) {
  try {
    if (searchValue !== null) {
      if (searchValue === '') {
        filterData.value.fullname = undefined;
        filterData.value.email = undefined;
      } else {
        // Если вводимая строка в поисковик похожа на E-mail то мы интерпретируем ее как E-mail
        filterData.value.fullname = searchValue
      }
    }
    if (isShifter !== null) {
      filterData.value.isShifter = isShifter;
    }
    if (isActive !== null) {
      filterData.value.isActive = isActive;
    }
    emit('userFilter', filterData.value);
  } catch (err) {
    throw new Error(`components/employees/employeesListComp: filledFilterData  => ${err}`);
  }
}

// Функция отображает окно просмотра информации о пользователе и пушит соответствующий query-параметр
function selectEmployee(userId: number, isDeleted?: boolean) {
  if (isDeleted !== true) {
    isShowDialog.value = true;
    router.push({query: {'select-user': userId}});
  } else {
    isShowDisableDialog.value = true;
  }
}

// Обработчик успешного выполнения какой-либо операции
function handlerSuccess() {
  try {
    store.activeSuccessOperation(900);
  } catch (err) {
    throw new Error(`components/employees/employeesListComp: handlerSuccess  => ${err}`);
  }
}

// Обработчик ошибки при выполнении какой-либо операции
function handlerError(msg: string) {
  errorMsg.value = msg;
  store.activeErrorOperation(1600);
}

// Обработчик выполнения анимации уведомления об успехе
function handlerCompleteSuccAnim() {
  if (isShowDialog.value === true) {
    isShowDialog.value = false;  // Скрываем диалог информации о пользователе
    watch(() => route.query['select-user'], () => {
      window.location.reload();
    });
  }
  if (isShowCreationDialog.value === true) {
    isShowCreationDialog.value = false;  // Скрываем диалог создания пользователя
    isResetCreationFields.value = true;  // очищаем поля в диалоге создания
    isShowSuccessDialog.value = true;
  }
  if (isShowDisableDialog.value === true) {
    isShowDisableDialog.value = false;  // Скрываем диалог деактивации пользователя
    window.location.reload();
  }
  if (isShowActivateDialog.value === true) {
    isShowActivateDialog.value = false;  // Скрываем диалог активации пользователя
    window.location.reload();
  }
}

// Обработчик открытия окна удаления/активации пользователя
function handlerStatusEmployee(employeeId: number, employeeFullName: string | null) {
  try {
    if (props.isActiveEmployees === false) {
      activatedData.value.id = employeeId;
      if (employeeFullName) {
        activatedData.value.fullName = employeeFullName;
        isShowActivateDialog.value = true;
      }
    } else {
      deletedEmployeeData.value.id = employeeId;
      if (employeeFullName) {
        deletedEmployeeData.value.fullName = employeeFullName;
        isShowDisableDialog.value = true;
      }
    }

  } catch (err) {
    throw new Error(`components/employees/employeesListComp: handlerDeleteEmployee  => ${err}`);
  }
}

// API Изменение статуса активности текущего пользователя
async function changeStatusEmployeeByIdGeneral(userId: number, isActive: boolean) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await changeStatusEmployeeById(userId, isActive);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await changeStatusEmployeeById__chief(userId, isActive);
  } catch (err) {
    throw new Error(`components/employees/employeesListComp.vue: changeStatusEmployeeByIdGeneral  => ${err}`);
  }
}

// Обработчик удаления пользователя из БД
async function confirmDeleteEmployee() {
  try {
    isLoadingDeleted.value = true;
    if (deletedEmployeeData.value.id) {
      await changeStatusEmployeeByIdGeneral(deletedEmployeeData.value.id, false);
      store.activeSuccessOperation(900);
    }
  } catch (err) {
    throw new Error(`components/employees/employeesListComp: confirmDeleteEmployee  => ${err}`);
  } finally {
    isLoadingDeleted.value = false;
  }
}

// Обработчик удаления пользователя из БД
async function confirmActivateEmployee() {
  try {
    isLoadingActivated.value = true;
    if (activatedData.value.id) {
      await changeStatusEmployeeByIdGeneral(activatedData.value.id, true);
      store.activeSuccessOperation(900);
    }
  } catch (err) {
    throw new Error(`components/employees/employeesListComp: confirmActivateEmployee  => ${err}`);
  } finally {
    isLoadingActivated.value = false;
  }
}

// Исключение меня из списка пользователей
function excludeMeOfEmployees() {
  try {
    // Исключение меня из списка пользователей
    const me = store.fetchDecryptedUser()
    employeesList.value = props.employees.filter((employee: UserClient) => {
      if(employee.id !== me.id) {
        if(authStore.isChief === true && employee.isSuperuser !== true) {
          return true;
        }
        return true;
      }
      else return false;
    });
  } catch (err) {
    throw new Error(`components/employees/employeesListComp: excludeMeOfEmployees  => ${err}`);
  }
}

// ======================================  WATCH   ======================================
// Удаляем query-параметр если диалоговое окно просмотра закрывается
watch(() => isShowDialog.value, (isShow) => {
  if (isShow === false) {
    router.push({name: 'employees'});
  }
});

// Отслеживание достижения тригеррного блока для смены страницы пагинации
watch(() => props.employees.length, async (newLength) => {
  if (newLength > 0) {
    excludeMeOfEmployees();
    await nextTick();
    // получение последнего элемента списка по его ID
    const triggerPagination = document.querySelector('.trigger-pagination') as HTMLDivElement;
    if (triggerPagination) {
      observer.observe(triggerPagination);  // запуск observer
    }
  }
});

// ======================================   LIFECYCLE HOOKS   ======================================
onMounted(() => {
  // При монтировании списка больниц проверям существует ли query-параметр select-hospital
  try {
    if (route.query['select-user']) {
      const userIdQuery = route.query['select-user']
      if (typeof userIdQuery === 'string' && +userIdQuery > 0) {
        // Если он существует то открывается окно просмотра этой больницы
        selectEmployee(+userIdQuery);
      }
    }
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsListComp.vue: onMounted  => ${err}`)
  }
});

</script>

<style scoped>

.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
}

.is-list-empty {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.is-list-empty__title {
  font-size: 2rem;
  color: var(--color-default);
}

.row-hover {
  position: relative;
  transition: background-color 0.4s ease;
  cursor: pointer;
}

.row-hover:hover {
  background-color: rgba(128, 128, 128, 0.2);
  transition: background-color 0.4s ease;
}

.employees-list__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--bg-color-white);
  overflow: hidden;
}

.employees-list__header {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  background-color: var(--bg-color-default);
  color: var(--color-white);
  border-bottom: var(--border-thin);
  z-index: 20;
}


.btn-back {
  position: absolute;
  left: 1rem;
  cursor: pointer;
}

.btn-back:hover {
  color: rgba(255, 255, 255, 0.519);
}

.employees-list__actions-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
}

.employees-list__header--title {
  font-family: var(--font);
  font-size: 1.25rem;
  font-weight: bold;
}

.employees-list__content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 93%;
  top: 0;
  max-height: 100%;
}

.is-not-items {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 90%;
  height: max-content;
  margin: auto;
  z-index: 99;
}

.is-not-items__title {
  font-size: 2rem;
  color: var(--color-default);
}

.th {
  background-color: white !important;
  backdrop-filter: blur(9px);
  font-weight: 100;
  border-top: 1px solid rgba(128, 128, 128, 0.316);
  font-size: 13px;
}

.avatar-employee {
  border-left: 1px solid rgba(128, 128, 128, 0.316);
}

.names-th {
  width: 25%;
}

.actions-th {
  width: 10%;
}

.list-item img {
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  width: 25px !important;
  height: 25px;
}

.fullname {
  font-weight: 700;
}

.employee-img {
  width: 25px !important;
}

.actions-td {
  display: flex;
  align-items: center;
}

.last-item {
  position: relative;
}

.trigger-pagination {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: rgba(0, 0, 0, 0);
}

.actions__btn.del {
  margin-left: 5px;
}

.employees-list__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 5px;
  padding-bottom: 4rem;
  z-index: 10;
}
</style>