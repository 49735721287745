<template>
  <error-note-comp>{{ errMsg }}</error-note-comp>

  <v-dialog v-model="modelValue" width="auto">

    <v-card class="dialog__wrapper">

      <!-- Крутилка загрузки -->
      <span class="progress-circular" v-if="isShowLoadingData">
        <v-progress-circular
          indeterminate
          bg-color="var(--bg-color-white)"
          color="var(--bg-color-default)"
          :size="60"
          :width="6"
        ></v-progress-circular>
      </span>

      <form class="dialog__form">
        <!-- Превью -->
        <div class="dialog__form--preview-block">
          <input id="preview-image" v-show="false"/>
          <label
            class="preview-image"
            for="preview-image"
          >
            <v-icon
              icon="mdi-bed-outline"
              color="var(--color-default)"
              size="80px"
            ></v-icon>
          </label>
          <h1 class="preview-name">{{ hospitalBedFormCopy.bedName }}</h1>
        </div>

        <!-- Общие данные -->
        <div class="dialog__form--general-data">

          <!-- Имя -->
          <v-card-subtitle class="mb-2">* Название койки</v-card-subtitle>
          <v-text-field
            class="general-data__input"
            bg-color="var(--bg-color-white)"
            v-model="bedName"
            color="var(--color-default)"
            rounded="xs"
            :rules="inputRules"
            :placeholder="'Название'"
            :density="'compact'"
            variant="outlined"
          ></v-text-field>

          <div class="patient-data-block">
            <!-- Имя -->
            <div class="patient-data-block__item">
              <v-card-subtitle class="mb-2">* Имя пациента</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model="patientFirstName"
                color="var(--color-default)"
                rounded="xs"
                :rules="inputRules"
                :placeholder="'Иван'"
                :density="'compact'"
                variant="outlined"
              ></v-text-field>
            </div>

            <!-- Фамилия -->
            <div class="patient-data-block__item">
              <v-card-subtitle class="mb-2">* Фамилия пациента</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model="patientLastName"
                color="var(--color-default)"
                rounded="xs"
                :rules="inputRules"
                :placeholder="'Иванов'"
                :density="'compact'"
                variant="outlined"
              ></v-text-field>
            </div>

            <!-- Отчество -->
            <div class="patient-data-block__item">
              <v-card-subtitle class="mb-2">Отчество пациента</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model="patientPatronymic"
                color="var(--color-default)"
                rounded="xs"
                :rules="inputRules"
                :placeholder="'Иванович'"
                :density="'compact'"
                variant="outlined"
              ></v-text-field>
            </div>
          </div>


          <!-- Список Больниц -->
          <hospitalListComp
            v-if="authStore.isSuperUser === true"
            style="width: 100% !important; margin-top: 1.5rem;"
            :label-compbox="'Больница'"
            :item-list="hospitalList"
            @load-items="loadHospitals"
            @close-menu="hospitalList.length = 0"
            @clear-hospital="clearHospital"
            @select-hospital="(hospital) => handlerSelectHospital(hospital)"
            :selected-hospital-name="selectedHospitalName"
          />

          <!-- Список Отделений -->
          <departmentListComp
            style="width: 100% !important; margin-top: 1.5rem;"
            :label-compbox="'отделение'"
            :item-list="departmentList"
            :filter-by-hospital-id="hospitalId"
            @load-items="loadDepartments"
            @close-menu="departmentList.length = 0"
            @clear-department="handlerClearDepartment"
            @select-department="(department) => handlerSelectDepartment(department)"
            :selected-department-name="selectedDepartmentName"
          />

          <!-- Список палат -->
          <hospitalRoomListComp
            style="width: 100% !important; margin-top: 1.5rem;"
            :label-compbox="'палата'"
            :item-list="hospitalRoomList"
            :filter-by-department-id="departmentId"
            @load-items="loadHospitalRoom"
            @close-menu="hospitalRoomList.length = 0"
            @clear-hospital-room="clearHospitalRoom"
            @select-hospital-room="(hospitalRoom: HospitalRoomClient | null) => handlerSelectHospitalRoom(hospitalRoom)"
            :selected-hospital-room-name="selectedHospitalRoomName"
            :is-disabled="(departmentId)? false : true"
          />
        </div>
      </form>
      <div class="dialog__actions">

        <!-- Сохранить -->
        <v-btn
          class="mr-6"
          @click="confirmToUpdateHospitalBed"
          :loading="isLoading"
          variant="flat"
          style="color: white; font-weight: normal; font-size: 12px;"
          color="var(--bg-color-default)"
        >
          Сохранить
        </v-btn>
        <!-- Сбросить изменения -->
        <v-btn
          variant="tonal"
          style="margin-right: auto; font-size: 12px;"
          color="var(--bg-color-default)"
          @click="resetChanges"
        >
          Сбросить изменения
        </v-btn>

        <!-- Закрыть -->
        <v-btn
          variant="outlined"
          color="red"
          style="font-size: 12px;"
          @click="handlerClose">
          Закрыть
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
// COMPONENTS
import hospitalListComp from '../departments/hospitalListComp.vue';
import departmentListComp from '../hospitalRooms/departmentListComp.vue';
import hospitalRoomListComp from './hospitalRoomListComp.vue';
// API
import {getHospitalBedById, putHospitalBedById} from '../../api/superuser/hospitalBedsApi'
import {getHospitalBedById__chief, putHospitalBedById__chief} from '../../api/hospitalChief/hospitalBedsApi';
// COMPOSABLES
import useLoadingHospital from '../../composable/loadHospitalList';
import useLoadingDepartments from '../../composable/loadDepartmentList';
import useLoadingHospitalRooms from '../../composable/loadHospitalRoomList';
// STORE
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';
// TYPES
import {HospitalBedClient, HospitalBedForUpdate} from '../../types/hospitalBedType';
import {DepartmentClient} from '../../types/departmentType';
import {HospitalRoomClient} from '../../types/hospitalRoomType';
import {HospitalClient} from '../../types/hospitalType';
// VUE
import {useRoute} from 'vue-router';
import {ref, defineModel, defineEmits, watch, onMounted} from 'vue';

const store = useMainStore();
const authStore = useAuthStore();
const route = useRoute();
const modelValue = defineModel<boolean>();

// ==============================================  EMITS  ==============================================
const emit = defineEmits<{
  close: [],
  success: [],
  error: [msg: string],
  loadHospitalBed: [hospitalBed: HospitalBedClient],
  updateHospitalBed: [hospitalBed: HospitalBedClient],
}>();

// ==============================================  DATA  ==============================================
const bedName = ref<string | null>(null);
const patientFirstName = ref<string | null>(null);
const patientLastName = ref<string | null>(null);
const patientPatronymic = ref<string | null>(null);
const isLoading = ref(false);
const isShowLoadingData = ref<boolean>(false)
const id = ref<number>(0);
const hospitalId = ref<number | null>(null);
const departmentId = ref<number | null>(null);
const hospitalRoomId = ref<number | null>(null);
const errMsg = ref<string>('');
const hospitalBedFormCopy = ref({
  bedName: null as string | null,
  patientFirstName: null as string | null,
  patientLastName: null as string | null,
  patientPatronymic: null as string | null,
  selectedHospitalName: null as string | null,
  selectedDepartmentName: null as string | null,
  selectedHospitalRoomName: null as string | null,
  selectedHospitalId: null as number | null,
  selectedDepartmentId: null as number | null,
  selectedHospitalRoomId: null as number | null,
});

const inputRules = ref([
  (value: string) => !!value || 'Обязательное поле',
])

// Компонуемый файл для получения списка больниц и отображения их в выпадающем меню
const {
  hospitalList,
  selectedHospital,
  selectedHospitalName,

  loadHospitals,
  clearHospital,
  selectHospital,
} = useLoadingHospital();

// Компонуемый файл для получения списка отделений и отображения их в выпадающем меню
const {
  departmentList,
  selectedDepartment,
  selectedDepartmentName,

  loadDepartments,
  clearDepartment,
  selectDepartment,
} = useLoadingDepartments();

// Компонуемый файл для получения списка палат и отображения их в выпадающем меню
const {
  hospitalRoomList,
  selectedHospitalRoom,
  selectedHospitalRoomName,
  isLoadingHospitalRoomsFetch,

  loadHospitalRoom,
  clearHospitalRoom,
  selectHospitalRoom,
} = useLoadingHospitalRooms();


// ==============================================  METHODS  ===============================================

// Функция обработчик для фиксирования ID БОЛЬНИЦЫ из селект-меню и сохранения выбранной БОЛЬНИЦЫ
function handlerSelectHospital(hospital: HospitalClient | null) {
  if (hospital) {
    hospitalId.value = hospital.id;
    departmentId.value = null;
    hospitalRoomId.value = null;
    selectedHospitalRoom.value = null;
    selectedHospitalRoomName.value = null;
    selectedDepartmentName.value = null;
    selectedDepartment.value = null;
    selectHospital(hospital);
  }
}

// Функция обработчик для фиксирования ID ОТДЕЛЕНИЯ из селект-меню и сохранения выбранного ОТДЕЛЕНИЯ
function handlerSelectDepartment(department: DepartmentClient | null) {
  if (department) {
    departmentId.value = department.id;
    hospitalRoomId.value = null;
    selectedHospitalRoom.value = null;
    selectedHospitalRoomName.value = null;
    selectDepartment(department);
  }
}

// Функция обработчик для фиксирования ID ОТДЕЛЕНИЯ из селект-меню и сохранения выбранного ОТДЕЛЕНИЯ
function handlerSelectHospitalRoom(hospitalRoom: HospitalRoomClient | null) {
  if (hospitalRoom) {
    hospitalRoomId.value = hospitalRoom.id;
    selectHospitalRoom(hospitalRoom);
  }
}

function handlerClearDepartment() {
    departmentId.value = null;
    hospitalRoomId.value = null;
    selectedHospitalRoom.value = null;
    selectedHospitalRoomName.value = null;
    clearDepartment();
}

// Получение койки по ID
async function getHospitalBedByIdGeneral(hospitalBedId: number) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getHospitalBedById(hospitalBedId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getHospitalBedById__chief(hospitalBedId);
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsDialogComp.vue: getHospitalBedByIdGeneral  => ${err}`)
  }
}

// Обновление данных койки
async function putHospitalRoomByIdGeneral(changedData?: HospitalBedForUpdate | undefined) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await putHospitalBedById(changedData);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await putHospitalBedById__chief(changedData);
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsDialogComp.vue: putHospitalRoomByIdGeneral  => ${err}`)
  }
}

// Подготавливает объект для отправки на сервер
function prepareForConfirm(entries: Array<Array<string>>) {
  try {
    const resultObjectData: object | any = {};
    let patientFirstname: string | null = null;
    let patientLastname: string | null = null;
    let patientPatronymic: string | null = null;
    for (const entry of entries) {
      if (entry[0] === 'patientFirstName') {
        patientFirstname = entry[1].split(' ').join('');
      }
      if (entry[0] === 'patientLastName') {
        patientLastname = entry[1].split(' ').join('');
      }
      if (entry[0] === 'patientPatronymic') {
        patientPatronymic = entry[1].split(' ').join('');
      }
      if (entry[0] === 'selectedHospitalName') {
        resultObjectData['hospitalId'] = hospitalId.value;
      }
      if (entry[0] === 'selectedDepartmentName') {
        resultObjectData['departmentId'] = departmentId.value;
      }
      if (entry[0] === 'selectedHospitalRoomName') {
        resultObjectData['hospitalRoomId'] = hospitalRoomId.value;
      } else {
        resultObjectData[entry[0]] = entry[1]
      }
    }
    resultObjectData.bedId = id.value;
    resultObjectData.hospitalId = hospitalId.value;
    resultObjectData.departmentId = departmentId.value;
    resultObjectData.hospitalRoomId = hospitalRoomId.value;
    resultObjectData.patientLastName = patientLastname ?? undefined;
    resultObjectData.patientFirstName = patientFirstname ?? undefined;
    resultObjectData.patientPatronymic = patientPatronymic ?? undefined;
    return resultObjectData;
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsDialogComp.vue: prepareForConfirm  => ${err}`);

  }
}

// Подтверждение данных для обновления выбранной койки
async function confirmToUpdateHospitalBed() {
  try {
    if (!bedName.value || !patientFirstName.value || !patientLastName.value) {
      errMsg.value = 'Заполните все поля';
      store.activeErrorOperation(1200);
      return
    }

    isLoading.value = true;
    // сравниваем два объекта на наличие изменения данных в полях ввода (Для исключения пустого запроса)
    const {entries, isCompare} = store.compareObjectsFetchFields(hospitalBedFormCopy.value, {
      bedName: bedName.value,
      patientFirstName: patientFirstName.value,
      patientLastName: patientLastName.value,
      patientPatronymic: patientPatronymic.value,
      selectedHospitalName: selectedHospitalName.value,
      selectedDepartmentName: selectedDepartmentName.value,
      selectedHospitalRoomName: selectedHospitalRoomName.value,
    });
    const readyChangedData = prepareForConfirm(entries);
    // Если два объекта отличаются то обрабатываем запрос к серверу на изменение данных
    if (!isCompare) {
      if (selectedHospitalRoom.value?.id && selectedHospitalRoomName.value) {
        const updatedHospitalBed = await putHospitalRoomByIdGeneral(readyChangedData);
        emit('success');
        emit('updateHospitalBed', updatedHospitalBed); // Передаем обновленное отделение в родительский компонент для обновления списка элементов
      } else {
        emit('error', 'Укажите палату');
      }
    }
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsDialogComp.vue: confirmToUpdateHospitalBed  => ${err}`);
  } finally {
    isLoading.value = false;
  }
}

// Сбросить изменения в форме 
function resetChanges() {
    try {
        // Проверяем объекты на разность значений их свойств
        const isCompare = store.compareObjects(hospitalBedFormCopy.value, { 
            bedName: bedName.value,
            patientFirstName: patientFirstName.value,
            patientLastName: patientLastName.value,
            patientPatronymic: patientPatronymic.value,
            selectedHospitalName: selectedHospitalName.value,
            selectedDepartmentName: selectedDepartmentName.value,
            selectedHospitalRoomName: selectedHospitalRoomName.value,
        });
        if(isCompare === false) {
            // Возвращаем поля формы в исзодное состояние
            bedName.value = hospitalBedFormCopy.value.bedName;
            patientFirstName.value = hospitalBedFormCopy.value.patientFirstName;
            patientLastName.value = hospitalBedFormCopy.value.patientLastName;
            patientPatronymic.value = hospitalBedFormCopy.value.patientPatronymic;
            selectedHospitalName.value = hospitalBedFormCopy.value.selectedHospitalName;
            selectedDepartmentName.value = hospitalBedFormCopy.value.selectedDepartmentName;
            selectedHospitalRoomName.value = hospitalBedFormCopy.value.selectedHospitalRoomName;
            hospitalId.value = hospitalBedFormCopy.value.selectedHospitalId;
            departmentId.value = hospitalBedFormCopy.value.selectedDepartmentId;
            hospitalRoomId.value = hospitalBedFormCopy.value.selectedHospitalRoomId;
        }
    } catch (err) {
        throw new Error(`components/hospitalBeds/hospitalBedsDialogComp.vue: resetChanges  => ${err}`);
    }
}

// Функция применяется для создания копии объекта формы
function createCopyForm() {
  try {
    // На этом этапе selectedHospitalRoomName и др. еще не существуют так как они будет поулчен асинхронно в parsingObject
    hospitalBedFormCopy.value.bedName = bedName.value;
    hospitalBedFormCopy.value.patientFirstName = patientFirstName.value;
    hospitalBedFormCopy.value.patientLastName = patientLastName.value;
    hospitalBedFormCopy.value.patientPatronymic = patientPatronymic.value;
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsDialogComp.vue: createCopyForm  => ${err}`);
  }
}

// Функция разбивает ключи получаемого объекта по реактивным переменным для отображаения их в полях ввода
async function parsingObject(hospitalBed: HospitalBedClient) {
    try {
        bedName.value = hospitalBed.name;
        patientFirstName.value = hospitalBed.patientFirstName;
        patientLastName.value = hospitalBed.patientLastName;
        patientPatronymic.value = hospitalBed.patientPatronymic;
        if(hospitalBed.hospitalRoom) {
            try {
                isLoadingHospitalRoomsFetch.value = true;
                // Получаем все родительские сущности койки
                const fetchedHospital = hospitalBed.hospitalRoom.department.hospital;
                const fetchedDepartment = hospitalBed.hospitalRoom.department;
                const fetchedHospitalRoom = hospitalBed.hospitalRoom;

                // Добавляем в селект-меню БОЛЬНИЦУ
                selectedHospitalName.value = fetchedHospital.name;
                selectedHospital.value = fetchedHospital;
                hospitalId.value = fetchedHospital.id;

                // Добавляем в селект-меню ОТДЕЛЕНИЕ
                selectedDepartmentName.value = fetchedDepartment.name;
                selectedDepartment.value = fetchedDepartment;
                departmentId.value = fetchedDepartment.id;

                // Добавляем в селект-меню ПАЛАТУ
                selectedHospitalRoomName.value = fetchedHospitalRoom.name;
                selectedHospitalRoom.value = fetchedHospitalRoom;
                hospitalRoomId.value = fetchedHospitalRoom.id;
                
                // собираем копию селект-полей 
                hospitalBedFormCopy.value.selectedHospitalName = fetchedHospital.name;
                hospitalBedFormCopy.value.selectedDepartmentName = fetchedDepartment.name;
                hospitalBedFormCopy.value.selectedHospitalRoomName = fetchedHospitalRoom.name;
                hospitalBedFormCopy.value.selectedHospitalId = fetchedHospital.id;
                hospitalBedFormCopy.value.selectedDepartmentId = fetchedDepartment.id;
                hospitalBedFormCopy.value.selectedHospitalRoomId = fetchedHospitalRoom.id;
            } catch (err) {
                throw new Error(`components/hospitalBeds/hospitalBedsDialogComp.vue: parsingObject[fetch hospital-room-data]  => ${err}`);
            } finally {
                isLoadingHospitalRoomsFetch.value = false;
            }
        }
    } catch (err) {
        throw new Error(`components/hospitalBeds/hospitalBedsDialogComp.vue: parsingObject  => ${err}`);
    }
}

// Обработчик закрытия формы
function handlerClose() {
  id.value = 0;
  selectedHospitalRoom.value = null;
  selectedHospitalRoomName.value = '';
  hospitalBedFormCopy.value = {
    bedName: null,
    patientFirstName: null,
    patientLastName: null,
    patientPatronymic: null,
    selectedHospitalName: null,
    selectedDepartmentName: null,
    selectedHospitalRoomName: null,
    selectedHospitalId: null,
    selectedHospitalRoomId: null,
    selectedDepartmentId: null,
  }
  emit('close');
}

// Получение данных выбранного объекта в списке
watch(() => route.query['select-hospital-bed'], async (hospitalBedId) => {
  try {
    isShowLoadingData.value = true;
    if (typeof hospitalBedId === 'string' && +hospitalBedId > 0) {
      id.value = +hospitalBedId;
      const hospitalBed = await getHospitalBedByIdGeneral(id.value);
      // Заполняем поля формы при открытии информации о койке
      parsingObject(hospitalBed);
      // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
      createCopyForm();
    } else {
      // Иммитируем обнуление полей ввода для формы этого компонента
      emit('close');
    }
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsDialogComp.vue: watch[fetch hospital-bed-data]  => ${err}`)

  } finally {
    isShowLoadingData.value = false;
  }
});


// ==============================================   LIFECYCLE HOOKS   ==============================================
onMounted(async () => {
  try {
    if (route.query['select-hospital-bed']) {
      const hospitalBedIdQuery = route.query['select-hospital-bed'];
      if (typeof hospitalBedIdQuery === 'string' && +hospitalBedIdQuery > 0) {
        // Загружаем данные выбранной койки если query параметр select-hospital-bed существует
        try {
          isShowLoadingData.value = true;
          id.value = +hospitalBedIdQuery;
          const hospitalBed = await getHospitalBedByIdGeneral(id.value);
          parsingObject(hospitalBed);
          // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
          createCopyForm();
        } catch (err) {
          throw new Error(`components/hospitalBeds/hospitalBedsDialogComp.vue: onMounted[fetch hospital-bed-data]  => ${err}`)
        } finally {
          isShowLoadingData.value = false;
        }
      }
    } else {
      emit('close');
    }
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsDialogComp.vue: onMounted => ${err}`);
  }
});


</script>

<style scoped>
.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
  z-index: 999;
}

.dialog__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  height: 85vh;
  max-height: 800px;
  padding-bottom: 2rem;
  background-color: var(--bg-color-block);
}

.dialog__form {
  width: 100%;
  height: max-content;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-block);
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 2rem !important;
  background-color: var(--bg-color-white);
  margin-bottom: 2rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dialog__form--preview-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: max-content;
  padding: 2rem 0;
  background-color: rgba(239, 246, 248, 1);
}

.preview-image {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.image {
  width: 100%;
  height: 100%;
}

.preview-name {
  font-size: 26px;
  font-weight: 500;
  margin-top: 1rem;
}

.dialog__form--general-data {
  width: 80%;
  padding: 2rem 2rem 0 2rem;
  background-color: var(--bg-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.general-data__input {
  margin-bottom: 1rem;
}

.general-data__hospital-room-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1.5rem;
  border: 1px solid black;
}

.general-data__hospital-room-amount p {
  color: var(--color-default);
}

</style>