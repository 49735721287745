<template>
  <!-- Диалоговое окно для подтверждения АКТИВАЦИИ пользователя -->
  <v-dialog class="activated-dialog" v-model="modelValue">
    <template v-slot:default>
      <v-card style="
                    display: flex;
                    align-items: center;
                    padding: 10px;
                ">
        <div class="activated-dialog-title">
          <div style="display: flex; flex-direction: column;">
            <span class="title__text">Вы уверены, что хотите сделать пользователя </span>
            <div>
              <span class="title__var">{{ props.employeeName }} </span>
              <span class="title__text"> активным?</span>
            </div>
          </div>
        </div>
        <!-- Блок кнопок -->
        <v-card-actions style="
                    width: 50%;
                    display: flex;
                    justify-content: space-evenly;
                    border-top: var(--border-thin);
                    margin-top: 1rem;
                    padding-top: 1rem;
                    ">
          <!-- ДА -->
          <v-btn
            color="var(--color-default)"
            text="Да"
            variant="outlined"
            density="comfortable"
            :loading="props.isLoading"
            @click="() =>emit('confirm')"></v-btn>

          <!-- НЕТ -->
          <v-btn
            color="var(--color-default)"
            text="Нет"
            variant="outlined"
            density="comfortable"
            @click="() => emit('cancel')"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import {defineProps, defineEmits, defineModel} from 'vue';

const modelValue = defineModel<boolean>();
const props = defineProps<{
  employeeName: string,
  isLoading?: boolean,
}>();

const emit = defineEmits<{
  confirm: [];
  cancel: [];
}>();
</script>

<style scoped>
.activated-dialog {
  width: 40%;
}

.activated-dialog-title {
  display: flex;
  color: var(--bg-color-default-light-2);
  font-size: 0.7rem;
  padding: 0 2rem;
  text-align: center;
  font-weight: bold;
}

.title__text {
  font-size: 1.2rem;
}

.title__var {
  color: var(--color-default);
  text-align: center;
  font-size: 1.2rem;
}

</style>