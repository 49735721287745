<template>
  <!-- Компонент для отрисовки Списка Палат -->
  <div class="hospital-rooms-list__container">

    <!-- уведомление об ошибке -->
    <error-note-comp>{{ errorMsg }}</error-note-comp>

    <!-- Уведомление об успехе -->
    <success-note-comp @complete="handlerCompleteAnimation"></success-note-comp>

    <!-- Заголовок -->
    <div class="hospital-rooms-list__header">
      <v-icon
        @click="router.go(-1)"
        class="btn-back"
        icon="mdi-arrow-left"
        size="22"
      ></v-icon>
      <h1 class="hospital-rooms-list__header--title">Палаты</h1>
    </div>

    <!-- Диалоговое окно с данными о выбранном элементе -->
    <hospitalRoomsDialogComp
      v-model="isShowDialog"
      @error="(e) => handlerError(e)"
      @success="handlerSuccess"
      @close="handlerCloseDialogInfo"
      @load-hospital-room="(hospitalRoom) => emit('loadHospitalRoom', hospitalRoom)"
      @update-hospital-room="(hospitalRoom) => emit('updateHospitalRoom', hospitalRoom)"
    />

    <!-- Диалоговое окно для создания новой палаты -->
    <creationHospitalRoomsComp
      v-model="isShowCreationDialog"
      @error="(e) => handlerError(e)"
      @success="handlerSuccess"
      @close="isShowCreationDialog = false"
      @append-hospital-room="(hospitalRoom) => emit('loadHospitalRoom', hospitalRoom)"
    />

    <!-- Диалоговое окно для подтверждения удаления элемента -->
    <disableHospitalRoomsDialogComp
      v-model="isShowDisabledDialog"
      @cancel="isShowDisabledDialog = false"
      @confirm="confirmDisabledHospitalRoom"
      :isDeleted="isDeleted"
      :is-loading="isLoadingDisabled"
      :title="`${disabledHospitalRoomData.name} `"
      :subtitle="isDeleted ? 'активной' : 'неактивной'"
    />

    <!-- Диалоговое окно для подтверждения Активации элемента -->
    <activeHospitalRoomDialogComp
      :hospital-room-name="activatedData.name"
      v-model="isShowActivateDialog"
      @confirm="confirmActiveHospitalRoom"
      @cancel="isShowActivateDialog = false"
      :is-loading="isLoadingActivated"
    />

    <!-- Панель действий -->
    <div class="hospital-rooms-list__actions-block">
      <v-btn
        style="color: var(--color-white); font-size: 0.8em; font-weight: bold;"
        color="var(--color-default)"
        rounded="large"
        size="large"
        @click="isShowCreationDialog = true"
      >
        Создать
      </v-btn>

      <!-- Фильтр панель -->
      <hospitalRoomsFilterPanelComp
        @select-status="(status: boolean) => filledFilterData(status)"
        :is-active-department="props.isActiveDepartment"
      />
    </div>

    <!-- Контент список -->
    <div class="hospital-rooms-list__content-container" v-if="!props.isLoadHospitalRooms">

      <!-- Подсказка указывает на отсутствие элементов в списке -->
      <div class="is-not-items-hint" v-if="!props.hospitalRooms.length">
        <h1>Палаты не найдены</h1>
      </div>

      <!-- Отрисовка элементов списка -->
      <v-table class="hospitals-list__wrapper" :density="'comfortable'" fixed-header>
        <thead>
        <tr>
          <th class="text-left th avatar-hospital-room">
          </th>
          <th class="text-left th">
            Название
          </th>
          <th class="text-left th">
            Организация
          </th>
          <th class="text-left th">
            Отделение
          </th>
          <th class="text-left th">
            Статус
          </th>
          <th class="text-left th actions-th">
            Действия
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          class="row-hover"
          v-for="(hospitalRoom) in props.hospitalRooms as ArrayHospitalRoomClient"
          :key="hospitalRoom.id"
          @click="() => routeToHospitalBeds(hospitalRoom?.id, hospitalRoom?.department?.hospital?.id,  hospitalRoom?.department?.id)"
        >
          <!-- № Номер -->
          <td class="list-item">
            <v-icon icon="mdi-table-large" color="var(--color-default)"></v-icon>
          </td>
          <!-- Название -->
          <td class="list-item hospital-room-name">{{ hospitalRoom?.name }}</td>
          <!-- Организация -->
          <td class="list-item">{{ hospitalRoom?.department?.hospital?.name }}</td>
          <!-- Отделение -->
          <td class="list-item">{{ hospitalRoom?.department?.name }}</td>
          <!-- статус -->
          <td class="list-item">{{ (!props.isDeleted) ? "Активен" : "Не активен" }}</td>
          <!-- ДЕЙСТВИЯ -->
          <td class="list-item actions-td">

            <!-- Редактировать -->
            <v-btn
              class="actions__btn"
              @click.stop="selectHospitalRoom(hospitalRoom?.id)"
              rounded="xs"
              icon="mdi-cog-outline"
              :color="'var(--color-gray)'"
              variant="text"
              :density="'comfortable'"
            >
            </v-btn>

            <!-- Удалить -->
            <v-btn
              class="actions__btn del"
              @click.stop="handlerStatusHospitalRoom(hospitalRoom.id, hospitalRoom.name)"
              rounded="xs"
              variant="text"
              :density="'comfortable'"
              icon="mdi-power"
              :color="isDeleted ? 'green' : 'red'"
            >
            </v-btn>
          </td>
        </tr>
        <!-- Триггерный блок для пагинации -->
        <tr class="last-item">
          <td class="trigger-pagination">
            <v-progress-circular
              v-show="isShowLoadingNewPage"
              indeterminate
              bg-color="var(--bg-color-white)"
              color="var(--bg-color-default)"
              :size="20"
              :width="2"
            ></v-progress-circular>
          </td>
        </tr>
        </tbody>
      </v-table>
    </div>

    <!-- Крутилка загрузки -->
    <span class="progress-circular" v-else>
      <v-progress-circular
        indeterminate
        bg-color="var(--bg-color-white)"
        color="var(--bg-color-default)"
        :size="60"
        :width="6"
      ></v-progress-circular>
    </span>
  </div>
</template>

<script setup lang="ts">
import hospitalRoomsDialogComp from './hospitalRoomsDialogComp.vue';
import creationHospitalRoomsComp from './creationHospitalRoomsComp.vue';
import activeHospitalRoomDialogComp from './activeHospitalRoomDialogComp.vue';
import disableHospitalRoomsDialogComp from './disableHospitalRoomsDialogComp.vue';
import {putStatusHospitalRoomById} from '../../api/superuser/hospitalRoomsApi';
import {putStatusHospitalRoomById__chief} from '../../api/hospitalChief/hospitalRoomsApi';
import hospitalRoomsFilterPanelComp from './hospitalRoomsFilterPanelComp.vue';
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';
import {HospitalRoomClient, ArrayHospitalRoomClient} from '../../types/hospitalRoomType';
import {ref, defineProps, onMounted, watch, defineEmits, nextTick} from 'vue';
import {useRoute, useRouter} from 'vue-router';

const authStore = useAuthStore();
const store = useMainStore();
const route = useRoute();
const router = useRouter();

// ===================================   PROPS   ===================================
const props = defineProps<{
  hospitalRooms: Array<any>;
  isLoadHospitalRooms: boolean;
  isDeleted: boolean;
  isActiveDepartment: boolean;
}>();

const emit = defineEmits<{
  loadHospitalRoom: [hospitalRoom: HospitalRoomClient];
  updateHospitalRoom: [hospitalRoom: HospitalRoomClient];
  disableHospitalRoom: [hospitalRoomId: number];
  activateHospitalRoom: [hospitalRoomId: number];
  hospitalRoomFilter: [isDeleted: boolean];
  paginationUp: [];
}>();

// ===================================   DATA   ===================================
const errorMsg = ref('');
const isShowDialog = ref<boolean>(false);
const isShowCreationDialog = ref<boolean>(false);
const isShowDisabledDialog = ref<boolean>(false);
const isLoadingDisabled = ref<boolean>(false);
const isLoadingActivated = ref<boolean>(false);
const isShowActivateDialog = ref<boolean>(false);
const isShowLoadingNewPage = ref<boolean>(false);
const disabledHospitalRoomData = ref({
  id: null as number | null,
  name: '',
});
const activatedData = ref({
  id: null as null | number,
  name: '',
});
// Наблюдатель для триггера подгрузки страниц пагинации списка
const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      isShowLoadingNewPage.value = store.isHospitalRoomsPaginationHasNext === true && props.hospitalRooms.length >= 30;
      // Событие paginationUp для подгрузки данных следующей страницы пагинации
      emit('paginationUp');
    }
  });
});

// ===================================   METHODS   ========================================
async function putStatusHospitalRoomByIdGeneral(hospitalRoomId: number, deleted: boolean) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await putStatusHospitalRoomById(hospitalRoomId, deleted);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await putStatusHospitalRoomById__chief(hospitalRoomId, deleted);
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsListComp: putStatusHospitalRoomByIdGeneral  => ${err}`);
  }
}

// функция для открытия окна информации палаты
function selectHospitalRoom(hospitalRoomId: number) {
  isShowDialog.value = true;
  store.toPushWithQuery({name: 'select-hospital-room', value: hospitalRoomId}, route, router);
}

// Обработчик открытия окна удаления/активации палаты
function handlerStatusHospitalRoom(hospitalRoomId: number, hospitalRoomName: string | null) {
  try {
    if (props.isDeleted) {
      activatedData.value.id = hospitalRoomId;
      if (hospitalRoomName) {
        activatedData.value.name = hospitalRoomName;
        isShowActivateDialog.value = true;
      }
    } else {
      disabledHospitalRoomData.value.id = hospitalRoomId;
      if (hospitalRoomName) {
        disabledHospitalRoomData.value.name = hospitalRoomName;
        isShowDisabledDialog.value = true;
      }
    }

  } catch (err) {
    throw new Error(`components/employees/employeesListComp: handlerDeleteEmployee  => ${err}`);
  }
}

// Заполняет объект фильтр данных
function filledFilterData(isDeleted: boolean | null) {
  try {
    if (isDeleted !== null) {
      emit('hospitalRoomFilter', isDeleted);
    }
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsListComp: filledFilterData  => ${err}`);
  }
}

// Обработчик перехода на маршрут палат выбранного отделения
function routeToHospitalBeds(hospitalRoomId: number, hospitalId: number, departmentId: number) {
  try {
    router.push({
      name: 'hospitalBeds', query: {
        'hospital': hospitalId,
        'department': departmentId,
        'hospital-room': hospitalRoomId,
      }
    });
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsListComp: routeToHospitalBeds  => ${err}`);
  }
}

// отображение уведомления об успехе какой-либо операции
function handlerSuccess() {
  store.activeSuccessOperation(1200);
}

// отображение уведомления об ошибке при выполнении какой-либо операции
function handlerError(error: string) {
  errorMsg.value = error;
  store.activeErrorOperation(3500);
}

// Обработчик выполнения анимации уведомления об успехе
function handlerCompleteAnimation() {
  if (isShowCreationDialog.value === true) {
    isShowCreationDialog.value = false;
    window.location.reload();
  }
  if (isShowDialog.value === true) isShowDialog.value = false;
  if (isShowDisabledDialog.value === true) {
    isShowDisabledDialog.value = false;
    isLoadingDisabled.value = false;
    window.location.reload();
  }
  if (isShowActivateDialog.value === true) {
    isLoadingActivated.value = false;
    isShowActivateDialog.value = false;  // Скрываем диалог активации палаты
    window.location.reload();
  }
}

// Подтверждение активации Палаты
async function confirmActiveHospitalRoom() {
  try {
    isLoadingActivated.value = true;
    if (activatedData.value.id) {
      await putStatusHospitalRoomByIdGeneral(activatedData.value.id, false);
      emit('activateHospitalRoom', activatedData.value.id);
      store.activeSuccessOperation(900);
    }
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsListComp.vue: confirmActiveHospitalRoom  => ${err}`);
  } finally {
    isLoadingDisabled.value = false;
  }
}

// Подтверждение деактивации Палаты
async function confirmDisabledHospitalRoom() {
  try {
    isLoadingDisabled.value = true;
    if (disabledHospitalRoomData.value.id) {
      await putStatusHospitalRoomByIdGeneral(disabledHospitalRoomData.value.id, true);
      emit('disableHospitalRoom', disabledHospitalRoomData.value.id);
      store.activeSuccessOperation(900);
    }
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsListComp.vue: confirmDisabledHospitalRoom  => ${err}`);
  } finally {
    isLoadingDisabled.value = false;
  }
}

// Обработчик закрытия окна информации о палате 
function handlerCloseDialogInfo() {
  try {
    store.toPopQuery('select-hospital-room', route, router);
    isShowDialog.value = false;
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsListComp.vue: handlerCloseDialogInfo  => ${err}`)
  }
}

// ========================================   WATCH   ==================================
// Отслеживание достижения тригеррного блока для смены страницы пагинации
watch(() => props.hospitalRooms.length, async (newLength) => {
  if (newLength > 0) {
    await nextTick();
    // получение последнего элемента списка
    const triggerPagination = document.querySelector('.trigger-pagination') as HTMLDivElement;
    if (triggerPagination) {
      observer.observe(triggerPagination);  // запуск observer
    }
  }
});
// Удаляем query-параметр если диалоговое окно просмотра закрывается
watch(() => isShowDialog.value, (isShow) => {
  if (!isShow) {
    handlerCloseDialogInfo();
  }
})

// ========================================   LIFECYCLE HOOKS   ========================================
onMounted(() => {
  // При монтировании списка палат проверям существует ли query-параметр select-hospital-room
  try {
    const hospitalRoomIdQuery = route.query['select-hospital-room'];
    if (hospitalRoomIdQuery) {
      if (typeof hospitalRoomIdQuery === 'string' && +hospitalRoomIdQuery > 0) {
        // Если query-параметр существует то открывается окно просмотра этой палаты
        selectHospitalRoom(+hospitalRoomIdQuery);
      }
    }
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsListComp.vue: onMounted  => ${err}`);
  }
});

</script>

<style scoped>
.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
}

.row-hover {
  transition: background-color 0.4s ease;
  cursor: pointer;
}

.row-hover:hover {
  background-color: rgba(128, 128, 128, 0.2);
  transition: background-color 0.4s ease;
}

.hospital-rooms-list__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--bg-color-white);
  overflow: hidden;
}

.is-not-items-hint {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--color-default);
  z-index: 25;
}

.hospital-rooms-list__header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  background-color: var(--bg-color-default);
  color: var(--color-white);
  border-bottom: var(--border-thin);
  z-index: 20;
}

.btn-back {
  position: absolute;
  left: 1rem;
  cursor: pointer;
}

.btn-back:hover {
  color: rgba(255, 255, 255, 0.519);
}

.hospital-rooms-list__header--title {
  font-family: var(--font);
  font-size: 1.25rem;
  font-weight: bold;
}

.hospital-rooms-list__actions-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
}

.hospital-rooms-list__content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 93%;
  top: 0;
  max-height: 100%;
}

.hospitals-list__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 5px;
  padding-bottom: 2rem;
  z-index: 10;
}

.avatar-hospital-room {
  border-left: 1px solid rgba(128, 128, 128, 0.316);
}

.th {
  backdrop-filter: blur(9px);
  font-weight: 100;
  border-top: 1px solid rgba(128, 128, 128, 0.316);
  font-size: 13px;
}

.actions-th {
  width: 10%;
}

.hospital-room-name {
  font-weight: 600;
}

.actions-td {
  display: flex;
  align-items: center;
}

.actions__btn.del {
  margin-left: 5px;
}

.last-item {
  position: relative;
}

.trigger-pagination {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: rgba(0, 0, 0, 0);
}
</style>