// Взаимодействие суперпользователя с Койками 
import { ArrayHospitalBedClient, HospitalBedClient, HospitalBedForUpdate } from '../../types/hospitalBedType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import axios from 'axios';
import useMainStore from '../../store/mainStore';

// Создание новой койки
async function createNewHospitalBedDB(
        bedName: string,
        patientFirstName: string,
        patientLastName: string,
        patientPatronymic: string | undefined,
        hospitalId: number, 
        departmentId: number, 
        hospitalRoomId: number,
        
    ): Promise<HospitalBedClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.post(hostname + 'api/cp/hospitals/departments/rooms/beds', {
            name: bedName,
            patient_first_name: patientFirstName,
            patient_last_name: patientLastName,
            patient_patronymic: patientPatronymic,
            hospital_id: hospitalId,
            department_id: departmentId,
            hospital_room_id: hospitalRoomId,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalBedsApi: createNewHospitalBedDB  => ${err}`);
    }
}

// Получение всех коек с сервера
async function getAllHospitalBedsDB(isDeleted?: boolean, page?: number, hospitalId?: number, departmentId?: number, hospitalRoomId?: number): Promise<ArrayHospitalBedClient> {
    const store = useMainStore();
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + 'api/cp/beds', {
            params: {
                deleted: isDeleted,
                page,
                hospital_id: hospitalId,
                department_id: departmentId,
                hospital_room_id: hospitalRoomId,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data } = response;
        const beds = data?.data;
        const paginator = data?.paginator;
        // Задаем в стор информацию о том доступны ли следующие страницы пагинации
        store.isHospitalBedsPaginationHasNext = paginator?.has_next;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(beds);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalBedsApi: getAllHospitalBedsDB  => ${err}`);
    }
}

// Получение Коек которые принадлежат конкретной палате (по ID палаты)
async function getHospitalBedsByHospitalRoomIdDB(
    hospitalRoomId: number, 
    hospitalId: number,
    departmentId: number,
    isDeleted?: boolean, 
    page?: number
    ): Promise<ArrayHospitalBedClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/cp/beds`, {
            params: {
                deleted: isDeleted,
                hospital_id: hospitalId,
                department_id: departmentId,
                hospital_room_id: hospitalRoomId,
                page,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalBedsApi: getHospitalBedsByHospitalRoomIdDB  => ${err}`);
    }
}

// Получение Койки по ID
async function getHospitalBedById(hospitalBedId: number): Promise<HospitalBedClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/cp/hospitals/departments/rooms/beds/${hospitalBedId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalBedsApi: getHospitalBedById  => ${err}`);
    }
}


// Изменить данные койки по ее ID
async function putHospitalBedById(changedData?: HospitalBedForUpdate): Promise<HospitalBedClient> {
    try {
        // Обновление сущности и получение её с сервера
        const response = await axios.put(hostname + `api/cp/hospitals/departments/rooms/beds/${changedData?.bedId}`, {
            name: changedData?.bedName,
            patient_first_name: changedData?.patientFirstName,
            patient_last_name: changedData?.patientLastName,
            patient_patronymic: changedData?.patientPatronymic,
            hospital_id: changedData?.hospitalId,
            department_id: changedData?.departmentId,
            hospital_room_id: changedData?.hospitalRoomId,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalBedsApi: putHospitalBedById  => ${err}`);
    }
}

// Обновление статуса активности
async function putStatusHospitalBedById(hospitalBedId: number, deleted: boolean ): Promise<HospitalBedClient> {
    try {
        // Обновление статуса сущности и получение её с сервера
        const response = await axios.put(hostname + `api/cp/hospitals/departments/rooms/beds/${hospitalBedId}/is_active`, {
            deleted,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalsApi: putStatusHospitalBedById  => ${err}`);
    }
}

export {
    createNewHospitalBedDB,
    getAllHospitalBedsDB,
    getHospitalBedsByHospitalRoomIdDB,
    getHospitalBedById,
    putHospitalBedById,
    putStatusHospitalBedById,
}