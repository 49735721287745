<template>
  <v-dialog v-model="modelValue" width="auto">
    <v-card class="dialog__wrapper">
      <form class="dialog__form">

        <!-- Блок с аватаркой -->
        <div class="dialog__form--preview-block">
          <input id="preview-image" v-show="false"/>
          <label
            class="preview-image"
            for="preview-image"
          >
            <img class="image" src="../../assets/base-icons/empty-user.svg">
          </label>
        </div>

        <!-- Общие данные -->
        <div class="dialog__form--general-data">
          <v-card-title class="data-title">Общие данные</v-card-title>
          <div class="data-body">
            <!-- Фамилия -->
            <div class="data-body__item">
              <v-card-subtitle class="mb-2">* Фамилия</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model.trim="lastname"
                @update:model-value="lastnameErrorMsg = ''"
                :placeholder="'Иванов'"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
                validateOn="blur"
                :rules="lastnameRules"
                :error-messages="lastnameErrorMsg"
              ></v-text-field>
            </div>

            <!-- Имя -->
            <div class="data-body__item">
              <v-card-subtitle class="mb-2">* Имя</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model.trim="firstname"
                @update:model-value="firstnameErrorMsg = ''"
                :placeholder="'Иван'"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
                validateOn="blur"
                :rules="firstnameRules"
                :error-messages="firstnameErrorMsg"
              ></v-text-field>
            </div>

            <!-- Отчество -->
            <div class="data-body__item">
              <v-card-subtitle class="mb-2">Отчество (При наличии)</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                :placeholder="'Иванович'"
                v-model.trim="patronymic"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
                hide-details
              ></v-text-field>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Учетные данные -->
        <div class="dialog__form--credentials-data">
          <v-card-title class="data-title">Учетные данные</v-card-title>

          <!-- Email -->
          <v-card-subtitle class="mb-2">* E-mail</v-card-subtitle>
          <v-text-field
            class="general-data__input"
            bg-color="var(--bg-color-white)"
            v-model.trim="email"
            @update:model-value="emailErrorMsg = ''"
            :placeholder="'ivanivanov@ex.com'"
            color="var(--color-default)"
            rounded="xs"
            :density="'compact'"
            variant="outlined"
            :validateOn="'blur'"
            :rules="emailRules"
            :error-messages="emailErrorMsg"
          ></v-text-field>

          <!-- Привязать сотрудника к Организации -->
          <hospitalListComp
            v-if="authStore.isSuperUser === true"
            style="width: 100% !important;"
            :label-compbox="'Укажите организацию'"
            :header-title="'Привязать сотрудника к организации'"
            @clear-hospital="clearHospital"
            @select-hospital="(hospital) => selectHospital(hospital)"
            :selected-hospital-name="selectedHospitalName"
            :is-disabled="isDisabledHospital"
          />

          <!-- Привязать сотрудника к отделению -->
          <departmentListComp
            style="width: 100% !important;"
            :label-compbox="'Отделение'"
            :header-title="'Привязать сотрудника к отделению'"
            :item-list="departmentList"
            @load-items="loadDepartments"
            @clear-department="clearDepartment"
            @select-department="(department) => handlerSelectDepartment(department)"
            :filter-by-hospital-id="selectedHospital?.id"
            :selected-department-name="selectedDepartmentName"
            :is-disabled="isDisabledDepartment"
          />

          <!-- Назначить суперпользователем -->
          <v-switch
            v-model="isSuperuser"
            @update:model-value="(isChecked: boolean | null) => handlerCheckedSuperuser(isChecked)"
            base-color="var(--bg-color-second)"
            color="var(--bg-color-second)"
            :label="isSuperuserLabel"
            inset
          ></v-switch>

        </div>
      </form>
      <div class="dialog__actions">
        <!-- Сохранить -->
        <v-btn
          class="mr-6"
          @click="confirmForm"
          variant="flat"
          color="var(--bg-color-default)"
          style="color: white;"
          :loading="isLoadingToCreate"
        >
          Сохранить
        </v-btn>
        <!-- Очистить форму -->
        <v-btn
          variant="tonal"
          style="margin-right: auto; font-size: 0.8rem;"
          color="var(--bg-color-default)"
          @click="resetFormFields"
        >
          Очистить форму
        </v-btn>

        <v-btn variant="outlined" color="red" @click="emit('close')">Закрыть</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
// COMPONENTS
import hospitalListComp from '../departments/hospitalListComp.vue';
import departmentListComp from '../hospitalRooms/departmentListComp.vue';
// API
import {createNewEmployeeDB} from '../../api/superuser/employeesApi';
import {createNewEmployeeDB__chief} from '../../api/hospitalChief/employeesApi';
// COMPOSABLES
import useLoadingHospitals from '../../composable/loadHospitalList';
import useLoadingDepartments from '../../composable/loadDepartmentList';
import useValidationUserFields from '../../composable/validationUserFields';
// TYPES
import {UserClient} from '../../types/userType';
import {DepartmentClient} from '../../types/departmentType';
import {HospitalClient} from '../../types/hospitalType';
// STORE
import useMainStore from '../../store/mainStore';
import useDataStore from '../../store/dataStore';
import useAuthStore from '../../store/authStore';
// VUE
import {ref, defineModel, defineEmits, defineProps, watch, onMounted} from 'vue';


const store = useMainStore();
const authStore = useAuthStore();
const dataStore = useDataStore();

// ==========================================  PROPS  ==========================================
const props = defineProps<{
  toResetFields: boolean,
}>();

const modelValue = defineModel<boolean>();
const emit = defineEmits<{
  close: [],
  success: [],
  appendUser: [user: UserClient],
  error: [msg: string],
  isResetFields: [],
}>();

// ==========================================   DATA   ==========================================
const firstname = ref('');
const lastname = ref('');
const patronymic = ref('');
const email = ref('');
const tel = ref('');
const isSuperuser = ref<boolean>(false);
const isSuperuserLabel = ref<string>('Назначить суперпользователем');
const password = ref('');
const isLoadingToCreate = ref<boolean>(false);
const isDisabledHospital = ref<boolean>(false);
const isDisabledDepartment = ref<boolean>(false);
const hospitalIdChief = ref<number | null>(null);


// Компонуемый файл для получения списка больниц и отображения их в выпадающем меню
const {
  selectedHospital,
  selectedHospitalName,

  clearHospital,
  selectHospital,
} = useLoadingHospitals();

// Компонуемый файл для получения списка отделений и отображения их в выпадающем меню
const {
  departmentList,
  selectedDepartment,
  selectedDepartmentName,

  loadDepartments,
  clearDepartment,
  selectDepartment,
} = useLoadingDepartments();

// Компонуемый файл для валидации полей при создании пользователя или обновления его данных 
const {
  isValidEmail,
  isValidPassword,

  firstnameErrorMsg,
  lastnameErrorMsg,
  emailErrorMsg,
  passwordErrorMsg,
  telErrorMsg,

  firstnameRules,
  lastnameRules,
  emailRules,
} = useValidationUserFields();

// Функция проверяет были ли отдельные поля ФИО разделены на несколько слов через пробел - что недопустимо
function fixFullName() {
  try {
    interface ResultFixFullname {
      fullName?: string | undefined;
      firstName: string;
      lastName: string;
      patronymic?: string | undefined;
    }

    const firstNameWords = firstname.value.split(' ');
    const lastNameWords = lastname.value.split(' ');
    const patronymicWords = patronymic.value.split(' ');

    let readyFullName: undefined | string = undefined;
    let readyFirstName = '';
    let readyLastName = '';
    let readyPatronymic: undefined | string = undefined;

    // Проверка длины слов в каждом слове. Цель - чтобы ни один массив не имел больше одного слова
    if (firstNameWords.length >= 1) {
      readyFirstName = firstNameWords.join('');  // Объединяем всё в одну строку
    }
    if (lastNameWords.length >= 1) {
      readyLastName = lastNameWords.join('');  // Объединяем всё в одну строку
    }
    if (patronymicWords.length >= 1) {
      readyPatronymic = patronymicWords.join('');  // Объединяем всё в одну строку
    }
    // Если фамилия пустая строка
    if (readyPatronymic === '') {
      readyPatronymic = undefined;
      // Собираем fullName с частей ФИО Исключая Фамилию если ее нет
      readyFullName = `${readyLastName} ${readyFirstName}`;
      readyFullName = readyFullName.trim(); // Обрезаем пробелы по краям строки
    } else {
      readyFullName = `${readyLastName} ${readyFirstName} ${readyPatronymic}`;
      readyFullName = readyFullName.trim(); // Обрезаем пробелы по краям строки
    }


    return {
      firstName: readyFirstName,
      lastName: readyLastName,
      patronymic: readyPatronymic,
      fullName: readyFullName,
    } as ResultFixFullname;

  } catch (err) {
    throw new Error(`components/employees/creationEmployeeComp: fixFullName  => ${err}`);
  }
}

function handlerCheckedSuperuser(isChecked: boolean | null) {
  try {
    if (isChecked === true) {
      isDisabledHospital.value = true;
    } else {
      isDisabledHospital.value = false;
    }
  } catch (err) {
    throw new Error(`components/employees/creationEmployeeComp: handlerCheckedSuperuser  => ${err}`);
  }
}

// API создание нового пользователя
async function createNewEmployeeGeneral(
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  isSuperuser: boolean,
  hospitalId?: number | null | undefined,
  departmentId?: number | null | undefined,
  fullName?: string | undefined,
  patronymic?: string | undefined
) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await createNewEmployeeDB(firstName, lastName, email, password, isSuperuser, hospitalId, departmentId, fullName, patronymic);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await createNewEmployeeDB__chief(firstName, lastName, email, password, isSuperuser, hospitalIdChief.value, departmentId, fullName, patronymic);
  } catch (err) {
    throw new Error(`components/employees/creationEmployeeComp: createNewEmployeeGeneral  => ${err}`);

  }
}

// Подтверждение формы и отправка её на сервер
async function confirmForm() {
  try {
    isLoadingToCreate.value = true;
    // Поля ввода прошли проверки то выполняется отправка формы
    if (
      isValidEmail.value === true &&
      firstname.value.length > 0 &&
      lastname.value.length > 0
    ) {

      // Проверка на то чтобы имя и фамилия не были числами
      // Получение обработанных полей ФИО
      const {firstName, fullName, lastName, patronymic} = fixFullName();

      // Создание нового пользователя (Доступно для Суперпользователя)
      const creationUser = await createNewEmployeeGeneral(
        firstName,
        lastName,
        email.value,
        password.value,
        isSuperuser.value,
        selectedHospital.value?.id,
        selectedDepartment.value?.id,
        fullName,
        patronymic,
      );
      // Уведомление об успехе
      emit('appendUser', creationUser);
      emit('success');
    } else {
      // Проверки для определения типа ошибки
      if (
        firstname.value.length <= 0 ||
        lastname.value.length <= 0 ||
        email.value.length <= 0 ||
        password.value.length <= 0
      ) {
        // Если хотябы одно обязательное поле не заполнено
        emit('error', 'Заполните обязательные поля');
      }
      // Уведомление об ошибке
      store.activeErrorOperation(2500);
      // Отображаем подпись полей об ошибках если они пустые
      if (firstname.value.length <= 0) firstnameErrorMsg.value = 'Имя обязательное поле';
      if (lastname.value.length <= 0) lastnameErrorMsg.value = 'Фамилия обязательное поле';
      if (email.value.length <= 0) emailErrorMsg.value = "E-mail обязательное поле";
      if (password.value.length <= 0) passwordErrorMsg.value = "Пароль обязательное поле";
    }
  } catch (err) {
    emit('error', 'Проверьте правильность введенных данных');
    store.activeErrorOperation(2500);
    throw new Error(`components/employees/creationEmployeeComp: confirmForm  => ${err}`);
  } finally {
    isLoadingToCreate.value = false;
  }
}

// Функция сбрасывает поля формы
function resetFormFields() {
  try {
    // Обнуление полей после успешной записи пользователя
    firstname.value = '';
    lastname.value = '';
    patronymic.value = '';
    email.value = '';
    tel.value = '';
    isSuperuser.value = false;
    password.value = '';
    isValidEmail.value = false;
    isValidPassword.value = false;
    firstnameErrorMsg.value = '';
    lastnameErrorMsg.value = '';
    emailErrorMsg.value = '';
    passwordErrorMsg.value = '';
    telErrorMsg.value = '';
    selectedDepartment.value = null;
    selectedDepartmentName.value = null;
    selectedHospital.value = null;
    selectedHospitalName.value = null;
    isDisabledHospital.value = false;
    isDisabledDepartment.value = false;
  } catch (err) {
    throw new Error(`components/employees/creationEmployeeComp: confirmForm  => ${err}`);
  }
}

// Обработчик выбора отделения
function handlerSelectDepartment(department: DepartmentClient | null) {
  try {
    if (selectedHospital.value === null) {
      dataStore.hospitals.forEach((hospital: HospitalClient) => {
        if (hospital.id === department?.hospital.id) {
          selectedHospitalName.value = hospital.name;
          selectHospital(hospital);
        }
      });
    }
    selectDepartment(department);
  } catch (err) {
    throw new Error(`components/employees/creationEmployeeComp: handlerSelectHospital  => ${err}`);
  }
}

// ============================   WATCH   ========================
watch(() => props.toResetFields, (isResetFielsd) => {
  if (isResetFielsd === true) {
    // Обнуление полей после успешной записи пользователя
    resetFormFields();
    emit('isResetFields');
  }
});

// ========================================== LIFECYCLE HOOKS ==========================================
onMounted(() => {
  const decryptedUser = store.fetchDecryptedUser();
  hospitalIdChief.value = decryptedUser.hospitalId;
});

</script>

<style scoped>
.dialog__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  background-color: var(--bg-color-block);
}

.dialog__form {
  width: 100%;
  height: max-content;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-block);
}

.dialog__form--preview-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  padding: 2rem 0;
}

.preview-image {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
}

.preview-username {
  font-size: 26px;
  font-weight: 700;
}

.data-body {
  display: flex;
  gap: 8px;
}

.data-body__item {
  flex: 1;
  width: 33%;
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 0 2rem 2rem 2rem;
  margin-bottom: 3rem;
  background-color: var(--bg-color-white);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dialog__form--top {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 1rem 2rem 1rem;
  color: var(--color-default);
  background-color: var(--bg-color-op-blue);
  backdrop-filter: blur(6px);
  z-index: 90;
}

.top-title {
  font-size: 1.4rem;
}

.data-title {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 17px;
  text-align: left;
  width: max-content;
  padding: 0.5rem 0 !important;
}

.dialog__form--general-data {
  width: 80%;
  padding: 1rem 2rem 0 2rem;
  background-color: var(--bg-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.general-data__input {
  margin-bottom: 1rem;
}

.dialog__form--credentials-data {
  width: 80%;
  padding: 0 2rem;
  /* border-top: 2px solid rgba(128, 128, 128, 0.316); */
  background-color: var(--bg-color-white);
}
</style>