<template>
  <div class="group-hospital__wrapper">
    <v-card-subtitle class="mb-2">
      {{ (props.headerTitle) ? props.headerTitle : 'Организация, к которой принадлежит отделение' }}
    </v-card-subtitle>
    <v-combobox
    class="group-items"
    :placeholder="props.labelCompbox"
    :items="listNames"
    :loading="props.isLoading"
    @update:model-value="(name: string) => selectHospital(name)"
    @click:clear="handlerClear"
    rounded="lg"
    v-model="hospitalName"
    :error="props.required?.isTriggerErr"
    :error-messages="(props.required?.isTriggerErr === true)? props.required?.errorMessage : ''"
    variant="outlined"
    bg-color="var(--bg-color-white)"
    color="var(--color-default)"
    base-color="var(--color-default)"
    :density="'compact'"
    :readonly="isDisabled"
    >
      <!-- Компонент для отображения загрузки -->
      <template v-if="true" v-slot:no-data>
        <v-list-item class="d-flex justify-center" :density="'compact'">
          <!-- Крутилка загрузки -->
          <v-progress-circular
            indeterminate
            bg-color="var(--bg-color-white)"
            color="var(--bg-color-default)"
            :size="20"
            :width="2"
          ></v-progress-circular>
        </v-list-item>
      </template>

      <template v-slot:selection>
        <p class="group-items__selection-value">{{ computedHospitalName }}</p>
      </template>

      <!-- Элемент группы -->
      <template v-slot:item="{item, props}">
        <div v-bind="props" class="group-items__item">
          <p class="item__value">{{ item.value }}</p>
          <!-- <v-btn
            class="group-items__btn"
            style="font-size: 12px;"
            color="var(--bg-color-default)"
            variant="outlined"
            :density="'compact'"
            @click="selectHospital(item.value)"
          >Выбрать
          </v-btn> -->
        </div>
      </template>

    </v-combobox>
  </div>
</template>

<script setup lang="ts">
import {HospitalClient} from '../../types/hospitalType';
import {getAllHospitalDB} from '../../api/superuser/hospitalsApi';
import {ref, watch, computed, defineProps, defineEmits, defineModel, onMounted} from 'vue';
import useDataStore from '../../store/dataStore';
import useAuthStore from '../../store/authStore';

const dataStore = useDataStore();
const authStore = useAuthStore();

// =======================================  V-MODEL  =======================================
defineModel<string>('checked');

// =======================================  PROPS  =======================================
const props = defineProps<{
  labelCompbox?: string;
  selectedHospitalName: string | null;
  isLoading?: boolean;
  headerTitle?: string;
  required?: {
    isRequired: boolean;
    errorMessage: string;
    isTriggerErr: boolean;
  };
  isDisabled?: boolean;
}>();

// =======================================  EMITS  =======================================
const emit = defineEmits<{
  loadItems: [],
  closeMenu: [],
  selectHospital: [HospitalClient | null],
  clearHospital: [],
}>();

// =======================================  DATA  =======================================
const hospitalName = ref<string | null>(null);
const selectedHospitalData = ref<HospitalClient | null>(null);
const listNames = ref<Array<string | any>>([]);

// =======================================  METHODS  =======================================
// Обработчик отчистки селект-поля
function handlerClear() {
  emit('clearHospital');
}

// Функция для отправки выбранной больницы в диалоговое окно редактирования пользователя 
async function selectHospital(hospitalNameValue: string) {
    hospitalName.value = hospitalNameValue;
    const fetchedHospitals = await getAllHospitalDB(false, undefined);
    fetchedHospitals.forEach((hospital: HospitalClient) => {
        if (hospitalNameValue === hospital.name) {
            emit('selectHospital', hospital);
        }
    });
}

// =======================================  COMPUTED  =======================================
// Вычисляем название больницы. Если оно существует и было передано как реквизит с род.компонента или оно выбирается в селект-меню
const computedHospitalName = computed(() => {
    if (props.selectedHospitalName && props.selectedHospitalName.length > 0) {
        return props.selectedHospitalName;
    } else {
        return selectedHospitalData.value?.name;
    }
});
// =======================================  WATCH  =======================================
// Отслеживание имени управляющего больницы если он существует
watch(() => props.selectedHospitalName, (newHospitalName: string | null) => {
    if (newHospitalName && newHospitalName.length) {
        hospitalName.value = newHospitalName;
    } else {
        hospitalName.value = null;
    }
})

// =======================================  LIFECYCLE HOOKS  =======================================
onMounted(async () => {
    hospitalName.value = props.selectedHospitalName;
    if (dataStore.hospitals.length) {
        for (const hospital of dataStore.hospitals) {
            if (hospital.deleted === null) {
                listNames.value.push(hospital.name);
            }
        }
    } else {
        if (authStore.isSuperUser === true) {
            const fetchedHospitals = await getAllHospitalDB(false, undefined);
            for (const hospital of fetchedHospitals) {
                if (hospital.deleted === null) {
                    listNames.value.push(hospital.name);
                }
            }
        }
    }
});

</script>

<style scoped>
.group-hospital__wrapper {
  width: 15% !important;
}

.group-items {
  width: 100% !important;
}

.group-items__selection-value {
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

.group-items__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: .5rem .5rem .5rem 1rem;
  cursor: pointer;
}
.group-items__item:hover {
  background-color: var(--bg-color-gray);
}
.group-items__item + .group-items__item {
  border-top: var(--border-thin-light);
}

.item__value {
  width: 65% !important;
  padding: 0.1rem 0.5rem;
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

</style>