<template>
  <div class="employees-filter-panel my-2 d-flex align-center">
    <!-- Ввод имени сотрудника -->
    <v-combobox
      class="filter-input"
      v-model="searchField"
      :items="(isSearchEmail === false)? listNames : listEmails"
      clearable
      label="Поиск..."
      prepend-inner-icon="mdi-magnify"
      variant="outlined"
      @update:model-value="(value: string | null) => selectSearchValue(value)"
      :loading="isLoadingFilteringSearch"
      :hide-details="true"
    ></v-combobox>

    <!-- Выбор сотрудников которые на смене -->
    <v-select
      class="filter-input"
      v-model="selectedShifter"
      :items="listShifter"
      variant="outlined"
      @update:model-value="(status: string) => selectIsShifter(status)"
      :hide-details="true"
    ></v-select>

    <v-switch
      v-model="selectedStatus"
      class="ml-6"
      color="var(--bg-color-default)"
      label="Неактивные"
      @update:model-value="(status) => selectStatus(status)"
      :value="true"
      hide-details
    >
    </v-switch>
  </div>
</template>

<script setup lang="ts">
import {ref, defineProps, defineEmits, watch} from 'vue';
import useMainStore from '../../store/mainStore';

const store = useMainStore();

// ==========================   PROPS   ==================================
const props = defineProps<{
  listNames: Array<string | undefined>;
  listEmails: Array<string | undefined>;
}>();

// ==========================   EMIT   ==================================
const emit = defineEmits<{
  selectSearchValue: [value: string | null];
  selectShifter: [status: boolean];
  selectStatus: [status: boolean];
}>();

// ==========================   DATA   ==================================
const isSearchEmail = ref<boolean>(false);
const isLoadingFilteringSearch = ref<boolean>(false);
const searchField = ref<string | null>(null);
const selectedStatus = ref<string>("Активен")
const selectedShifter = ref<string>("Все")
const listNames = ref<Array<string | undefined>>([]);
const listEmails = ref<Array<string | undefined>>([]);
const listShifter = ref<Array<string>>(['Все', 'На смене']);
const payload = store.LazyLoading(500);

// ==========================   METHODS   ==================================
// Функция для записи введенного значения в поле поиска. Поиск может проходить либо по E-mail либо по ФИО пользователя
function selectSearchValue(value: string | null) {
  try {
    isLoadingFilteringSearch.value = true;
    payload(value, (entries) => {
      let currentMessage: string | null = entries[entries.length - 1];
      if (currentMessage === null) currentMessage = '';
      isLoadingFilteringSearch.value = false;
      emit('selectSearchValue', currentMessage);
    });
  } catch (err) {
    throw new Error(`components/employees/employeesFilterPanelComp: selectSearchValue  => ${err}`);
  }
}

// Функция для выбора Статуса активности пользователя в списке
function selectStatus(status) {
  try {
    emit('selectStatus', !status);
  } catch (err) {
    throw new Error(`components/employees/employeesFilterPanelComp: selectStatus  => ${err}`);
  }
}

// Функция для выбора Сотрудников которые на смене, либо выбор всех сотрудников
function selectIsShifter(status: string) {
  try {
    if (status === listShifter.value[1]) {
      emit('selectShifter', true);
    } else if (status === listShifter.value[0]) {
      emit('selectShifter', false);
    }
  } catch (err) {
    throw new Error(`components/employees/employeesFilterPanelComp: selectIsShifter  => ${err}`);
  }
}

// ==========================   WATCH   ==================================
// Проверяем вводим ли мы строку похожую на email. Нужно для отрисовки списка Имен или Эл. Почт пользователей
watch(() => searchField.value, (newValue) => {
  if (newValue?.includes('@')) {
    isSearchEmail.value = true;
  } else {
    isSearchEmail.value = false;
  }
})
// Отслеживание изменения приходящего массива с ФИО пользователей для их отображения в списке
watch(() => props.listNames.length, (newLength) => {
  if (newLength > 0) {
    listNames.value = props.listNames;
  }
});

// Отслеживание изменения приходящего массива с E-mail для их отображения в списке
watch(() => props.listEmails.length, (newLength) => {
  if (newLength > 0) {
    listEmails.value = props.listEmails;
  }
});

</script>

<style>
.employees-filter-panel {
  min-width: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 1.5rem;
  z-index: 888;
}

.filter-input {
  width: 20%;

  .v-input__control {
    .v-field {
      .v-field__field {
        height: 44px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.filter-input + .filter-input {
  margin-left: 0.4rem;
}
</style>