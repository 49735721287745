import { ArrayHospitalRoomClient, HospitalRoomClient } from '../types/hospitalRoomType';
import { ref } from 'vue';


// Компонуемый файл для получения списка палат и отображения их в выпадающем меню
function useLoadingHospitalRooms() {

    // =================  DATA  ===================

    // Списки доступных элементов для селект-меню
    const hospitalRoomList = ref<ArrayHospitalRoomClient>([]);
    const selectedHospitalRoom = ref<HospitalRoomClient | null>(null);
    const selectedHospitalRoomName = ref<string | null>(null);
    const isLoadingHospitalRoomsFetch = ref<boolean>(true);


    // =================  METHODS  ===================

    // Функция получает массив палат с БД для отображения в селект-меню
    async function loadHospitalRoom() {
        try {
            hospitalRoomList.value = []
        } catch (err) {
            throw new Error(`compsables/loadHospitalRoomList.ts: loadHospitalRoom => ${err}`);
        }
    }

    // Функция выполняется когда в селект-меню убираем палату (она очищается из выбранных)
    function clearHospitalRoom() {
        try {
            selectedHospitalRoom.value = null;
            selectedHospitalRoomName.value = null;
        } catch (err) {
            throw new Error(`compsables/loadHospitalRoomList.ts: clearHospitalRoom  => ${err}`)
        }
    }

    // Выбор палаты в селект-меню
    function selectHospitalRoom(hospitalRoom: HospitalRoomClient | null) {
        try {
            selectedHospitalRoom.value = hospitalRoom;
            if (hospitalRoom?.name) {
                selectedHospitalRoomName.value = hospitalRoom?.name;
            }
        } catch (err) {
            throw new Error(`compsables/loadHospitalRoomList.ts: selectHospitalRoom => ${err}`);

        }
    }

    return {
        hospitalRoomList,
        selectedHospitalRoom,
        selectedHospitalRoomName,
        isLoadingHospitalRoomsFetch,

        loadHospitalRoom,
        clearHospitalRoom,
        selectHospitalRoom,
    }
}

export default useLoadingHospitalRooms;