<template>
    <v-dialog 
    class="success-dialog" 
    :model-value="props.isShow"
    @update:model-value="emit('close')"
    >
        <v-card class="success-dialog__form">
            <div class="form__header">
                <p class="header__title">
                    Для этого пользователя будет сгенерирован новый пароль и отправлен на электронную почту,
                    которую вы указали при создании
                </p>
            </div>
            <div class="form__actions">
                <v-btn
                style="
                color: var(--color-white); 
                font-size: 0.7em; 
                font-weight: bold;
                "
                density="compact"
                color="var(--color-default)"
                rounded="large"
                size="large"
                @click="handlerConfirm"
                >
                    Ok
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits<{
    close: [];
}>();

const props = defineProps<{
    isShow: boolean;
}>();

function handlerConfirm() {
    emit('close');
}

</script>

<style scoped>
.success-dialog {
    z-index: 99999999999 !important;
}

.success-dialog__form {
    width: 30%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.form__header {
    width: 100%;
    padding: 1.5rem 2rem 1rem 2rem;

}
.header__title {
    color: var(--color-default);
    font-size: 1.1rem;
    border-bottom: var(--border-thin-light);
    padding-bottom: 1rem;
    text-align: center;
}
.form__actions {
    display: flex;
    justify-content: flex-start;
    padding: 0rem 2rem 1.5rem 2rem;
}
</style>