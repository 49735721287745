// Взаимодействие Управляющего больницей с Койками 
import { ArrayHospitalBedClient, HospitalBedClient } from '../../types/hospitalBedType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import axios from 'axios';
import useMainStore from '../../store/mainStore';

// Создание новой койки
async function createNewHospitalBedDB__chief(
        bedName: string,
        patientFirstName: string,
        patientLastName: string,
        patientPatronymic: string | undefined,
        hospitalId: number, 
        departmentId: number, 
        hospitalRoomId: number,
        
    ): Promise<HospitalBedClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.post(hostname + 'api/hospitals/departments/rooms/beds', {
            name: bedName,
            patient_first_name: patientFirstName,
            patient_last_name: patientLastName,
            patient_patronymic: patientPatronymic,
            hospital_id: hospitalId,
            department_id: departmentId,
            hospital_room_id: hospitalRoomId,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/hospitalBedsApi: createNewHospitalBedDB__chief  => ${err}`);
    }
}

// Получение всех коек больницы с сервера
async function getAllHospitalBedsDB__chief(isDeleted?: boolean, page?: number): Promise<ArrayHospitalBedClient> {
    const store = useMainStore();
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + 'api/hospitals/beds', {
            params: {
                deleted: isDeleted,
                page,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data } = response;
        const beds = data?.data;
        const paginator = data?.paginator;
        // Задаем в стор информацию о том доступны ли следующие страницы пагинации
        store.isHospitalBedsPaginationHasNext = paginator?.has_next;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(beds);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/hospitalBedsApi: getAllHospitalBedsDB__chief  => ${err}`);
    }
}

// Получение Коек которые принадлежат конкретной палате (по ID палаты)
async function getHospitalBedsByHospitalRoomIdDB__chief(
    hospitalRoomId: number, 
    isDeleted?: boolean, 
    page?: number
    ): Promise<ArrayHospitalBedClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/hospitals/departments/rooms/${hospitalRoomId}/beds`, {
            params: {
                deleted: isDeleted,
                page,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/hospitalBedsApi: getHospitalBedsByHospitalRoomIdDB__chief  => ${err}`);
    }
}

// Получение Койки по ID
async function getHospitalBedById__chief(hospitalBedId: number): Promise<HospitalBedClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/hospitals/departments/rooms/beds/${hospitalBedId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/hospitalBedsApi: getHospitalBedById__chief  => ${err}`);
    }
}


type HospitalBedForUpdate = {
    bedId?: number, 
    bedName?: string,
    patientFirstName?: string, 
    patientLastName?: string, 
    patientPatronymic?: string, 
    hospitalId?: number, 
    departmentId?: number, 
    hospitalRoomId?: number
}
// Изменить данные койки по ее ID
async function putHospitalBedById__chief(changedData?: HospitalBedForUpdate): Promise<HospitalBedClient> {
    try {
        // Обновление сущности и получение её с сервера
        const response = await axios.put(hostname + `api/hospitals/departments/rooms/beds/${changedData?.bedId}`, {
            name: changedData?.bedName,
            patient_first_name: changedData?.patientFirstName,
            patient_last_name: changedData?.patientLastName,
            patient_patronymic: changedData?.patientPatronymic,
            hospital_id: changedData?.hospitalId,
            department_id: changedData?.departmentId,
            hospital_room_id: changedData?.hospitalRoomId,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/hospitalBedsApi: putHospitalBedById__chief  => ${err}`);
    }
}

// Обновление статуса активности
async function putStatusHospitalBedById__chief(hospitalBedId: number, deleted: boolean ): Promise<HospitalBedClient> {
    try {
        // Обновление статуса сущности и получение её с сервера
        const response = await axios.put(hostname + `api/hospitals/departments/rooms/beds/${hospitalBedId}/is_active`, {
            deleted,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/hospitalBedsApi: putStatusHospitalBedById__chief  => ${err}`);
    }
}


export {
    createNewHospitalBedDB__chief,
    getAllHospitalBedsDB__chief,
    getHospitalBedsByHospitalRoomIdDB__chief,
    getHospitalBedById__chief,
    putHospitalBedById__chief,
    putStatusHospitalBedById__chief,
}