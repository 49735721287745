<template>
    <div class="my-hospital-main">

        <success-note-comp></success-note-comp>

        <!-- Превью блок -->
        <div class="my-hospital-main__preview-block">
            <input id="preview-image" v-show="false"/>
            <label 
            class="preview-image" 
            for="preview-image"
            >
                <v-icon 
                icon="mdi-hospital-building" 
                color="var(--bg-color-second)"
                size="80px"
                ></v-icon>
            </label>

            <!-- Крутилка загрузки -->
            <span v-show="isLoadData === true">
                <v-progress-circular
                indeterminate 
                bg-color="var(--bg-color-white)" 
                color="var(--bg-color-default)"
                :size="20" 
                :width="2"
                ></v-progress-circular>
            </span>
            <h1 v-show="isLoadData === false" class="preview-name">
                <span >{{ hospitalNamePreview }}</span>
            </h1>
            <span v-show="isLoadData === false" class="preview-id">ID: {{ hospitalData.hospitalId }}</span>
        </div>

        <!-- Контент часть -->
        <div class="my-hospital-main__content-block">
            <!-- Общие данные -->
            <div class="content-block__general-data">
                <h2 class="content-block__subtitle">Общая информация</h2>
                
                <!-- Название больницы -->
                <v-card-subtitle class="mt-6">Название</v-card-subtitle>
                <v-text-field
                class="general-data__input "
                bg-color="var(--bg-color-white)"
                v-model="hospitalData.HospitalName"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
                hide-details
                ></v-text-field>

                <!-- Адрес -->
                <v-card-subtitle class="mt-8">Адрес</v-card-subtitle>
                <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model="hospitalData.HospitalAddress"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
                hide-details
                ></v-text-field>

            </div>

            <!-- Блок кнопок -->
            <div class="content-block__actions">
                <!-- Сохранить -->
                <v-btn 
                class="mr-6"
                :loading="isLoadingUpdate"
                @click="confirmToUpdateHospital"
                variant="flat" 
                style="color: white; font-weight: normal; font-size: 12px;"
                color="var(--bg-color-default)" 
                >
                    Сохранить
                </v-btn>
                <!-- Сбросить изменения -->
                <v-btn 
                variant="tonal"
                style="margin-right: auto; font-size: 12px;"
                color="var(--bg-color-default)" 
                @click="resetChanges" 
                >
                    Сбросить изменения
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { getInfoMyHospital_chief } from '../../api/hospitalChief/hospitalsApi';
import { HospitalClient, HospitalClientCreationServer } from '../../types/hospitalType';
import { putHospitalById_chief } from '../../api/hospitalChief/hospitalsApi';
import useMainStore from '../../store/mainStore';

const store =  useMainStore();

// ============================  DATA  ================================
const isLoadingUpdate = ref<boolean>(false);
const isLoadData = ref<boolean>(false);
const hospitalNamePreview = ref<string | null>(null)
const hospitalData = ref({
    hospitalId: null as number | null,
    HospitalName: null as string | null,
    HospitalAddress: null as string | null,
});
const hospitalFormCopy = ref({
    name: null as null | string,
    address: null as null | string,
});

// ============================  METHODS  ================================
// функция собирает ключи объекта с полученного массива entries функции compareObjectsFetchFields 
// И подготавливает объект к отправке на сервер. Собирает только те поля которые фактически были изменены
function bundleDataForSend(entries: Array<any>) {
    try {
        const changedFields: object | any = {};
        // В цикле собираем объект тех ключей, чьи значения действительно были изменены, 
        // чтобы отправить этот объект на сервер для изменения пользователя
        entries.forEach((entry) => {
            changedFields[entry[0]] = entry[1];
        });
        return changedFields;
    } catch (err) {
        throw new Error(`components/myHospital/myHospitalMainComp: bundleDataForSend  => ${err}`);
    }
}

// Функция для подтверждения обновления данных больницы
async function confirmToUpdateHospital() {
    try {
        // сравниваем два объекта на наличие изменения данных в полях ввода (Для исключения пустого запроса)
        const { entries, isCompare } = store.compareObjectsFetchFields(hospitalFormCopy.value, { 
            name: hospitalData.value.HospitalName, 
            address: hospitalData.value.HospitalAddress, 
        });
        // Если два объекта отличаются то обрабатываем запрос к серверу на изменение данных
        if(isCompare === false) {
            if(
                hospitalData.value.HospitalAddress && 
                hospitalData.value.HospitalName
            ) {
                try {
                    isLoadingUpdate.value = true;
                    const changedFields: HospitalClientCreationServer  = bundleDataForSend(entries);
                    const updatedHospital = await putHospitalById_chief(changedFields);
                    store.activeSuccessOperation(1200);
                } catch (err) {
                    throw new Error(`components/myHospital/myHospitalMainComp: confirmToUpdateHospital  => ${err}`)
                } finally {
                    isLoadingUpdate.value = false;
                }
            }
        }
    } catch (err) {
        throw new Error(`components/myHospital/myHospitalMainComp: confirmToUpdateHospital  => ${err}`)
    }
}

// Функция применяется для создания копии объекта формы
function createCopyForm() {
    try {
        hospitalFormCopy.value = {
            name: hospitalData.value.HospitalName,
            address: hospitalData.value.HospitalAddress,
        }
    } catch (err) {
        throw new Error(`components/myHospital/myHospitalMainComp: createCopyForm  => ${err}`);
    }
}

// Функция сбрасывает внесенные в форму изменения
function resetChanges() {
    try {
        hospitalData.value.HospitalAddress = hospitalFormCopy.value.address;
        hospitalData.value.HospitalName = hospitalFormCopy.value.name;
    } catch (err) {
        throw new Error(`components/myHospital/myHospitalMainComp: resetChanges  => ${err}`);

    }
}

// ============================  LIFECYCLE HOOKS  ================================
onMounted(async() => {
    try {
        isLoadData.value = true
        const infoHospital: HospitalClient = await getInfoMyHospital_chief();
        hospitalData.value.hospitalId = infoHospital.id;
        hospitalData.value.HospitalName = infoHospital.name;
        hospitalNamePreview.value = infoHospital.name;
        hospitalData.value.HospitalAddress = infoHospital.address;
        createCopyForm(); // Создается копия данных формы
    } catch (err) {
        throw new Error(`components/myHospital/myHospitalMainComp:onMounted  => ${err}`);
    } finally {
        isLoadData.value = false;
    }
});

</script>

<style scoped>
.my-hospital-main {
    position: absolute;
    right: 0;
    top: 10%;
    bottom: 0;
    left: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--bg-color-block);
    overflow: auto;
    font-family: var(--font);
}
.my-hospital-main__preview-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 25%;
    /* background-color: rgba(239, 246, 248, 1); */
    border-bottom: 2px solid rgba(128, 128, 128, 0.287);

}

.preview-image {
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
}
.image {
    width: 100%;
    height: 100%;
}
.preview-name {
    font-size: 26px;
    font-weight: 600;
    color: var(--color-dark);
}
.preview-id {
    color: gray;
}
/* CONTENT BLOCK */
.my-hospital-main__content-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: max-content;
    padding: 1rem;
}
.content-block__subtitle {
    font-size: 19px;
    margin-bottom: 1rem;
}
.content-block__general-data {
    width: 80%;
    padding: 1.5rem 1.5rem 3rem 1.5rem;
    background-color: var(--bg-color-white);
    border-right: 5px;
}
.content-block__actions {
    display: flex;
    justify-content: flex-end;
    width: 80%;
    padding: 1.5rem 1.5rem;
    background-color: var(--bg-color-white);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
</style>