<template>
  <!-- Компонент для отрисовки Списка Процедур -->
  <div class="procedure-list__container">

    <!-- Уведомление об успехе -->
    <success-note-comp @complete="handlerCompleteSuccAnim"></success-note-comp>
    <!-- Уведомление об ошибке -->
    <error-note-comp>{{ errorMsg }}</error-note-comp>

    <!-- Заголовок -->
    <div class="procedure-list__header">
      <v-icon
        @click="router.go(-1)"
        class="btn-back"
        icon="mdi-arrow-left"
        size="24"
      ></v-icon>
      <h1 class="procedure-list__header--title">Процедуры</h1>
    </div>

    <!-- Диалоговое окно просмотра информации -->
    <procedureDialogComp
      v-model="isShowDialog"
      @close="isShowDialog = false"
    />

    <!-- Панель действий -->
    <div class="procedure-list__actions-block">
      <!-- Фильтр панель -->
      <proceduresFilterPanelComp
        @select-drug-name="(dragName: string | null) => filledFilterData(dragName, null, null, null, null, null)"
        @select-patient-name="(patientName: string | null) => filledFilterData(null, patientName, null, null, null, null)"
        @select-responsible-name="(responsibleName: string | null) => filledFilterData(null, null, responsibleName, null, null, null)"
        @select-status="(isActive: boolean) => filledFilterData(null, null, null, null, null, isActive)"
        @select-date-time-from="(timestamp: number | null) => filledFilterData(null, null, null, timestamp, 0, null)"
        @select-date-time-to="(timestamp: number | null) => filledFilterData(null, null, null, 0, timestamp, null)"
        :list-patient-names="listPatientNames"
        :list-drug-names="listDrugNames"
        :list-responsible-names="listResponsibleNames"
      />
    </div>

    <!-- Контент список -->
    <div class="procedure-list__content-container" v-if="!!props.procedures.length || props.isNotProcedures">
      <!-- Баннер сообщающий что пользователей нет -->
      <div class="is-not-items" v-show="props.procedures.length <= 0">
        <h1 class="is-not-items__title">
          Процедуры не найдены
        </h1>
      </div>
      <!-- Отрисовка элементов списка -->
      <v-table class="procedure-list__wrapper" :density="'comfortable'" fixed-header>
        <thead>
        <tr class="procedure-table__header">
          <th class="text-left th avatar-procedure"></th>
          <!-- Имя пациента -->
          <th class="text-left th">ФИО пациента</th>
          <!-- Название препарата -->
          <th class="text-left th">Название препарата</th>
          <!-- Ответственный -->
          <th class="text-left th">Ответственный</th>
          <!-- Время начала процедуры -->
          <th class="text-left th">Время начала</th>
          <!-- Время завершения процедуры -->
          <th class="text-left th">Время завершения</th>
          <!-- Статус -->
          <th class="text-left th">Статус</th>
        </tr>
        </thead>
        <tbody class="tbody">
        <tr
          class="row-hover"
          @click="() => selectProcedure(procedure?.id)"
          v-for="procedure in props.procedures"
          :key="procedure.id">
          <!-- № Номер -->
          <td class="list-item">
            <v-icon icon="mdi-water-outline" color="var(--color-default)"></v-icon>
          </td>
          <!-- Имя пациента -->
          <td class="list-item fullname">{{ procedure?.patientName }}</td>
          <!-- Название препарата -->
          <td class="list-item fullname">{{ procedure?.drugName }}</td>
          <!-- Ответственный -->
          <td class="list-item">{{ procedure?.user?.fullName }}</td>
          <!-- Время начала процедуры -->
          <td class="list-item">{{ timestampFormat(procedure?.started * 1000, 'HH:mm:ss DD/MM/YYYY') }}</td>
          <!-- Время завершения процедуры -->
          <td class="list-item" :style="
                        (procedure?.ended === null)? 
                        { display: 'flex', alignItems: 'center', justifyContent: 'center' } : 
                        {}">{{ computedEndTimeProcedure()(procedure) }}
          </td>
          <!-- Статус -->
          <td class="list-item">{{ (procedure?.ended === null) ? 'В процессе' : "Завершена" }}</td>
        </tr>
        <!-- Триггерный блок для пагинации -->
        <tr class="last-item">
          <td class="trigger-pagination">
            <v-progress-circular
              v-show="isShowLoadingNewPage"
              indeterminate
              bg-color="var(--bg-color-white)"
              color="var(--bg-color-default)"
              :size="20"
              :width="2"
            ></v-progress-circular>
          </td>
        </tr>
        </tbody>
      </v-table>
    </div>

    <!-- Крутилка загрузки -->
    <span class="progress-circular" v-else-if="props.isLoadingData">
          <v-progress-circular
            v-show="isShowLoadingNewPage"
            indeterminate
            bg-color="var(--bg-color-white)"
            color="var(--bg-color-default)"
            :size="60"
            :width="6"
          ></v-progress-circular>
        </span>
    <!-- Баннер сообщающий что Процедур нет -->
    <div class="is-list-empty" v-else-if="!props.procedures.length || props.isNotProcedures === false">
      <h1 class="is-list-empty__title">
        Процедуры не найдены
      </h1>
    </div>
  </div>


</template>

<script setup lang="ts">
import procedureDialogComp from './procedureDialogComp.vue';
import proceduresFilterPanelComp from './proceduresFilterPanelComp.vue';
import useMainStore from '../../store/mainStore';
import {useRoute, useRouter} from 'vue-router';
import {ProcedureClient} from '@/types/procedureType';
import {ref, defineProps, defineEmits, watch, onMounted, computed, nextTick} from 'vue';

const store = useMainStore();
const router = useRouter();
const route = useRoute();

// ======================================   PROPS   ======================================
const props = defineProps<{
  procedures: Array<any>;
  isLoadingData: boolean;
  isNotProcedures: boolean;
}>();

// ======================================   EMITS   ======================================
const emit = defineEmits<{
  paginationUp: [];
  procedureFilter: [filterData: {
    drugName: string | undefined;
    patientName: string | undefined;
    responsibleName: string | undefined;
    dateFrom: number | undefined;
    dateTo: number | undefined;
    isActive: boolean;
  }];
}>();

// ======================================   DATA   ======================================
const isShowDialog = ref<boolean>(false);
const isShowLoadingNewPage = ref<boolean>(false);
const errorMsg = ref('');
const filterData = ref({
  drugName: undefined as string | undefined,
  patientName: undefined as string | undefined,
  responsibleName: undefined as string | undefined,
  dateFrom: undefined as number | undefined,
  dateTo: undefined as number | undefined,
  isActive: true as boolean,
});

// Наблюдатель для триггера подгрузки страниц пагинации списка
const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (store.isProceduresPaginationHasNext === true) {
        isShowLoadingNewPage.value = true;
      } else isShowLoadingNewPage.value = false;
      // Событие paginationUp для подгрузки данных следующей страницы пагинации
      emit('paginationUp');
    }
  });
});


// ======================================   COMPUTED  ======================================
// Вычисление списка имен пациентов для фильтр-панели
const listPatientNames = computed<Array<string | undefined>>(() => {
  try {
    let names = props.procedures.map((procedure: ProcedureClient) => {
      if (procedure.patientName !== null && procedure.patientName !== undefined) {
        return procedure.patientName;
      }
    });

    return [...new Set(names)];
  } catch (err) {
    throw new Error(`components/procedures/procedureListComp: computed[listDrugNames]  => ${err}`);
  }
});

// Вычисление списка названий препаратов для фильтр-панели
const listDrugNames = computed<Array<string | undefined>>(() => {
  try {
    let names = props.procedures.map((procedure: ProcedureClient) => {
      if (procedure.drugName !== null && procedure.drugName !== undefined) {
        return procedure.drugName;
      }
    });

    return [...new Set(names)];
  } catch (err) {
    throw new Error(`components/procedures/procedureListComp: computed[listDrugNames]  => ${err}`);
  }
});

// Вычисление списка ответственных для фильтр-панели
const listResponsibleNames = computed<Array<string | undefined>>(() => {
  try {
    let names = props.procedures.map((procedure: ProcedureClient) => {
      if (procedure) {

        if (procedure.user?.fullName !== null && procedure.user?.fullName !== undefined) {
          return procedure.user?.fullName;
        }
      }
    });
    return [...new Set(names)];
  } catch (err) {
    throw new Error(`components/procedures/procedureListComp: computed[listResponsibleNames]  => ${err}`);
  }
});

const computedEndTimeProcedure = (() => {
  return (procedure: any) => {
    if (procedure?.ended !== null) {
      return timestampFormat(procedure?.ended * 1000, 'HH:mm:ss DD/MM/YYYY');
    } else {
      return '-';
    }
  }
});

// ======================================   METHODS   ========================================
// Функция для форматирования timestamp в читабельную строку в определенном формате
const {timestampFormat} = store;

// Заполняет объект фильтр данных
function filledFilterData(drugName: string | null, patientName: string | null, responsibleName: string | null, dateFrom: number | null, dateTo: number | null, isActive: boolean | null) {
  try {
    if (drugName !== null) {
      if (drugName === '') {
        filterData.value.drugName = undefined;
      } else filterData.value.drugName = drugName;
    }
    if (patientName !== null) {
      if (patientName === '') {
        filterData.value.patientName = undefined;
      } else filterData.value.patientName = patientName;
    }

    if (responsibleName !== null) {
      if (responsibleName === '') {
        filterData.value.responsibleName = undefined;
      } else filterData.value.responsibleName = responsibleName;
    }

    if (dateFrom !== 0) {
      if (dateFrom === null) {
        filterData.value.dateFrom = undefined;
      } else filterData.value.dateFrom = dateFrom;
    }

    if (dateTo !== 0) {
      if (dateTo === null) {
        filterData.value.dateTo = undefined;
      } else filterData.value.dateTo = dateTo;
    }

    if (isActive !== null) {
      filterData.value.isActive = isActive;
    }
    emit('procedureFilter', filterData.value);

  } catch (err) {
    throw new Error(`components/procedures/procedureListComp: filledFilterData  => ${err}`);
  }
}

// Функция отображает окно просмотра информации о процедуре и пушит соответствующий query-параметр
function selectProcedure(procedureId: number) {
  isShowDialog.value = true;
  router.push({query: {'select-procedure': procedureId}});
}

// Обработчик выполнения анимации уведомления об успехе
function handlerCompleteSuccAnim() {
  if (isShowDialog.value === true) {
    isShowDialog.value = false;  // Скрываем диалог информации о пользователе
  }
}

// =============================================  WATCH   =============================================
// Удаляем query-параметр если диалоговое окно просмотра закрывается
watch(() => isShowDialog.value, (isShow) => {
  if (!isShow) {
    router.push({name: 'procedures'});
  }
});

// Отслеживание достижения тригеррного блока для смены страницы пагинации
watch(() => props.procedures.length, async (newLength) => {
  if (newLength > 0) {
    await nextTick();
    // получение последнего элемента списка по его ID
    const triggerPagination = document.querySelector('.trigger-pagination') as HTMLDivElement;
    if (triggerPagination) {
      observer.observe(triggerPagination);  // запуск observer
    }
  }
});

// =============================================   LIFECYCLE HOOKS   =============================================
onMounted(() => {
  // При монтировании списка больниц проверям существует ли query-параметр select-hospital
  try {
    if (route.query['select-procedure']) {
      const procedureIdQuery = route.query['select-procedure']
      if (typeof procedureIdQuery === 'string' && +procedureIdQuery > 0) {
        // Если он существует то открывается окно просмотра этой больницы
        selectProcedure(+procedureIdQuery);
      }
    }
  } catch (err) {
    throw new Error(`components/procedures/procedureListComp: onMounted  => ${err}`);
  }
});

</script>

<style scoped>

.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
}

.is-list-empty {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.is-list-empty__title {
  font-size: 2rem;
  color: var(--color-default);
}

.row-hover {
  position: relative;
  transition: background-color 0.4s ease;
  cursor: pointer;
}

.row-hover:hover {
  background-color: rgba(128, 128, 128, 0.2);
  transition: background-color 0.4s ease;
}

.procedure-list__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--bg-color-white);
  overflow: hidden;
}

.procedure-list__header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  background-color: var(--bg-color-default);
  color: var(--color-white);
  border-bottom: var(--border-thin);
  z-index: 20;
}

.btn-back {
  position: absolute;
  left: 1rem;
  cursor: pointer;

  &:before {
    font-weight: 500;
  }
}

.btn-back:hover {
  color: rgba(255, 255, 255, 0.519);
}

.procedure-list__actions-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
}

.procedure-list__header--title {
  font-family: var(--font);
  font-size: 1.25rem;
  font-weight: bold;
}

.procedure-list__content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 93%;
  top: 0;
  max-height: 100%;
}

.is-not-items {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 90%;
  height: max-content;
  margin: auto;
  z-index: 99;
}

.is-not-items__title {
  font-size: 2rem;
  color: var(--color-default);
}

.th {
  background-color: black;
  backdrop-filter: blur(9px);
  font-weight: 100;
  border-top: 1px solid rgba(128, 128, 128, 0.316);
  font-size: 13px;
}

.avatar-procedure {
  border-left: 1px solid rgba(128, 128, 128, 0.316);
}

.actions-th {
  width: 10%;
}

.list-item img {
  display: flex;
  align-items: center;
}

.fullname {
  font-weight: 700;
}

.procedure-img {
  width: 25px !important;
}

.actions-td {
  display: flex;
  align-items: center;
}

.last-item {
  position: relative;
}

.trigger-pagination {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: rgba(0, 0, 0, 0);
}

.actions__btn.del {
  margin-left: 5px;
}

.procedure-list__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 5px;
  padding-bottom: 4rem;
  z-index: 10;
}
</style>