// Взаимодействие суперпользователя с Отделениями 
import { ArrayDepartmentClient, DepartmentClient } from '@/types/departmentType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import useMainStore from '../../store/mainStore';
import axios from 'axios';

// Создание нового отделения
async function createNewDepartmentDB(name: string, hospitalId: number | null): Promise<DepartmentClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.post(hostname + 'api/cp/hospitals/departments', {
            name,
            hospital_id: hospitalId,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/departmentsApi: createNewDepartmentDB  => ${err}`);
    }
}

// Получение всех Отделений с сервера
async function getAllDepartmentsDB(isDeleted?: boolean, page?: number, hospitalId?: number ): Promise<ArrayDepartmentClient> {
    const store = useMainStore();
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + 'api/cp/departments', {
            params: {
                deleted: isDeleted,
                page,
                hospital_id: hospitalId,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        store.isDepartmentPaginationHasNext = response.data?.paginator?.has_next;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/departmentsApi: getAllDepartmentsDB  => ${err}`);
    }
}

// Получение Отделения по ID
async function getDepartmentById(departmentId: number): Promise<DepartmentClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/cp/hospitals/departments/${departmentId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/departmentsApi: getDepartmentById  => ${err}`);
    }
}

// Получение Отделений которые принадлежат конкретной больнице (по ID больницы)
async function getDepartmentsByHospitalIdDB(hospitalId: number, isDeleted?: boolean, page?: number): Promise<ArrayDepartmentClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/cp/departments/`, {
            params: {
                hospital_id: hospitalId,
                deleted: isDeleted,
                page,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/departmentsApi: getDepartmentsByHospitalIdDB  => ${err}`);
    }
}

// Получение отделения по ID
async function getDepartmentByID(departmentId: number): Promise<DepartmentClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/cp/hospitals/departments/${departmentId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/departmentsApi: getDepartmentByID  => ${err}`);
    }
}

// Изменить данные отделения по его ID
async function putDepartmentById( name: string, departmentId: number): Promise<DepartmentClient> {
    try {
        // Обновление сущности и получение её с сервера
        const response = await axios.put(hostname + `api/cp/hospitals/departments/${departmentId}`, {
            name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/departmentsApi: putDepartmentById  => ${err}`);
    }
}

// Изменение активности отделения по ID
async function putStatusDepartmentById(departmentId: number, deleted: boolean ): Promise<DepartmentClient> {
    try {
        // Обновление статуса сущности и получение её с сервера
        const response = await axios.put(hostname + `api/cp/hospitals/departments/${departmentId}/is_active`, {
            deleted,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/departmentsApi: putStatusDepartmentById  => ${err}`);
    }
}

export {
    createNewDepartmentDB,
    getAllDepartmentsDB,
    getDepartmentById,
    getDepartmentsByHospitalIdDB,
    getDepartmentByID,
    putDepartmentById,
    putStatusDepartmentById,
}