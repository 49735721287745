import { hostname } from './indexApi';
import axios from 'axios';
import { convertKeysToCamelCase } from '../utils/index';
import useMainStore from '../store/mainStore';

async function loginEmailPassword(email: string, password: string) {
    const store = useMainStore();
    try {
        const response = await axios.post(hostname + 'api/sign-in/email-password', {
            email,
            password,
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const { data: { data: { token, user } } } = response;
        const convertedUser = convertKeysToCamelCase(user)
        // Фиксируем полученные данные в localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('user', store.encryptObject(convertedUser, token));
        // Выводим уведомление об успехе
        store.activeSuccessOperation(800);
    } catch (err) {
        console.log(err);
        throw new Error(`api/authApi: loginEmailPassword  => ${err}`);
    }
}

// Функция для получения нового пароля на E-mail
async function forgotPassword(email: string) {
    try {
        const response = await axios.post(hostname + 'api/sign/forgot-password', {}, {
            params: {
                email,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const convertedUser = convertKeysToCamelCase(response)
        // Выводим уведомление об успехе
        return convertedUser;
    } catch (err) {
        console.log(err);
        throw new Error(`api/authApi: forgotPassword  => ${err}`);
    }
}

async function checkRank(email: string) {
    try {
        const response = await axios.get(hostname + 'api/rank', {
            params: {
                email,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        });

        const { data } = response;

        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/authApi: forgotPassword  => ${err}`);
    }
}

export {
    loginEmailPassword,
    forgotPassword,
    checkRank,
}


