
import { ref } from 'vue';
import { existsUserDB } from '../api/superuser/employeesApi';
import useMainStore from '../store/mainStore';


// Компонуемый файл для валидации полей при создании пользователя или обновления его данных 
function useValidationUserFields() {
    const store = useMainStore();

    // =================  DATA  ===================

    // Если поля ввода прошли проверку то эти переменные будут равны true и проверка форма отправится
    const isValidEmail = ref(false);
    const isValidPassword = ref(false);
    const isValidTel = ref(false);
    // Если обязательные поля пустые при отправке то значения меняются на true 
    const firstnameErrorMsg = ref('');
    const lastnameErrorMsg = ref('');
    const emailErrorMsg = ref('');
    const passwordErrorMsg = ref('');
    const telErrorMsg = ref('');

    // Правила валидации Имени
    const firstnameRules = ref([
        (value: string) => !!value || "Имя обязательное поле",
    ]);
    // Правила валидации Фамилии
    const lastnameRules = ref([
        (value: string) => !!value || "Фамилия обязательное поле",
    ]);
    // Правила валидации E-mail
    const emailRules = ref([
        (value: string) => {
            if (value.length > 0) {
                isValidEmail.value = true;
                return true;
            } else {
                isValidEmail.value = false;
                return "E-mail обязательное поле";
            }
        },
        (value: string) => {
            if (/.+@.+\..+/.test(value)) {
                isValidEmail.value = true;
                return true;
            } else {
                isValidEmail.value = false;
                emailErrorMsg.value = "Введите корректный E-mail"; // записываем сообщение об ошибке для ее сохраенения если пользователь закрывает форму
                return "Введите корректный E-mail";
            }
        },
        async (value: string) => {
            // Делать запрос на проверку пользователя только при заполненном поле
            if (value.length > 0) {
                // Проверка на существование пользователя с таким E-mail в базе данных
                const exists = await existsUserDB(value, undefined);
                if (exists === false) {
                    isValidEmail.value = true;
                    return true;
                } else {
                    isValidEmail.value = false;
                    emailErrorMsg.value = 'Пользователь с таким E-mail уже существует'; // записываем сообщение об ошибке для ее сохраенения если пользователь закрывает форму
                    return 'Пользователь с таким E-mail уже существует';
                }
            } else {
                emailErrorMsg.value = 'E-mail обязательное поле'; // записываем сообщение об ошибке для ее сохраенения если пользователь закрывает форму
                return true;
            }
        }
    ]);
    // Правила валидации E-mail для Обновления существующего пароля
    const emailRulesForUpdated = ref([
        (value: string) => {
            if (value.length <= 0) {
                isValidEmail.value = false;
                return "E-mail обязательное поле";
            } else {
                isValidEmail.value = true;
                return true;
            }
        },
        (value: string) => {
            if (/.+@.+\..+/.test(value)) {
                isValidEmail.value = true;
                return true;
            } else {
                emailErrorMsg.value = "Введите корректный E-mail"; // записываем сообщение об ошибке для ее сохраенения если пользователь закрывает форму
                isValidEmail.value = false;
                return "Введите корректный E-mail";
            }
        },
    ]);
    // Правила валидации Телефона
    const telRules = ref([
        async (value: string) => {
            // Делать запрос на проверку пользователя только при заполненном поле
            if (value && value.length > 0) {

                // Форматирование формата номера +7(999)-99-99 в 79999999999
                const formatedNumber = store.getPhoneNumberDigits(value);
                if (formatedNumber) {
                    // Проверка на существование пользователя с таким Телефоном в базе данных
                    const exists = await existsUserDB(undefined, formatedNumber);
                    if (exists === false) {
                        isValidTel.value = true;  // указываем форме что телефон валидный
                        return true;
                    }
                    else {
                        isValidTel.value = false;  // указываем форме что телефон не валидный, чтобы не была доступна отправка на сервер
                        telErrorMsg.value = 'Пользователь с таким номером телефона уже существует';
                        return 'Пользователь с таким номером телефона уже существует';
                    }
                }
            } else return true;
        },
        (value: string) => {
            // Форматирование формата номера +7(999)-99-99 в 79999999999
            const formatedNumber = store.getPhoneNumberDigits(value);
            if (formatedNumber && formatedNumber?.length < 11) {
                isValidTel.value = false;
                telErrorMsg.value = 'Номер телефона не может содержать меньше 11 цифр';
                return 'Номер телефона не может содержать меньше 11 цифр';
            } else {
                isValidTel.value = true;
                return true;
            }
        }
    ]);
    const telRulesForUpdated = ref([
        (value: string) => {
            // Форматирование формата номера +7(999)-99-99 в 79999999999
            const formatedNumber = store.getPhoneNumberDigits(value);
            if (formatedNumber && formatedNumber?.length < 11) {
                isValidTel.value = false;
                telErrorMsg.value = 'Номер телефона не может содержать меньше 11 цифр';
                return 'Номер телефона не может содержать меньше 11 цифр';
            } else {
                isValidTel.value = true;
                return true;
            }
        }
    ])
    // Правила валидации Пароля
    const passwordRules = ref([
        (value: string) => {
            if(value && value.length > 0) {
                return true;
            } else {
                return 'Пароль обязательное поле'
            }
        },
        (value: string) => {
            if (value && value.length < 8) {
                isValidPassword.value = false;
                passwordErrorMsg.value = 'Пароль должен содержать минимум 8 символов';  // записываем сообщение об ошибке для ее сохраенения если пользователь закрывает форму
                return 'Пароль должен содержать минимум 8 символов';
            } else {
                isValidPassword.value = true;
                return true;
            }
        }
    ]);
    // Правила валидации Пароля
    const passwordRulesForUpdated = ref([
        (value: string) => {
            if (value && value.length < 8) {
                isValidPassword.value = false;
                passwordErrorMsg.value = 'Пароль должен содержать минимум 8 символов';  // записываем сообщение об ошибке для ее сохраенения если пользователь закрывает форму
                return 'Пароль должен содержать минимум 8 символов';
            } else {
                isValidPassword.value = true;
                return true;
            }
        }
    ]);

    // =================  METHODS  ===================


    return {
        isValidEmail,
        isValidPassword,
        isValidTel,

        firstnameErrorMsg,
        lastnameErrorMsg,
        emailErrorMsg,
        passwordErrorMsg,
        telErrorMsg,

        firstnameRules,
        lastnameRules,
        emailRules,
        emailRulesForUpdated,
        telRules,
        telRulesForUpdated,
        passwordRules,
        passwordRulesForUpdated,
    }
}

export default useValidationUserFields;