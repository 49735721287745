// Взаимодействие суперпользователя с Палатами 
import { ArrayHospitalRoomClient, HospitalRoomClient, HospitalRoomCreationServer } from '@/types/hospitalRoomType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import axios from 'axios';
import useMainStore from '../../store/mainStore';

// Создание новой палаты
async function createNewHospitalRoomDB(name: string, hospitalId: number | null, departmentId: number): Promise<HospitalRoomClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.post(hostname + 'api/cp/hospitals/departments/rooms', {
            name,
            hospital_id: hospitalId,
            department_id: departmentId,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalRoomsApi: createNewHospitalRoomDB  => ${err}`);
    }
}

// Получение всех палат с сервера
async function getAllHospitalRoomsDB(isDeleted?: boolean, page?: number, departmentId?: number, hospitalId?: number): Promise<ArrayHospitalRoomClient> {
    const store = useMainStore();
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + 'api/cp/rooms/', {
            params: {
                deleted: isDeleted,
                page,
                department_id: departmentId,
                hospital_id: hospitalId,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data } = response;
        const rooms = data?.data;
        const paginator = data?.paginator;
        // Задаем в стор информацию о том доступны ли следующие страницы пагинации
        store.isHospitalRoomsPaginationHasNext = paginator?.has_next;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(rooms);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalRoomsApi: getAllHospitalRoomsDB  => ${err}`);
    }
}

// Получение Палат которые принадлежат конкретному отделению (по ID отделения)
async function getHospitalRoomByDepartmentIdDB(departmentId: number, hospitalId?: number, isDeleted?: boolean, page?: number): Promise<ArrayHospitalRoomClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/cp/rooms`, {
            params: {
                deleted: isDeleted,
                hospital_id: hospitalId,
                department_id: departmentId,
                page,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalRoomsApi: getHospitalRoomByDepartmentIdDB  => ${err}`);
    }
}

// Получение Палаты по ID
async function getHospitalRoomById(hospitalRoomId: number): Promise<HospitalRoomClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/cp/hospitals/departments/rooms/${hospitalRoomId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalRoomsApi: getHospitalRoomById  => ${err}`);
    }
}

// Изменить данные палаты по его ID
async function putHospitalRoomById(roomId: number,  changedFields: HospitalRoomCreationServer): Promise<HospitalRoomClient> {
    try {
        // Обновление сущности и получение её с сервера
        const response = await axios.put(hostname + `api/cp/hospitals/departments/rooms/${roomId}/`, {
            ...changedFields,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalRoomsApi: putHospitalRoomById  => ${err}`);
    }
}

// Сделать палату не активной
async function putStatusHospitalRoomById(hospitalRoomId: number, deleted: boolean ): Promise<HospitalRoomClient> {
    try {
        // Обновление статуса сущности и получение её с сервера
        const response = await axios.put(hostname + `api/cp/hospitals/departments/rooms/${hospitalRoomId}/is_active`, {
            deleted,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalRoomsApi: putStatusHospitalRoomById  => ${err}`);
    }
}


export {
    createNewHospitalRoomDB,
    getAllHospitalRoomsDB,
    getHospitalRoomById,
    getHospitalRoomByDepartmentIdDB,
    putHospitalRoomById,
    putStatusHospitalRoomById,
}