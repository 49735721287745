<template>
  <v-dialog v-model="modelValue" width="auto">
    <v-card class="dialog__wrapper">

      <!-- Крутилка загрузки -->
      <span class="progress-circular" v-if="isShowLoadingData">
                <v-progress-circular
                  indeterminate
                  bg-color="var(--bg-color-white)"
                  color="var(--bg-color-default)"
                  :size="60"
                  :width="6"
                ></v-progress-circular>
            </span>

      <form class="dialog__form">
        <!-- Превью -->
        <div class="dialog__form--preview-block">
          <input id="preview-image" v-show="false"/>
          <label
            class="preview-image"
            for="preview-image"
          >
            <v-icon
              icon="mdi-table-large"
              color="var(--color-default)"
              size="80px"
            ></v-icon>
          </label>
          <h1 class="preview-name">{{ hospitalRoomFormCopy.name }}</h1>
        </div>

        <!-- Общие данные -->
        <div class="dialog__form--general-data">
          <!-- Имя -->
          <v-card-subtitle class="mb-2">* Название палаты</v-card-subtitle>
          <v-text-field
            class="general-data__input"
            bg-color="var(--bg-color-white)"
            v-model.trim="name"
            color="var(--color-default)"
            rounded="xs"
            :density="'compact'"
            variant="outlined"
            hide-details
          ></v-text-field>

          <!-- Выбор Организации -->
          <hospitalListComp
            v-if="authStore.isSuperUser === true"
            style="width: 100% !important;"
            :label-compbox="'Организация'"
            :item-list="hospitalList"
            :header-title="'Выберите организацию'"
            @clear-hospital="handlerClearHospital"
            @select-hospital="(hospital) => handlerSelectHospital(hospital)"
            :selected-hospital-name="selectedHospitalName"
          />
          <!-- Выбор отделения -->
          <departmentListComp
            style="width: 100% !important;`"
            :label-compbox="'Отделение'"
            :item-list="departmentList"
            :header-title="'Выберите отделение'"
            :filter-by-hospital-id="(authStore.isSuperUser === true)? hospitalId : null"
            @clear-department="handlerClearDepartment"
            @select-department="(department) => handlerSelectDepartment(department)"
            :selected-department-name="selectedDepartmentName"
            :is-disabled="hospitalId === null && authStore.isSuperUser === true"
          />

        </div>
      </form>
      <div class="dialog__actions">

        <!-- Сохранить -->
        <v-btn
          class="mr-6"
          @click="confirmToUpdateHospitalRoom"
          :loading="isLoading"
          variant="flat"
          style="color: white; font-weight: normal; font-size: 12px;"
          color="var(--bg-color-default)"
        >
          Сохранить
        </v-btn>

        <!-- Сбросить изменения -->
        <v-btn
          variant="tonal"
          style="margin-right: auto; font-size: 12px;"
          color="var(--bg-color-default)"
          @click="resetChanges"
        >
          Сбросить изменения
        </v-btn>

        <!-- Закрыть -->
        <v-btn
          variant="outlined"
          color="red"
          style="font-size: 12px;"
          @click="handlerClose">
          Закрыть
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import hospitalListComp from '../departments/hospitalListComp.vue';
import departmentListComp from './departmentListComp.vue';
import {HospitalRoomClient, HospitalRoomCreationClient, HospitalRoomCreationServer} from '../../types/hospitalRoomType';
import {HospitalClient} from '../../types/hospitalType';
import {DepartmentClient} from '../../types/departmentType';
import {getHospitalRoomById, putHospitalRoomById} from '../../api/superuser/hospitalRoomsApi';
import {putHospitalRoomById__chief} from '../../api/hospitalChief/hospitalRoomsApi';
import {getHospitalRoomById__chief} from '../../api/hospitalChief/hospitalRoomsApi';
import {ref, defineModel, defineEmits, watch, onMounted} from 'vue';
import useLoadingDepartments from '../../composable/loadDepartmentList';
import useLoadingHospital from '../../composable/loadHospitalList';
import {useRoute} from 'vue-router';
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';

const store = useMainStore();
const authStore = useAuthStore();
const route = useRoute();
const modelValue = defineModel<boolean>();

// ============================  EMITS  ============================
const emit = defineEmits<{
  close: [],
  success: [],
  error: [msg: string],
  loadHospitalRoom: [hospitalRoom: HospitalRoomClient],
  updateHospitalRoom: [hospitalRoom: HospitalRoomClient],
}>();

// =======================================  DATA  ====================================

const isLoading = ref(false);
const isShowLoadingData = ref<boolean>(false)
const id = ref<number>(0);
const hospitalId = ref<number | null>(null);
const departmentId = ref<number | null>(null);
const name = ref<string | null>(null);
const hospitalRoomFormCopy = ref({
    name: null as string | null,
    selectedHospitalName: null as string | null,
    selectedDepartmentName: null as string | null,
    selectedHospitalId: null as number | null,
    selectedDepartmentId: null as number | null,

});
// Компонуемый файл для получения списка больниц и отображения их в выпадающем меню
const {
  hospitalList,
  selectedHospital,
  selectedHospitalName,
  clearHospital,
  selectHospital,
} = useLoadingHospital();

// Компонуемый файл для получения списка отделений и отображения их в выпадающем меню
const {
  departmentList,
  selectedDepartment,
  selectedDepartmentName,
  isLoadingDepartmentsFetch,
  clearDepartment,
  selectDepartment,
} = useLoadingDepartments();

// =======================================  METHODS  ======================================

function handlerClearHospital() {
  clearHospital();
  hospitalId.value = null;
  hospitalList.value.length = 0;
}

function handlerClearDepartment() {
  clearDepartment();
  departmentId.value = null;
  departmentList.value.length = 0;
}

// Функция обработчик для фиксирования ID БОЛЬНИЦЫ из селект-меню и сохранения выбранной БОЛЬНИЦЫ
function handlerSelectHospital(hospital: HospitalClient | null) {
  if (hospital) {
    hospitalId.value = hospital.id;
    selectHospital(hospital);
  }
}

// Функция обработчик для фиксирования ID ОТДЕЛЕНИЯ из селект-меню и сохранения выбранного ОТДЕЛЕНИЯ
function handlerSelectDepartment(department: DepartmentClient | null) {
  if (department) {
    departmentId.value = department.id;
    selectDepartment(department);
  }
}

// Получение палаты по её ID
async function getHospitalRoomByIdGeneral(hospitalRoomId: number) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getHospitalRoomById(hospitalRoomId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return getHospitalRoomById__chief(hospitalRoomId);
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsDialogComp.vue: getHospitalRoomByIdGeneral  => ${err}`);
  }
}

// Обновление информации о палате
async function putHospitalRoomByIdGeneral(roomId: number, changedFields: HospitalRoomCreationServer) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await putHospitalRoomById(roomId, changedFields);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await putHospitalRoomById__chief(roomId, changedFields);
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsDialogComp.vue: putHospitalRoomByIdGeneral  => ${err}`);
  }
}


// функция собирает ключи объекта с полученного массива entries функции compareObjectsFetchFields 
// И подготавливает объект к отправке на сервер. Собирает только те поля которые фактически были изменены
function bundleDataForSend(entries: Array<any>): HospitalRoomCreationClient {
  try {
    const changedFields: object | any = {};
    // В цикле собираем объект тех ключей, чьи значения действительно были изменены,
    // чтобы отправить этот объект на сервер для изменения пользователя
    entries.forEach((entry) => {
      if (entry[0] === 'selectedHospitalName') {
        changedFields.hospitalId = selectedHospital.value?.id;
      } else if (entry[0] === 'selectedDepartmentName') {
        changedFields.departmentId = selectedDepartment.value?.id;
      } else {
        changedFields[entry[0]] = entry[1];
      }
    });
    return changedFields;
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsDialogComp.vue: bundleDataForSend  => ${err}`);
  }
}

// Подтверждение данных для обновления выбранного отделения
async function confirmToUpdateHospitalRoom() {
  try {
    isLoading.value = true;
    // сравниваем два объекта на наличие изменения данных в полях ввода (Для исключения пустого запроса)
    const {entries, isCompare} = store.compareObjectsFetchFields(hospitalRoomFormCopy.value, {
      name: name.value,
      selectedHospitalName: selectedHospitalName.value,
      selectedDepartmentName: selectedDepartmentName.value,
    });
    // Если два объекта отличаются то обрабатываем запрос к серверу на изменение данных
    if (isCompare === false) {
      if (name.value && name.value.length > 0) {
        if (selectedDepartment.value?.id && selectedDepartmentName.value) {
          const changedFields = bundleDataForSend(entries);
          // Форматирование объекта измененных полей в snake_case
          const changedFieldsToSnake: HospitalRoomCreationServer = store.camelToSnake(changedFields);
          const updatedHospitalRoom = await putHospitalRoomByIdGeneral(id.value, changedFieldsToSnake);
          emit('success');
          emit('updateHospitalRoom', updatedHospitalRoom); // Передаем обновленную палату в родительский компонент для обновления списка элементов
        } else {
          emit('error', 'Укажите отделение');
        }
      } else {
        emit('error', 'Укажите название отделения');
      }
    }
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsDialogComp.vue: confirmToUpdateHospitalRoom  => ${err}`);
  } finally {
    isLoading.value = false;
  }
}

// Сбросить изменения в форме 
function resetChanges() {
    try {
        // Проверяем объекты на разность значений их свойств
        const isCompare = store.compareObjects(hospitalRoomFormCopy.value, { 
            name: name.value,
            selectedHospitalName: selectedHospitalName.value,
            selectedDepartmentName: selectedDepartmentName.value,
            selectedDepartment: selectedDepartment.value,
            selectedHospital: selectedHospital.value,
        });
        if(isCompare === false) {
            if(hospitalRoomFormCopy.value.name) {
                name.value = hospitalRoomFormCopy.value.name;
            } else name.value = null;
            selectedDepartmentName.value = hospitalRoomFormCopy.value.selectedDepartmentName;
            selectedHospitalName.value = hospitalRoomFormCopy.value.selectedHospitalName;
            hospitalId.value = hospitalRoomFormCopy.value.selectedHospitalId;
            departmentId.value = hospitalRoomFormCopy.value.selectedDepartmentId;
        }
    } catch (err) {
        throw new Error(`components/hospitalRooms/hospitalRoomsDialogComp.vue: resetChanges  => ${err}`);

    }

}

// Функция применяется для создания копии объекта формы
function createCopyForm() {
  try {
    // На этом этапе selectedDepartmentName и selectedHospitalName еще не существует так как он будет получен асинхронно в parsingObject
    hospitalRoomFormCopy.value.name = name.value;
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsDialogComp.vue: createCopyForm  => ${err}`);
  }
}

// Функция разбивает ключи получаемого объекта по реактивным переменным для отображаения их в полях ввода
async function parsingObject(hospitalRoom: HospitalRoomClient) {
    try {
        if(hospitalRoom?.name) {
            name.value = hospitalRoom?.name;
        }
        if(hospitalRoom?.department) {
            try {
                isLoadingDepartmentsFetch.value = true;
                const fetchedDepartment = hospitalRoom?.department;
                const fetchedHospital = hospitalRoom.department.hospital;
                // Добавляем в селект Больницу
                selectedHospital.value = fetchedHospital;
                selectedHospitalName.value = fetchedHospital?.name;
                hospitalId.value = fetchedHospital.id;
                // Добавляем в селект Отделение
                selectedDepartmentName.value = fetchedDepartment?.name;
                selectedDepartment.value = fetchedDepartment;
                departmentId.value = fetchedDepartment.id;
                // Также добавляем имя и объекты выбранных отделения и больницы в копию формы
                hospitalRoomFormCopy.value.selectedDepartmentName = fetchedDepartment?.name;
                hospitalRoomFormCopy.value.selectedHospitalName = fetchedHospital?.name;
                hospitalRoomFormCopy.value.selectedHospitalId = fetchedHospital.id;
                hospitalRoomFormCopy.value.selectedDepartmentId = fetchedDepartment.id;
            } catch (err) {
                throw new Error(`components/hospitalRooms/hospitalRoomsDialogComp.vue: parsingObject[fetch department-data]  => ${err}`);
            } finally {
                isLoadingDepartmentsFetch.value = false;
            }
        }
    } catch (err) {
        throw new Error(`components/hospitalRooms/hospitalRoomsDialogComp.vue: parsingObject  => ${err}`);

    }
}

// Обработчик закрытия формы
function handlerClose() {
  id.value = 0;
  selectedDepartment.value = null;
  selectedDepartmentName.value = '';
  hospitalRoomFormCopy.value = {
    name: null,
    selectedDepartmentName: null,
    selectedHospitalName: null,
  }
  emit('close');
}

// Получение данных выбранного объекта в списке
watch(() => route.query['select-hospital-room'], async (hospitalRoomId) => {
  try {
    isShowLoadingData.value = true;
    if (typeof hospitalRoomId === 'string' && +hospitalRoomId > 0) {

      id.value = +hospitalRoomId;
      const hospitalRoom = await getHospitalRoomByIdGeneral(id.value);
      // Заполняем поля формы при открытии информации о палате
      parsingObject(hospitalRoom);
      // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
      createCopyForm();
    } else {
      // Иммитируем обнуление полей ввода для формы этого компонента
      emit('close');
    }
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsDialogComp.vue: watch[fetch hospital-room-data]  => ${err}`)

  } finally {
    isShowLoadingData.value = false;
  }
});


// =====================================   LIFECYCLE HOOKS   ================================
onMounted(async () => {
  try {
    if (route.query['select-hospital-room']) {
      const hospitalRoomIdQuery = route.query['select-hospital-room']
      if (typeof hospitalRoomIdQuery === 'string' && +hospitalRoomIdQuery > 0) {
        // Загружаем данные выбранной палаты если query параметр select-hospital-room существует
        try {
          isShowLoadingData.value = true;
          id.value = +hospitalRoomIdQuery;
          const hospitalRoom = await getHospitalRoomByIdGeneral(id.value);
          parsingObject(hospitalRoom);
          // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
          createCopyForm();
        } catch (err) {
          throw new Error(`components/hospitalRooms/hospitalRoomsDialogComp.vue: onMounted[fetch hospital-room-data]  => ${err}`)
        } finally {
          isShowLoadingData.value = false;
        }
      }
    } else {
      emit('close');
    }
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsDialogComp.vue: onMounted => ${err}`);
  }
});


</script>

<style scoped>
.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
  z-index: 999;
}

.dialog__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  background-color: var(--bg-color-block);
}

.dialog__form {
  width: 100%;
  height: max-content;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-block);
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 2rem;
  background-color: var(--bg-color-white);
  margin-bottom: 3rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dialog__form--preview-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: max-content;
  padding: 2rem 0;
  background-color: rgba(239, 246, 248, 1);
}

.preview-image {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.image {
  width: 100%;
  height: 100%;
}

.preview-name {
  font-size: 26px;
  font-weight: 500;
  margin-top: 1rem;
}

.data-title {
  display: flex;
  justify-content: center;
  font-size: 1.1em;
}

.dialog__form--general-data {
  width: 80%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  background-color: var(--bg-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.general-data__input {
  margin-bottom: 1rem;
}

.general-data__hospital-room-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1.5rem;
  border: 1px solid black;
}

.general-data__hospital-room-amount p {
  color: var(--color-default);
}

</style>