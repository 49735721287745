<template>
    <div class="group-hospital__wrapper">
        <v-card-subtitle class="mb-3">
            {{ (props.headerTitle)? props.headerTitle : 'Палата, к которой принадлежит койка' }}  
            <span style="font-weight: 600; color: red; margin-left: 0.5rem;">
                {{ (isNotNamesList === false)? '' : 'У выбранного отделения нет палат, создайте новую'}}
            </span> 
        </v-card-subtitle>
        <v-combobox
        class="group-items"
        :clearable="true"
        :placeholder="props.labelCompbox"
        :items="listNames"
        :loading="props.isLoading"
        @update:model-value="(e) => selectHospitalRoom(e)"
        @click:clear="emit('clearHospitalRoom')"
        rounded="lg"
        v-model="hospitalRoomName"
        :disabled="(isNotNamesList === true)? isNotNamesList : props.isDisabled"
        variant="outlined"
        bg-color="var(--bg-color-white)"
        color="var(--color-default)"
        base-color="var(--color-default)"
        :density="'compact'"
    >
      <template v-slot:selection>
        <p class="group-items__selection-value">{{ computedHospitalRoomName }}</p>
      </template>


      <!-- Элемент группы -->
      <template v-slot:item="{item, props}">
        <div v-bind="props" class="group-items__item">
          <p class="item__value">{{ item.value }}</p>
        </div>
      </template>

    </v-combobox>
  </div>
</template>

<script setup lang="ts">
import {HospitalRoomClient} from '../../types/hospitalRoomType';
import {ref, watch, computed, defineProps, defineEmits, defineModel, onMounted} from 'vue';
import {useRoute} from 'vue-router';
import {getAllHospitalRoomsDB} from '../../api/superuser/hospitalRoomsApi';
import {getHospitalRoomByDepartmentIdDB__chief} from '../../api/hospitalChief/hospitalRoomsApi';
import useDataStore from '../../store/dataStore';
import useAuthStore from '../../store/authStore';

const dataStore = useDataStore();
const authStore = useAuthStore();
const route = useRoute();

// =====================================  V-MODEL  =====================================
defineModel<string>('checked');

// =====================================  PROPS  =====================================
const props = defineProps<{
  labelCompbox: string,
  itemList: Array<any>,
  selectedHospitalRoomName: string | null,
  isLoading?: boolean,
  headerTitle?: string,
  filterByDepartmentId?: number | null;
  isDisabled?: boolean;
}>();

// =====================================  EMITS  =====================================
const emit = defineEmits<{
  loadItems: [],
  closeMenu: [],
  selectHospitalRoom: [HospitalRoomClient | null],
  clearHospitalRoom: [],
}>()

// =====================================  DATA  =====================================
const isLoading = ref<boolean>(false);
const hospitalRoomName = ref<string | null>(null);
const selectedHospitalRoomData = ref<HospitalRoomClient | null>(null);
const listNames = ref<Array<string | any>>([]);
const isNotNamesList = ref<boolean>(false);


// =====================================  METHODS  =====================================
// Функция для отправки выбранной палаты в диалоговое окно редактирования койки 
function selectHospitalRoom(hospitalRoomName: string) {
  dataStore.hospitalRooms.forEach((hospitalRoom: HospitalRoomClient) => {
    if (hospitalRoomName === hospitalRoom.name) {
      emit('selectHospitalRoom', hospitalRoom);
    }
  });
}

// Получение всех палат выбранного отделения
async function getAllHospitalRooms(
  isDeleted?: boolean | undefined,
  page?: number | undefined,
  departmentId?: number | undefined,
  hospitalId?: number | undefined
) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getAllHospitalRoomsDB(isDeleted, page, departmentId, hospitalId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    if (departmentId) {
      return await getHospitalRoomByDepartmentIdDB__chief(departmentId, isDeleted, page);
    }
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalRoomListComp.vue: getAllHospitalRooms  => ${err}`);
  }
}

// =====================================  COMPUTED  ==========================================
// Вычисляем название палаты. Если оно существует и было передано как реквизит с род.компонента или оно выбирается в селект-меню
const computedHospitalRoomName = computed(() => {
  if (props.selectedHospitalRoomName && props.selectedHospitalRoomName.length > 0) {
    return props.selectedHospitalRoomName;
  } else {
    return selectedHospitalRoomData.value?.name;
  }
});
// =====================================  WATCH  =====================================
// Отслеживает изменение приходящего ID больницы в пропсы
watch(() => props.filterByDepartmentId, async (newValue) => {
  try {
    if (newValue !== null) {
      listNames.value.length = 0;
      const fetchedHospitalRooms = await getAllHospitalRooms(false, undefined, newValue, undefined);

      if (fetchedHospitalRooms) {
        dataStore.hospitalRooms = fetchedHospitalRooms;
        for (const hospitalRoom of fetchedHospitalRooms) {
          if (hospitalRoom.deleted === null) {
            listNames.value.push(hospitalRoom.name);
          }
        }
        if (listNames.value.length === 0) {
          isNotNamesList.value = true;
        } else {
          isNotNamesList.value = false;
        }
      }
    } else {
      isNotNamesList.value = false;
      selectedHospitalRoomData
    }
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalRoomListComp.vue: watch[fetch hospitalRooms]`);
  }
});

// Отслеживает изменение массива приходящих элементов и отключает progress-circle
watch(() => props.itemList.length, (newLength: number, oldLength: number) => {
  if (oldLength === 0 && newLength > 0) {
    isLoading.value = false;
  }
});

// Отслеживание название палаты если оно существует
watch(() => props.selectedHospitalRoomName, (newValue) => {
  hospitalRoomName.value = newValue;
})

// =====================================  LIFECYCLE HOOKS  =====================================
onMounted(async () => {
  hospitalRoomName.value = props.selectedHospitalRoomName;
  if (dataStore.hospitalRooms.length) {
    for (const hospitalRoom of dataStore.hospitalRooms) {
      if (hospitalRoom.deleted === null) {
        listNames.value.push(hospitalRoom.name);
      }
    }
  } else {
    try {
      // Получаем query-параметр department для получения палат если выбрано отделение
      let departmentId: any = route.query['department'] ?? undefined;
      if (typeof departmentId === 'string') departmentId = +departmentId;
      const res = await getAllHospitalRooms(false, undefined, departmentId, undefined);
      if (res) {
        dataStore.hospitalRooms = res;
        for (const hospitalRoom of dataStore.hospitalRooms) {
          if (hospitalRoom.deleted === null) {
            listNames.value.push(hospitalRoom.name);
          }
        }
      }
    } catch (err) {
      throw new Error(`components/hospitalBeds/hospitalRoomListComp.vue: onMounted[fetch hospitalRooms]`);
    }
  }
});


</script>

<style scoped>
.group-hospital__wrapper {
  width: 15% !important;
}

.group-items {
  width: 100% !important;
}

.group-items__selection-value {
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

.group-items__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: .5rem .5rem .5rem 1rem;
  cursor: pointer;
}
.group-items__item:hover {
  background-color: var(--bg-color-gray);
}
.group-items__item + .group-items__item {
  border-top: var(--border-thin-light);
}

.item__value {
  width: 65% !important;
  padding: 0.1rem 0.5rem;
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>