<template>
  <!-- Главный Блок для взаимодействия с Устройствами -->
  <div class="droppers-main">
    <div class="droppers-main__wrapper">
      <!-- Список Устройств -->
      <droppersListComp
        :droppers="droppers"
        :is-load-droppers="isLoadDroppers"
        :isDeleted="isDeleted"
        @load-dropper="(dropper) => appendDropper(dropper)"
        @update-dropper="(dropper) => updateDropper(dropper)"
        @dropper-filter="(filterData) => filteredDroppers(filterData)"
        @pagination-up="uploadNewPageItems"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue';
// COMPONENTS
import droppersListComp from './droppersListComp.vue';
// API
import {getAllDroppersDB} from '../../api/superuser/droppersApi';
import {getAllDroppersDB__chief} from '../../api/hospitalChief/droppersApi';
// TYPES
import {ArrayDropperClient, DropperClient} from '../../types/droppersType';
// STORE
import useMainStore from '@/store/mainStore';
import useDataStore from '../../store/dataStore';
import useAuthStore from '../../store/authStore';

const mainStore = useMainStore();
const authStore = useAuthStore();
const dataStore = useDataStore();
const page = ref<number>(1);

// ======================================  DATA  ======================================
// Полученный с БД массив данных
const droppers = ref<ArrayDropperClient>([]);
const isLoadDroppers = ref<boolean>(false);
const isDeleted = ref<boolean>(false);


// ======================================  METHODS  ======================================
// Получение всех устройств больницы
async function getAllDroppers(
  isDeleted?: boolean | undefined,
  page?: number | undefined,
  vendorId?: string | undefined,
  hospitalId?: number | undefined,
  departmentId?: number | undefined
) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getAllDroppersDB(isDeleted, page, vendorId, hospitalId, departmentId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getAllDroppersDB__chief(isDeleted, page, vendorId, departmentId);
  } catch (err) {
    throw new Error(`components/droppers/droppersMainComp: getAllDroppers  => ${err}`);
  }
}

// Отрабатывает при создании нового устройства
function appendDropper(dropper: DropperClient) {
  try {
    dataStore.droppers.push(dropper);
    droppers.value = dataStore.droppers;
  } catch (err) {
    throw new Error(`components/droppers/droppersMainComp: appendDropper  => ${err}`);
  }
}

// Отрабатывает когда происходит обновление данных Устройства
function updateDropper(dropperUpdated: DropperClient) {
  try {
    dataStore.droppers = dataStore.droppers.map((dropper: DropperClient) => {
      if (dropperUpdated.id === dropper.id) {
        dropper = dropperUpdated;
        return dropperUpdated;
      }
      return dropper;
    });
    droppers.value = dataStore.droppers;
  } catch (err) {
    throw new Error(`components/droppers/droppersMainComp: updateDropper  => ${err}`);
  }
}

// Подгрузка новых данных по пагинации
async function uploadNewPageItems() {
  try {
    // Проверка, если на сервере есть следующая страница с данными то выполнять подгрузку этой страницы
    if (mainStore.isDropperPaginationHasNext === true) {
      page.value += 1;
      const droppersNextPage: any = await getAllDroppers(isDeleted.value, page.value);
      // Добавляем в уже существующий массив элементов новые элементы пришедшие с другой страницы пагинации
      dataStore.droppers = dataStore.droppers.concat(droppersNextPage);
      droppers.value = dataStore.droppers;
    }
  } catch (err) {
    throw new Error(`components/droppers/droppersMainComp: uploadNewPageItems  => ${err}`);
  }
}

// Функция фильтрует массив Устройств относительно фильтр-данных
async function filteredDroppers(filterData: {
  id: string | null,
  hospitalName: string | null,
  departmentName: string | null,
  isDeleted: boolean
}) {
  try {
    isDeleted.value = false;
    page.value = 1;
    isLoadDroppers.value = true;

    // Если фильтр-данные пусты, то возвращаем список устройств в исходное состояние
    if (filterData.id === null && filterData.hospitalName === undefined && filterData.departmentName === undefined && !filterData.isDeleted) {
      if (dataStore.droppers.length) {
        droppers.value = dataStore.droppers;
      } else {
        // так как поле isDeleted в getAllHospitalDB и isDeleted не одно и то же, мы меняем isActive на противоположное значение
        dataStore.droppers = await getAllDroppers(false, page.value);
        droppers.value = dataStore.droppers;
      }
      // Возвращаем переменные для пагинации в исходное состояние
      isDeleted.value = false;
      mainStore.isDropperPaginationHasNext = true;
      page.value = 1;
      return;
    }
    // Если данные для фильтрации были указаны то получаем весь список Устройств в системе и фильтруем его по фильтр данным
    else {
      let filteredDataList = await getAllDroppers(filterData.isDeleted, undefined);

      // Фильтрация по id
      if (filterData.id) {
        filteredDataList = filteredDataList.filter((dropper: DropperClient) => {
          if (dropper.vendorId) {
            return dropper.vendorId === filterData.id;
          }
          return false
        });
      }

      // Фильтрация по hospitalName
      if (filterData.hospitalName) {
        filteredDataList = filteredDataList.filter((dropper: DropperClient) => {
          if (filterData.hospitalName) {
            return !!dropper.department.hospital.name?.toLocaleLowerCase()?.includes(filterData.hospitalName.toLocaleLowerCase());
          }
          return true;
        });
      }

      // Фильтрация по departmentName
      if (filterData.departmentName) {
        filteredDataList = filteredDataList.filter((dropper: DropperClient) => {
          if (filterData.departmentName) {
            return !!dropper.department.name?.toLocaleLowerCase()?.includes(filterData.departmentName.toLocaleLowerCase());
          }
          return true;
        });
      }
      // Фильтрация по isActive
      if (filterData.isDeleted) {
        isDeleted.value = true;
        filteredDataList = await getAllDroppers(filterData.isDeleted, undefined);
        // Если введены другие данные в фильтр панель
        if(filterData.departmentName || filterData.hospitalName) {
            filteredDataList = filteredDataList.filter((dropper: DropperClient) => {
                // Если в фильтр панеле указано название больницы, то проверяем сопоставляется ли оно
                if(filterData.hospitalName) {
                    if(dropper.department.hospital.name.toLocaleLowerCase().includes(filterData.hospitalName?.toLocaleLowerCase())) {
                        return true;
                    }
                    else return false;
                } 
                // Если больница не была сопоставлена с больницей текущего устройства то далее проверяется сопоставление по имени отделения
                if(filterData.departmentName) {
                    if(dropper.department.name.toLocaleLowerCase().includes(filterData.departmentName?.toLocaleLowerCase())) {
                        return true;
                    } 
                    else return false;
                }
            });
        }
      }
      droppers.value = filteredDataList;
    }

  } catch (err) {
    throw new Error(`components/droppers/droppersMainComp: filteredDroppers  => ${err}`);
  } finally {
    isLoadDroppers.value = false;
  }
}


// ======================================  LIFECYCLE HOOKS  ======================================
// Получение массива Устройств с БД
onMounted(async () => {
  // Получение списка Устройств если есть query-параметр (hospital-room) открытого отделения
  try {
    isLoadDroppers.value = true;
    // Получение списка Устройств
    dataStore.droppers = await getAllDroppers(false, page.value);
    droppers.value = dataStore.droppers;

  } catch (err) {
    throw new Error(`components/droppers/droppersMainComp: onMounted[fetch droppers]  => ${err}`);
  } finally {
    isLoadDroppers.value = false;
  }
});

</script>

<style scoped>
.droppers-main {
  width: 100%;
  height: 100%;
  padding: 0 1rem 0 4rem;
}

.droppers-main__wrapper {
  width: 100%;
  height: 107%;
  margin-top: 1rem;
  background-color: var(--bg-color-white);
}
</style>