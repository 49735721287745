<template>
  <div class="procedure-main">
    <div class="procedure-main__wrapper">
      <procedureListComp
        :procedures="procedures"
        @pagination-up="uploadNewPageItems"
        @procedure-filter="(filterData) => filteredProcedures(filterData)"
        :is-not-procedures="isNotProcedures"
        :is-loading-data="isLoadingData"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {ArrayProcedureClient, ProcedureClient} from '../../types/procedureType';
import procedureListComp from './procedureListComp.vue';
import {getAllProceduresDB} from '../../api/superuser/proceduresApi';
import {getAllProceduresDB__chief} from '../../api/hospitalChief/proceduresApi';
import useMainStore from '../../store/mainStore';
import useDataStore from '../../store/dataStore';
import useAuthStore from '../../store/authStore';
import {onMounted, ref} from 'vue';

const mainStore = useMainStore();
const dataStore = useDataStore();
const authStore = useAuthStore();

// ===========================   DATA   ==========================
const isNotProcedures = ref<boolean>(false);
const procedures = ref<ArrayProcedureClient>([]);
const isActive = ref<boolean>(true);
const isLoadingData = ref<boolean>(false);
const page = ref<number>(1);
const causeEmptyMsg = ref<string>('Процедуры не найдены');
const causeList = ref<Array<string>>([
  "Процедуры не найдены",
  "Не активных процедур нет",
  "Активных процедур нет",
]);

// Фильтр данные
const dateFrom = ref<number | undefined>(undefined);
const dateTo = ref<number | undefined>(undefined);

// ===========================   METHODS   ==========================
// Подгрузка новых данных по пагинации
async function uploadNewPageItems() {
  try {
    // Проверка, если на сервере есть следующая страница с данными то выполнять подгрузку этой страницы
    if (mainStore.isProceduresPaginationHasNext === true) {
      page.value += 1;
      const proceduresNextPage: any = await getAllProceduresGeneral(page.value, isActive.value, dateFrom.value, undefined, undefined, dateTo.value);
      // Добавляем в уже существующий массив элементов новые элементы пришедшие с другой страницы пагинации
      dataStore.procedures = [...dataStore.procedures, ...proceduresNextPage];
      procedures.value = dataStore.procedures;
    }
  } catch (err) {
    throw new Error(`components/procedure/procedureMainComp: uploadNewPageItems  => ${err}`);
  }
}

// Общая функция для получения всех процедур
async function getAllProceduresGeneral(
  page?: number | undefined,
  isActive?: boolean | undefined,
  startedFrom?: number | undefined,
  startedTo?: number | undefined,
  endedFrom?: number | undefined,
  endedTo?: number | undefined,
  userId?: number | undefined,
  hospitalId?: number | undefined,
  departmentId?: number | undefined,
  hospitalRoomId?: number | undefined,
  hospitalBedId?: number | undefined,
  idropperId?: number | undefined) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getAllProceduresDB(page, isActive, startedFrom, startedTo, endedFrom, endedTo, userId, hospitalId, departmentId, hospitalRoomId, hospitalBedId, idropperId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getAllProceduresDB__chief(page, isActive, startedFrom, startedTo, endedFrom, endedTo, userId, hospitalId, departmentId, hospitalRoomId, hospitalBedId, idropperId);
  } catch (err) {
    throw new Error(`components/procedure/procedureMainComp: getAllProceduresGeneral  => ${err}`);
  }
}

// Функция фильтрует массив процедур относительно фильтр-данных
async function filteredProcedures(filterData: {
  drugName: string | undefined,
  patientName: string | undefined,
  responsibleName: string | undefined,
  dateFrom: number | undefined,
  dateTo: number | undefined,
  isActive: boolean
}) {
  try {
    page.value = 1;
    isLoadingData.value = true;
    // Если фильтр-данные пусты, то возвращаем список процедур в исходное состояние
    if (
      filterData.drugName === undefined &&
      filterData.patientName === undefined &&
      filterData.responsibleName === undefined &&
      filterData.dateFrom === undefined &&
      filterData.dateTo === undefined &&
      filterData.isActive
    ) {
      // Убираем сообщения о пустом массиве процедур
      causeEmptyMsg.value = causeList.value[0];
      isNotProcedures.value = false;
      isActive.value = true;
      if (dataStore.employees.length) {
        procedures.value = dataStore.procedures;
      } else {
        procedures.value = await getAllProceduresGeneral(page.value, filterData.isActive);
      }
      return;
    } else {
      // Если данные для фильтрации были указаны то получаем весь список процедур в системе и фильтруем его по фильтр данным
      let filteredDataList = await getAllProceduresGeneral(undefined, filterData.isActive);

      // Фильтрация по Имени Пациента  (patientName)
      if (filterData.patientName) {
        filteredDataList = filteredDataList.filter((procedure: ProcedureClient) => {
          // фильтрация по имени Пациента
          if (filterData.patientName) {
            return !!procedure.patientName?.toLocaleLowerCase()?.includes(filterData.patientName.toLocaleLowerCase());
          }
          return true;
        });
        if (filteredDataList.length === 0) {
          isNotProcedures.value = true;
          causeEmptyMsg.value = causeList.value[0]; // Запись об ошибке чтобы вывести ее на экран
        } else {
          isNotProcedures.value = false;
        }
      }
      // Фильтрация по Названию препарата  (drugName)
      if (filterData.drugName) {
        // Если до этого в фильтр панель мы вводили еще что-либо
        if (filteredDataList.length > 0) {
          filteredDataList = filteredDataList.filter((procedure: ProcedureClient) => {
            // фильтрация по drugName
            if (filterData.drugName) {
              return !!procedure.drugName?.toLocaleLowerCase()?.includes(filterData.drugName.toLocaleLowerCase());
            }
            return true;
          });
        }
        // Если до этого в фильтр панель ничего введено не было
        else {
          filteredDataList = dataStore.procedures.filter((procedure: ProcedureClient) => {
            // фильтрация по drugName
            if (filterData.drugName) {
              return !!procedure.drugName?.toLocaleLowerCase()?.includes(filterData.drugName.toLocaleLowerCase());
            }
            return true;
          });
        }
        if (filteredDataList.length === 0) {
          isNotProcedures.value = true;
          causeEmptyMsg.value = causeList.value[0];
        } else {
          isNotProcedures.value = false;
          causeEmptyMsg.value = causeList.value[0];
        }
      }

      // Фильтрация по Имени Управляющего  (responsibleName)
      if (filterData.responsibleName) {
        // Если до этого в фильтр панель мы вводили еще что-либо
        if (filteredDataList.length > 0) {
          filteredDataList = filteredDataList.filter((procedure: ProcedureClient) => {
            // фильтрация по responsibleName
            if (filterData.responsibleName) {
              return !!procedure.user.fullName?.toLocaleLowerCase()?.includes(filterData.responsibleName.toLocaleLowerCase());
            }
            return true;
          });
        }
        // Если до этого в фильтр панель ничего введено не было
        else {
          filteredDataList = dataStore.procedures.filter((procedure: ProcedureClient) => {
            // фильтрация по responsibleName
            if (filterData.responsibleName) {
              return !!procedure.user.fullName?.toLocaleLowerCase()?.includes(filterData.responsibleName.toLocaleLowerCase());
            }
            return true;
          });
        }
        if (filteredDataList.length === 0) {
          isNotProcedures.value = true;
          causeEmptyMsg.value = causeList.value[0];
        } else {
          isNotProcedures.value = false;
          causeEmptyMsg.value = causeList.value[0];
        }
      }

      // Фильтрация по Дате Начала процедуры  (dateFrom)
      if (filterData.dateFrom) {
        const formattedTimeStamp = Math.round((filterData.dateFrom) / 1000);
        dateFrom.value = formattedTimeStamp;

        // Если до этого в фильтр панель мы вводили еще что-либо
        if (filteredDataList.length > 0) {
          filteredDataList = filteredDataList.filter((procedure: ProcedureClient) => {
            // фильтрация по responsibleName
            if (procedure.started) {
              return procedure.started > formattedTimeStamp;
            }
            return true;
          });
        }
        // Если до этого в фильтр панель ничего введено не было
        else {
          filteredDataList = dataStore.procedures.filter((procedure: ProcedureClient) => {
            // фильтрация по responsibleName
            if (procedure.started) {
              return procedure.started >= formattedTimeStamp;
            }
            return true;
          });
        }
        if (filteredDataList.length === 0) {
          isNotProcedures.value = true;
          causeEmptyMsg.value = causeList.value[0];
        } else {
          isNotProcedures.value = false;
          causeEmptyMsg.value = causeList.value[0];
        }
      }

      // Фильтрация по Дате Конца процедуры  (dateTo)
      if (filterData.dateTo) {
        const formattedTimeStamp = Math.round((filterData.dateTo) / 1000);
        dateTo.value = formattedTimeStamp;

        // Если до этого в фильтр панель мы вводили еще что-либо
        if (filteredDataList.length > 0) {
          filteredDataList = filteredDataList.filter((procedure: ProcedureClient) => {
            // фильтрация по responsibleName
            if (procedure.ended) {
              return procedure.ended <= formattedTimeStamp;
            }
            return true;
          });
        }
        // Если до этого в фильтр панель ничего введено не было
        else {
          filteredDataList = dataStore.procedures.filter((procedure: ProcedureClient) => {
            // фильтрация по responsibleName
            if (procedure.ended) {
              return procedure.ended <= formattedTimeStamp;
            }
            return true;
          });
        }
        if (filteredDataList.length === 0) {
          isNotProcedures.value = true;
          causeEmptyMsg.value = causeList.value[0];
        } else {
          isNotProcedures.value = false;
          causeEmptyMsg.value = causeList.value[0];
        }
      }

      // Фильтрация по isActive
      if (!filterData.isActive) {
        // Если фильтры по дате были выставлены то получаем с сервера массив с этими фильтрами
        if (dateFrom.value !== undefined || dateTo.value !== undefined) {
          return procedures.value = await getAllProceduresGeneral(undefined, filterData.isActive, dateFrom.value, undefined, undefined, dateTo.value);
        }
        // Если до этого в фильтр панель мы вводили еще что-либо
        if (filteredDataList.length > 0) {

          filteredDataList = filteredDataList.filter((procedure: ProcedureClient) => {
            // фильтрация по dateFrom
            if (filterData.dateFrom && procedure.started) {
              if (procedure.ended !== null) {
                return true;
              } else return false;
            }
            return true;
          });
        }
        // Если до этого в фильтр панель ничего введено не было
        else {
          filteredDataList = dataStore.procedures.filter((procedure: ProcedureClient) => {
            // фильтрация по dateFrom
            if (filterData.dateFrom && procedure.started) {
              return procedure.ended !== null;
            }
            return true;
          });
        }
        isActive.value = false;
      } else isActive.value = true;

      procedures.value = filteredDataList;
    }
  } catch (err) {
    throw new Error(`components/procedure/procedureMainComp: filteredProcedures  => ${err}`);
  } finally {
    isLoadingData.value = false;
  }
}


// ===========================   LYFECYCLE HOOKS   ==========================
// Получение массива Процедур с БД
onMounted(async () => {
  try {
    isLoadingData.value = true;
    // Если ранее не был получен список больниц, то получаем его с сервера
    if (dataStore.procedures.length <= 0) {
      dataStore.procedures = await getAllProceduresGeneral(page.value, isActive.value);
      procedures.value = dataStore.procedures;
    } else {
      // Если в dataStore есть массив больниц, то данные не запрашиваются у сервера
      procedures.value = dataStore.procedures;
    }
  } catch (err) {
    throw new Error(`components/procedure/procedureMainComp: onMounted  => ${err}`);
  } finally {
    isLoadingData.value = false;
  }
});
</script>

<style scoped>
.procedure-main {
  width: 100%;
  height: 100%;
  padding: 0 1rem 0 4rem;
}

.procedure-main__wrapper {
  width: 100%;
  height: 107%;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: var(--bg-color-white);
}
</style>