<template>
    <div v-show="isShowNote" class="error-note">
        <!-- Сообщение ошибки -->
        <h2 class="error-note__title">
            <slot></slot>
        </h2>
        <v-icon class="ml-2" icon="mdi-alert-circle-outline"></v-icon>
    </div>
</template>

<script setup lang="ts">
import useMainStore from '../../store/mainStore';
import { ref, watch, defineEmits } from 'vue';
import gsap from 'gsap';
const store = useMainStore();

const emit = defineEmits<{
    complete: [],
}>();

const isShowNote = ref(false);

watch(() => store.isErrorOperation, (isActive) => {
    if(isActive === true) {
        // Анимированное появление ошибки
        isShowNote.value = true;
        gsap.to('.error-note', { duration: 0.3, right: '1.5rem' });
        
    } else {
        // Скрытие ошибки
        gsap.to('.error-note', { duration: 0.3, right: '-40rem' })
            .then(() => {
                isShowNote.value = false
                emit('complete');
            });
    }
});

</script>

<style scoped>
.error-note {
    position: fixed !important;
    right: -40rem;
    top: 10%;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    width: max-content;
    height: max-content;
    padding: 0.3rem 2rem;
    border-radius: 0.2rem;
    backdrop-filter: blur(6px);
    font-weight: normal;
    color: var(--color-note-err);
    user-select: none;
    background-color: var(--bg-color-note-err);
    z-index: 9999;
}
</style>