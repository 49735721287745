import { RouteRecordRaw } from 'vue-router';
import MainView from '../views/MainView.vue';
import MyHospitalView from '../views/MyHospitalView.vue';
import NotFound404View from '../views/NotFound404View.vue';
import ProfileView from '../views/ProfileView.vue';
import hospitalsMainComp from '../components/hospitals/hospitalsMainComp.vue';
import departmentsMainComp from '../components/departments/departmentsMainComp.vue';
import hospitalRoomsMainComp from '../components/hospitalRooms/hospitalRoomsMainComp.vue';
import hospitalBedsMainComp from '../components/hospitalBeds/hospitalBedsMainComp.vue';
import droppersMainComp from '../components/droppers/droppersMainComp.vue';
import employeesComp from '../components/employees/employeesComp.vue';
import procedureMainComp from '../components/procedure/procedureMainComp.vue';

export default [
    {
        path: '/main/',
        name: 'main',
        component: MainView,
        redirect: { name: 'profile' },
        children: [
            // ПРОФИЛЬ
            {
                path: 'profile',
                name: 'profile',
                component: ProfileView,
                meta: {
                    requireAuth: true,
                }
            },
            // БОЛЬНИЦЫ
            {
                path: 'hospitals',
                name: 'hospitals',
                component: hospitalsMainComp,
                meta: {
                    title: 'больницы',
                    listItemTitle: 'Больница',
                    requireAuth: true,
                },
            },
            // МОЯ БОЛЬНИЦА
            {
                path: 'my-hospital',
                name: 'myHospital',
                component: MyHospitalView,
                meta: {
                    requireAuth: true,
                },
            },
            // ОТДЕЛЕНИЯ
            {
                path: 'departments',
                name: 'departments',
                component: departmentsMainComp,
                meta: {
                    requireAuth: true,
                }
            },
            // ПАЛАТЫ
            {
                path: 'hospital-rooms',
                name: 'hospitalRooms',
                component: hospitalRoomsMainComp,
                meta: {
                    requireAuth: true,
                }
            },
            // КОЙКИ
            {
                path: 'hospital-beds',
                name: 'hospitalBeds',
                component: hospitalBedsMainComp,
                meta: {
                    requireAuth: true,
                }
            },
            // УСТРОЙСТВА
            {
                path: 'droppers',
                name: 'droppers',
                component: droppersMainComp,
                meta: {
                    requireAuth: true,
                }
            },
            // СОТРУДНИКИ
            {
                path: 'employees',
                name: 'employees',
                component: employeesComp,
                meta: {
                    requireAuth: true,
                }
            },
            // ПРОЦЕДУРЫ
            {
                path: 'procedures',
                name: 'procedures',
                component: procedureMainComp,
                meta: {
                    requireAuth: true,
                }
            },
        ],
        
    },
    // not found 404
    {
        path: '/:notMatched(.*)*',
        name: 'notFound',
        component: NotFound404View,
    }
] as Array<RouteRecordRaw>


