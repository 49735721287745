<template>
    <!-- Главный Блок для взаимодействия с Палатами -->
    <div class="hospital-rooms-main">
        <div class="hospital-rooms-main__wrapper">
            <!-- Список Палат -->
            <hospitalRoomsListComp 
            :hospital-rooms="hospitalRooms"
            :is-load-hospital-rooms="isLoadHospitalRooms"
            :isDeleted="isDeleted"
            :is-active-department="isActiveDepartment"
            @load-hospital-room="(hospitalRoom: HospitalRoomClient) => appendHospitalRoom(hospitalRoom)"
            @update-hospital-room="(hospitalRoom: HospitalRoomClient) => updateHospitalRoom(hospitalRoom)"
            @hospital-room-filter="(isDeleted: boolean) => filteredHospitalRooms(isDeleted)"
            @disableHospitalRoom="(hospitalRoomId: number) => disableHospitalRoom(hospitalRoomId)"
            @activeHospitalRoom="(hospitalRoomId: number) => activeHospitalRoom(hospitalRoomId)"
            @pagination-up="uploadNewPageItems"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import {onBeforeUnmount, onMounted, ref, watch} from 'vue';
// COMPONENTS
import hospitalRoomsListComp from './hospitalRoomsListComp.vue';
// API
import { getAllHospitalRoomsDB, getHospitalRoomByDepartmentIdDB } from '../../api/superuser/hospitalRoomsApi';
import { getAllHospitalRoomsDB__chief, getHospitalRoomByDepartmentIdDB__chief } from '../../api/hospitalChief/hospitalRoomsApi';
import { getDepartmentById } from '../../api/superuser/departmentsApi';
import { getDepartmentById__chief } from '../../api/hospitalChief/departmentsApi';
// TYPES
import {ArrayHospitalRoomClient, HospitalRoomClient} from '../../types/hospitalRoomType';
// STORE
import useMainStore from '../../store/mainStore';
import useDataStore from '../../store/dataStore';
import useAuthStore from '../../store/authStore';
import {useRoute} from 'vue-router';

const route = useRoute();
const mainStore = useMainStore();
const dataStore = useDataStore();
const authStore = useAuthStore();

// ===================  DATA  ===========================
// Полученный с БД массив данных
const hospitalRooms = ref<ArrayHospitalRoomClient>([]);
const isLoadHospitalRooms = ref<boolean>(false);
const isSelectedDepartment = ref<boolean>(false);
const selectedHospitalId = ref<null | number>(null);
const selectedDepartmentId = ref<null | number>(null);
const isActiveDepartment = ref<boolean>(true);
const isDeleted = ref<boolean>(false);
const page = ref<number>(1);


// ================================  METHODS  =================================
// Поулчает все палаты (для Управляющего и Суперпользователя)
async function getAllHositalRooms(isDeleted?: boolean, page?: number, departmentId?: number, hospitalId?: number) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getAllHospitalRoomsDB(isDeleted, page, departmentId, hospitalId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getAllHospitalRoomsDB__chief(isDeleted, page);
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsMainComp: getAllHositalRooms  => ${err}`);
  }
}

// Получение отделения по ID
async function getDepartmentByIdGeneral(departmentId: number) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getDepartmentById(departmentId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getDepartmentById__chief(departmentId);
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsMainComp: getDepartmentByIdGeneral  => ${err}`);
  }
}

// Получает все палаты выбранного отделения (для Управляющего и Суперпользователя)
async function getHospitalRoomByDepartmentId(departmentId: number, hospitalId?: number, isDeleted?: boolean, page?: number) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getHospitalRoomByDepartmentIdDB(departmentId, hospitalId, isDeleted, page);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getHospitalRoomByDepartmentIdDB__chief(departmentId, isDeleted, page);

  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsMainComp: getHospitalRoomByDepartmentId  => ${err}`);
  }
}

// Отрабатывает при создании новой палаты
function appendHospitalRoom(hospitalRoom: HospitalRoomClient) {
  try {
    dataStore.hospitalRooms.push(hospitalRoom);
    hospitalRooms.value = dataStore.hospitalRooms;
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsMainComp: appendHospitalRoom  => ${err}`);
  }
}

// Отрабатывает когда происходит обновление данных палаты
function updateHospitalRoom(hospitalRoomUpdated: HospitalRoomClient) {
  try {
    dataStore.hospitalRooms = dataStore.hospitalRooms.map((hospitalRoom: HospitalRoomClient) => {
      if (hospitalRoomUpdated.id === hospitalRoom.id) {
        hospitalRoom = hospitalRoomUpdated;
        return hospitalRoomUpdated;
      }
      return hospitalRoom;
    });
    hospitalRooms.value = dataStore.hospitalRooms;
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsMainComp: updateHospitalRoom  => ${err}`);

  }
}

// Подгрузка новых данных по пагинации
async function uploadNewPageItems() {
  try {
    // Проверка, если на сервере есть следующая страница с данными то выполнять подгрузку этой страницы
    if (mainStore.isHospitalRoomsPaginationHasNext === true && hospitalRooms.value.length >= 30) {
      page.value += 1;
      const hospitalRoomsNextPage: any = await getAllHositalRooms(isDeleted.value, page.value, undefined);
      // Добавляем в уже существующий массив элементов новые элементы пришедшие с другой страницы пагинации
      dataStore.hospitalRooms = dataStore.hospitalRooms.concat(hospitalRoomsNextPage);
      hospitalRooms.value = dataStore.hospitalRooms;
    }
  } catch (err) {
    throw new Error(`components/employees/employeesComp: uploadNewPageItems  => ${err}`);
  }
}

// Функция фильтрует массив Палат относительно фильтр-данных
async function filteredHospitalRooms(isDeactivated: boolean) {
  // Возвращаем переменные для пагинации в исходное состояние
  isLoadHospitalRooms.value = true;
  isDeleted.value = false;
  page.value = 1;
  try {
    // Если выбрано отделение в query-параметрах то получаем все палаты по нему;
    if (isSelectedDepartment.value && selectedDepartmentId.value) {
        hospitalRooms.value = await getAllHositalRooms(isDeactivated, page.value, selectedDepartmentId.value, selectedHospitalId.value);
        isDeleted.value = isDeactivated;
    } else {
          isDeleted.value = isDeactivated;
        if (isDeactivated) {
        if (dataStore.hospitalRoomsDisabled.length <= 0) {
            dataStore.hospitalRoomsDisabled = await getAllHositalRooms(isDeactivated, page.value, undefined);
            hospitalRooms.value = dataStore.hospitalRoomsDisabled;
        } else {
            hospitalRooms.value = dataStore.hospitalRoomsDisabled;
        }
        } else {
        dataStore.hospitalRooms = await getAllHositalRooms(false, page.value, undefined);
        hospitalRooms.value = dataStore.hospitalRooms;
        }
    }
  } catch (err) {
    throw new Error(`components/hospitalRooms/hospitalRoomsMainComp: filteredHospitalRooms  => ${err}`);
  } finally {
    isLoadHospitalRooms.value = false;
  }
}

// Нужна для того чтобы в реал-тайме убрать активируемую палату со списка
async function activeHospitalRoom(hospitalRoomId: number) {
  try {
    // Получаем массив dataStore.hospitals с сервера если его нет
    if (!dataStore.hospitals.length) {
      dataStore.hospitalRooms = await getAllHositalRooms(false);
      hospitalRooms.value = dataStore.hospitalRooms;
    }
    // Фильтруем массив убирая с него деактивируемую больницу
    dataStore.hospitals = dataStore.hospitals.filter((hospital) => {
      return hospital.id !== hospitalRoomId;
    });
    hospitalRooms.value = dataStore.hospitalRooms;
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsMainComp: activeHospitalRoom  => ${err}`);
  }
}

// Нужна для того чтобы в реал-тайме убрать деактивируемую палату со списка
async function disableHospitalRoom(hospitalRoomId: number) {
  try {
    // Получаем массив dataStore.hospitalRooms с сервера если его нет
    if (!dataStore.hospitalRooms.length) {
      dataStore.hospitalRooms = await getAllHositalRooms(false);
      hospitalRooms.value = dataStore.hospitalRooms;
    }
    // Фильтруем массив убирая с него деактивируемую больницу
    dataStore.hospitalRooms = dataStore.hospitalRooms.filter((hospitalRoom: HospitalRoomClient) => {
      return hospitalRoom.id !== hospitalRoomId;
    });
    hospitalRooms.value = dataStore.hospitalRooms;
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsMainComp: disableHospital  => ${err}`);
  }
}

// ===================  WATCH  ===========================
// Отслеживает удаление query-параметра department в случае если мы переходим со страницы выбранных палат конкретного отделения 
// на список всех палат
watch(() => route.query['department'], async (newValue) => {
  if (newValue === null) {
    try {
      isLoadHospitalRooms.value = true;
      dataStore.hospitalRooms.length = 0;
      hospitalRooms.value.length = 0;
      // Получаем массив палат
      dataStore.hospitalRooms = await getAllHositalRooms();
      hospitalRooms.value = dataStore.hospitalRooms;
    } catch (err) {
      throw new Error(`components/hospitalRooms/hospitalRoomsMainComp: watch[fetch hospitalRooms]  => ${err}`);
    } finally {
      isLoadHospitalRooms.value = false;
    }
  }
});


// ===================  LIFECYCLE HOOKS  ===========================
// Получение массива ПАЛАТ с БД
onMounted(async () => {
    // Получение списка палат если есть query-параметр (department) и (hospital) открытого отделения
    try {
        isLoadHospitalRooms.value = true;
        const departmentId = route.query['department'];
        if(departmentId && +departmentId > 0) {
            const hospitalId = route.query['hospital'];
            isSelectedDepartment.value = true;
            selectedDepartmentId.value = +departmentId;
            if(hospitalId) {
                dataStore.hospitalRooms = await getHospitalRoomByDepartmentId(+departmentId, +hospitalId, false, page.value);
                hospitalRooms.value = dataStore.hospitalRooms;
            }
            // Получаем выбранное в query парметр отделение для того чтобы получить неактивные палаты, если это отделение не активно
            const currentDepartment = await getDepartmentByIdGeneral(+departmentId);
            if(currentDepartment.hospital.deleted || currentDepartment.deleted) {
                isActiveDepartment.value = false;
            }
            return;
        } else {
            // Получение списка палат
            dataStore.hospitalRooms = await getAllHositalRooms(false, page.value);
            hospitalRooms.value = dataStore.hospitalRooms;
        }
    } catch (err) {
        throw new Error(`components/hospitalRooms/hospitalRoomsMainComp: onMounted[fetch hospitalRooms]  => ${err}`);
    } finally {
        isLoadHospitalRooms.value = false;
    }
});

// При размонтировании компонента происходит очистка массива dataStore если при монтировании этого компонента было выбрано отделение и был query-параметр department
// Нужно для того чтобы по новой подгрузить актуальные данные с сервера 
onBeforeUnmount(() => {
  if (isSelectedDepartment.value === true) {
    dataStore.hospitalRooms.length = 0;
    hospitalRooms.value.length = 0;
  }
})

</script>

<style scoped>
.hospital-rooms-main {
  width: 100%;
  height: 100%;
  padding: 0 1rem 0 4rem;
}

.hospital-rooms-main__wrapper {
  width: 100%;
  height: 107%;
  margin-top: 1rem;
  background-color: var(--bg-color-white);
}
</style>