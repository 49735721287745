<template>
  <div class="profile-main">
    <v-card class="dialog__wrapper">

      <success-note-comp></success-note-comp>
      <error-note-comp>
        {{ errorMsg }}
      </error-note-comp>

      <!-- Крутилка загрузки -->
      <span class="progress-circular" v-if="isShowLoadingData">
        <v-progress-circular
          indeterminate
          bg-color="var(--bg-color-white)"
          color="var(--bg-color-default)"
          :size="60"
          :width="6"
        ></v-progress-circular>
      </span>
      <form class="dialog__form">

        <!-- Блок с аватаркой -->
        <div class="dialog__form--preview-block">
          <avatarInputComp
            :avatar="avatar"
            :selectedImage="selectedImage"
            :is-show-upload="true"
            @select-image="(image: File | undefined, blobImage: string | null) => handlerSelectImage(image, blobImage)"
            @resetSelectedImage="resetAvatar"
          />
          <h1 class="preview-username">{{ fullName }}</h1>
        </div>

        <!-- Общие данные -->
        <div class="dialog__form--general-data">
          <v-card-title class="data-title mb-2">Общие данные</v-card-title>
          <div class="data-body">
            <!-- Фамилия -->
            <div class="data-body__item">
              <v-card-subtitle>Фамилия</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model.trim="lastname"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
                :placeholder="'Иванов'"
                @update:model-value="lastnameErrorMsg = ''"
                :error-messages="lastnameErrorMsg"
              ></v-text-field>
            </div>

            <!-- Имя -->
            <div class="data-body__item">
              <v-card-subtitle>Имя</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model.trim="firstname"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
                :placeholder="'Иван'"
                @update:model-value="firstnameErrorMsg = ''"
                :error-messages="firstnameErrorMsg"
              ></v-text-field>
            </div>


            <!-- Отчество -->
            <div class="data-body__item">
              <v-card-subtitle>Отчество</v-card-subtitle>
              <v-text-field
                class="general-data__input"
                bg-color="var(--bg-color-white)"
                v-model.trim="patronymic"
                color="var(--color-default)"
                rounded="xs"
                :density="'compact'"
                variant="outlined"
                :placeholder="'Иванович'"
                hide-details
              ></v-text-field>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Учетные данные -->
        <div class="dialog__form--credentials-data">
          <v-card-title class="data-title mb-2">Учетные данные</v-card-title>

          <!-- Email -->
          <v-card-subtitle>E-mail</v-card-subtitle>
          <v-text-field
            class="general-data__input"
            bg-color="var(--bg-color-white)"
            v-model="email"
            color="var(--color-default)"
            rounded="xs"
            :density="'compact'"
            :type="'email'"
            variant="outlined"
            :placeholder="'ivanivanov@ex.com'"
            @update:model-value="emailErrorMsg = ''"
            :rules="emailRulesForUpdated"
            :error-messages="emailErrorMsg"
          ></v-text-field>

        </div>
      </form>

      <!-- Блок кнопок -->
      <div class="dialog__actions">
        <!-- Сохранить -->
        <v-btn
          class="mr-6"
          variant="flat"
          color="var(--bg-color-default)"
          style="color: white; font-weight: 300; font-size: 12px;"
          :loading="isLoadingConfirmData"
          @click="confirmToUpdateEmployee"
        >
          Сохранить
        </v-btn>
        <!-- Сбросить изменения -->
        <v-btn
          variant="tonal"
          style="margin-right: auto; font-weight: 400; font-size: 12px;"
          color="var(--bg-color-default)"
          @click="resetChanges"
        >
          Сбросить изменения
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import {UserClient} from '../../types/userType';
import {ProfileChanged, ProfileChangedServer} from '../../types/profileType';
import avatarInputComp from '../general/avatarInputComp.vue';
import { updateProfileGeneralData, updateProfileAvatar } from '../../api/superuser/profileApi';
import { updateProfileAvatar__chief, updateProfileGeneralData__chief } from '../../api/hospitalChief/profileApi';
import useValidationUserFields from '../../composable/validationUserFields'
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';
import {getInfoProfileChief} from '../../api/hospitalChief/profileApi';

import {ref, onMounted} from 'vue';

const store = useMainStore();
const authStore = useAuthStore();

// ============================  DATA  ============================
const errorMsg = ref<string | null>(null);
const selectedImage = ref<File | null | undefined>(null);
const isShowLoadingData = ref<boolean>(false);
const isLoadingConfirmData = ref<boolean>(false);
const avatar = ref<null | string>(null);
const avatarImage = ref<null | string>(null);
const id = ref<null | number>(0);
const fullName = ref<string | null>(null);
const firstname = ref<string | null>(null);
const lastname = ref<string | null>(null);
const patronymic = ref<string | null>(null);
const email = ref<string>('');
const empolyeeFormComp = ref({
  firstname: null as null | string,
  lastname: null as null | string,
  patronymic: null as null | string,
  email: null as null | string,
});

const {
  firstnameErrorMsg,
  lastnameErrorMsg,
  emailErrorMsg,
  emailRulesForUpdated,
} = useValidationUserFields();

// ============================  METHODS  ============================

// Обработчик выбора аватарки
function handlerSelectImage(image: File | undefined, blobImage: string | null) {
  try {
    avatar.value = blobImage;
    selectedImage.value = image;
  } catch (err) {
    throw new Error(`components/profile/profileMainComp.vue: handlerSelectImage  => ${err}`);
  }
}

// API Обновление ОБЩИХ данных
async function updateProfileGeneralDataGeneral(firstName?: string | undefined, lastName?: string | undefined, patronymic?: string | undefined, email?: string | undefined) {
    try {
        // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
        if (authStore.isSuperUser === true) {
            return await updateProfileGeneralData(firstName, lastName, patronymic, email);
        }
        // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
        return await updateProfileGeneralData__chief(firstName, lastName, patronymic, email);
    } catch (err) {
        throw new Error(`components/profile/profileMainComp.vue: updateProfileGeneralDataGeneral  => ${err}`);
    }
}

// API Обновление Автарки
async function updateProfileAvatarGeneral(avatar?: File | null | undefined) {
    try {
        // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
        if (authStore.isSuperUser === true) {
            return await updateProfileAvatar(avatar);
        }
        // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
        return await updateProfileAvatar__chief(avatar);
    } catch (err) {
        throw new Error(`components/profile/profileMainComp.vue: updateProfileAvatarGeneral  => ${err}`);
    }
}

// функция собирает ключи объекта с полученного массива entries функции compareObjectsFetchFields 
// И подготавливает объект к отправке на сервер. Собирает только те поля которые фактически были изменены
function bundleDataForSend(entries: Array<any>): ProfileChanged {
  try {
    const changedFields: object | any = {};
    let firstnameChanged: string | null = null;
    let lastnameChanged: string | null = null;
    let patronymicChanged: string | null = null;
    // В цикле собираем объект тек ключей, чьи значения действительно были изменены,
    // чтобы отправить этот объект на сервер для изменения пользователя
    entries.forEach((entry) => {
      // собираем fullName из полей ввода ФИО. Если какое-то поле было изменено, то мы добавялем его в состав fullName
      if (entry[0] === 'firstname' || entry[0] === 'lastname' || entry[0] === 'patronymic') {
        if (entry[0] === 'firstname') {
          firstnameChanged = entry[1];
          changedFields['firstName'] = entry[1];
        } else firstnameChanged = firstname.value;

        if (entry[0] === 'lastname') {
          lastnameChanged = entry[1];
          changedFields['lastName'] = entry[1];
        } else lastnameChanged = lastname.value;

        if (entry[0] === 'patronymic') {
          patronymicChanged = entry[1];
          changedFields['patronymic'] = entry[1];
        } else patronymicChanged = patronymic.value;
      } else {
        changedFields[entry[0]] = entry[1];
      }
    });
    // Сопоставление частей полного имени, если некоторые поля пустые то они не вставляются
    const readyFullName = [firstnameChanged, lastnameChanged, patronymicChanged].join(' ').trim();
    if (readyFullName.length) {
      changedFields.fullName = readyFullName;
    }
    return changedFields;
  } catch (err) {
    throw new Error(`components/profile/profileMainComp.vue: bundleDataForSend  => ${err}`);
  }
}

// Подтверждение обновления выбранного пользователя
async function confirmToUpdateEmployee() {
  try {
    // сравниваем два объекта на наличие изменения данных в полях ввода (Для исключения пустого запроса)
    const {entries, isCompare} = store.compareObjectsFetchFields(empolyeeFormComp.value, {
      firstname: firstname.value,
      lastname: lastname.value,
      patronymic: patronymic.value,
      email: email.value,
    });
    // Если два объекта отличаются то обрабатываем запрос к серверу на изменение данных
    if (isCompare === false) {
      // Если изменения были внесены в форму и валидация полей прошла все проверки, то выполняется запрос на обновление пользователя
      if (
        firstname.value?.length ||
        lastname.value?.length ||
        email.value?.length
      ) {
        try {
          isLoadingConfirmData.value = true;
          if (id.value) {
            const filedsForUpdated = bundleDataForSend(entries);
            const fieldsChangedSnake: ProfileChangedServer = store.camelToSnake(filedsForUpdated);
            if(fieldsChangedSnake.email) {
              const updatedEmployee = await updateProfileGeneralDataGeneral(
                    fieldsChangedSnake.first_name,
                    fieldsChangedSnake.last_name,
                    fieldsChangedSnake.patronymic,
                    fieldsChangedSnake.email
                );
                store.activeSuccessOperation(1200);
                // Обновляем объект пользователя в localStorage
                const token = store.getAccessToken();
                if (token) {
                    const encryptedUser = store.encryptObject(updatedEmployee, token);
                    localStorage.setItem('user', encryptedUser);
                }
                parsingObject(updatedEmployee);
                createCopyForm();
                window.location.reload();
            } else {
                store.activeErrorOperation(1200);
                errorMsg.value = 'Email не может быть пустым!';
            }
          }
        } catch (err) {
          throw new Error(`components/profile/profileMainComp.vue: запрос на обновление пользователя  => ${err}`);
        } finally {
          isLoadingConfirmData.value = false;
        }
      } else {
        store.activeErrorOperation(1200);
        errorMsg.value = 'Проверьте правильность введенных данных';
      }
    }
    // Если выбрана аватарка то отправляем ее на сервер
    if (selectedImage.value) {
      try {
        isLoadingConfirmData.value = true;
        await updateProfileAvatarGeneral(selectedImage.value);
        store.activeSuccessOperation(1200);
        selectedImage.value = null;
      } catch (err) {
        throw new Error(`components/profile/profileMainComp.vue: обновление автарки  => ${err}`);
      } finally {
        isLoadingConfirmData.value = false;
      }
    }
  } catch (err) {
    throw new Error(`components/profile/profileMainComp.vue: confirmToUpdateHospital  => ${err}`)
  }
}

// Функция разбивает ключи получаемого объекта по реактивным переменным для отображаения их в полях ввода
function parsingObject(employee: UserClient) {
  try {
    id.value = employee.id;
    // ФИО пользователя
    if (employee.fullName) {
      fullName.value = employee.fullName;
      firstname.value = employee.firstName;
      lastname.value = employee.lastName;
      patronymic.value = employee.patronymic;
      if (employee.avatar) {
        avatarImage.value = employee.avatar
        avatar.value = employee.avatar;
      }
    }
    if (employee.email) email.value = employee.email;
  } catch (err) {
    throw new Error(`components/profile/profileMainComp.vue: parsingObject  => ${err}`);
  }
}

// Функция применяется для создания копии объекта формы
function createCopyForm() {
  try {
    empolyeeFormComp.value = {
      firstname: firstname.value,
      lastname: lastname.value,
      patronymic: patronymic.value,
      email: email.value,
    }
  } catch (err) {
    throw new Error(`components/profile/profileMainComp.vue: createCopyForm  => ${err}`);
  }
}

// Сбросить изменения в форме 
function resetChanges() {
  try {

    const isCompare = store.compareObjects(empolyeeFormComp.value, {
      firstname: firstname.value,
      lastname: lastname.value,
      patronymic: patronymic.value,
      email: email.value,
    });
    if (isCompare === false) {
      firstname.value = empolyeeFormComp.value.firstname;
      lastname.value = empolyeeFormComp.value.lastname;
      patronymic.value = empolyeeFormComp.value.patronymic;
      if (empolyeeFormComp.value.email) email.value = empolyeeFormComp.value.email;
    }
  } catch (err) {
    throw new Error(`components/profile/profileMainComp.vue: resetChanges  => ${err}`)
  }
}

const resetAvatar = () => {
  selectedImage.value = null
  avatar.value = avatarImage.value
}

// ============================  LIFECYCLE HOOKS  ============================
onMounted(async () => {
  // Загружаем данные выбранного пользователя если query параметр select-user существует
  try {
    isShowLoadingData.value = true;
    const user = await getInfoProfileChief();
    parsingObject(user);
    // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
    createCopyForm();
  } catch (err) {
    throw new Error(`components/profile/profileMainComp.vue: onMounted[fetch user-data]  => ${err}`)
  } finally {
    isShowLoadingData.value = false;
  }
});

</script>

<style scoped>
.profile-main {
  position: absolute;
  right: 0;
  top: 8%;
  bottom: 0;
  left: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--bg-color-block);
  overflow: auto;
  font-family: var(--font);
  padding-top: 1rem;
}

.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
  z-index: 999;
}

.dialog__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 4rem;
  background-color: var(--bg-color-block);
}

.dialog__form {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-block);
}

.dialog__form--preview-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  padding: 1rem 0;
}

.preview-image {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.preview-username {
  font-size: 26px;
  font-weight: 700;
  margin-top: 1rem;
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 0 1.5rem 2rem 1.5rem;
  margin-bottom: 2rem;
  background-color: var(--bg-color-white);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.data-body {
  display: flex;
}

.data-body__item {
  width: 33%;
  flex-grow: 1;
}

.data-title {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 17px;
  text-align: left;
  width: max-content;
  padding: 0.4rem 0 !important;
}

.dialog__form--general-data {
  width: 80%;
  padding: 26px 1.5rem 0 1.5rem;
  background-color: var(--bg-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.general-data__input {
  margin: 1rem;
}

.dialog__form--credentials-data {
  width: 80%;
  padding: 0 1.5rem;
  /* border-top: 2px solid rgba(128, 128, 128, 0.316); */
  background-color: var(--bg-color-white);
}
</style>