<template>
  <!-- Компонент для отрисовки Списка коек -->
  <div class="hospital-beds-list__container">

    <!-- уведомление об ошибке -->
    <error-note-comp>{{ errorMsg }}</error-note-comp>

    <!-- Уведомление об успехе -->
    <success-note-comp @complete="handlerCompleteAnimation"></success-note-comp>

    <!-- Заголовок -->
    <div class="hospital-beds-list__header">
      <v-icon
        @click="router.go(-1)"
        class="btn-back"
        icon="mdi-arrow-left"
        size="22"
      ></v-icon>
      <h1 class="hospital-beds-list__header--title">Койки</h1>
    </div>

    <!-- Диалоговое окно с данными о выбранном элементе -->
    <hopsitalBedsDialogComp
      v-model="isShowDialog"
      @error="(e) => handlerError(e)"
      @success="handlerSuccess"
      @close="handlerCloseDialogInfo"
      @load-hospital-bed="(hospitalBed: HospitalBedClient) => emit('loadHospitalBeds', hospitalBed)"
      @update-hospital-bed="(hospitalBed: HospitalBedClient) => emit('updateHospitalBed', hospitalBed)"
    />

    <!-- Диалоговое окно для создания новой койки -->
    <creationHospitalBedsComp
      v-model="isShowCreationDialog"
      @error="(e) => handlerError(e)"
      @success="handlerSuccess"
      @close="isShowCreationDialog = false"
      @append-hospital-bed="(hospitalBed: HospitalBedClient) => emit('loadHospitalBeds', hospitalBed)"
    />

    <!-- Диалоговое окно для подтверждения деактивации элемента -->
    <disableHospitalBedsDialogComp
      v-model="isShowDisabledDialog"
      @cancel="isShowDisabledDialog = false"
      @confirm="confirmDisabledHospitalBed"
      :is-loading="isLoadingDisabled"
      :title="`${disabledHospitalBedData.name} `"
      :subtitle="isDeleted ? 'активной' : 'неактивной'"
    />

    <!-- Диалоговое окно для подтверждения Активации элемента -->
    <activeHospitalBedsDialogComp
    :bed-name="activatedData.name"
    v-model="isShowActivateDialog"
    @confirm="confirmActiveHospitalBed"
    @cancel="isShowActivateDialog = false"
    :is-loading="isLoadingActivated"
    />

    <!-- Панель действий -->
    <div class="hospital-beds-list__actions-block">
      <v-btn
        style="color: var(--color-white); font-size: 0.8em; font-weight: bold;"
        color="var(--color-default)"
        rounded="large"
        size="large"
        @click="isShowCreationDialog = true"
      >
        Создать
      </v-btn>

      <!-- Фильтр панель -->
      <hospitalBedFilterPanelComp
      :is-active-hospital-room="isActiveHospitalRoom"
      :hospital-bed-names="hospitalBedsNameList"
      @select-status="(status: boolean) => filledFilterData(status, null)"
      @select-hospital-bed-name="(hospitalName: string | null) => filledFilterData(null, hospitalName)"
      />
    </div>

    <!-- Контент список -->
    <div class="hospital-beds-list__content-container" v-if="!props.isLoadHospitalBeds">

      <!-- Подсказка указывает на отсутствие элементов в списке -->
      <div class="is-not-items-hint" v-if="!props.hospitalBeds.length">
        <h1>Коек не найдено</h1>
      </div>

      <!-- Отрисовка элементов списка -->
      <v-table class="hospitals-list__wrapper" :density="'comfortable'" fixed-header>
        <thead>
        <tr>
          <th class="text-left th avatar-beds">
          </th>

          <th class="text-left th">
            Пациент
          </th>
          <th class="text-left th">
            Организация
          </th>
          <th class="text-left th">
            Отделение
          </th>
          <th class="text-left th">
            Палата
          </th>
          <th class="text-left th bed-name">
            Койка
          </th>
          <th class="text-left th">
            Статус
          </th>
          <th class="text-left th actions-th">
            Действия
          </th>
        </tr>
        </thead>
        <tbody>
        <tr class="row-hover" v-for="hospitalBed in props.hospitalBeds as ArrayHospitalBedClient" :key="hospitalBed.id">
          <!-- № Номер -->
          <td class="list-item">
            <v-icon icon="mdi-bed-outline" color="var(--color-default)"></v-icon>
          </td>

          <!-- Пациент -->
          <td class="list-item hospital-beds-name">{{
              `${hospitalBed.patientLastName} ${hospitalBed.patientFirstName} ${hospitalBed.patientPatronymic ?? ''}`
            }}
          </td>
          <!-- Больница -->
          <td class="list-item">{{ hospitalBed?.hospitalRoom?.department?.hospital?.name }}</td>
          <!-- Отделение -->
          <td class="list-item">{{ hospitalBed?.hospitalRoom?.department?.name }}</td>
          <!-- Палата -->
          <td class="list-item">{{ hospitalBed?.hospitalRoom?.name }}</td>
          <!-- Название -->
          <td class="list-item">{{ hospitalBed?.name }}</td>
          <!-- Палата -->
          <td class="list-item">{{ (!props.isDeleted) ? "Активен" : "Не активен" }}</td>
          <!-- ДЕЙСТВИЯ -->
          <td class="list-item actions-td">

            <!-- Редактировать -->
            <v-btn
              class="actions__btn"
              @click.stop="selectHospitalBed(hospitalBed?.id)"
              rounded="xs"
              icon="mdi-cog-outline"
              :color="'var(--color-gray)'"
              variant="text"
              :density="'comfortable'"
            >
            </v-btn>

            <!-- Удалить -->
            <v-btn
              class="actions__btn del"
              @click.stop="handlerStatusHospitalBed(hospitalBed.deleted, hospitalBed?.id, hospitalBed?.name)"
              rounded="xs"
              variant="text"
              :density="'comfortable'"
              icon="mdi-power"
              :color="(props.isDeleted)? 'green' : 'red'"
            >
            </v-btn>
          </td>
        </tr>
        <!-- Триггерный блок для пагинации -->
        <tr class="last-item">
          <td class="trigger-pagination">
            <v-progress-circular
              v-show="isShowLoadingNewPage"
              indeterminate
              bg-color="var(--bg-color-white)"
              color="var(--bg-color-default)"
              :size="20"
              :width="2"
            ></v-progress-circular>
          </td>
        </tr>
        </tbody>
      </v-table>
    </div>

    <!-- Крутилка загрузки -->
    <span class="progress-circular" v-else>
      <v-progress-circular
        indeterminate
        bg-color="var(--bg-color-white)"
        color="var(--bg-color-default)"
        :size="60"
        :width="6"
      ></v-progress-circular>
    </span>
  </div>
</template>

<script setup lang="ts">
// COMPONENTS
import hopsitalBedsDialogComp from './hopsitalBedsDialogComp.vue';
import creationHospitalBedsComp from './creationHospitalBedsComp.vue';
import disableHospitalBedsDialogComp from './disableHospitalBedsDialogComp.vue';
import activeHospitalBedsDialogComp from './activeHospitalBedsDialogComp.vue';
import hospitalBedFilterPanelComp from './hospitalBedFilterPanelComp.vue';
// STORE
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';
// TYPES
import {HospitalBedClient, ArrayHospitalBedClient} from '../../types/hospitalBedType';
// API
import {putStatusHospitalBedById} from "../../api/superuser/hospitalBedsApi";
import {putStatusHospitalBedById__chief} from '../../api/hospitalChief/hospitalBedsApi';
// VUE
import {ref, defineProps, onMounted, watch, defineEmits, nextTick, computed} from 'vue';
import {useRoute, useRouter} from 'vue-router';

const store = useMainStore();
const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

// ======================================   PROPS   ======================================
const props = defineProps<{
  hospitalBeds: Array<any>,
  isLoadHospitalBeds: boolean,
  isDeleted: boolean,
  isActiveHospitalRoom: boolean;
}>();

const emit = defineEmits<{
  loadHospitalBeds: [hospitalBed: HospitalBedClient];
  updateHospitalBed: [hospitalBed: HospitalBedClient];
  disableHospitalBed: [hospitalBedId: number];
  activateBed: [hospitalBedId: number];
  hospitalBedFilter: [filterData: { hospitalBedName: string | null | undefined, isDeleted: boolean }];
  paginationUp: [];
}>();

// ======================================   DATA   ================================
const errorMsg = ref('');
const isShowDialog = ref<boolean>(false);
const isShowCreationDialog = ref<boolean>(false);
const isShowDisabledDialog = ref<boolean>(false);
const isShowActivateDialog = ref<boolean>(false);
const isShowLoadingNewPage = ref<boolean>(false);
const isLoadingDisabled = ref<boolean>(false);
const isLoadingActivated = ref<boolean>(false);
const disabledHospitalBedData = ref({
  id: null as number | null,
  name: '',
});
const activatedData = ref({
    id: null as null | number,
    name: '',
});
const filterData = ref({
  hospitalBedName: undefined as string | undefined | null,
  isDeleted: false as boolean,
})
// Наблюдатель для триггера подгрузки страниц пагинации списка
const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (store.isHospitalBedsPaginationHasNext === true && props.hospitalBeds.length >= 30) {
        isShowLoadingNewPage.value = true;
      } else isShowLoadingNewPage.value = false;
      // Событие paginationUp для подгрузки данных следующей страницы пагинации
      emit('paginationUp');
    }
  });
});

// ======================================   METHODS   ==================================
// Заполняет объект фильтр данных
function filledFilterData(isDeleted: boolean | null, hospitalBedName: string | null) {
  try {
    // hospitalName
    if (hospitalBedName !== null) {
      if (hospitalBedName === '') {
        filterData.value.hospitalBedName = undefined;
      } else filterData.value.hospitalBedName = hospitalBedName;
    }
    if (isDeleted !== null) {
      filterData.value.isDeleted = isDeleted;
    }
    emit('hospitalBedFilter', filterData.value);
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsListComp: filledFilterData  => ${err}`);
  }
}

// функция для открытия окна информации палаты
function selectHospitalBed(hospitalBedId: number) {
  isShowDialog.value = true;
  store.toPushWithQuery({name: 'select-hospital-bed', value: hospitalBedId}, route, router);
}

// отображение уведомления об успехе какой-либо операции
function handlerSuccess() {
  store.activeSuccessOperation(1200);
}

// отображение уведомления об ошибке при выполнении какой-либо операции
function handlerError(error: string) {
  errorMsg.value = error;
  store.activeErrorOperation(3500);
}

// Обработчик выполнения анимации уведомления об успехе
function handlerCompleteAnimation() {
  if (isShowCreationDialog.value === true) {
    isShowCreationDialog.value = false;
    window.location.reload();
  }
  if (isShowDialog.value === true) {
    isShowDialog.value = false;
  }
  if (isShowDisabledDialog.value === true) {
    isShowDisabledDialog.value = false;
    isLoadingDisabled.value = false;
    window.location.reload();
  }
  if(isShowActivateDialog.value === true) {
    isLoadingActivated.value = false;
    isShowActivateDialog.value = false;  // Скрываем диалог активации палаты
    window.location.reload();
  }
}

// Обработчик открытия окна деактивации / активации Койки
function handlerStatusHospitalBed(isDeleted: number | null, hospitalId: number, name: string | null) {
  try {
    // Если нажимаем на не активную Койку, то открывается окно активации её
    if(isDeleted !== null) {
        activatedData.value.id = hospitalId;
        if(name) activatedData.value.name = name;
        isShowActivateDialog.value = true;
    }
    // Если нажимаем на активную Койку, то открывается окно её деактивации 
    else if(isDeleted === null) {
        disabledHospitalBedData.value.id = hospitalId;
        if(name) disabledHospitalBedData.value.name = name;
        isShowDisabledDialog.value = true;
    }
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsListComp: handlerStatusHospitalBed  => ${err}`);
  }
}

// Функция для отпарвки запроса на обновление статуса активности койки 
async function putStatusHospitalBedByIdGeneral(hospitalBedId: number, deleted: boolean) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await putStatusHospitalBedById(hospitalBedId, deleted);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await putStatusHospitalBedById__chief(hospitalBedId, deleted);
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsListComp.vue: putStatusHospitalBedByIdGeneral  => ${err}`);
  }
}

// Подтверждение деактивации Койки
async function confirmDisabledHospitalBed() {
  try {
    isLoadingDisabled.value = true;
    if (disabledHospitalBedData.value.id) {
        await putStatusHospitalBedByIdGeneral(disabledHospitalBedData.value.id, !props.isDeleted);
        emit('disableHospitalBed', disabledHospitalBedData.value.id);
        store.activeSuccessOperation(900);
    }
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsListComp.vue: confirmDisabledHospitalBed  => ${err}`);
  } finally {
    isLoadingDisabled.value = false;
  }
}

// Подтверждение активации Койки
async function confirmActiveHospitalBed() {
  try {
    isLoadingActivated.value = true;
    if (activatedData.value.id) {
      await putStatusHospitalBedByIdGeneral(activatedData.value.id, false);
      emit('activateBed', activatedData.value.id);
      store.activeSuccessOperation(900);
    }
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsListComp.vue: confirmActiveBed  => ${err}`);
  } finally {
    isLoadingActivated.value = false;
  }
}

// Обработчик закрытия окна информации о койки 
function handlerCloseDialogInfo() {
  try {
    store.toPopQuery('select-hospital-bed', route, router);
    isShowDialog.value = false;
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsListComp.vue: handlerCloseDialogInfo  => ${err}`)
  }
}

// ========================================   COMPUTED   ===================================

// Вычисляет список названий коек 
const hospitalBedsNameList = computed(() => {
  try {
    let listNames: Array<any> = []; 
    props.hospitalBeds.forEach((bed: HospitalBedClient) => {
      let name = bed.name;
      if(name?.trim() !== '' && !listNames.includes(name)) {
        listNames.push(name);
      }
    });
    return listNames;
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsListComp.vue: hospitalBedsNameList  => ${err}`);
  }
});

// ========================================   WATCH   ===================================
// Отслеживание достижения тригеррного блока для смены страницы пагинации
watch(() => props.hospitalBeds.length, async (newLength) => {
  if (newLength > 0) {
    await nextTick();
    // получение последнего элемента списка
    const triggerPagination = document.querySelector('.trigger-pagination') as HTMLDivElement;
    if (triggerPagination) {
      observer.observe(triggerPagination);  // запуск observer
    }
  }
});

// Удаляем query-параметр если диалоговое окно просмотра закрывается
watch(() => isShowDialog.value, (isShow) => {
  if (!isShow) {
    handlerCloseDialogInfo();
  }
})

// ========================================   LIFECYCLE HOOKS   ================================
onMounted(() => {
  // При монтировании списка коек проверям существует ли query-параметр select-hospital-bed
  try {
    const hospitalBedIdQuery = route.query['select-hospital-bed'];
    if (hospitalBedIdQuery) {
      if (typeof hospitalBedIdQuery === 'string' && +hospitalBedIdQuery > 0) {
        // Если query-параметр существует то открывается окно просмотра этой койки
        selectHospitalBed(+hospitalBedIdQuery);
      }
    }
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsListComp.vue: onMounted  => ${err}`);
  }
});

</script>

<style scoped>
.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
}

.row-hover {
  transition: background-color 0.4s ease;
  cursor: pointer;
}

.row-hover:hover {
  background-color: rgba(128, 128, 128, 0.2);
  transition: background-color 0.4s ease;
}

.hospital-beds-list__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--bg-color-white);
  overflow: hidden;
}

.is-not-items-hint {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--color-default);
  z-index: 25;
}

.hospital-beds-list__header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  background-color: var(--bg-color-default);
  color: var(--color-white);
  border-bottom: var(--border-thin);
  z-index: 20;
}

.btn-back {
  position: absolute;
  left: 1rem;
  cursor: pointer;
}

.btn-back:hover {
  color: rgba(255, 255, 255, 0.519);
}

.hospital-beds-list__header--title {
  font-family: var(--font);
  font-size: 1.25rem;
  font-weight: bold;
}

.hospital-beds-list__actions-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
}

.hospital-beds-list__content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 93%;
  top: 0;
  max-height: 100%;
}

.hospitals-list__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 5px;
  padding-bottom: 2.5rem;
  z-index: 10;
}

.avatar-beds {
  border-left: 1px solid rgba(128, 128, 128, 0.316);
}
.bed-name {
  width: 10% !important;
}

.th {
  backdrop-filter: blur(9px);
  font-weight: 100;
  border-top: 1px solid rgba(128, 128, 128, 0.316);
  font-size: 13px;
}

.hospital-beds-name {
  font-weight: 600;
}

.actions-th {
  width: 10%;
}

.actions-td {
  display: flex;
  align-items: center;
}

.actions__btn.del {
  margin-left: 5px;
}

.last-item {
  position: relative;
}

.trigger-pagination {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: rgba(0, 0, 0, 0);
}
</style>