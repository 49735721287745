// Харнилище для локального хранения подгружаемых с сервера данных 

import { defineStore } from "pinia";
import { ref } from 'vue';
// TYPES
import {ArrayUserClient, UserClient} from '../types/userType';
import { ArrayHospitalClient } from '../types/hospitalType';
import { ArrayDepartmentClient } from '../types/departmentType';
import { ArrayHospitalRoomClient } from '../types/hospitalRoomType';
import { ArrayHospitalBedClient } from '../types/hospitalBedType';
import { ArrayProcedureClient } from '../types/procedureType';
import { ArrayDropperClient } from "../types/droppersType";
// API
// import { getAllHospitalDB } from '../api/superuser/hospitalsApi';
// import {  } from '../api/hospitalChief/';

const useDataStore = defineStore('dataStore', () => {

    // ============================   STATE   ==========================
    // Общие данные полученные с БД
    // Employees
    const employees = ref<UserClient[]>([]);
    const employeesDisabled = ref<ArrayUserClient>([]);

    // Hospitals
    const hospitals = ref<ArrayHospitalClient>([]);
    const hospitalsDisabled = ref<ArrayHospitalClient>([]);

    // Departments
    const departments = ref<ArrayDepartmentClient>([]);
    const departmentsDisabled = ref<ArrayDepartmentClient>([]);

    // HospitalRooms
    const hospitalRooms = ref<ArrayHospitalRoomClient>([]);
    const hospitalRoomsDisabled = ref<ArrayHospitalRoomClient>([]);

    // Beds
    const hospitalBeds = ref<ArrayHospitalBedClient>([]);
    const hospitalBedsDisabled = ref<ArrayHospitalBedClient>([]);

    // Droppers (Устройства)
    const droppers = ref<ArrayDropperClient>([]);
    const droppersDisabled = ref<ArrayDropperClient>([]);

    // Procedures
    const procedures = ref<ArrayProcedureClient>([]);

    // ==========================   MUTATION   ========================



    // =========================  ACTIONS  ===========================
    // Получение всех больниц с сервера


    return {
        // State
        employees,
        hospitals,
        departments,
        hospitalRooms,
        hospitalBeds,
        droppers,
        procedures,
        
        employeesDisabled,
        hospitalsDisabled, 
        departmentsDisabled, 
        hospitalRoomsDisabled,
        droppersDisabled, 
        hospitalBedsDisabled,

        // Mutations


        // Actions
    }
});

export default useDataStore;