// Взаимодействие суперпользователя с Больницами 
import { HospitalClient, HospitalClientCreationServer } from '@/types/hospitalType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import axios from 'axios';


// Получение информации о своей больнице (по токену)
async function getInfoMyHospital_chief(): Promise<HospitalClient> {
    try {
        const response = await axios.get(hostname + `api/hospitals`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/myHospital/hospitalInfoApi: getInfoMyHospital  => ${err}`);
    }
}


// Изменить информацию о своей больнице
async function putHospitalById_chief(changedFields: HospitalClientCreationServer ): Promise<HospitalClient> {
    try {
        // Обновление сущности и получение её с сервера
        const response = await axios.put(hostname + `api/hospitals`, {
            ...changedFields,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/hospitalsApi: createNewHospitalDB  => ${err}`);
    }
}


export {
    getInfoMyHospital_chief,
    putHospitalById_chief,
}