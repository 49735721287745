import { ArrayHospitalClient, HospitalClient } from '../types/hospitalType';
import { getAllHospitalDB } from '@/api/superuser/hospitalsApi';
import { ref } from 'vue';


// Компонуемый файл для получения списка больниц и отображения их в выпадающем меню
function useLoadingHospitals() {

    // =================  DATA  ===================
    interface SelectedHoslital {
        name: string | null;
        address: string | null;
        id: number;
        deleted: number | null;
    }

    // Списки доступных элементов для селект-меню
    const hospitalList = ref<ArrayHospitalClient>([]);
    const selectedHospital = ref<SelectedHoslital | HospitalClient | null>(null);
    const selectedHospitalName = ref<string | null>(null);
    const isLoadingHospitalsFetch = ref<boolean>(true);


    // =================  METHODS  ===================

    // Функция получает массив больниц с БД для отображения в селект-меню
    async function loadHospitals() {
        try {
            hospitalList.value = await getAllHospitalDB(false, undefined);
        } catch (err) {
            throw new Error(`compsables/loadHospitalList.ts: loadHospitals => ${err}`);
        }
    }

    // Функция выполняется когда в селект-меню убираем больницу (она очищается из выбранных)
    function clearHospital() {
        try {
            selectedHospital.value = null;
            selectedHospitalName.value = null;
        } catch (err) {
            throw new Error(`compsables/loadHospitalList.ts: clearHospital  => ${err}`)
        }
    }

    // Выбор больницы в селект-меню
    function selectHospital(hospital: HospitalClient | null) {
        try {
            if(hospital === null) {
                selectedHospital.value = null;
                selectedHospitalName.value = null;
                return;
            }
            selectedHospital.value = hospital;
            selectedHospitalName.value = hospital.name;
        } catch (err) {
            throw new Error(`compsables/loadHospitalList.ts: loadHospitals => ${err}`);
        }
    }

    return {
        hospitalList,
        selectedHospital,
        selectedHospitalName,
        isLoadingHospitalsFetch,

        loadHospitals,
        clearHospital,
        selectHospital,
    }
}

export default useLoadingHospitals;