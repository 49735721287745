// Взаимодействие Управляющего больницей с Отделениями 
import { ArrayDepartmentClient, DepartmentClient } from '@/types/departmentType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import axios from 'axios';

// Создание нового отделения
async function createNewDepartmentDB__chief(name: string): Promise<DepartmentClient> {
    try {
        // Создание сущности и получение её с сервера
        const response = await axios.post(hostname + 'api/hospitals/departments', {
            name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/departmentsApi: createNewDepartmentDB__chief  => ${err}`);
    }
}

// Получение всех Отделений с сервера
async function getAllDepartmentsDB__chief(isDeleted?: boolean, page?: number): Promise<ArrayDepartmentClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + 'api/hospitals/departments', {
            params: {
                deleted: isDeleted,
                page,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/departmentsApi: getAllDepartmentsDB__chief  => ${err}`);
    }
}

// Получение Отделения по ID
async function getDepartmentById__chief(departmentId: number): Promise<DepartmentClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/hospitals/departments/${departmentId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/departmentsApi: getDepartmentById__chief  => ${err}`);
    }
}

// Получение Отделений которые принадлежат конкретной больнице (по ID больницы)
async function getDepartmentsMyHospital__chief(isDeleted?: boolean, page?: number): Promise<ArrayDepartmentClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/hospitals/departments/`, {
            params: {
                deleted: isDeleted,
                page,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/departmentsApi: getDepartmentsMyHospital__chief  => ${err}`);
    }
}

// Получение отделения по ID
async function getDepartmentByID__chief(departmentId: number): Promise<DepartmentClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/hospitals/departments/${departmentId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/departmentsApi: getDepartmentByID__chief  => ${err}`);
    }
}

// Изменить данные отделения по его ID
async function putDepartmentById__chief( name: string, departmentId: number): Promise<DepartmentClient> {
    try {
        // Обновление сущности и получение её с сервера
        const response = await axios.put(hostname + `api/hospitals/departments/${departmentId}`, {
            name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/departmentsApi: putDepartmentById__chief  => ${err}`);
    }
}

// Изменение активности отделения по ID
async function putStatusDepartmentById__chief(departmentId: number, deleted: boolean ): Promise<DepartmentClient> {
    try {
        // Обновление статуса сущности и получение её с сервера
        const response = await axios.put(hostname + `api/hospitals/departments/${departmentId}/is_active`, {
            deleted,
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/hospitalChief/departmentsApi: putStatusDepartmentById__chief  => ${err}`);
    }
}

export {
    createNewDepartmentDB__chief,
    getAllDepartmentsDB__chief,
    getDepartmentById__chief,
    getDepartmentsMyHospital__chief,
    getDepartmentByID__chief,
    putDepartmentById__chief,
    putStatusDepartmentById__chief,
}