// Харнилище для работы с системой авторизации
import { defineStore } from "pinia";
import { ref } from 'vue';

const useAuthStore = defineStore('authStore', () => {

    // ============================  STATE  =============================
    const isSuperUser = ref<boolean>(false);
    const isChief = ref<boolean>(false);


    // ============================  MUTATIONS  =========================
    function setAuthRole(state: boolean) {
        isSuperUser.value = state;
    }

    function setChiefRole(state: boolean) {
        isChief.value = state;
    }

    return {
        // STATE
        isSuperUser,
        isChief,

        // MUTATIONS
        setAuthRole,
        setChiefRole,
    }
});

export default useAuthStore;