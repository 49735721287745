<template>
    <v-dialog v-model="modelValue" width="auto">

        <v-card class="dialog__wrapper">
            
            <form class="dialog__form">
                <!-- Превью -->
                <div class="dialog__form--preview-block">
                    <input id="preview-image" v-show="false"/>
                    <label 
                    class="preview-image" 
                    for="preview-image"
                    >
                        <v-icon 
                        icon="mdi-table" 
                        color="var(--color-default)"
                        size="80px"
                        ></v-icon>
                    </label>
                    <h1 class="preview-name">Создание отделения</h1>
                </div>

                <!-- Общие данные -->
                <div class="dialog__form--general-data">
                    <!-- Имя -->
                    <v-card-subtitle class="mb-2">* Название отделения</v-card-subtitle>
                    <v-text-field
                    class="general-data__input"
                    bg-color="var(--bg-color-white)"
                    :rules="inputRules"
                    v-model="name"
                    :placeholder="'Укажите название'"
                    color="var(--color-default)"
                    rounded="xs"
                    :density="'compact'"
                    variant="outlined"
                    ></v-text-field>

                    <!-- Выбор Организаций -->
                    <hospitalListComp
                    v-if="!hospitalId && authStore.isSuperUser === true"
                    style="width: 100% !important; margin-top: 1.5rem;"
                    :label-compbox="'Организация'"
                    @clear-hospital="clearHospital"
                    @select-hospital="(hospital) => selectHospital(hospital)"
                    :selected-hospital-name="selectedHospitalName"
                    />
                </div>
            </form>
            <div class="dialog__actions">
                <v-btn 
                class="mr-6"
                @click="confirmData"
                variant="flat" 
                style="color: white; font-weight: normal; font-size: 12px;"
                :loading="isLoading"
                color="var(--bg-color-default)" 
                >
                    Сохранить
                </v-btn>
                <!-- Очистить форму -->
                <v-btn 
                variant="tonal"
                style="margin-right: auto; font-size: 12px;"
                color="var(--bg-color-default)"
                @click="resetForm"
                >
                    Очистить форму
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn 
                variant="outlined"
                color="red" 
                style="font-size: 12px;"
                @click="handlerClose">
                    Закрыть
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import hospitalListComp from './hospitalListComp.vue';
import { createNewDepartmentDB } from '../../api/superuser/departmentsApi';
import { createNewDepartmentDB__chief } from '../../api/hospitalChief/departmentsApi';
import useLoadingHospitals from '@/composable/loadHospitalList';
import useAuthStore from '../../store/authStore';
import { ref, defineModel, defineEmits, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { DepartmentClient } from '../../types/departmentType';

const route = useRoute();
const authStore = useAuthStore();
const modelValue = defineModel<boolean>();

// ===============================================  EMITS  ===================================================
const emit = defineEmits<{
    close: [],
    error: [msg: string],
    success: [];
    appendDepartment: [department: DepartmentClient],
}>();

// ===============================================  DATA  ===================================================
const isLoading = ref(false);
// const id = ref<number>(0);
const name = ref<string | null>(null);
const hospitalId = ref<number | null>(null);

const inputRules = ref([
    (value: string) => !!value || 'Обязательное поле',
]);
// Компонуемый файл для получения списка больниц и отображения их в выпадающем меню
const { 
    selectedHospital,
    selectedHospitalName,

    clearHospital,
    selectHospital, 
} = useLoadingHospitals();

// ===============================================  METHODS  ===================================================
// Подтверждение данных для создания нового отделения
async function confirmData() {
    try {
        isLoading.value = true;
        if(name.value && name.value.length > 0) {
            // Если мы перешли на список отделений выбрав больницу в списке больниц, то получаем берем ID больницы с query-параметра 
            if(hospitalId.value) {
                const createdDepartment = await createNewDepartmentDB(name.value, hospitalId.value);
                emit('appendDepartment', createdDepartment);
                emit('success');
            } else {
                // Если мы перешли на список отделений по навигационной панели то ID больницы получаем с селект-меню
                if(selectedHospital.value?.id) {
                    const createdDepartment = await createNewDepartmentDB(name.value, selectedHospital.value?.id);
                    emit('appendDepartment', createdDepartment);
                    emit('success');
                } 
                // Если пользователь выступает в роли управляющего больницей
                else if(authStore.isChief === true) {
                    const createdDepartment = await createNewDepartmentDB__chief(name.value);
                    emit('appendDepartment', createdDepartment);
                    emit('success');
                }
                else {
                    emit('error', 'Выберите больницу');
                }
            } 
        } else {
            // Поднимаем ошибку если поля ввода пусты
            emit('error', 'Укажите название');
        }
    } catch (err) {
        throw new Error(`components/departments/creationDepartmentsComp.vue: confirmData  => ${err}`);
    } finally {
        isLoading.value = false;
        clearForm();
    }
}

const resetForm = () => {
  name.value = null;
  hospitalId.value = null;
  selectedHospital.value = null;
  selectedHospitalName.value = null;
}

// Обработчик для сбрасывания формы создания
function clearForm() {
    selectedHospital.value = null;
    selectedHospitalName.value = null;
    name.value = null;
}

// Обработчик закрытия формы
function handlerClose() {
    emit('close');
    clearForm();
}

onMounted(() => {
    try {
        // Получение query параметра hospital
        const hospitalIdQuery = route.query['hospital'];
        if(hospitalIdQuery) {
            if(+hospitalIdQuery > 0) {
                hospitalId.value = +hospitalIdQuery;
            }
        } else {
            emit('close');
        }
    } catch (err) {
        throw new Error(`components/departments/creationDepartmentsComp.vue: onMounted[fetch query]  => ${err}`);

    }
});

</script>

<style scoped>
.dialog__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 900px;
    padding-bottom: 2rem;
    height: max-content;
    background-color: var(--bg-color-block);
}
.dialog__form {
    width: 100%;
    height: max-content;
    display: flex;
    padding-top: 3rem;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg-color-block);
}
.dialog__actions {
    display: flex;
    justify-content: flex-end;
    width: 80%;
    padding: 1.5rem;
    background-color: var(--bg-color-white);
    margin-bottom: 2rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dialog__form--preview-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: max-content;
    padding: 2rem 0;
    background-color: rgba(239, 246, 248, 1);
}
.preview-image {
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
}
.image {
    width: 100%;
    height: 100%;
}
.preview-name {
    font-size: 26px;
    font-weight: 500;
    margin-top: 1rem;
}
.dialog__form--general-data {
    width: 80%;
    padding: 2rem 2rem 0 2rem;
    background-color: var(--bg-color-white);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.general-data__input {
    margin-bottom: 1rem;
}
</style>