<template>
  <!-- Компонент для отрисовки Списка Сотрудников -->
  <div class="hospitals-list__container">

    <!-- уведомление об ошибке -->
    <error-note-comp>{{ errorMsg }}</error-note-comp>

    <!-- Уведомление об успехе -->
    <success-note-comp @complete="handlerCompleteNote"></success-note-comp>

    <!-- Заголовок -->
    <div class="hospitals-list__header">
      <v-icon
        @click="router.go(-1)"
        class="btn-back"
        icon="mdi-arrow-left"
        size="22"
      ></v-icon>
      <h1 class="hospitals-list__header--title">Больницы</h1>
    </div>

    <!-- Диалоговое окно с данными о выбранном элементе -->
    <hospitalsDialogComp
      v-model="isShowDialog"
      @close="handlerCloseDialog"
      @update-hospital="(e) => emit('updateHospital', e)"
      @success="handlerSuccess"
    />

    <!-- Диалоговое окно для создания новой больницы -->
    <creationHospitalComp
      v-model="isShowCreationDialog"
      @error="(e) => handlerError(e)"
      @success="handlerSuccess"
      @close="isShowCreationDialog = false"
      @append-hospital="(hospital: HospitalClient) => emit('appendHospital', hospital)"
    />

    <!-- Диалоговое окно для подтверждения удаления элемента -->
    <disableHospitalDialogComp
      v-model="isShowDisableDialog"
      @cancel="isShowDisableDialog = false"
      @confirm="confirmDisabledHospital"
      :isActive="isActive"
      :is-loading="isLoadingDisabled"
      :title="`${deletedHospitalData.name} `"
      :subtitle="isActive ? `неактивной` : 'активной'"
    />

    <!-- Диалоговое окно для подтверждения Активации элемента -->
    <activeHospitalDialogComp
      :hospital-name="activatedData.name"
      v-model="isShowActivateDialog"
      @confirm="confirmActiveHospital"
      @cancel="isShowActivateDialog = false"
      :is-loading="isLoadingActivated"
    />

    <!-- Панель действий -->
    <div class="hospitals-list__actions-block my-2">
      <v-btn
        style="color: var(--color-white); font-size: 0.8em; font-weight: bold;"
        color="var(--color-default)"
        rounded="large"
        size="large"
        @click="isShowCreationDialog = true"
      >
        Создать
      </v-btn>

      <!-- Фильтр панель -->
      <hospitalsFilterPanelComp
        :list-names="listNames"
        :list-address="listAddress"
        @select-name="(name: string| null) => filledFilterData(null, name, null)"
        @select-address="(address: string| null) => filledFilterData(address, null, null)"
        @select-status="(status: boolean) => filledFilterData(null, null, status)"
      />
    </div>

    <!-- Крутилка загрузки -->
    <span class="progress-circular" v-if="props.isLoadingData">
      <v-progress-circular
        indeterminate
        bg-color="var(--bg-color-white)"
        color="var(--bg-color-default)"
        :size="60"
        :width="6"
      ></v-progress-circular>
    </span>

    <!-- Контент список -->
    <div class="hospitals-list__content-container" v-else-if="!!props.hospitals.length || props.isNotHospitals">
      <!-- Баннер сообщающий что больниц нет -->
      <div class="is-not-items" v-show="props.isNotHospitals">
        <h1 class="is-not-items__title">
          {{ props.isNotHospitalsMessage }}
        </h1>
      </div>
      <!-- Отрисовка элеменитов списка -->
      <v-table class="hospitals-list__wrapper" :density="'comfortable'" fixed-header>
        <thead>
        <tr>
          <th class="text-left th avatar-hospital">
          </th>
          <th class="text-left th">
            Название
          </th>
          <th class="text-left th">
            Адрес
          </th>
          <th class="text-left th">
            Управляющий
          </th>
          <th class="text-left th">
            Статус
          </th>
          <th class="text-left th actions-th">
            Действия
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          class="row-hover"
          @click="() => routeToDepartments(hospital?.id)"
          v-for="(hospital) in props.hospitals as ArrayHospitalClient"
          :key="hospital.id">
          <!-- № Номер -->
          <td class="list-item">
            <v-icon icon="mdi-hospital-building" color="var(--color-default)"></v-icon>
          </td>
          <!-- Название -->
          <td class="list-item hospital-name">{{ hospital?.name }}</td>
          <!-- Адрес -->
          <td class="list-item">{{ hospital?.address }}</td>
          <!-- Управляющий -->
          <td class="list-item">{{ hospital?.chief?.fullName }}</td>
          <!-- Статус -->
          <td class="list-item">{{ (hospital.deleted) ? listStatus[1] : listStatus[0] }}</td>
          <!-- ДЕЙСТВИЯ -->
          <td class="list-item actions-td">

            <!-- Редактировать -->
            <v-btn
              class="actions__btn"
              @click.stop="selectHospital(hospital.id)"
              rounded="xs"
              icon="mdi-cog-outline"
              :color="'var(--color-gray)'"
              variant="text"
              :density="'comfortable'"
            >
            </v-btn>
            <!-- <v-icon icon="mdi-pen" :color="'var(--color-default)'"></v-icon> -->

            <!-- Деактивировать / Активировать -->
            <v-btn
              class="actions__btn del"
              @click.stop="handlerStatusHospital(hospital.deleted, hospital.id, hospital.name)"
              rounded="xs"
              variant="text"
              :density="'comfortable'"
              icon="mdi-power"
              :color="(hospital.deleted === null)? 'red' : 'green'"
            ></v-btn>
            <!-- <v-icon icon="mdi-delete" :color="'red'"></v-icon> -->
          </td>
        </tr>
        </tbody>
      </v-table>
    </div>

    <!-- Баннер сообщающий что Процедур нет -->
    <div class="is-list-empty" v-else-if="!props.hospitals.length || props.isNotHospitals === false">
      <h1 class="is-list-empty__title">
          Больницы не найдены
      </h1>
    </div>
    <!-- Крутилка загрузки -->
    <span class="progress-circular" v-else>
      <v-progress-circular
        indeterminate
        bg-color="var(--bg-color-white)"
        color="var(--bg-color-default)"
        :size="60"
        :width="6"
      ></v-progress-circular>
    </span>

  </div>
</template>

<script setup lang="ts">
// COMPONENTS
import hospitalsDialogComp from './hospitalsDialogComp.vue';
import creationHospitalComp from './creationHospitalComp.vue';
import disableHospitalDialogComp from './disableHospitalDialogComp.vue';
import activeHospitalDialogComp from './activeHospitalDialogComp.vue';
import hospitalsFilterPanelComp from './hospitalsFilterPanelComp.vue';
// STORE
import useMainStore from '../../store/mainStore';
// API
import { putStatusHospitalById } from '../../api/superuser/hospitalsApi';
// TYPES
import { HospitalClient, ArrayHospitalClient} from '../../types/hospitalType';
// VUE
import {ref, defineProps, onMounted, watch, defineEmits, computed} from 'vue';
import {useRoute, useRouter} from 'vue-router';

const router = useRouter();
const route = useRoute();
const store = useMainStore();


const props = defineProps<{
  hospitals: Array<any>;
  isActive: boolean;
  isLoadingData: boolean;
  isNotHospitals: boolean;
  isNotHospitalsMessage: string;
}>();

const emit = defineEmits<{
  updateHospital: [hospital: HospitalClient];
  disableHospital: [hospitalId: number];
  appendHospital: [hospital: HospitalClient];
  hospitalFilter: [filterData: { name: string | undefined, isActive: boolean }];
  activateHospital: [hospitalId: number];
}>();

// ================================   DATA   ======================================
const errorMsg = ref('');
const isShowDialog = ref<boolean>(false);
const isShowCreationDialog = ref<boolean>(false);
const isShowDisableDialog = ref<boolean>(false);
const isShowActivateDialog = ref<boolean>(false);
const isLoadingActivated = ref<boolean>(false);
const isLoadingDisabled = ref<boolean>(false);
const listStatus = ref<Array<string>>(['Активен', 'Не активен']);
const deletedHospitalData = ref({
    id: null as null | number,
    name: '',
});
const activatedData = ref({
    id: null as null | number,
    name: '',
});
const filterData = ref({
  name: undefined as string | undefined,
  isActive: true as boolean,
});


// ==================================   COMPUTED  ============================

// Вычисление списка Названий больниц для фильтр-панели
const listNames = computed<Array<string | undefined>>(() => {
  try {
    return props.hospitals.map((hospital: HospitalClient) => {
      if (hospital.name !== null && hospital.name !== undefined) {
        return hospital.name;
      }
    });
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsListComp: computed[listNames]  => ${err}`);
  }
});

// Вычисление списка адресов больниц пользователей для фильтр-панели
const listAddress = computed<Array<string | undefined>>(() => {
  try {
    const readyList = props.hospitals.map((hospital: HospitalClient) => {
      if (hospital.address !== null && hospital.address !== undefined) {
        return hospital.address;
      }
    });
    return readyList;
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsListComp: computed[listAddress]  => ${err}`);
  }
});

// ==============================   METHODS   ================================
// Заполняет объект фильтр данных
function filledFilterData(address: string | null, name: string | null, isActive: boolean | null) {
  try {
    if (name !== null) {
      if (name === '') {
        filterData.value.name = undefined;
      } else filterData.value.name = name;
    }
    if (isActive !== null) {
      filterData.value.isActive = isActive;
    }
    emit('hospitalFilter', filterData.value);
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsListComp: filledFilterData  => ${err}`);
  }
}

async function selectHospital(hospitalId: number) {
  isShowDialog.value = true;
  store.toPushWithQuery({name: 'select-hospital', value: hospitalId}, route, router);
}

// Функция-обработчик выполняется когда отображения уведомления успеха скрывается
function handlerCompleteNote() {
  if (isShowCreationDialog.value === true) {
    isShowCreationDialog.value = false;
    window.location.reload();
  }
  if (isShowDialog.value === true) {
    isShowDialog.value = false;
    isShowDialog.value = false;
  }
  if (isShowDisableDialog.value === true) {
    isShowDisableDialog.value = false;
    isLoadingDisabled.value = false;
    window.location.reload();
  }
  if(isShowActivateDialog.value === true) {
    isShowActivateDialog.value = false;
    isLoadingActivated.value = false;
    window.location.reload();
  }
}

// Активация уведомления об успехе  
function handlerSuccess() {
  store.activeSuccessOperation(1200);
}

// Активация уведомления об ошибке 
function handlerError(error: string) {
  errorMsg.value = error;
  store.activeErrorOperation(3500);
}

// Обработка закрытия окна просмотра элемента
function handlerCloseDialog() {
  isShowDialog.value = false;
  router.push({name: 'hospitals'});
}

// Обработчик открытия окна деактивации / активации больницы
function handlerStatusHospital(isDeleted: number | null, hospitalId: number, name: string | null) {
  try {
    // Если нажимаем на не активную больницу, то открывается окно активации её
    if(isDeleted !== null) {
      activatedData.value.id = hospitalId;
      if(name) activatedData.value.name = name;
      isShowActivateDialog.value = true;
    }
    // Если нажимаем на активную больницу, то открывается окно её деактивации 
    else if(isDeleted === null) {
      deletedHospitalData.value.id = hospitalId;
      if(name) deletedHospitalData.value.name = name;
      isShowDisableDialog.value = true;
    }
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsListComp: handlerDeleteEmployee  => ${err}`);
  }
}

// Подтверждение деактивации больницы
async function confirmDisabledHospital() {
  try {
    isLoadingDisabled.value = true;
    if (deletedHospitalData.value.id) {
      await putStatusHospitalById(deletedHospitalData.value.id, props.isActive);
      emit('disableHospital', deletedHospitalData.value.id);
      store.activeSuccessOperation(900);
    }
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsListComp: confirmDeleteEmployee  => ${err}`);
  } finally {
    isLoadingDisabled.value = false;
  }
}

// Подтверждение активации больницы
async function confirmActiveHospital() {
  try {
    isLoadingActivated.value = true;
    if (activatedData.value.id) {
      await putStatusHospitalById(activatedData.value.id, false);
      emit('activateHospital', activatedData.value.id);
      store.activeSuccessOperation(900);
    }
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsListComp.vue: confirmActiveHospital  => ${err}`);
  } finally {
    isLoadingActivated.value = false;
  }
}

// Обработчик перехода на маршрут отделений выбранной больницы
function routeToDepartments(hospitalId: number) {
  try {
    store.toPushWithQuery({name: 'hospital', value: hospitalId, namePath: 'departments'}, route, router);
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsListComp.vue: routeToDepartments  => ${err}`);
  }
}

// Удаляем query-параметр если диалоговое окно просмотра закрывается
watch(() => isShowDialog.value, (isShow) => {
  if (!isShow) {
    router.push({name: 'hospitals'});
  }
});

// ============   LIFECYCLE HOOKS   ============
onMounted(() => {
  // При монтировании списка больниц проверям существует ли query-параметр select-hospital
  try {
    if (route.query['select-hospital']) {
      const hospitalIdQuery = route.query['select-hospital']
      if (typeof hospitalIdQuery === 'string' && +hospitalIdQuery > 0) {
        // Если он существует то открывается окно просмотра этой больницы
        selectHospital(+hospitalIdQuery);
      }
    }
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsListComp.vue: onMounted  => ${err}`)
  }
});

</script>

<style scoped>
.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
}

.row-hover {
  transition: background-color 0.4s ease;
  cursor: pointer;
}

.row-hover:hover {
  background-color: rgba(128, 128, 128, 0.2);
  transition: background-color 0.4s ease;
}

.hospitals-list__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--bg-color-white);
  border-radius: var(--radius);
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.hospitals-list__header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  background-color: var(--bg-color-default);
  color: var(--color-white);
  border-bottom: var(--border-thin);
  z-index: 20;
}

.btn-back {
  position: absolute;
  left: 1rem;
  cursor: pointer;
}

.btn-back:hover {
  color: rgba(255, 255, 255, 0.519);
}

.hospitals-list__header--title {
  font-family: var(--font);
  font-size: 1.25rem;
  font-weight: bold;
}

.hospitals-list__actions-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
}

.hospitals-list__content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 93%;
  top: 0;
  max-height: 100%;
}

.is-not-items {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 90%;
  height: max-content;
  margin: auto;
  z-index: 99;
}

.is-not-items__title {
  font-size: 2rem;
  color: var(--color-default);
}

.hospitals-list__action-block {
  position: relative;
  margin: 2rem 0 1rem 0;
}

.avatar-hospital {
  border-left: 1px solid rgba(128, 128, 128, 0.316);
}

.th {
  backdrop-filter: blur(9px);
  font-weight: 100;
  border-top: 1px solid rgba(128, 128, 128, 0.316);
  font-size: 13px;
}

.list-item {
  font-size: 15px;
  font-weight: 400;
}

.actions-th {
  width: 10%;
}

.hospital-name {
  font-weight: 600;
}

.actions-td {
  display: flex;
  align-items: center;
}

.actions__btn.del {
  margin-left: 5px;
}

.hospitals-list__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 5px;
  padding-bottom: 6rem;
  z-index: 10;
}
</style>