<template>
  <div class="hospitals-filter-panel">
    <!-- Ввод названия больницы -->
    <v-combobox
      class="filter-input"
      v-model="selectedName"
      :items="listNames"
      clearable
      label="Название"
      variant="outlined"
      @update:model-value="(name: string | null) => selectName(name)"
      :loading="isLoadingFilteringName"
      :hide-details="true"
    ></v-combobox>

    <v-switch
      v-model="selectedStatus"
      class="ml-6"
      color="var(--bg-color-default)"
      label="Неактивные"
      @update:model-value="(status: boolean) => selectStatus(status)"
      :value="true"
      hide-details
    >
    </v-switch>
  </div>
</template>

<script setup lang="ts">
import {ref, defineProps, defineEmits, watch} from 'vue';
import useMainStore from '../../store/mainStore';

const store = useMainStore();

// ==========================   PROPS   ==================================
const props = defineProps<{
  listNames: Array<string | undefined>;
  listAddress: Array<string | undefined>;
}>();

// ==========================   EMIT   ==================================
const emit = defineEmits<{
  selectName: [name: string | null];
  selectAddress: [address: string | null];
  selectStatus: [status: boolean];
}>();

// ==========================   DATA   ==================================
const isLoadingFilteringName = ref<boolean>(false);
const selectedName = ref<string | null>(null);
const selectedStatus = ref<string>("Активен")
const listNames = ref<Array<string | undefined>>([]);
const listAddress = ref<Array<string | undefined>>([]);
const payload = store.LazyLoading(300);

// ==========================   METHODS   ==================================
// Функция для выбора Названия больницы в списке
function selectName(name: string | null) {
  try {
    isLoadingFilteringName.value = true;
    payload(name, (entries) => {
      let currentMessage: string | null = entries[entries.length - 1];
      if (currentMessage === null) currentMessage = '';
      isLoadingFilteringName.value = false;
      emit('selectName', currentMessage);
    });
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsFilterPanelComp: selectName  => ${err}`);
  }
}

// Функция для выбора Статуса больницы в списке
function selectStatus(status: boolean) {
  try {
    emit('selectStatus', !status);
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsFilterPanelComp: selectStatus  => ${err}`);
  }
}

// ==========================   WATCH   ==================================
// Отслеживание изменения приходящего массива с Названиями больниц для их отображения в списке
watch(() => props.listNames, (newList) => {
  if (newList.length) {
    listNames.value = props.listNames;
  }
});

// Отслеживание изменения приходящего массива с Адресами больниц для их отображения в списке
watch(() => props.listAddress, (newList) => {
  if (newList.length) {
    listAddress.value = props.listAddress;
  }
});

// ==========================   LIFECYCLE HOOKS   ==================================


</script>

<style>
.hospitals-filter-panel {
  min-width: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1.5rem;
}

.filter-input {
  width: 250px !important;

  .v-input__control {
    .v-field {
      .v-field__field {
        height: 44px;
      }
    }
  }

}
</style>