// Взаимодействие суперпользователя с Процедурами 
import { ArrayProcedureClient, ProcedureClient } from '@/types/procedureType';
import { convertKeysToCamelCase } from '../../utils/index';
import { hostname } from '../indexApi';
import useMainStore from '../../store/mainStore';
import axios from 'axios';

// Получение всех процедур с сервера
async function getAllProceduresDB(
        page?: number, 
        isActive?: boolean, 
        startedFrom?: number,
        startedTo?: number,
        endedFrom?: number,
        endedTo?: number,
        userId?: number,
        hospitalId?: number,
        departmentId?: number,
        hospitalRoomId?: number,
        hospitalBedId?: number,
        idropperId?: number,
    
    ): Promise<ArrayProcedureClient> {
    try {
        const store = useMainStore();
        // Получение данных с сервера
        const response = await axios.get(hostname + 'api/cp/procedures', {
            params: {
                page: page, 
                is_active: isActive, 
                started_from: startedFrom,
                started_to: startedTo,
                ended_from: endedFrom,
                ended_to: endedTo,
                user_id: userId,
                hospital_id: hospitalId,
                department_id: departmentId,
                hospital_room_id: hospitalRoomId,
                hospital_bed_id: hospitalBedId,
                idropper_id: idropperId,
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        store.isProceduresPaginationHasNext = response.data?.paginator?.has_next;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/proceduresApi: getAllProceduresDB  => ${err}`);
    }
}

// Получение процедуры по ID
async function getProcedureByID(procedureId: number): Promise<ProcedureClient> {
    try {
        // Получение данных с сервера
        const response = await axios.get(hostname + `api/cp/hospitals/departments/rooms/procedures/${procedureId}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/www-form-urlencoded',
            },
        });
        const { data: { data } } = response;
        // Форматировение ключей объекта с snake_case в CamelCase
        return convertKeysToCamelCase(data);
    } catch (err) {
        console.log(err);
        throw new Error(`api/superuser/proceduresApi: getProcedureByID  => ${err}`);
    }
}

export {
    getAllProceduresDB,
    getProcedureByID,
}